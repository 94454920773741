<template>
  <div class="ml-4">
    <a
      class="pl-4"
      v-if="item.display"
      v-for="item in tabList"
      :key="item.url"
      :ripple="true"
      @click="currentTab = item.url"
      style="background-color: transparent; width: auto"
      :elevation="0"
    >
      <span
        style="font-size: 15px"
        class="pa-0"
        :class="{
          'active-tab': currentTab == item.url,
          'un-active-tab': currentTab != item.url,
        }"
        >{{ item.title }}</span
      >
    </a>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "AppBarResonanceContrast",
  props: {},
  components: {},
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          title: "模型试用",
          url: 0,
          display: true,
        },
        // {
        //   title: "多模态GPT",
        //   url: 1,
        //   display: true,
        // },
        // {
        //   title: "AI金融助手",
        //   url: 2,
        //   display: true,
        // },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {
    currentTab(val) {
      Bus.$emit("AiTabChange", val);
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
}
.un-active-tab {
  color: #7e7e7e;
}
</style>
