<template>
  <div style="width: 100%">
    <div
      :class="
        isDashBoard ? ['comment-container'] : ['comment-container-dashboard']
      "
    >
      <div style="width: 100%" class="pb-0 mb-0">
        <div style="width: 100%">
          <vxe-textarea
            v-model="content"
            placeholder="请填写您的评论"
            :autosize="{ minRows: 1, maxRows: 3 }"
          ></vxe-textarea>
          <vxe-button
            style="width: 60px"
            @click="sendComment()"
            status="my-primary"
            size="mini"
            >评论</vxe-button
          >
        </div>
        <p style="font-size: 16px" class="mt-4">全部评论</p>
      </div>

      <div v-if="comments.length == 0" style="font-size: 13px; color: #aaaaaa">
        暂无评论
      </div>

      <div v-else v-for="comment in comments">
        <div class="">
          <div class="d-flex align-center">
            <v-avatar size="37" rounded>
              <v-img
                src="@/assets/img/user/stock.png"
                alt="profile-image"
              ></v-img>
            </v-avatar>
            <div class="mx-4">
              <small class="d-block text-muted" style="letter-spacing: 2px">
                <!-- <span>{{ comment.user_name }}</span> -->
                <span>{{ comment.created_at }}</span>
              </small>
              <small href="javascript:;" class="d-block text-muted">
                {{ comment.user_name }}
              </small>
            </div>
          </div>
          <div class="mt-1">
            <div style="font-size: 14px">
              <div
                style="
                  white-space: pre-wrap;
                  word-break: break-all;
                  width: 100%;
                "
                class="pb-0 mb-0"
              >
                {{ comment.content }}
              </div>

              <div>
                <a
                  href="javascript:;"
                  @click="parentId = comment.id"
                  class="text-dark"
                  style="font-size: 12px; text-decoration: none"
                >
                  <i class="fa fa-reply" aria-hidden="true"></i>
                  <span class="ml-1">回复</span>
                </a>
                <a
                  href="javascript:;"
                  @click="deleteComment(comment.id)"
                  class="text-dark ml-2"
                  style="font-size: 12px; text-decoration: none"
                  v-if="comment.user_id == userStore.user_id"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span class="ml-1">删除</span>
                </a>
              </div>

              <div
                class="d-flex align-items-center"
                style="width: 100%"
                v-if="parentId == comment.id"
              >
                <vxe-textarea
                  v-model="replyContent"
                  placeholder="请填写您的评论"
                  :autosize="{ minRows: 1, maxRows: 1 }"
                >
                </vxe-textarea>

                <vxe-button
                  style="width: 60px; margin-left: 10px"
                  @click="replyComment(comment.id)"
                  status="my-primary"
                  size="mini"
                  >评论</vxe-button
                >
              </div>
            </div>

            <div style="margin-top: 10px" v-if="comment.children.length > 0">
              <div
                style="background-color: #eee; padding: 10px; font-size: 12px"
              >
                <ReplyComment
                  :comments="comment.children"
                  :parentUserName="comment.user_name"
                  :noteId="noteId"
                  :parentId="comment.id"
                ></ReplyComment>
              </div>
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-3" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Note from "@/api/note.js";

import Bus from "@/components/Bus/bus.js";
import ReplyComment from "@/components/Note/ReplyComment";

export default Vue.extend({
  name: "NoteComment",
  components: { ReplyComment },
  props: {
    isDashBoard: false,
    noteId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comments: [],
      content: "",
      replyContent: "",
      parentId: "",
    };
  },
  mounted() {
    this.getComment();
    Bus.$on("refreshComment", (e) => {
      this.getComment();
    });
  },
  methods: {
    getComment() {
      Note.getNoteContent(this.noteId)
        .then((res) => {
          this.comments = res.data.comments;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        });
    },
    sendComment() {
      if (this.content == "") {
        this.showToast("评论不能为空", "danger", 2000);
        return;
      }
      this.showLoading("发送评论中，请稍候");
      Note.comment(this.noteId, "", this.content)
        .then((res) => {
          this.content = "";
          Bus.$emit("refreshComment", res.data);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    replyComment(parentId) {
      this.showLoading("发送评论中，请稍候");
      //console.log(parentId);
      Note.comment(this.noteId, parentId, this.replyContent)
        .then((res) => {
          this.replyContent = "";
          this.showToast("评论成功！", "success", 2000);
          Bus.$emit("refreshComment", res.data);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteComment(id) {
      this.$layer.confirm(
        "确定要删除这个评论吗？",
        {
          shade: true,
          shadeClose: false,
        },
        (layerid) => {
          this.showLoading("删除评论中，请稍候");
          Note.deleteComment(id)
            .then((res) => {
              this.showToast("删除成功！", "success", 2000);
              Bus.$emit("refreshComment", res.data);
            })
            .catch((err) => {
              console.log(err.msg);
              this.showToast(err.msg, "danger", 10000);
            })
            .finally(() => {
              this.hideLoading();
            });
          this.$layer.close(layerid);
        }
      );
    },
  },
  watch: {
    noteId(val) {
      if (val) {
        this.getComment();
      }
    },
  },
  computed: {},
});
</script>
<style>
.comment-container {
  width: calc(100% - 30px);
  margin: 20px auto 50px auto;
  background-color: #fff;
  padding: 20px 50px 50px 50px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
}
.comment-container-dashboard {
  width: calc(100% - 30px);
  margin: 20px auto 50px auto;
  background-color: #fff;
  padding: 10px 50px 0px 50px;
  border-top: 1px solid #e8e8e8;
}
</style>
