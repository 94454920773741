var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"mx-3",attrs:{"custom":"","refresh":{ query: _vm.getStockRecommendList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("推荐列表")]),_c('vxe-input',{staticClass:"mx-1",attrs:{"type":"search","placeholder":"搜索股票或推荐人"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('vxe-button',{staticClass:"mx-1",attrs:{"size":"medium","content":"新增推荐","status":"my-primary"},on:{"click":function($event){return _vm.recommendAdd()}}}),_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"size":"medium","content":"导出","status":"my-primary"},on:{"click":function($event){return _vm.exportTable()}}}),_c('UserFilter',{staticClass:"mr-1",attrs:{"colName":"user_name","placeholder":"推荐人","table":_vm.$refs.recommendStockTable,"inputWidth":"100px"}})]},proxy:true}])}),_c('div',{style:({ height: _vm.tableHight + 'px' })},[_c('vxe-table',{ref:"recommendStockTable",staticClass:"mytable-scrollbar",attrs:{"id":"recommendStockTable","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.tableLoading,"column-config":{ resizable: true },"height":"100%","size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 100 },"empty-render":{ name: 'NotData' },"export-config":{},"filter-config":{ showIcon: false }}},[_c('vxe-column',{attrs:{"fixed":"left","width":"70","field":"stock_code","title":"股票代码"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_code ? row.stock_code : "-"))])])]}}])}),_c('vxe-column',{attrs:{"fixed":"left","width":"80","field":"stock_name","title":"股票名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_name ? row.stock_name : "-"))])])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"user_name","title":"推荐人","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'user_name'); }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var $panel = ref.$panel;
var column = ref.column;
return [_vm._l((column.filters),function(option,index){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.data),expression:"option.data"}],key:index,staticClass:"pa-2",attrs:{"type":"type","placeholder":"按回车确认筛选"},domProps:{"value":(option.data)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(option, "data", $event.target.value)},function($event){return $panel.changeOption($event, !!option.data, option)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $panel.confirmFilter()}}})]})]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.user_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"created_at","title":"推荐时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.created_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"end_date","title":"结束时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.end_date)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"90","field":"rise_per","title":"预期空间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s((row.rise_per * 100).toFixed(0) + "%"))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_focus","title":"推荐以来涨幅","sortable":"","width":"120"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_focus))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_focus)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_max","title":"最大涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_max))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_max)))])])]}}])}),_c('vxe-column',{attrs:{"field":"retreat_max","title":"最大回撤","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.retreat_max))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.retreat_max)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_day","title":"日涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_day))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_day)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_five","title":"5日涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_five))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_five)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_ten","title":"10日涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_ten))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_ten)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_20","title":"20日涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_20))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_20)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_60","title":"60日涨幅","sortable":"","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_60))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_60)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_120","title":"120日涨幅","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_120))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_120)))])])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"sw_level2_name","title":"行业"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.sw_level2_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"field":"benchmark","title":"基准(行业)","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.benchmark))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.benchmark)))])])]}}])}),_c('vxe-column',{attrs:{"field":"excessreturn","title":"超额收益","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.excessreturn))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.excessreturn)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"150","field":"reason","title":"推荐理由"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.reason)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"status_name","title":"推荐状态"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.status_name)+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"100","resizable":false,"show-overflow":"","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.$commonHelpers.getPermissions('stockRecommend.manage'))?_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.recommendEdit(row)}}},[_vm._v(" 修改 ")]),(row.status === 0)?_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.endRecommend(row.id)}}},[_vm._v(" 结束 ")]):_vm._e()]):_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.recommendEdit(row)}}},[_vm._v(" 修改 ")]),(row.status === 0)?_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.endRecommend(row.id)}}},[_vm._v(" 结束 ")]):_vm._e(),_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteRecommend(row.id)}}},[_vm._v(" 删除 ")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }