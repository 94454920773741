<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-if="!tableLoading">
      <div>
        <v-card class="border-radius-md pa-3 mb-3">
          <h3 class="text-dark" style="opacity: 0.8">金股组合表现情况</h3>
          <h4 class="text-dark mt-2" style="opacity: 0.8">风险指标</h4>
          <div class="mt-1">
            <a
              class="pr-4"
              v-if="item.display"
              v-for="item in tabList"
              :key="item.url"
              :ripple="true"
              @click="currentTab = item.url"
              style="background-color: transparent; width: auto"
              :elevation="0"
            >
              <span
                class="pa-0"
                :class="{
                  'active-tab': currentTab == item.url,
                  'un-active-tab': currentTab != item.url,
                }"
                >{{ item.title }}</span
              >
            </a>
          </div>

          <v-tabs-items v-model="currentTab" class="pa-2">
            <v-tab-item :key="0">
              <GoldStockTable :list="brokersList"> </GoldStockTable>
              <h3 class="text-dark mt-2" style="opacity: 0.8">券商金股组合</h3>
              <h4 class="text-dark mt-2" style="opacity: 0.8">
                券商金股组合 - 近一季度收益前五
              </h4>

              <GoldStockChart
                chartId="brokers_top5_chart"
                :chartData="chartData['brokers_top5']"
                :dateArr="chartData['trade_dates']"
              ></GoldStockChart>

              <h4 class="text-dark mt-2" style="opacity: 0.8">
                券商金股组合 - 近一季度收益后五
              </h4>

              <GoldStockChart
                chartId="brokers_low5_chart"
                :chartData="chartData['brokers_low5']"
                :dateArr="chartData['trade_dates']"
              ></GoldStockChart>
            </v-tab-item>

            <v-tab-item :key="1">
              <GoldStockTable :list="industryList"> </GoldStockTable>
              <h3 class="text-dark mt-2" style="opacity: 0.8">行业金股组合</h3>

              <h4 class="text-dark mt-2" style="opacity: 0.8">
                行业金股组合- 近一季度收益前五
              </h4>

              <GoldStockChart
                chartId="industry_top5_chart"
                :chartData="chartData['industry_top5']"
                :dateArr="chartData['trade_dates']"
              ></GoldStockChart>

              <h4 class="text-dark mt-2" style="opacity: 0.8">
                行业金股组合- 近一季度收益后五
              </h4>

              <GoldStockChart
                chartId="industry_low5_chart"
                :chartData="chartData['industry_low5']"
                :dateArr="chartData['trade_dates']"
              ></GoldStockChart>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <span style="color: #ccc; font-size: 12px">
          更新日期：{{ chartData.updated_at }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from '@/api/resonanceContrast.js';
import GoldStockTable from './GoldStockTable.vue';
import GoldStockChart from './GoldStockChart.vue';
export default {
  name: 'GoldStock',
  components: { GoldStockTable, GoldStockChart },
  data() {
    return {
      tableLoading: false,

      currentTab: 0,
      tabList: [
        {
          title: '券商',
          url: 0,
          display: true,
        },
        {
          title: '行业',
          url: 1,
          display: true,
        },
      ],

      brokersList: [],
      industryList: [],

      chartData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      ResonanceContrast.getGoldStockInfo()
        .then((res) => {
          //console.log(res.data);
          this.brokersList = res.data['brokers'];
          this.industryList = res.data['industry'];

          this.chartData = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, 'danger', 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
