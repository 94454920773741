<template>
  <div>
    <vxe-toolbar class="mx-5" custom :refresh="{ query: refreshData }"
      >>
      <template v-slot:buttons> </template>
    </vxe-toolbar>
    <vxe-table
      id="applyTable"
      ref="applyTable"
      :data="applyList"
      :border="false"
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      align="center"
      :loading="applyTableLoading"
      :column-config="{ resizable: true }"
      :height="tableHeight"
      size="small"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
      :empty-render="{ name: 'NotData' }"
      :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
    >
      <vxe-column field="stock.code" title="股票代码" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.stock.code }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="stock.name" title="股票名称" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.stock.name }}
          </div>
        </template>
      </vxe-column>

      <vxe-column field="date" title="申请日期" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.date }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="apply_type_name" title="方向" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.apply_type_name }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="reason" title="申请理由" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.reason }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="user.base" title="申请人" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.user ? row.user.base : "" }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="status" title="状态" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ getStatus(row.status) }}
          </div>
        </template>
      </vxe-column>

      <vxe-column title="修正" type="seq" show-overflow>
        <template #default="{ row }">
          <a
            href="javascript:;"
            @click="cancel(row.id)"
            class="text-xs font-weight-normal text-typo mr-3"
            v-if="row.user_id == userStore.user_id"
            >撤销</a
          >

          <a
            href="javascript:;"
            @click="pass(row.id)"
            class="text-xs font-weight-normal text-typo mr-3"
            >通过</a
          >
          <a
            href="javascript:;"
            @click="refuse(row.id)"
            class="text-xs font-weight-normal text-typo mr-3"
            >拒绝</a
          >
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-pager
      :current-page="pageInfo.currentPage"
      :page-size.sync="pageInfo.pageSize"
      :total="pageInfo.totalResult"
      :layouts="[
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Sizes',
        'Total',
      ]"
      @page-change="handlePageChange1"
    >
    </vxe-pager>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import StockPool from "@/api/stockPool.js";
import XEUtils from "xe-utils";

export default {
  name: "StockApply",
  props: {},
  data() {
    return {
      applyList: [],
      applyTableLoading: false,
      tableHeight: "500px",

      filterName: "",
      tableData: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0,
      },
    };
  },
  components: {},
  mounted() {
    this.tableHeight = document.body.offsetHeight - 180 + "px";
    this.getApplyList();

    Bus.$on("refreshApplyStockPool", (e) => {
      this.getApplyList();
    });
  },
  computed: {},
  methods: {
    getApplyList() {
      this.applyTableLoading = true;
      StockPool.getApplyList({
        page: this.pageInfo.currentPage,
        perPage: this.pageInfo.pageSize,
        pool_type: 1,
      })
        .then((res) => {
          this.applyList = res.data.data;
          this.pageInfo.totalResult = res.data.total;
          this.currentPage = res.data.current_page;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.applyTableLoading = false;
        });
    },

    cancel(id) {
      this.showLoading("操作中，请稍候");
      StockPool.cancelApply(id)
        .then((res) => {
          this.showToast("撤销成功", "success", 5000);
          Bus.$emit("refreshCoreStockPool", true);
          this.getApplyList();
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    pass(id) {
      this.showLoading("操作中，请稍候");
      StockPool.passApply(id)
        .then((res) => {
          this.showToast("已通过申请", "success", 5000);
          Bus.$emit("refreshCoreStockPool", true);
          Bus.$emit("refreshCoreStockPoolOut", true);
          //this.getApplyList();
          this.applyList = this.applyList.filter((item) => item.id != id);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
          this.getApplyList();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    refuse(id) {
      this.showLoading("操作中，请稍候");
      StockPool.refuseApply(id)
        .then((res) => {
          this.showToast("已拒绝申请", "success", 5000);
          //Bus.$emit('refreshCoreStockPool', true);
          Bus.$emit("refreshCoreStockPoolOut", true);
          this.getApplyList();
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getStatus(status) {
      //console.log(status);
      if (status == 0) {
        return "待审核";
      } else if (status == 1) {
        return "已通过";
      } else if (status == 2) {
        return "已拒绝";
      }
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.pageInfo.currentPage = currentPage;
      this.pageInfo.pageSize = pageSize;
      this.getApplyList();
    },
    refreshData() {
      this.pageInfo.currentPage = 1;
      this.getApplyList();
    },
  },
};
</script>
