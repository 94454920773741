<template>
  <input
    type="file"
    ref="kimiFileInput"
    style="display: none"
    @change="kimiUploadFile"
  />
</template>
<script>
import Vue from "vue";

export default {
  name: "kimiFile",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      kimiKey: "sk-ug0SLz8t17vvEpUY3gXhWq4XOlVZofHxYL9BcORepjzuKNXF",
      uploadedFile: null,
      fileContent: null,
    };
  },
  methods: {},
  computed: {},
  methods: {
    async kimiUploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.isUploading = true;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("purpose", "file-extract");

      try {
        const response = await this.$axios.post(
          "https://api.moonshot.cn/v1/files",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.kimiKey}`,
            },
          }
        );

        this.uploadedFile = response.data;
        await this.getFileContent(this.uploadedFile.id);
        await this.deleteFile(this.uploadedFile.id);
        this.$emit("returnFileContent", this.fileContent);
      } catch (error) {
        console.error("File upload failed:", error);
        alert("文件上传失败");
      } finally {
        this.isUploading = false;
      }
    },
    async getFileContent(fileId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`https://api.moonshot.cn/v1/files/${fileId}/content`, {
            headers: {
              Authorization: `Bearer ${this.kimiKey}`,
            },
          })
          .then((response) => {
            this.fileContent = response.data.content;
            console.log("File content get success");
            resolve();
          })
          .catch((error) => {
            console.error("Failed to get file content:", error);
            alert("获取文件内容失败");
            reject();
          });
      });
    },
    async deleteFile(fileId) {
      try {
        await this.$axios.delete(`https://api.moonshot.cn/v1/files/${fileId}`, {
          headers: {
            Authorization: `Bearer ${this.kimiKey}`,
          },
        });
        console.log("File deleted successfully");
      } catch (error) {
        console.error("Failed to delete file:", error);
        alert("删除文件失败，但文件内容已成功获取");
      }
    },
    triggerFileUpload() {
      this.$refs.kimiFileInput.click();
    },
  },
};
</script>
<style scoped="scoped"></style>
