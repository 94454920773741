<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-show="!tableLoading">
      <v-card class="border-radius-md pa-3 mb-3">
        <input
          style="display: none"
          ref="uploadInput"
          type="file"
          @change="startUpload"
        />
        <vxe-button
          class="mt-1"
          @click="clickUpload()"
          status="my-primary"
          size="small"
          style="border-radius: 5px"
          >上传数据</vxe-button
        >
        <vxe-button
          class="mt-1"
          @click="download()"
          status="my-primary"
          size="small"
          style="border-radius: 5px"
          >下载模板</vxe-button
        >
        <a ref="muban" style="display: none" href="/波动率模板.xlsx"
          >下载模板</a
        >
        <vxe-table
          :data="list"
          style="width: 100%"
          class="mytable-scrollbar mt-2"
          :border="false"
          resizable
          :scroll-y="{ enabled: false }"
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          max-height="100000"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="left"
        >
          <vxe-column fixed="left" min-width="80" field="code" title="股票代码">
            <template #default="{ row }">
              <span v-if="row.pool_id">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.pool_id)"
                  class="text-xs text-dark"
                  style="font-weight: bolder"
                  >{{ row.code ? row.code : "-" }}
                </a>
              </span>
              <span v-else>
                <span>
                  {{ row.code ? row.code : "" }}
                </span>
              </span>
            </template>
          </vxe-column>

          <vxe-column fixed="left" min-width="80" field="name" title="股票名称">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span v-if="row.pool_id">
                  <a
                    href="javascript:;"
                    @click="$stockHelpers.openStockDetail(row.pool_id)"
                    class="text-xs text-dark"
                    style="font-weight: bolder"
                    >{{ row.name ? row.name : "-" }}
                  </a>
                </span>
                <span v-else>
                  <span>
                    {{ row.name ? row.name : "" }}
                  </span>
                </span>
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="220" field="races" title="赛道">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div class="d-flex" style="flex-wrap: wrap">
                  <span
                    v-for="(race, index) in row.races"
                    :key="race.id"
                    class="px-1 py-0"
                    style="
                      cursor: pointer;
                      font-size: 10px;
                      color: #fff;
                      border-radius: 3px;
                      margin-bottom: 1px;
                      margin-right: 1px;
                    "
                    :style="{
                      backgroundColor:
                        race.color == `#e8f5e9` ? `#00b294` : race.color,
                    }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </span>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="100" field="vols" title="波动率" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.vols ? row.vols : "-" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="100" field="sw_level2" title="申万二级">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.sw_level2 ? row.sw_level2 : "-" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="150"
            field="updated_at"
            title="修改时间"
            sortable
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.updated_at ? row.updated_at : "-" }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import VolatilitySurfaces from "@/api/volatilitySurfaces.js";
export default {
  name: "Hotspot",
  components: {},
  data() {
    return {
      tableLoading: false,
      list: [],
      uploadInputValue: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      VolatilitySurfaces.getHistory()
        .then((res) => {
          console.log(res.data);
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    clickUpload() {
      this.$refs.uploadInput.click();
    },
    startUpload() {
      this.showLoading("上传中，请稍候！");
      let file = this.$refs.uploadInput.files[0];
      VolatilitySurfaces.uploadHistory(file)
        .then((res) => {
          console.log(res.data);
          this.showToast("上传成功", "success", 10000);
          this.getList();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
          this.$refs.uploadInput.value = "";
        });
    },
    download() {
      this.$refs.muban.click();
    },
  },
};
</script>
