<template>
  <v-container fluid class="px-1 py-0">
  	<v-card class=" border-radius-xl mt-3 pb-2">
  		
  		<v-tabs-items v-model="currentTab">
  			<v-tab-item
  				:key="0"
  			>
  				<StockPool></StockPool>
  			</v-tab-item>
  			<v-tab-item
  				:key="1"
  			>
  				<StockPoolOut></StockPoolOut>
  			</v-tab-item>
  			<v-tab-item
  				:key="2"
  			>
  				<StockPoolOtc></StockPoolOtc>
  			</v-tab-item>
  			<v-tab-item
  				:key="3"
  			>
  				<StockPoolOtcOut></StockPoolOtcOut>
  			</v-tab-item>
  			<v-tab-item
  				:key="4"
  			>
  				<StockApply></StockApply>
  			</v-tab-item>
  		</v-tabs-items>
  		
  	</v-card>
  	
    
  	
  	
  	<!-- <v-bottom-navigation v-model="currentTab" 
  		background-color="bg-gradient-default" width="400px"
  		style="position: absolute;left: calc( 50% - 200px );bottom: 5px;opacity: 0.9;border-radius: 10px;" 
  		class="blur shadow"
  		dark
  	>
  		
  		<v-btn><span>股票池</span></v-btn>
  	
  		<v-btn><span>OTC池</span></v-btn>
  	
  		<v-btn><span>流程</span></v-btn>
  	</v-bottom-navigation> -->
  	
  </v-container>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import StockPool from "@/components/StockPool/StockPool.vue";
import StockPoolOut from "@/components/StockPool/StockPoolOut.vue";
import StockPoolOtc from "@/components/StockPool/StockPoolOtc.vue";
import StockPoolOtcOut from "@/components/StockPool/StockPoolOtcOut.vue";
import StockApply from "@/components/StockPool/StockApply.vue";

export default {
  name: "Dashboard",
  components: {
    StockPool,
		StockPoolOut,
		StockPoolOtc,
		StockPoolOtcOut,
		StockApply,
  },
  data() {
  	return {
  		currentTab:0,
  	}
  },
  mounted() {
		Bus.$on('poolTabChange', e => {
			this.currentTab=e;
		});
  },
	watch: {
		// currentTab(newValue) {
		// 	console.log(newValue);
		// }
		
	},
};
</script>
