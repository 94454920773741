import { render, staticRenderFns } from "./TagReview.vue?vue&type=template&id=3701f62a&scoped=true"
import script from "./TagReview.vue?vue&type=script&lang=js"
export * from "./TagReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3701f62a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VAvatar,VCard,VImg,VLazy})
