<template>
  <div>
    <div class="mt-2" v-if="rateList.length == 0">
      <v-skeleton-loader
        v-for="item in 20"
        v-bind="attrs"
        type="article, actions"
      >
      </v-skeleton-loader>
    </div>

    <v-card
      class="border-radius-md mt-2"
      v-for="(item, index) in rateList"
      :key="index"
      style="margin-top: 10px"
    >
      <div class="border-bottom d-flex align-center px-4 py-1">
        <div class="d-flex align-center">
          <v-avatar size="37" rounded>
            <v-img
              src="@/assets/img/user/stock.png"
              alt="profile-image"
            ></v-img>
          </v-avatar>

          <div class="mx-4">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <a
                href="javascript:;"
                class="text-dark"
                @click="$stockHelpers.openStockDetail(item.stock_pool_id)"
                style="width: 80px; font-size: 12px"
              >
                {{ item.name ? item.name : "" }}
              </a>

              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  width: calc(100% - 80px);
                  opacity: 0.8;
                "
              >
                <span
                  v-for="(race, index) in item.races"
                  v-if="race.level !== 0"
                  :key="race.id"
                  style="
                    cursor: pointer;
                    font-size: 10px;
                    color: #fff;
                    border-radius: 3px;
                    margin-bottom: 1px;
                    margin-right: 1px;
                    padding: 3px;
                  "
                  :style="{
                    backgroundColor:
                      race.color == `#e8f5e9` ? `#00b294` : race.color,
                  }"
                  @click="$stockHelpers.openTagDetail(race.id)"
                >
                  {{ race.name }}
                </span>
              </div>
            </div>

            <small class="d-block text-muted" style="letter-spacing: 2px">
              <!-- <span>
                {{ item.rates[0].update_name }}
              </span> -->
              {{ item.rates[0][0].created_at }}
            </small>
          </div>
        </div>
        <div class="text-end ms-auto">
          <vxe-button
            @click="$stockHelpers.openRate(item.stock_id, item.name, this)"
            size="mini"
            status="my-primary"
            content="打分详情"
          ></vxe-button>
        </div>
      </div>
      <div class="px-5 py-2">
        <div
          v-for="(item1, index1) in item.rates"
          :key="index1"
          class="pt-1"
          style="font-size: 14px"
        >
          <div
            v-for="(item2, index2) in item1"
            :key="index2"
            class="pt-1"
            style="font-size: 14px"
          >
            <v-chip
              dense
              small
              dark
              color="#84a5b8"
              class="px-2 py-0 mb-1"
              style="height: 16px; margin-right: 1px; opacity: 0.8"
            >
              {{ item2.update_name }}
            </v-chip>
            <b class="mr-2 text-dark" style="font-size: 15px"
              >{{ item2.factor[0].name }}:</b
            >
            <span
              class="mr-1 pa-1"
              :class="[getScoreColor(item2)]"
              style="text-align: center"
            >
              <span>{{ item2.last_score ? item2.last_score : 0 }}</span>
              →
              <span>{{ item2.score }}</span>
            </span>

            <ShowMoreText :content="getContent(item2)"> </ShowMoreText>

            <div
              style="display: flex; width: 100%; flex-wrap: wrap"
              v-if="item2.attachments.length > 0"
            >
              <div
                v-for="(attachmentItem, index) in item2.attachments"
                style="
                  margin-right: 20px;
                  margin-top: 10px;
                  width: 100px;
                  height: 100px;
                "
              >
                <v-img
                  :src="attachmentItem.url"
                  :lazy-src="attachmentItem.url"
                  @click="$commonHelpers.previewFile(attachmentItem.id)"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  style="width: 100px; height: 100px"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>

            <hr class="horizontal dark my-1" />
          </div>
        </div>
      </div>
    </v-card>
    <vxe-pager
      background
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :total="totalResult"
      :layouts="[
        'PrevJump',
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'NextJump',
        'FullJump',
        'Total',
      ]"
      @page-change="handlePageChange"
    >
    </vxe-pager>
  </div>
</template>
<script>
import Rate from "@/api/rate.js";
import ShowMoreText from "@/components/ShowMoreText";
import Bus from "@/components/Bus/bus.js";

export default {
  name: "StockRate",
  props: {},
  components: {
    ShowMoreText,
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      rateList: [],
      currentPage: 1,
      pageSize: 20,
      totalResult: 0,
    };
  },
  mounted() {
    this.getRateHistory();
  },

  methods: {
    handlePageChange({ type, currentPage, pageSize, $event }) {
      this.currentPage = currentPage;
      //this.pageSize = pageSize;
      this.getRateHistory();
    },
    getRateHistory() {
      this.rateList = [];
      scrollTo(0, 0);
      Rate.getRateExplore(this.currentPage, this.pageSize)
        .then((res) => {
          this.rateList = res.data;
          this.totalResult = res.total;

          //console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getScoreColor(item) {
      if (parseInt(item.score) > parseInt(item.last_score)) {
        return "text-red lighten-1 font-weight-bolder";
      } else if (parseInt(item.score) < parseInt(item.last_score)) {
        return "text-teal lighten-1 font-weight-bolder";
      } else {
        return "text-dark lighten-1 font-weight-bolder";
      }
    },
    getContent(item1) {
      return '<b class="text-dark" style="font-size: 15px;">评分理由</b>:' +
        item1.reason
        ? item1.reason
        : "无";
    },
  },
};
</script>
<style scoped="scoped"></style>
