<template>
  <div class="px-0 py-1">
    <v-card class="border-radius-md pa-3 mb-3">
      <lemon-imui
        ref="IMUI"
        :user="user"
        simple
        width="100%"
        :style="{ height: pageHeight + 'px' }"
        theme="blue"
        @pull-messages="handlePullMessages"
        @message-click="handleMessageClick"
        @send="handleSend"
      >
        <template #cover>
          <div class="chatCover">
            <span
              ><b style="text-decoration: none">New Bing</b> For Knight</span
            >
          </div>
        </template>
      </lemon-imui>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import lemonMessageMd from '@/components/Ai/lemonMessageMd';
Vue.component(lemonMessageMd.name, lemonMessageMd);

const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);
import { v4 as uuidv4 } from 'uuid';
import Ai from '@/api/ai.js';
export default {
  name: 'Chat',
  components: {},
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      user: {
        id: '1',
        displayName: 'Knight',
        avatar: require('@/assets/img/user/user.png'),
      },
      conversationSignature: null,
      conversationId: null,
      clientId: null,
      invocationId: null,
      conversationCounter: 0,
    };
  },
  mounted() {
    const { IMUI } = this.$refs;
    IMUI.initEditorTools([]);
    IMUI.initContacts([
      {
        id: '3',
        displayName: 'New Bing',
        avatar: require('@/assets/img/logos/bing.jpg'),
        type: 'single',
        index: 'T',
        unread: 0,
        lastSendTime: getTime(),
        lastContent: '你好123',
      },
    ]);

    setTimeout(() => {
      IMUI.changeContact('3');
    }, 1000);
    setInterval(() => {
      this.clearConversation();
    }, 6000000);
  },

  methods: {
    clearConversation() {
      this.conversationSignature = null;
      this.conversationId = null;
      this.clientId = null;
      this.invocationId = null;
      this.conversationCounter = 0;
    },
    sendMsg(message) {
      //console.log(this.conversationId);
      Ai.ask({
        type: 'bing',
        message: message.content,
        conversationSignature: this.conversationSignature,
        conversationId: this.conversationId,
        clientId: this.clientId,
        invocationId: this.invocationId,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.hasOwnProperty('response')) {
            this.conversationCounter++;
            if (this.conversationCounter > 4) {
              const data = {
                id: uuidv4(),
                status: 'succeed',
                type: 'text',
                sendTime: getTime(),
                content: '由于微软限制，当前回话已有5轮，将清空上下文记忆！',
                toContactId: '3',
                fromUser: {
                  //如果 id == this.user.id消息会显示在右侧，否则在左侧
                  id: '3',
                  displayName: 'New Bing',
                  avatar: require('@/assets/img/logos/bing.jpg'),
                },
              };
              this.$refs.IMUI.appendMessage(data);
              this.$refs.IMUI.messageViewToBottom();
              this.clearConversation();
            } else {
              this.conversationSignature =
                res.data.response.conversationSignature;
              this.conversationId = res.data.response.conversationId;
              this.clientId = res.data.response.clientId;
              this.invocationId = res.data.response.invocationId;
            }

            this.$refs.IMUI.updateMessage({
              id: message.id,
              status: 'succeed',
            });
            const data = {
              id: uuidv4(),
              status: 'succeed',
              type: 'md',
              sendTime: getTime(),
              content: res.data.response.response,
              fullContent: res.data.response,
              toContactId: '3',
              fromUser: {
                //如果 id == this.user.id消息会显示在右侧，否则在左侧
                id: '3',
                displayName: 'New Bing',
                avatar: require('@/assets/img/logos/bing.jpg'),
              },
            };
            this.$refs.IMUI.appendMessage(data);
            this.$refs.IMUI.messageViewToBottom();
          } else {
            this.clearConversation();
            const data = {
              id: uuidv4(),
              status: 'succeed',
              type: 'text',
              sendTime: getTime(),
              content: '抱歉，服务器出错了，请稍后再试！',
              toContactId: '3',
              fromUser: {
                //如果 id == this.user.id消息会显示在右侧，否则在左侧
                id: '3',
                displayName: 'New Bing',
                avatar: require('@/assets/img/logos/bing.jpg'),
              },
            };
            this.$refs.IMUI.appendMessage(data);
            this.$refs.IMUI.messageViewToBottom();
          }
        })
        .catch((err) => {
          this.clearConversation();
          this.$refs.IMUI.updateMessage({
            id: message.id,
            status: 'failed',
          });
          const data = {
            id: uuidv4(),
            status: 'succeed',
            type: 'text',
            sendTime: getTime(),
            content: '抱歉，服务器出错了，请稍后再试！',
            toContactId: '3',
            fromUser: {
              //如果 id == this.user.id消息会显示在右侧，否则在左侧
              id: '3',
              displayName: 'New Bing',
              avatar: require('@/assets/img/logos/bing.jpg'),
            },
          };
          this.$refs.IMUI.appendMessage(data);
          this.$refs.IMUI.messageViewToBottom();
        });
    },
    handleMessageClick(e, key, message) {
      if (key == 'status' && message.status === 'failed') {
        this.$refs.IMUI.updateMessage({
          id: message.id,
          status: 'going',
        });
        this.sendMsg(message);
      }
    },
    handleSend(message, next, file) {
      //console.log(message, next, file);
      this.sendMsg(message);
    },
    handlePullMessages(contact, next) {
      const { currentContactId } = this.$refs.IMUI;
      const otherUser = {
        id: contact.id,
        avatar: contact.avatar,
        displayName: contact.displayName,
      };
      const message = (content, fromUser = this.user) => {
        return {
          id: uuidv4(),
          status: 'succeed',
          type: 'text',
          sendTime: getTime(),
          content,
          toContactId: currentContactId,
          fromUser,
        };
      };

      const messages = [
        message(
          '你好我是青骊实验室的聊天AI，基于New Bing。您可以问我任何问题！\n请注意，目前本功能仅供试用，消息发送失败时请点击重新发送。',
          otherUser
        ),
        message(
          '发送内容请不要高于1000字。长文本的回复速度较慢，可能在1到2分钟！回复的消息如果不完整时，请发送“继续”，系统会回复您剩余的消息！',
          otherUser
        ),
        // message('来'),
      ];
      next(messages, true);
    },
  },
};
</script>
<style>
.chatCover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}
.lemon-editor__tool {
  height: 10px;
  border-top: 1px solid #ddd;
}
.v-md-hljs-js {
  padding: 0px !important;
}
</style>
