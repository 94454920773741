<template>
  <div style="width: 100%; display: flex; justify-content: center">
    <!-- <div style="position: absolute; left: 200px">
      <span style="font-size: 12px" class="mr-1">图例:</span>
      <span
        style="border-radius: 5px; font-size: 12px; padding: 1px 5px 1px 5px"
        class="blue-grey lighten-5 mr-1"
        >行业风格</span
      >
      <span
        style="border-radius: 5px; font-size: 12px; padding: 1px 5px 1px 5px"
        class="red lighten-5 mr-1"
        >核心</span
      >
      <span
        style="border-radius: 5px; font-size: 12px; padding: 1px 5px 1px 5px"
        class="orange lighten-5 mr-1"
        >一级主题</span
      >
      <span
        style="border-radius: 5px; font-size: 12px; padding: 1px 5px 1px 5px"
        class="green lighten-5 mr-1"
        >普通主题</span
      >
    </div> -->

    <div style="position: absolute; left: 10px; z-index: 888">
      <span
        style="
          border-radius: 5px;
          font-size: 12px;
          padding: 1px 5px 1px 5px;
          cursor: pointer;
        "
        class="blue lighten-1 mr-1 text-white"
        @click="switchOpen(false)"
        >全收</span
      >
      <br />
      <span
        style="
          border-radius: 5px;
          font-size: 12px;
          padding: 1px 5px 1px 5px;
          cursor: pointer;
        "
        class="blue lighten-1 mr-1 text-white"
        @click="switchOpen(true)"
        >全开</span
      >
    </div>

    <div style="min-height: 100% !important; width: 350px">
      <v-jstree
        :data="treeData"
        multiple
        allow-batch
        whole-row
        @item-click="itemClick"
        @item-drag-end="itemDragEnd"
        v-if="treeData.length != ''"
        ref="tree"
        oncontextmenu="javascript:void(0)"
      >
        <template slot-scope="_">
          <div
            style="
              display: inherit;
              border-radius: 5px;
              font-size: 12px;
              height: 22px;
            "
            :class="getTagClass(_.model)"
            class="pr-2"
          >
            <!-- <i
              :class="_.vm.themeIconClasses"
              role="presentation"
              v-if="!_.model.loading"
            ></i> -->
            {{ _.model.text }}
            <!-- <a class="ml-2 text-dark" @click="moveTag">
              <svg
                t="1665457657919"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2595"
                width="18"
                height="18"
                style="cursor: pointer; padding-top: 5px"
              >
                <path
                  d="M486.4 776.533333v-213.333333H247.466667v106.666667L85.333333 512l162.133334-162.133333V512h238.933333V247.466667H349.866667L512 85.333333l162.133333 162.133334h-132.266666V512h238.933333V349.866667L938.666667 512l-162.133334 162.133333v-106.666666h-238.933333v213.333333h132.266667L512 938.666667l-162.133333-162.133334h136.533333z"
                  p-id="2596"
                ></path>
              </svg>
            </a> -->
          </div>
        </template>
      </v-jstree>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";
import VJstree from "vue-jstree";

export default {
  name: "StockAdd",
  components: {
    VJstree,
  },
  props: {
    searchKeyword: {
      type: String,
      default: "",
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.getTreeData();
    Bus.$on("refreshTagList", (e) => {
      this.getTreeData();
    });
  },
  data() {
    return {
      treeData: [],
      openStatus: false,
    };
  },
  methods: {
    submit() {},
    cancel() {
      this.$layer.close(this.layerid);
    },
    getTreeData() {
      this.treeData = [];
      //this.showLoading('获取标签中，请稍候！');

      Tag.getTagTree()
        .then((res) => {
          let treeData = res.data;

          let getTree = (node) => {
            node.text = node.name;
            node.value = node.name;
            node.opened = this.openStatus;
            if (node.is_show == 1) {
              node.icon = "fa fa-eye text-info";
            } else {
              node.icon = "fa fa-eye-slash text-default";
            }

            if (node.children.length != 0) {
              node.children.forEach((item) => {
                getTree(item);
              });
            }
          };
          treeData.forEach((item) => {
            getTree(item);
          });
          this.treeData = [
            {
              id: "race",
              text: "所有标签",
              opened: true,
              children: treeData,
            },
          ];
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          //this.hideLoading();
        });
    },
    itemClick(node, item, e) {
      // if (node.model.id != 'race' && node.model.id != 'style') {
      //   if (node.model.is_show == 1) {
      //     node.model.is_show = 0;
      //     node.model.icon = 'fa fa-eye-slash text-default';
      //   } else {
      //     node.model.is_show = 1;
      //     node.model.icon = 'fa fa-eye text-info';
      //   }
      // }
      // this.saveTree();
      //console.log(node.model);
      this.$emit("tagClick", { row: node.model });
    },
    itemDragEnd(node) {
      //console.log(node.model);
      this.saveTree();
    },
    saveTree() {
      Tag.editTagTree(this.treeData[0].children)
        .then((res) => {
          Bus.$emit("refreshTagList", true);
          this.showToast("保存成功", "success", 10000);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getTagClass(tag) {
      if (tag.level) {
        if (tag.level === 1) {
          return "red lighten-5";
        } else if (tag.level === 2) {
          return "orange lighten-5";
        } else if (tag.level === 3) {
          return "green lighten-5";
        }
      } else {
        if (tag.id != "race" && tag.id != "style") {
          return "blue-grey lighten-5";
        } else {
          return "";
        }
      }
    },
    switchOpen(status) {
      this.openStatus = status;
      //this.getTreeData();
      let getTree = (node) => {
        node.opened = status;
        if (node.children.length != 0) {
          node.children.forEach((item) => {
            getTree(item);
          });
        }
      };
      this.treeData[0].children.forEach((item) => {
        getTree(item);
      });
    },
    moveTag(e) {
      e.stopPropagation();
    },
  },
  computed: {},
  watch: {
    searchKeyword(val) {
      //console.log(val);
      const patt = new RegExp(val.toLowerCase());
      this.$refs.tree.handleRecursionNodeChilds(
        this.$refs.tree,
        function (node) {
          if (val !== "" && node.model !== undefined) {
            const str = node.model.text;
            const pinyin = node.model.pinyin;
            if (patt.test(str) || patt.test(pinyin)) {
              node.$el.querySelector(".tree-anchor").style.color = "red";
              node.$el.querySelector(".tree-anchor").style.fontWeight = "1000";
            } else {
              node.$el.querySelector(".tree-anchor").style.color = "#000";
              node.$el.querySelector(".tree-anchor").style.fontWeight = "500";
            }
          } else {
            node.$el.querySelector(".tree-anchor").style.color = "#000";
            node.$el.querySelector(".tree-anchor").style.fontWeight = "500";
          }
        }
      );
    },
  },
};
</script>
<style scoped="scoped"></style>
