<template>
  <div class="px-0 py-1"></div>
</template>

<script>
import Quant from "@/api/quant";
import Bus from "@/components/Bus/bus.js";
export default {
  name: "Settings",
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
