<template>
  <div>
    <!-- <vxe-button type="primary" @click="fullPageCaptures">打印</vxe-button> -->
    <div ref="tagChart" id="tagChart">
      <div
        style="
          padding: 5px;
          background-color: #bf0000;
          color: #fff;
          font-size: 25px;
          text-align: center;
          border-bottom: 1px solid #fff;
        "
      >
        <vxe-button
          @click="fullPageCaptures"
          size="medium"
          style="
            width: 80px;
            padding: 0px;
            position: absolute;
            left: 5px;
            background-color: #bf0000;
            border-color: #fff;
            color: #fff;
          "
        >
          打印
        </vxe-button>
        {{ appStore.tagDetail.name + "估值表" }}
        <div style="position: absolute; right: 5px; font-size: 13px; top: 13px">
          更新时间：{{
            appStore.tagDetail.updated_at
              ? appStore.tagDetail.updated_at.split(" ")[0]
              : ""
          }}
        </div>
      </div>
      <ShowMoreContent
        v-for="baseInfo in appStore.tagDetail.base_info"
        :content_name="baseInfo.name"
        :content_show="baseInfo.content_show"
        :content_hide="baseInfo.content_hide"
      ></ShowMoreContent>
      <ShowBindStock :currTag="appStore.tagDetail" ref="ShowBindStock">
      </ShowBindStock>
    </div>
  </div>
</template>

<script>
import ShowMoreContent from "@/components/TagStore/ShowMoreContent";
import ShowBindStock from "@/components/TagStore/showBindStock";
import ScreenShot from "js-web-screen-shot";
// import "@/util/html2canvas.js";
let html2canvas = require("@/util/html2canvas.js");
import logo from "@/assets/logo.png";
export default {
  name: "PrintTag",
  components: { ShowMoreContent, ShowBindStock },
  data() {
    return {
      output: "",
      dpr: window.devicePixelRatio || 1,
      containerHeight: 0,
      containerWidth: 0,
      logo: logo,
      imaData2: "",
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.containerHeight = this.$refs.tagChart.offsetHeight;
    //   this.containerWidth = this.$refs.tagChart.offsetWidth;
    //   this.fullPageCapture();
    // }, 3000);
    this.$refs.ShowBindStock.handleData(this.appStore.tagDetail);
  },
  methods: {
    async getScreenStream() {
      let mediaWidth = window.outerWidth * this.dpr;
      let mediaHeight = window.outerHeight * this.dpr;
      try {
        return await navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: {
            width: mediaWidth,
            height: mediaHeight,
            displaySurface: "window",
          },
        });
      } catch (err) {
        console.error("Error: " + err);
        return null;
      }
    },
    scrollToNextPart(height, delay = 1000) {
      return new Promise((resolve) => {
        window.scrollBy(0, height);
        setTimeout(resolve, delay); // 等待页面稳定后再截图
      });
    },
    async captureScreen(video, width, height) {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      console.log(width, height);
      ctx.drawImage(video, 0, 0, width, height);
      //
      const startX = (window.outerWidth - window.innerWidth) / 2;
      //浏览器高度-可视区域高度
      const startY = window.outerHeight - window.innerHeight - 15;
      const cutWidth =
        window.innerWidth - (window.outerWidth - window.innerWidth);
      const cutHeight = height - startY - 4;
      console.log(startX, startY, cutWidth, cutHeight);
      // 获取裁剪框区域图片信息;
      let finalImg = ctx.getImageData(
        startX * this.dpr,
        startY * this.dpr,
        cutWidth * this.dpr,
        cutHeight * this.dpr
      );
      // 创建一个新的canvas元素
      let newCanvas = document.createElement("canvas");
      newCanvas.width = finalImg.width;
      newCanvas.height = finalImg.height;
      let newCtx = newCanvas.getContext("2d");
      newCtx.putImageData(finalImg, 0, 0);
      let finalDataURL = newCanvas.toDataURL();

      // 下载图片
      // let link = document.createElement("a");
      // link.href = finalDataURL;
      // //link.href = canvas.toDataURL("image/png");
      // link.download = "fullPage.png";
      // link.click();

      return finalDataURL;
    },
    async captureAndScroll(video, totalHeight, viewportHeight) {
      let images = [];
      let scrollHeight = 0;

      while (scrollHeight < totalHeight) {
        let imageData = this.captureScreen(
          video,
          window.innerWidth,
          viewportHeight
        );
        images.push(imageData);
        await this.scrollToNextPart(viewportHeight);
        scrollHeight += viewportHeight;
      }
      return images;
    },
    mergeImages(images, width, totalHeight) {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = totalHeight;

      let ctx = canvas.getContext("2d");
      let currentY = 0;

      images.forEach((imgData) => {
        let img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, currentY, img.width, img.height);
          currentY += img.height;
        };
        img.src = imgData;
      });

      return canvas.toDataURL("image/png");
    },
    /**
     * 生成图片
     */
    async fullPageCaptures() {
      let that = this;
      this.showLoading("下载中，请稍候！");
      let overMask = document.querySelector("#maskOver");
      let cloneEle = overMask.cloneNode(true);
      cloneEle.className = "cloneMask";
      cloneEle.style.width =
        document.querySelector(".vxe-table--body").offsetWidth + "px";
      // cloneEle.style.display = "none";
      document.body.appendChild(cloneEle);
      const canvas = await this.hanledDownload2(cloneEle, null);
      var imgData = canvas.toDataURL("image/jpeg");
      this.fileDownload(imgData);
    },
    /**
     * 生成图片
     */
    hanledDownload2(ref, color) {
      console.log(ref);
      let that = this;
      try {
        return html2canvas(ref, {
          scale: 1,
          backgroudColor: color,
        }).then((canvas) => {
          console.log(canvas);
          return canvas;
        });
      } catch (e) {
        //TODO handle the exception
        this.hideLoading();
        return null;
      }
    },
    /**
     * 下载文件到本地
     */
    fileDownload(downloadUrl) {
      let aLink = document.createElement("a");
      let cloneEle = document.querySelector(".cloneMask");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      aLink.download = "img.png";
      // 触发点击-然后移除
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
      document.body.removeChild(cloneEle);
      this.hideLoading();
    },
    async fullPageCapture() {
      let stream = await this.getScreenStream();
      if (!stream) {
        console.error("无法获取屏幕流");
        return;
      }
      let video = document.createElement("video");
      video.srcObject = stream;
      video.play();
      // 等待视频流准备就绪
      await new Promise((resolve) => (video.onloadedmetadata = resolve));
      //再等1秒
      await new Promise((resolve) => setTimeout(resolve, 1000));

      let viewportHeight = window.innerHeight;
      let totalHeight = document.body.scrollHeight;
      let images = await this.captureAndScroll(
        video,
        totalHeight,
        viewportHeight
      );
      console.log(images);

      let mergedImage = await this.mergeImages(
        images,
        window.innerWidth,
        totalHeight
      );
      //下载图片
      let link = document.createElement("a");
      link.href = mergedImage;
      link.download = "fullPage.png";
      link.click();

      stream.getTracks().forEach((track) => track.stop());
    },
    async print() {
      try {
        // 获取屏幕分享权限
        const mediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          displaySurface: "browser",
        });

        // 创建视频元素并设置视频流
        const video = document.createElement("video");
        video.srcObject = mediaStream;
        video.play();

        // 等待视频流准备就绪
        await new Promise((resolve) => (video.onloadedmetadata = resolve));

        // 创建 canvas 并绘制当前视频帧
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // 获取截图
        const screenshot = canvas.toDataURL("image/png");

        // 停止视频流
        mediaStream.getTracks().forEach((track) => track.stop());

        //下载图片
        const a = document.createElement("a");
        a.className = "printWid";
        a.href = screenshot;
        a.download = "screenshot.png";
        a.click();
      } catch (error) {
        console.error("Error capturing screen:", error);
      }
    },
  },
};
</script>
<style>
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>
