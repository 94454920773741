<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          v-model="stock_id"
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :disableArr="[]"
        ></StockSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*调研日期</label
        >
        <vxe-input
          v-model="research_date"
          placeholder="您准备去调研的日期"
          type="date"
          class="my-2"
          style="width: 100%"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*申请说明</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="申请说明"
          class="font-size-input border text-light-input border-radius-sm my-2"
          auto-grow
          rows="3"
          v-model="comment"
        ></v-textarea>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*申请附件</label
        >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
          "
        >
          <p class="pr-2">{{ fileName }}</p>
          <vxe-button class="my-2" status="default" @click="startSelectFile"
            >上传</vxe-button
          >
        </div>

        <v-file-input
          id="AddResearchAttachmentSelect"
          v-model="fileSelect"
          prepend-icon=""
          style="display: none"
        >
        </v-file-input>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Bus from '@/components/Bus/bus.js';
import Research from '@/api/research.js';
import StockSelect from '@/components/CommonSelect/StockSelect.vue';

const OSS = require('ali-oss');
import File from '@/api/file.js';
export default {
  name: 'AddResearch',
  components: { StockSelect },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: '',
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {
      fileSelect: null,
      fileName: '请点击上传按钮开始选择文件',

      stock_id: '',
      research_date: '',
      comment: '',
      file_id: '',
    };
  },
  methods: {
    startSelectFile() {
      document.getElementById('AddResearchAttachmentSelect').click();
    },
    uploadWorker(fileItem) {
      this.showLoading('上传中，请稍候');
      File.getUploadSts()
        .then((res) => {
          let info = res.data;
          let client = new OSS({
            region: 'oss-cn-shanghai',
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: 'qinglitouyan',
          });

          let fileName = 'fileStore/' + info.uuid + '/' + fileItem.name;
          this.fileName = fileItem.name + ' (正在上传) ';

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf('uploadId') != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                '/fileStore/' + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  this.showToast('上传成功！', 'success', 5000);
                  this.file_id = info.uuid;
                  this.fileName = this.fileName.replace(
                    '(正在上传)',
                    '(已上传成功)'
                  );
                  //处理附件json
                })
                .catch((err) => {
                  console.log('err:', err);
                })
                .finally(() => {
                  this.hideLoading();
                });
            })
            .catch((err) => {
              this.showToast('上传失败！', 'danger', 5000);
              console.log('err:', err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.hideLoading();
          this.showToast('上传失败，获取上传前置信息失败！', 'danger', 5000);
        });
    },
    submit() {
      if (!this.stock_id) {
        this.showToast('请先选择申请的股票', 'warning', 5000);
        return;
      }
      if (!this.research_date) {
        this.showToast('请先选择调研的时间', 'warning', 5000);
        return;
      }
      if (!this.comment) {
        this.showToast('请先填写申请说明', 'warning', 5000);
        return;
      }
      if (!this.file_id) {
        this.showToast('请先上传申请的附件', 'warning', 5000);
        return;
      }
      this.showLoading('保存中，请稍候');
      Research.addResearch(
        this.stock_id,
        this.research_date,
        this.comment,
        this.file_id
      )
        .then((res) => {
          this.showToast('保存成功', 'success', 5000);
          Bus.$emit('refreshResearchList', true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {
    fileSelect(value) {
      if (value) {
        this.uploadWorker(value);
      }
    },
  },
};
</script>
<style scoped="scoped"></style>
