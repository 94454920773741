//调研
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.user_name }}
        </span>
        <b> | </b>
        <span class="font-weight-bold text-dark" style="opacity: 0.5"
          >骊阅调研
        </span>
      </div>
    </v-row>
    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
    >
      {{ str.data.abstract }}
    </v-list-item-title>
    <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      <div>
        <b>调研纪要：</b>
        <span v-if="!str.data.research_file_id">暂无</span>
        <span v-if="str.data.research_file_id">
          <a
            href="javascript:;"
            @click="$commonHelpers.previewFile(str.data.research_file_id)"
            class="text-xs font-weight-normal text-typo mr-1"
          >
            预览
          </a>
          <a
            href="javascript:;"
            @click="$commonHelpers.downloadFile(str.data.research_file_id)"
            class="text-xs font-weight-normal text-typo mr-1"
          >
            下载
          </a>
        </span>
      </div>
    </v-list-item-subtitle>
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
};
</script>
