<template>
  <v-app class="bg-white pa-0">
    <div class="pa-0">
      <AppBarQuant background="bg-transparent" has-bg></AppBarQuant>
      <router-view :key="$route.path" />
    </div>
  </v-app>
</template>
<script>
import $ from "jquery";
import AppBarQuant from "@/components/AppBarQuant";

export default {
  components: { AppBarQuant },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped></style>
