<template>
  <div>
    <h3 class="text-dark" style="opacity: 0.8">动量成分明细</h3>

    <vxe-table
      :data="momPageData"
      style="width: 100%; margin-top: 10px"
      class="mytable-scrollbar"
      :border="false"
      resizable
      :scroll-y="{ enabled: false }"
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="momDetailTable"
      max-height="100000"
      size="small"
      :empty-render="{ name: 'NotData' }"
      align="left"
    >
      <vxe-column
        fixed="left"
        min-width="80"
        field="block_name"
        title="标签名称"
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.block_name ? row.block_name : '-' }}
          </div>
        </template>
      </vxe-column>

      <vxe-column fixed="left" min-width="80" field="sec_name" title="股票名称">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.sec_name ? row.sec_name : '-' }}
          </div>
        </template>
      </vxe-column>

      <vxe-column fixed="left" min-width="80" field="code" title="股票代码">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.code ? row.code : '-' }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        title="总市值(万股)"
        field="total_mkt_cap"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.total_mkt_cap }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="换手率" field="turnover" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.turnover }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="1日收益率" field="MOM_1D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_1D)">{{
              $stockHelpers.getPercent(row.MOM_1D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="3日收益率" field="MOM_3D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_3D)">{{
              $stockHelpers.getPercent(row.MOM_3D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="5日收益率" field="MOM_5D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_5D)">{{
              $stockHelpers.getPercent(row.MOM_5D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="10日收益率" field="MOM_10D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_10D)">{{
              $stockHelpers.getPercent(row.MOM_10D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="20日收益率" field="MOM_20D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_20D)">{{
              $stockHelpers.getPercent(row.MOM_20D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="60日收益率" field="MOM_60D" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_60D)">{{
              $stockHelpers.getPercent(row.MOM_60D)
            }}</span>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      :current-page="pageInfo.currentPage"
      :page-size="10"
      :total="this.momentumComposition.length"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
      @sort-change="sortChangeEvent"
      @page-change="
        (value) =>
          getPage(
            value,
            this.momentumComposition,
            [$refs['momDetailTable']],
            pageInfo
          )
      "
    >
    </vxe-pager>
  </div>
</template>

<script>
import ResonanceContrast from '@/api/resonanceContrast.js';
export default {
  name: 'MomDetailTable',
  components: {},
  props: {
    momentumComposition: [],
    currentBarTab: 0,
  },
  data() {
    return {
      momPageData: [],
      MOM_type: 'MOM_1D',
      MOM_typeArr: [
        'MOM_1D',
        'MOM_3D',
        'MOM_5D',
        'MOM_10D',
        'MOM_20D',
        'MOM_60D',
      ],
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        totalResult: this.momentumComposition.length,
      },
      sortInfo: {
        field: 'MOM_1D',
        order: 'desc',
      },
    };
  },
  mounted() {},
  methods: {
    getPage(pager, tableData, table, pageObj) {
      pageObj.currentPage = pager.currentPage;
      this.momPageData = this.$tableHelpers.getPageWithSort(
        pager,
        tableData,
        table,
        this.sortInfo
      );
    },
    sortChangeEvent({ sortList }) {
      //console.log(sortList);
      this.sortInfo = sortList[0];

      this.momPageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        this.momentumComposition,
        [this.$refs.momDetailTable],
        this.sortInfo
      );
    },
  },
  watch: {
    currentBarTab: function (val) {
      this.MOM_type = this.MOM_typeArr[val];

      this.$refs.momDetailTable.sort(this.MOM_type, 'desc');
      this.sortInfo = {
        field: this.MOM_type,
        order: 'desc',
      };
      this.momPageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        this.momentumComposition,
        [this.$refs.momDetailTable],
        this.sortInfo
      );
    },
    momentumComposition: function (val) {
      //console.log(val);
      if (val.length > 0) {
        this.momPageData = this.$tableHelpers.getPageWithSort(
          this.pageInfo,
          this.momentumComposition,
          [this.$refs.momDetailTable],
          this.sortInfo
        );
        this.$refs.momDetailTable.sort(this.MOM_type, 'desc');
      }
    },
  },
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
