var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-pulldown',{ref:"groupEdit",attrs:{"destroy-on-close":"","transfer":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{staticClass:"mx-1 px-1",attrs:{"size":"medium","content":"分组设定","status":"my-primary"},on:{"click":function($event){return _vm.$refs.groupEdit.togglePanel()}}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('div',{staticClass:"roles-dropdown",staticStyle:{"border":"1px solid #ccc","border-radius":"5px"}},[_c('div',{staticStyle:{"padding":"5px","display":"flex","justify-content":"space-between"}},[_c('p',{staticStyle:{"padding-top":"5px","color":"#ff0000"}},[_vm._v("修改后请及时保存")]),(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-button',{attrs:{"status":"my-danger","size":"small","content":"保存"},on:{"click":function($event){return _vm.submit()}}}):_vm._e()],1),_c('vxe-table',{ref:"orderTable",attrs:{"auto-resize":"","data":_vm.appStore.tagDetail.tags,"checkbox-config":{ range: true, checkField: 'show' },"height":"300px","edit-config":{
          trigger: 'click',
          mode: 'cell',
          icon: ' ',
          activeMethod: _vm.activeCellMethod,
        }}},[_c('vxe-column',{attrs:{"field":"name","title":"字段名","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(row.name)+"("+_vm._s(row.order)+")")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","field":"cagr","title":"分组描述","edit-render":{ name: 'input' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(row.info ? row.info : "点击修改")+" ")])]}},{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"placeholder":"请输入描述"},on:{"blur":function($event){return _vm.editRow(row)}},model:{value:(row.info),callback:function ($$v) {_vm.$set(row, "info", $$v)},expression:"row.info"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"70","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-right":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveToUp(row.order)}}},[_vm._v("上移")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveToDown(row.order)}}},[_vm._v("下移")])])]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }