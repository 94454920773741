var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"mx-5",attrs:{"custom":"","refresh":{ query: _vm.getStockList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{attrs:{"type":"search","placeholder":"全文搜索"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('vxe-button',{attrs:{"size":"medium","content":"调入新股票","status":"my-primary"},on:{"click":function($event){return _vm.stockAdd()}}})]},proxy:true}])},[_vm._v("> ")]),_c('vxe-table',{ref:"coreTable",attrs:{"id":"coreTable","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.coreTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight,"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 50, oSize: 30 },"empty-render":{ name: 'NotData' },"custom-config":{ storage: true, checkMethod: _vm.checkColumnMethod },"tooltip-config":{ showAll: false, enterable: true, theme: 'dark' }}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"100","field":"stock_code","title":"股票代码"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.id)}}},[_vm._v(_vm._s(row.stock_code ? row.stock_code : '-'))])])]}}])}),_c('vxe-column',{attrs:{"fixed":"left","min-width":"80","field":"stock_name","title":"股票名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.id)}}},[_vm._v(_vm._s(row.stock_name ? row.stock_name : '-'))])])]}}])}),_c('vxe-column',{attrs:{"field":"created_at","min-width":"70","title":"入池日期","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.created_at.split(' ')[0])+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"recommend_date","min-width":"70","title":"推荐日期","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.recommend_date.split(' ')[0])+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"applies_recommend","min-width":"70","title":"推荐后涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[(row.recommend == 1)?_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_recommend))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_recommend)))]):_vm._e()])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"market_value_million","title":"市值/亿","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.market_value_million)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"current_market_million","title":"流通/亿","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.current_market_million)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"close_price","title":"收盘价","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.close_price)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"applies_day","title":"当日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_day))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_day)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"50","field":"volume_ratio","title":"量比","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.volume_ratio)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"50","field":"volatility","title":"波动率","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.volatility)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"applies_five","title":"5日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_five))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_five)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"applies_20","title":"20日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_20))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_20)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"applies_60","title":"60日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_60))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_60)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"applies_focus","title":"入池涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_focus))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_focus)))])])]}}])}),_c('vxe-column',{attrs:{"field":"pe_ttm","min-width":"70","title":"PE-TTM","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.pe_ttm)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"pb","min-width":"50","title":"PB","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.pb)+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"120","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.stockRemove(row.id)}}},[_vm._v("调出")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.stockEdit(row.id)}}},[_vm._v("修改")]),(row.recommend == 0)?_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.recommend(row)}}},[_vm._v("推荐")]):_vm._e(),(row.recommend == 1)?_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.recommend(row)}}},[_vm._v("取消推荐")]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }