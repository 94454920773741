<template>
	<!-- <fade-transition :duration="200" origin="center top" mode="out-in">
		<div v-if="visible" style="height: calc( 100vh - 100px );width: 100%;flex-direction: column;" class="d-flex justify-center align-center">
			<img src="@/assets/loading.gif" width="400px" height="400px" style="mix-blend-mode: multiply;opacity: 0.3;" />
			<div style="opacity: 0.3;">{{title}}</div>
		</div>
	</fade-transition> -->
	<div v-if="visible" style="height: calc( 100vh - 100px );width: 100%;flex-direction: column;" class="d-flex justify-center align-center">
		<img src="@/assets/loading.gif" width="400px" height="400px" style="mix-blend-mode: multiply;opacity: 0.3;" />
		<div style="opacity: 0.3;">{{title}}</div>
	</div>
	
</template>
<script>
  import { FadeTransition } from "vue2-transitions";
	import Vue from 'vue';

  export default {
    name: 'loadingPage',
    components: {
      FadeTransition
    },
		props: {
			
		},
    props: {
			visible:false,
			title: {
				type: String,
				default: '数据加载中...'
			},
			height:'',
    },
		data() {
      return {
        
				
      };
    },
    mounted() {
			
    },
    
    methods: {
			
    },
    computed: {
      
    },
  };
</script>
<style scoped="scoped">

</style>
