<template>
  <div style="margin-top: 10px" id="reviewContentDiv">
    <table
      border="1"
      style="
        width: 100%;
        font-size: 14px;
        text-align: center;
        border-collapse: collapse !important;
        border: 1px solid #000;
      "
    >
      <tr>
        <td
          colspan="16"
          class="py-2"
          style="
            background-color: #bf0000;
            color: #fff;
            font-size: 18px;
            font-weight: bolder;
          "
        >
          <span
            >每日复盘<span>({{ displayContent.title }})</span></span
          >
        </td>
      </tr>
      <tr>
        <td colspan="16" class="py-2" style="background-color: #fff2cc">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
            "
          >
            <span
              v-for="item in displayContent.content.marketData.compositeIndex"
              style="margin-right: 15px"
            >
              <span style="font-weight: bolder">{{ item.name }}:</span>
              <span :style="$stockHelpers.getColor(item.percent)">{{
                $stockHelpers.getPercent(item.percent)
              }}</span>
            </span>

            <span style="margin-right: 15px">
              <span style="font-weight: bolder">上涨家数:下跌家数 = </span>
              <span style="color: #ff1d19">{{
                displayContent.content.marketData.upStock
              }}</span>
              :
              <span style="color: #007f00">{{
                displayContent.content.marketData.downStock
              }}</span>
            </span>
            <span style="margin-right: 15px">
              <span style="font-weight: bolder">涨停板:跌停板 = </span>
              <span style="color: #ff1d19">{{
                displayContent.content.marketData.upStopStock
              }}</span>
              :
              <span style="color: #007f00">{{
                displayContent.content.marketData.downStopStock
              }}</span>
            </span>
          </div>
        </td>
      </tr>
      <tr v-for="item in displayContent.content.tableData.intro">
        <td
          colspan="16"
          style="text-align: start; padding: 10px"
          v-html="item"
        ></td>
      </tr>
      <tr style="background-color: #bf0000; color: #fff; font-size: 15px">
        <td colspan="4" rowspan="2" style="width: 340px">热门赛道/标签名称</td>
        <td colspan="8" rowspan="2">投资逻辑/信息更新</td>
        <td colspan="4" style="width: 300px">重点个股/个股表现</td>
      </tr>
      <tr style="background-color: #bf0000; color: #fff; font-size: 15px">
        <td colspan="1" style="width: 100px">证券代码</td>
        <td colspan="1" style="width: 100px">简称</td>
        <td colspan="1" style="width: 100px">日涨幅</td>
        <td colspan="1" style="width: 100px">近3日涨幅</td>
      </tr>
      <template
        v-for="(tag, tagIndex) in displayContent.content.tableData.labels"
      >
        <template v-for="(tagChild, tagChildIndex) in tag.child">
          <template v-for="(stock, stockId) in tagChild.stocks">
            <tr>
              <td
                colspan="2"
                :rowspan="getFatherSpan(tag)"
                v-if="tagChildIndex == 0 && stockId == 0"
                style="width: 140px"
                :style="{
                  backgroundColor:
                    tag.name.indexOf('38, 38, 38') == -1
                      ? '#e3e3e3'
                      : '#d6e9f3',
                }"
                v-html="tag.name"
              ></td>
              <td
                colspan="2"
                :rowspan="tagChild.stocks.length"
                v-if="stockId == 0"
                style="width: 200px"
                :style="{
                  backgroundColor:
                    tag.name.indexOf('38, 38, 38') == -1
                      ? '#e3e3e3'
                      : '#d6e9f3',
                }"
                v-html="tagChild.name"
              ></td>
              <td
                colspan="8"
                :rowspan="tagChild.stocks.length"
                v-if="stockId == 0"
                v-html="tagChild.content"
                style="text-align: start; background-color: #fff2cc"
              ></td>

              <td
                style="font-weight: bolder; font-size: 12px"
                :style="{ color: stock.isHot ? '#bf0000' : '#2f75b5' }"
              >
                {{ stock.code }}
              </td>
              <td
                style="font-weight: bolder; font-size: 12px"
                :style="{ color: stock.isHot ? '#bf0000' : '#2f75b5' }"
              >
                {{ stock.name }}
              </td>
              <td
                style=""
                :style="{ backgroundColor: getStockStyle(stock.day_1) }"
              >
                {{ $stockHelpers.getPercent(stock.day_1) }}
              </td>
              <td
                style=""
                :style="{ backgroundColor: getStockStyle(stock.day_3) }"
              >
                {{ $stockHelpers.getPercent(stock.day_3) }}
              </td>
            </tr>
          </template>
        </template>
      </template>
    </table>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import Print from "print-js";
export default {
  components: {},
  props: {
    displayContent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getFatherSpan(item) {
      let span = 0;
      item.child.forEach((item) => {
        span += item.stocks.length;
      });
      return span;
    },
    getStockStyle(value) {
      if (value > 0) {
        let opacity = 1 - Math.abs(0.1 - value) / 0.1;
        if (value > 0.1) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(0.1 - value) / 0.1 - 1;
        if (value < -0.1) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    print() {
      this.appStore.printTitle = "每日复盘" + this.displayContent.title;
      this.appStore.printContent =
        document.getElementById("reviewContentDiv").innerHTML;
      let routeUrl = this.$router.resolve({ name: "打印页面" });
      window.open(routeUrl.href, "_blank");
      // printJS({
      //   printable: reviewContentDiv,
      //   type: 'html',
      //   scanStyles: false,
      // });
    },
  },
};
</script>
