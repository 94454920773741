<template>
  <div style="width: 100%; height: 99%">
    <iframe
      :src="url"
      frameborder="0"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>
<script>
import File from "@/api/file.js";
export default {
  props: {
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.getMeetingUrl();
  },
  computed: {},
  methods: {
    getMeetingUrl() {
      console.log(this.iframeData.data.id);
      this.showLoading("获取会议内容中...");
      File.getMeetingUrl(this.iframeData.data.id)
        .then((res) => {
          console.log(res.data.url);
          this.url = res.data.url;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  watch: {},
};
</script>
<style>
.aplayer-pic {
  background-color: #fff !important;
}
.text-gray {
  color: rgba(255, 255, 255, 0.9);
}
.text-black {
  color: #000;
  font-weight: 800;
}
</style>
