<template>
  <div class="ml-4">
    <a
      class="pl-4"
      v-if="item.display"
      v-for="item in adminTabList"
      :key="item.url"
      :ripple="true"
      @click="currentTab = item.url"
      style="background-color: transparent; width: auto"
      :elevation="0"
    >
      <span
        style="font-size: 15px"
        class="pa-0"
        :class="{
          'active-tab': currentTab == item.url,
          'un-active-tab': currentTab != item.url,
        }"
        >{{ item.title }}</span
      >
    </a>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "AppBarReview",
  props: {},
  components: {},
  data() {
    return {
      currentTab: this.$commonHelpers.getPermissions("manage.user") ? 0 : 1,
      adminTabList: [
        {
          title: "用户",
          url: 0,
          display: this.$commonHelpers.getPermissions("manage.user"),
        },
        {
          title: "评分人员",
          url: 1,
          display: this.$commonHelpers.getPermissions("manage.rateUser"),
        },
        // {
        // 	title: '评分因子',
        // 	url: 2,
        // 	display: this.$commonHelpers.getPermissions(['管理员', '基金经理']),
        // },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {
    currentTab(val) {
      Bus.$emit("adminTabChange", val);
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
}
.un-active-tab {
  color: #7e7e7e;
}
</style>
