<template>
	<TableFilter :inputWidth="inputWidth" :colName="colName" :autoSave="false" :selectData="selectData" :table="table" :placeholder="placeholder"></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter'
import Stock from '@/api/stock.js';
export default {
	name: '',
	props: {
		placeholder: '',
		table:'',
		colName:'',
		inputWidth:'',
	},
	data() {
		return {
			selectData: [
				{ label: 'A股市场', value: 'A股市场' },
				{ label: '主板', value: '主板' },
				{ label: '中小板', value: '中小板' },
				{ label: '科创板', value: '科创板' },
				{ label: '创业板', value: '创业板' },
				{ label: 'HK', value: 'HK' },
				{ label: 'US', value: 'US' }
			],
			inputValue: '',
		};
	},
	components: {
		TableFilter,
	},
	mounted() {
		
	},
	methods: {
		
	},
	watch: {
		
	}
};
</script>
<style scoped>
	
</style>
