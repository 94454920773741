<template>
  <div>
    <div
      v-html="parsingEmoji(finalContent)"
      style="line-height: 18px; width: 70%"
      ref="newsContent"
    ></div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "NewsDisplay",
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      finalContent: this.content,
    };
  },
  mounted() {
    //将大于1个的连续<br>替换为一个<br>，避免过多空行
    this.finalContent = this.finalContent.replace(/<br>{1,}/g, "<br>");
    //将大于1个的连续\n替换为一个<br>，避免过多空行
    this.finalContent = this.finalContent.replace(/\n{1,}/g, "<br>");

    //问答处理
    this.finalContent = this.finalContent.replace("【答】", "<br>【答】");

    setTimeout(() => {
      let newsOBJ = this.$refs.newsContent;
      //图片处理
      let imgs = newsOBJ.getElementsByTagName("img");
      imgs.forEach((item) => {
        //将图片的宽度设为400px
        item.style.width = "400px";
        item.onclick = () => {
          Bus.$emit("previewFile", {
            url: item.src,
            name: item.alt + ".jpg",
          });
        };
      });
    }, 1000);
  },
  methods: {},
};
</script>
<style scoped="scoped"></style>
