<template>
  <v-container fluid class="px-3 py-0">
    <div class="d-flex pt-3">
      <div style="width: 500px" class="mr-4">
        <v-card class="border-radius-md pa-3">
          <div class="d-flex justify-space-between" style="width: 100%">
            <div class="pt-1">公告汇总</div>

            <div>
              <vxe-input
                v-model="keywords"
                type="search"
                placeholder="股票、关键词"
                size="mini"
                style="width: 200px"
                clearable
                @change="getAnnouncementList(true)"
              ></vxe-input>
            </div>
          </div>

          <div class="d-flex justify-space-between mt-2">
            <vxe-input
              v-model="start_date"
              type="date"
              placeholder="起始日期"
              size="mini"
              style="width: 200px"
              clearable
              @change="getAnnouncementList(true)"
            ></vxe-input>
            <span class=""> —— </span>
            <vxe-input
              v-model="end_date"
              type="date"
              placeholder="截止日期"
              size="mini"
              style="width: 200px"
              clearable
              @change="getAnnouncementList(true)"
            ></vxe-input>
          </div>

          <div class="mt-3">
            <vxe-table
              id="announcementTable"
              row-id="ID"
              ref="announcementTable"
              :data="tableData"
              :border="false"
              resizable
              show-overflow
              show-header-overflow
              highlight-hover-row
              align="left"
              :loading="announcementTableLoading"
              :column-config="{ resizable: true }"
              :height="tableHeight + 'px'"
              size="small"
              :stripe="true"
              :sort-config="{ trigger: 'cell', multiple: true }"
              :empty-render="{ name: 'NotData' }"
              :tooltip-config="{
                showAll: false,
                enterable: true,
                theme: 'dark',
              }"
              @scroll="tableScroll"
              @cell-click="cellClick"
            >
              <vxe-column
                fixed="left"
                width="80"
                field="stock_code"
                title="股票"
              >
                <template #header="{ column }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ column.title }}
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.stock_name }}
                </template>
              </vxe-column>

              <vxe-column width="75" field="created_at" title="日期">
                <template #header="{ column }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ column.title }}
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.date }}
                </template>
              </vxe-column>

              <vxe-column min-width="80" field="title" title="公告名">
                <template #header="{ column }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ column.title }}
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.title }}
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </v-card>
      </div>

      <v-card
        style="width: calc(100% - 500px); height: calc(100vh - 100px)"
        class="border-radius-md pa-3"
      >
        <div
          class="d-flex justify-space-between"
          style="height: 40px"
          v-if="showStock"
        >
          <div>
            <span style="color: #c10000"
              >{{ showStock ? showStock.stock.name : '' }}({{
                showStock ? showStock.stock.code : ''
              }})</span
            >
            <small class="ml-2"
              >《{{ showStock ? showStock.title : '' }}》</small
            >
          </div>
          <div style="width: 160px; display: flex">
            <vxe-button
              v-if="$commonHelpers.getPermissions('AI.AiAnalysis')"
              status="primary"
              @click="AiAnalysis(showStock)"
              size="medium"
              class="ml-0 mr-1"
              style="width: 80px"
            >
              AI解析
            </vxe-button>
            <vxe-button
              status="primary"
              @click="openRate(showStock)"
              size="medium"
              content="评分"
              class="ml-0 mr-1"
            ></vxe-button>
          </div>
        </div>
        <iframe
          :src="showStock ? showStock.url : ''"
          style="height: calc(100% - 40px); border: none; width: 100%"
        >
        </iframe>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import Announcement from '@/api/announcement.js';
import XEUtils from 'xe-utils';
export default {
  name: 'AnnouncementSummary',
  components: {},
  data() {
    return {
      announcementTableLoading: false,
      tableHeight: 500,
      tableData: [],

      keywords: '',
      start_date: '',
      end_date: '',

      currPage: 1,
      isEnd: false,

      showStock: '',
    };
  },
  computed: {},
  mounted() {
    this.tableHeight = document.body.offsetHeight - 180;
    this.getAnnouncementList(true, true);
  },
  methods: {
    //获取股票列表
    getAnnouncementList(refresh = true, isFirstLoad = false) {
      this.announcementTableLoading = true;
      if (refresh) {
        this.currPage = 1;
      }
      Announcement.getAnnouncementList(
        this.keywords,
        this.start_date,
        this.end_date,
        this.currPage
      )
        .then((res) => {
          if (refresh) {
            this.tableData = [];
            this.isEnd = false;
          }
          if (res.data.data.length == 0) {
            this.isEnd = true;
          }
          this.tableData = this.tableData.concat(res.data.data);
          if (isFirstLoad) {
            this.showStock = this.tableData[0];
          }
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.announcementTableLoading = false;
        });
    },
    tableScroll({ scrollTop, scrollHeight, bodyHeight }) {
      //console.log(scrollTop, scrollHeight , bodyHeight);
      if (
        scrollTop + this.tableHeight > scrollHeight - 100 &&
        this.announcementTableLoading == false &&
        !this.isEnd
      ) {
        //到底了触发下一页
        this.currPage++;
        this.getAnnouncementList(false);
      }
    },
    cellClick({ row }) {
      this.showStock = row;
    },
    openRate(stock) {
      console.log(stock);
      this.$stockHelpers.openRate(stock.stock.id, stock.stock_name, this);
    },
    AiAnalysis(stock) {
      console.log(stock);
      this.$commonHelpers.openAiAnalysis(
        'AI公告解析',
        `如何判断上市公司公告中的价值和风险的方法，主要有以下几点：1.初步浏览公告内容，详细分析公告细节，全面了解上市公司。2.判断公告信息的影响，包括财务影响和非财务影响，短期影响和长期影响，一次影响和多次影响，直接影响和间接影响，历史影响和现实影响。3.结合公司的基本面、行业情况、市场环境等因素进行综合评估。你是一名基金经理。搜索下面的上市公司公告，用上面的方法分析，并预测这些内容对上市公司的股价是否可能会产生影响，用中文回答。${stock.stock_name}《${stock.title}》`,
        'bing',
        this
      );
    },
  },
};
</script>
