<template>
  <div>
    <vxe-toolbar
      class="mx-3"
      custom
      :refresh="{ query: getStockRecommendList }"
    >
      <template v-slot:buttons>
        <span class="mr-2">推荐列表</span>
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="搜索股票或推荐人"
          class="mx-1"
        ></vxe-input>
        <vxe-button
          @click="recommendAdd()"
          size="medium"
          content="新增推荐"
          class="mx-1"
          status="my-primary"
        ></vxe-button>
        <vxe-button
          @click="exportTable()"
          size="medium"
          content="导出"
          class="ml-0 mr-1"
          status="my-primary"
        >
        </vxe-button>

        <UserFilter
          colName="user_name"
          placeholder="推荐人"
          :table="$refs.recommendStockTable"
          inputWidth="100px"
          class="mr-1"
        ></UserFilter>
      </template>
    </vxe-toolbar>

    <div :style="{ height: tableHight + 'px' }">
      <vxe-table
        id="recommendStockTable"
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="center"
        ref="recommendStockTable"
        :loading="tableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :scroll-y="{ mode: 'wheel', gt: 100 }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
        :filter-config="{ showIcon: false }"
      >
        <vxe-column fixed="left" width="70" field="stock_code" title="股票代码">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-typo"
                >{{ row.stock_code ? row.stock_code : "-" }}</a
              >
            </div>
          </template>
        </vxe-column>

        <vxe-column fixed="left" width="80" field="stock_name" title="股票名称">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-typo"
                >{{ row.stock_name ? row.stock_name : "-" }}</a
              >
            </div>
          </template>
        </vxe-column>

        <vxe-column
          width="70"
          field="user_name"
          title="推荐人"
          :filters="[{ data: '' }]"
          :filter-method="
            (value) => $tableHelpers.filterInputMethod(value, 'user_name')
          "
        >
          <template #filter="{ $panel, column }">
            <template v-for="(option, index) in column.filters">
              <input
                class="pa-2"
                type="type"
                :key="index"
                v-model="option.data"
                @input="$panel.changeOption($event, !!option.data, option)"
                @keyup.enter="$panel.confirmFilter()"
                placeholder="按回车确认筛选"
              />
            </template>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.user_name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="120" field="created_at" title="推荐时间" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.created_at }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="120" field="end_date" title="结束时间" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.end_date }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="90" field="rise_per" title="预期空间" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ (row.rise_per * 100).toFixed(0) + "%" }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="applies_focus"
          title="推荐以来涨幅"
          sortable
          width="120"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                $stockHelpers.getPercent(row.applies_focus)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_max" title="最大涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_max)">{{
                $stockHelpers.getPercent(row.applies_max)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="retreat_max" title="最大回撤" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.retreat_max)">{{
                $stockHelpers.getPercent(row.retreat_max)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_day" title="日涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_day)">{{
                $stockHelpers.getPercent(row.applies_day)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_five" title="5日涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_five)">{{
                $stockHelpers.getPercent(row.applies_five)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_ten" title="10日涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_ten)">{{
                $stockHelpers.getPercent(row.applies_ten)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_20" title="20日涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_20)">{{
                $stockHelpers.getPercent(row.applies_20)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_60" title="60日涨幅" sortable width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_60)">{{
                $stockHelpers.getPercent(row.applies_60)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_120" title="120日涨幅" sortable width="100">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_120)">{{
                $stockHelpers.getPercent(row.applies_120)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="100" field="sw_level2_name" title="行业">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.sw_level2_name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="benchmark" title="基准(行业)" sortable width="100">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.benchmark)">{{
                $stockHelpers.getPercent(row.benchmark)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="excessreturn" title="超额收益" sortable width="100">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.excessreturn)">{{
                $stockHelpers.getPercent(row.excessreturn)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="150" field="reason" title="推荐理由">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.reason }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="80" field="status_name" title="推荐状态">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.status_name }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          title="操作"
          type="seq"
          width="100"
          :resizable="false"
          show-overflow
          fixed="right"
        >
          <template #default="{ row }">
            <div v-if="!$commonHelpers.getPermissions('stockRecommend.manage')">
              <a
                href="javascript:;"
                @click="recommendEdit(row)"
                class="text-xs font-weight-normal text-typo"
                style="margin-left: 5px"
              >
                修改
              </a>
              <a
                href="javascript:;"
                @click="endRecommend(row.id)"
                class="text-xs font-weight-normal text-typo"
                style="margin-left: 5px"
                v-if="row.status === 0"
              >
                结束
              </a>
            </div>
            <div v-else>
              <a
                href="javascript:;"
                @click="recommendEdit(row)"
                class="text-xs font-weight-normal text-typo"
                style="margin-left: 5px"
              >
                修改
              </a>
              <a
                href="javascript:;"
                @click="endRecommend(row.id)"
                class="text-xs font-weight-normal text-typo"
                style="margin-left: 5px"
                v-if="row.status === 0"
              >
                结束
              </a>
              <a
                href="javascript:;"
                @click="deleteRecommend(row.id)"
                class="text-xs font-weight-normal text-typo"
                style="margin-left: 5px"
              >
                删除
              </a>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import StockRecommend from "@/api/stockRecommend.js";
import AddRecommend from "@/components/StockRecommend/AddRecommend.vue";
import EditRecommend from "@/components/StockRecommend/EditRecommend.vue";
import XEUtils from "xe-utils";
import UserFilter from "@/components/StockPool/filter/UserFilter";
export default {
  name: "PortfolioList",
  components: { UserFilter },
  props: {
    tableHight: "",
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["stock_code", "stock_name", "user_name"];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  data() {
    return {
      tableLoading: false,
      filterName: "",
      tableData: [],
    };
  },
  mounted() {
    this.getStockRecommendList();
    Bus.$on("refreshStockRecommendList", (e) => {
      this.getStockRecommendList();
    });
  },
  methods: {
    getStockRecommendList() {
      this.tableLoading = true;
      StockRecommend.getStockRecommendList()
        .then((res) => {
          //console.log(res.data);
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    recommendAdd() {
      this.$layer.iframe({
        content: {
          content: AddRecommend,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "500px"],
        title: "新增推荐(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    recommendEdit(row) {
      this.$layer.iframe({
        content: {
          content: EditRecommend,
          parent: this,
          data: { iframeData: { ...row } },
        },
        area: ["600px", "500px"],
        title: "推荐(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    endRecommend(id) {
      this.$layer.confirm(
        "确定结束该推荐吗？",
        {
          btn: ["确定", "取消"],
          title: "提示",
          icon: 3,
        },
        (layerid) => {
          StockRecommend.endStockRecommend(id)
            .then((res) => {
              this.showToast("已成功结束推荐！", "success", 5000);
              Bus.$emit("refreshStockRecommendList", true);
            })
            .catch((err) => {
              console.log(err.msg);
              this.showToast(err.msg, "danger", 10000);
            })
            .finally(() => {
              this.hideLoading();
            });

          this.$layer.close(layerid);
        }
      );
    },
    deleteRecommend(id) {
      this.$layer.confirm(
        "确定删除该推荐吗？",
        {
          btn: ["确定", "取消"],
          title: "提示",
          icon: 3,
        },
        (layerid) => {
          StockRecommend.deleteStockRecommend(id)
            .then((res) => {
              this.showToast("已成功删除推荐！", "success", 5000);
              Bus.$emit("refreshStockRecommendList", true);
            })
            .catch((err) => {
              console.log(err.msg);
              this.showToast(err.msg, "danger", 10000);
            })
            .finally(() => {
              this.hideLoading();
            });

          this.$layer.close(layerid);
        }
      );
    },
    exportTable() {
      this.$refs.recommendStockTable.openExport({});
    },
  },
};
</script>
