<template>
	<v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
		<div class="card-padding pb-0">
			<h4 class="text-h4 font-weight-bolder text-danger text-gradient mb-2"
				style="display: flex;align-items: center;">
				<div class="mr-5">
					<v-img src="@/assets/img/logo.png" class="" style="width: 60px;"></v-img>
				</div>
				<div class="ml-1">骊 阅</div>
			</h4>
			<p class="mb-0 text-body">如果您忘记了旧密码请联系管理员更改</p>
		</div>
		<div class="card-padding pb-4">
			<label class="text-xs text-typo font-weight-bolder ms-1">用户名</label>
			<v-text-field hide-details outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light
				height="40" placeholder="用户名" class="input-style font-size-input
				border border-radius-md
				placeholder-lighter
				text-color-light
				mb-4
				mt-2
				"
				v-model="username"
			></v-text-field>

			<label class="text-xs text-typo font-weight-bolder ms-1">输入旧密码</label>
			<v-text-field hide-details outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light
				height="40" placeholder="输入旧密码" type="password" class="
				input-style
				font-size-input
				border border-radius-md
				placeholder-lighter
				text-color-light
				mb-4
				mt-2"
				v-model="oldpwd"
				>
			</v-text-field>

			<label class="text-xs text-typo font-weight-bolder ms-1">输入新密码</label>
			<v-text-field hide-details outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light
				height="40" placeholder="输入新密码" type="password" class="
			    input-style
			    font-size-input
			    border border-radius-md
			    placeholder-lighter
			    text-color-light
			    mb-4
			    mt-2
			  "
			  v-model="newPwd"
			></v-text-field>

			<label class="text-xs text-typo font-weight-bolder ms-1">再次输入新密码</label>
			<v-text-field hide-details outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light
				height="40" placeholder="再次输入新密码" type="password" class="
			    input-style
			    font-size-input
			    border border-radius-md
			    placeholder-lighter
			    text-color-light
			    mb-4
			    mt-2
			  "
			  v-model="newPwd1"
			></v-text-field>

			<v-btn elevation="0" :ripple="false" height="43" class="
				font-weight-bold
				text-uppercase
				btn-danger
				bg-gradient-danger
				py-2
				px-6
				me-2
				mt-7
				mb-2
				w-100
				" color="#5e72e4" small
				@click="changePassword"
			>
				更改密码
			</v-btn>
			<div class="text-center">
				<p class="text-sm text-body mt-3 mb-0">
					不需要更改密码了?
					<a href="javascript:;" class="
              text-danger text-gradient text-decoration-none
              font-weight-bold
            " @click="backToLogin()">
						返回登录
					</a>
				</p>
			</div>
		</div>
	</v-card>
</template>
<script>
import User from '@/api/user.js';

export default {
	name: 'sign-up-cover',
	data() {
		return {
			checkbox: false,
			username: '',
			oldpwd: '',
			newPwd: '',
			newPwd1: '',
		};
	},
	methods: {
		jumpTo(toName) {
			this.$router.push({ name: toName });
		},
		backToLogin() {
			this.jumpTo('登录');
		},
		changePassword() {
			if(this.username == '') {
				this.showToast('请输入用户名','warning',5000);
				return;
			}
			if(this.newPwd!=this.newPwd1){
				this.showToast('两次输入的新密码不一致','warning',5000);
				return;
			}
			if(this.newPwd.length<6){
				this.showToast('新密码长度不能小于6位','warning',5000);
				return;
			}
			this.showLoading('登录中，请稍候');
			User.changePassword(this.username,this.oldpwd,this.newPwd).then(res => {
				this.showToast('密码修改成功','success',5000);
				this.jumpTo('登录');
			}).catch(err => {
				console.log(err);
				this.showToast(err.msg,'danger',5000);
			}).finally(() => {
				this.hideLoading();
			});
		}
	}
};
</script>
