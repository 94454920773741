<template>
  <v-card
    class="card-shadow border-radius-xl py-2 px-4"
    :style="{ height: pageHeight - 115 + 'px' }"
  >
    <div
      style="
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <vxe-input
        v-model="searchKeyword"
        placeholder="搜索"
        prefix-icon="fa fa-search"
        clearable
        style="width: 120px"
      >
      </vxe-input>
      <div>
        <!-- <vxe-button status="my-primary" @click="tagAdd()" class="mx-1"
          >新增标签</vxe-button
        > -->
        <vxe-button @click="close" status="my-primary" class="mx-1"
          >返回</vxe-button
        >
      </div>
    </div>
    <div
      :style="{ height: pageHeight - 170 + 'px' }"
      style="overflow-y: scroll"
    >
      <TagManageTree :searchKeyword="searchKeyword" @tagClick="tagClick">
      </TagManageTree>
    </div>
  </v-card>
</template>
<script>
import TagManageTree from "./TagManageTree.vue";

export default {
  name: "TagManage",
  components: {
    TagManageTree,
  },
  props: {},
  mounted() {
    this.pageHeight = document.body.offsetHeight;
  },
  data() {
    return {
      searchKeyword: "",
      pageHeight: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    tagClick(row) {
      this.$emit("tagClick", row);
    },
    tagAdd() {
      this.$emit("tagAdd");
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
