import Request from '@/components/request/js/index.js';

export default {
	//获取组合列表
	getPortfolioList(user_type = '') {
		let data = {};
		if (user_type) {
			data.user_type = user_type;
		}
		return Request().get('/api/firm/all', {
			header: {
				contentType: 'application/json'
			},
			data: {
				...data,
			}
		});
	},
	//新建组合
	addPortfolio(data) {
		return Request().post('/api/firm', {
			header: {
				contentType: 'application/json'
			},
			data: {
				...data
			}
		});
	},
	//获取组合详情
	getPortfolioDetail(id) {
		return Request().get('/api/firm/' + id, {
			header: {
				contentType: 'application/json'
			},
			data: {

			}
		});
	},
	//修改组合
	editPortfolio(id, data) {
		return Request().post('/api/firm/' + id, {
			header: {
				contentType: 'application/json'
			},
			data: {
				_method: 'PATCH',
				...data,
			}
		});
	},
	//获取首页的调仓历史
	getTradingHistoryList() {
		return Request().get('/api/firm/tradeHistories', {
			header: {
				contentType: 'application/json'
			},
			data: {

			}
		});
	},
	//组合调仓
	//direction:1买入，0卖出
	restocking(firm_id, code, direction, comment, pos_ratio) {
		return Request().post('/api/firm/restocking', {
			header: {
				contentType: 'application/json'
			},
			data: {
				firm_id: firm_id,
				code: code,
				direction: direction,
				comment: comment,
				pos_ratio: pos_ratio,
			}
		});
	},
	//撤单
	cancelTrade(id) {
		return Request().post('/api/firm/' + id + '/trade/cancel', {
			header: {
				contentType: 'application/json'
			},
			data: {
			}
		});
	},
	//更新交易说明
	updateTradeComment(id, comment) {
		return Request().post('/api/firm/' + id + '/trade/update', {
			header: {
				contentType: 'application/json'
			},
			data: {
				comment: comment,
			}
		});
	}
};
