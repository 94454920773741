//纪要
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <div>
        <span class="font-weight-bold text-dark" style="opacity: 0.5"
          >{{ str.data.source }}
        </span>
      </div>
    </v-row>
    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
    >
      {{ str.data.title }}
    </v-list-item-title>
    <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      <a
        href="javascript:;"
        @click="previewFile(str.data)"
        class="text-xs font-weight-normal text-typo mr-2"
        >查看纪要</a
      >
      <a
        href="javascript:;"
        @click="previewMeeting(str.data)"
        class="text-xs font-weight-normal text-typo mr-2"
        >会议原文</a
      >
      <!-- <a
        href="javascript:;"
        @click="downloadFile(str.data)"
        class="text-xs font-weight-normal text-typo mr-2"
        >下载</a
      > -->
    </v-list-item-subtitle>
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
import File from "@/api/file.js";
import Bus from "@/components/Bus/bus.js";
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  methods: {
    getFile(id) {
      return new Promise((resolve, reject) => {
        File.getFile(id)
          .then((res) => {
            resolve(res.data.url);
          })
          .catch((err) => {
            this.showToast(err.msg, "danger", 10000);
            reject();
          });
      });
    },
    async previewFile(row) {
      await this.getFile(row.id)
        .then((url) => {
          console.log(url);

          Bus.$emit("previewFile", { url: url, name: row.name });
        })
        .catch(() => {});
    },
    // previewMeeting(row) {
    //   this.$commonHelpers.openMeetintg(row);
    // },
    previewMeeting(row) {
      this.showLoading("获取会议内容中...");
      File.getMeetingUrl(row.id)
        .then((res) => {
          console.log(res.data.url);
          window.open(res.data.url);
        })
        .catch((err) => {
          this.showToast("该会议无原文资源", "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async downloadFile(row) {
      console.log(row);
      await this.getFile(row.id)
        .then((url) => {
          console.log(url);

          window.open(url);
        })
        .catch(() => {});
    },
  },
};
</script>
