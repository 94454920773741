<template>
  <div class="py-2">
    <div class="pa-1">
      <vxe-button
        @click="AiAnalysis(appStore.tagDetail.name)"
        size="medium"
        style="width: 120px; padding: 5px"
        status="my-primary"
      >
        AI标签更新建议
      </vxe-button>
      <div>
        本功能会根据当前市场新闻，提供该标签可更新的内容，如新闻中出现但没有在标签里的股票和基本信息
      </div>
    </div>
    <!-- <div class="pa-1">
      <vxe-button
        @click="TagQA()"
        size="medium"
        style="width: 120px; padding: 5px"
        status="my-primary"
      >
        标签问答
      </vxe-button>
      <div>本功能根据新闻和标签内容，回答用户提出的问题</div>
    </div> -->
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Stock from "@/api/stock.js";
import XEUtils from "xe-utils";
export default {
  name: "BindFile",
  components: {},
  props: {
    tagName: "",
  },

  data() {
    return {
      tableData: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  computed: {},
  mounted() {
    if (this.tagName) {
      this.getInfoList();
    }
  },
  methods: {
    AiAnalysis(tag) {
      this.$commonHelpers.openAiAnalysis(
        "AI标签更新建议",
        `${tag}`,
        "fastgpt-ENHsunfEhiOprCoIAqPXAIoic6Oov4DNtTRDFUGrd2u8KGiQh5Yvw8dY",
        this
      );
    },
    TagQA() {
      console.log(123);
    },
  },
  watch: {},
};
</script>
<style scoped="scoped"></style>
