<template>
  <v-app-bar
    :color="background"
    :height="isPc ? 'auto' : 'auto'"
    class="px-0 toolbar-content-padding-y-none"
    :class="getAppBarClass()"
  >
    <v-row :class="isPc ? 'py-1' : ''">
      <v-col cols="12" sm="6" class="d-flex">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div class="mb-0 mr-3" v-if="$route.meta.canBack">
            <v-btn
              depressed
              :ripple="false"
              class="font-weight-600 text-body"
              color="transparent"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              @click="back"
            >
              <v-icon size="13">fa fa-chevron-left</v-icon>
              <span
                class="d-sm-inline font-weight-bold d-none ml-1"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
              >
                返回
              </span>
            </v-btn>
          </div>

          <h6 class="text-h6 font-weight-bolder text-typo mb-0">
            {{ $route.name }}
          </h6>

          <AppBarHome v-show="$route.name === '主页'"></AppBarHome>
          <AppBarInfoCenter
            v-show="$route.name === '信息中心'"
          ></AppBarInfoCenter>
          <AppBarReview
            v-show="$route.name === '复盘' || $route.name === '复盘-详情'"
          ></AppBarReview>
          <AppBarStockPool v-show="$route.name === '股票池'"></AppBarStockPool>
          <AppBarAdmin v-show="$route.name === '系统管理'"></AppBarAdmin>
          <AppBarResonanceContrast
            v-show="
              $route.name === '共振对比' || $route.name === '共振对比-详情'
            "
          ></AppBarResonanceContrast>
          <AppBarAdditionalAndLifting
            v-show="$route.name === '增发与解禁'"
          ></AppBarAdditionalAndLifting>
          <AppBarVolatilitySurfaces
            v-show="$route.name === '波动率曲面'"
          ></AppBarVolatilitySurfaces>
          <AppBarAi v-show="$route.name === '实验室'"></AppBarAi>
          <AppBarStockDetail
            v-show="$route.name === '个股详情'"
          ></AppBarStockDetail>
          <AppBarQuant v-show="$route.name === '量化'"></AppBarQuant>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-0 rounded-sm"
            v-if="$vuetify.breakpoint.mobile"
            color="transparent"
            @click="drawerClose"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
            </div>
          </v-btn>
        </div>
        <!-- <div
          class="drawer-toggler pa-5 ms-6 cursor-pointer"
          :class="{ active: this.appStore.drawer }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div> -->
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center"
        style="justify-content: space-around"
      >
        <!-- <v-btn
				  elevation="0"
				  :ripple="false"
				  height="43"
				  class="
				    font-weight-600
				    text-capitalize
				    drawer-toggler
				    py-3
				    px-0
				    rounded-sm
				  "
				  :class="{
				    'btn-dark-toggler-hover': !hasBg,
				    'btn-toggler-hover': hasBg,
				    active: this.appStore.drawer,
				  }"
				  v-if="$vuetify.breakpoint.mobile"
				  color="transparent"
				  @click="drawerClose"
				>
				  <div class="drawer-toggler-inner">
				    <i class="drawer-toggler-line text-body"></i>
				    <i class="drawer-toggler-line text-body"></i>
				    <i class="drawer-toggler-line text-body"></i>
				  </div>
				</v-btn> -->

        <div
          style="
            position: absolute;
            right: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <StockSelect
            v-if="$commonHelpers.getPermissions('appBar.searchStock')"
            :returnItem="true"
            @getItem="stockJump"
            placeholder="股票名称/代码/缩写"
            :showIcon="true"
            class="mr-3"
            :disableArr="[]"
          ></StockSelect>

          <v-btn
            depressed
            :ripple="false"
            class="font-weight-600 text-body text-capitalize pa-0"
            color="transparent"
            @click="ai()"
            v-if="$commonHelpers.getPermissions('router.ai')"
          >
            <img src="~@/assets/img/ai.png" alt="" width="20" />
            <span class="d-sm-inline font-weight-bold d-none">实验室</span>
          </v-btn>

          <v-btn
            depressed
            :ripple="false"
            class="font-weight-600 text-body text-capitalize"
            color="transparent"
            @click="note()"
            v-if="$commonHelpers.getPermissions('appBar.note')"
          >
            <v-icon size="16" style="color: #cf0000"
              >fa fa-edit me-sm-2 text-sm</v-icon
            >
            <span
              class="d-sm-inline font-weight-bold d-none"
              style="color: #cf0000"
              >笔记</span
            >
          </v-btn>
        </div>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="200"
          max-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :ripple="false"
              class="
                font-weight-600
                text-body
                ls-0
                text-capitalize
                pe-0
                me-2
                ms-auto
              "
              color="transparent"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16">fa fa-user me-sm-2 text-sm</v-icon>
              <span
                class="d-sm-inline font-weight-bold d-none"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
                >账户</span
              >
            </v-btn>
          </template>

          <v-list class="py-2">
            <h5
              class="
                text-uppercase text-caption
                ls-0
                font-weight-bolder
                p-0
                text-muted
                mx-4
                mt-4
                mb-2
                white-space-nowrap
              "
            >
              欢迎您，{{ userStore.name }}
            </h5>
            <v-list-item v-ripple @click="logout()">
              <v-list-item-content class="pa-0">
                <a
                  href="javascript:;"
                  class="
                    text-sm
                    font-weight-normal
                    text-typo text-decoration-none
                  "
                >
                  注销
                </a>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <AppBarMessage :linkColor="linkColor" :hasBg="hasBg"></AppBarMessage>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import AppBarMessage from "@/components/AppBar/AppBarMessage";

import AppBarHome from "@/components/AppBar/AppBarHome";
import AppBarReview from "@/components/AppBar/AppBarReview";
import AppBarStockPool from "@/components/AppBar/AppBarStockPool";
import AppBarAdmin from "@/components/AppBar/AppBarAdmin";
import AppBarResonanceContrast from "@/components/AppBar/AppBarResonanceContrast";
import AppBarAdditionalAndLifting from "@/components/AppBar/AppBarAdditionalAndLifting";
import AppBarVolatilitySurfaces from "@/components/AppBar/AppBarVolatilitySurfaces";
import AppBarAi from "@/components/AppBar/AppBarAi";
import AppBarStockDetail from "@/components/AppBar/AppBarStockDetail";
import AppBarQuant from "@/components/AppBar/AppBarQuant";
import AppBarInfoCenter from "@/components/AppBar/AppBarInfoCenter";
import StockSelect from "@/components/CommonSelect/StockSelect.vue";
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StockSelect,
    AppBarMessage,
    AppBarHome,

    AppBarReview,
    AppBarStockPool,
    AppBarAdmin,
    AppBarResonanceContrast,
    AppBarAdditionalAndLifting,
    AppBarVolatilitySurfaces,
    AppBarAi,
    AppBarStockDetail,
    AppBarQuant,
    AppBarInfoCenter,
  },
  data() {
    return {
      drawer: false,

      noteDisplay: false,
    };
  },
  mounted() {
    // this.appStore.$subscribe((mutation, state) => {
    // 	console.log(mutation);
    // 	console.log(state.drawer);
    // });
  },
  methods: {
    logout() {
      this.userStore.token = "";
      this.userStore.name = "";
      this.$router.push({ name: "登录" });
    },
    jumpTo(toName) {
      this.$router.push({ name: toName });
    },
    getAppBarClass() {
      let classStr = "";
      classStr =
        classStr +
        (this.navbarFixed
          ? "position-sticky blur shadow-blur z-index-sticky py-0 "
          : " ");
      classStr =
        classStr + (this.isPc ? "mx-2 mt-1 border-radius-md " : "mx-0 ");
      classStr = classStr + (this.navbarFixed && this.isPc ? "top-0" : "top-0");
      return classStr;
    },
    drawerClose() {
      //console.log('click');
      this.appStore.drawer = !this.appStore.drawer;
    },
    minifyDrawer() {
      this.appStore.drawer = !this.appStore.drawer;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    back() {
      this.$router.go(-1);
    },
    stockJump(val) {
      if (val) {
        console.log(val);
        this.$stockHelpers.openStockDetail(val.id);
      }
    },
    note() {
      //this.showToast('速记功能暂未开放','warning',3000);
      if (this.$commonHelpers.getPermissions(["外部人员"])) {
        this.showToast("您暂时不能使用笔记功能", "warning", 3000);
      }
      // if (!this.noteDisplay) {
      //   this.noteDisplay = true;
      //   this.$layer.iframe({
      //     content: {
      //       content: Note, //传递的组件对象
      //       parent: this, //当前的vue对象
      //       data: {
      //         id: { a: 1 }
      //       } //props
      //     },
      //     area: ['1200px', '800px'],
      //     title: '笔记',
      //     shade: false,
      //     maxmin: true,//开启最大化最小化
      //     resize: false, //是否允许拉伸，默认是不允许
      //     cancel: () => { //关闭弹窗事件
      //       this.noteDisplay = false;
      //     },
      //   });
      // } else {
      //   this.showToast('仅能打开一个速记窗口！', 'warning', 5000);
      // }
      this.$router.push({ name: "笔记" });
    },
    ai() {
      this.$router.push({ name: "实验室" });
    },
  },
  watch: {},
};
</script>
