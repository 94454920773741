<template>
  <div>
    <h4>暂未开放</h4>
  </div>
</template>
<script>
export default {
  name: 'TagRate',
  props: {

  },
  components: {

  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
<style scoped="scoped">
</style>
