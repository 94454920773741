import Request from '@/components/request/js/index.js';

export default {
	//公告列表
	getAnnouncementList(name,start_date,end_date,page){
		return Request().get('/api/announcement/list', {
		  header: {
		    contentType: 'application/json'
		  },
		  data: {
		    name:name,
				start_date:start_date,
				end_date:end_date,
				page:page,
				pre_page:100,
		  }
		});
	},
  
};
