import { render, staticRenderFns } from "./AddStock.vue?vue&type=template&id=73f4aab0"
import script from "./AddStock.vue?vue&type=script&lang=js"
export * from "./AddStock.vue?vue&type=script&lang=js"
import style0 from "./AddStock.vue?vue&type=style&index=0&id=73f4aab0&prod&lang=css"
import style1 from "@vueform/slider/themes/default.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
