<template>
  <div style="margin-top: 10px" v-if="editContent">
    <h4 class="text-dark">当前编辑日期：{{ editContent.title }}</h4>
    <h4 class="text-dark mt-6">
      总结与点评：
      <a
        href="javascript:void(0)"
        @click="editData('', '新增点评', 'addIntro', {})"
        style="color: #2f75b5; font-size: 13px"
        >新增点评</a
      >
    </h4>
    <div
      v-for="(intro, introIndex) in editContent.content.tableData.intro"
      style="
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
      "
    >
      <h4 class="text-dark">
        点评：
        <a
          @click="deleteIntro(introIndex)"
          href="javascript:void(0)"
          style="color: #bf0000; font-size: 13px"
          >删除</a
        >
        <a
          href="javascript:void(0)"
          style="color: #2f75b5; font-size: 13px; margin-left: 10px"
          @click="
            editData(intro, '修改点评', 'editIntro', { introIndex: introIndex })
          "
          >编辑</a
        >
      </h4>
      <div v-html="intro"></div>
    </div>
    <h4 class="text-dark mt-6">
      标签与个股：
      <a
        href="javascript:void(0)"
        @click="editData('', '添加父标签', 'addTag', {})"
        style="color: #2f75b5; font-size: 13px"
        >添加父标签</a
      >
    </h4>
    <div
      v-for="(tag, tagIndex) in editContent.content.tableData.labels"
      :key="tag.name"
      style="
        border: 2px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
      "
      :class="{ lockedData: lockArr.indexOf(tag.id) != -1 }"
    >
      <h4 class="text-dark">
        父级标签：
        <a
          href="javascript:void(0)"
          style="color: #2f75b5; font-size: 16px; margin-right: 10px"
          @click="
            activeTag === tagIndex
              ? (activeTag = '999999')
              : (activeTag = tagIndex)
          "
          >{{ activeTag === tagIndex ? "收起" : "展开" }}</a
        >
        <a
          href="javascript:void(0)"
          style="color: #2f75b5; font-size: 13px; margin-right: 10px"
          @click="moveParentUp(tagIndex, tag.id)"
          >上移</a
        >
        <a
          href="javascript:void(0)"
          style="color: #2f75b5; font-size: 13px; margin-right: 10px"
          @click="moveParentDown(tagIndex, tag.id)"
          >下移</a
        >
        <a
          href="javascript:void(0)"
          style="color: #bf0000; font-size: 13px"
          @click="deleteTag(tagIndex, tag.id)"
          >删除父标签</a
        >
      </h4>
      <div>
        <span v-html="tag.name" class="mr-2"></span>
        <a
          href="javascript:void(0)"
          @click="
            editData(
              tag.name,
              '修改父标签名',
              'editTagName',
              {
                tagIndex: tagIndex,
              },
              tag.id
            )
          "
          style="
            color: #2f75b5;
            font-size: 13px;
            font-weight: bold;
            margin-right: 10px;
          "
          >编辑父标签名</a
        >
        <a
          href="javascript:void(0)"
          @click="
            editData(
              '',
              '添加子标签',
              'addChildTag',
              { tagIndex: tagIndex },
              tag.id
            )
          "
          style="color: #2f75b5; font-size: 13px; font-weight: bold"
          >添加子标签</a
        >
      </div>
      <div style="margin-left: 20px" v-show="activeTag == tagIndex">
        <div
          v-for="(tagChild, childTagIndex) in tag.child"
          style="
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-top: 5px;
            padding: 5px;
          "
        >
          <h4 class="text-dark">
            子标签：
            <a
              href="javascript:void(0)"
              style="
                color: #2f75b5;
                font-size: 13px;
                margin-right: 10px;
                margin-left: 10px;
              "
              @click="moveChildUp(childTagIndex, tagIndex, tag.id)"
              >上移</a
            >
            <a
              href="javascript:void(0)"
              style="color: #2f75b5; font-size: 13px; margin-right: 10px"
              @click="moveChildDown(childTagIndex, tagIndex, tag.id)"
              >下移</a
            >

            <a
              href="javascript:void(0)"
              @click="deleteTagChild(childTagIndex, tagIndex, tag.id)"
              style="color: #bf0000; font-size: 13px"
              >删除子标签</a
            >
          </h4>
          <div>
            <span v-html="tagChild.name" class="mr-2"></span>
            <a
              href="javascript:void(0)"
              @click="
                editData(
                  tagChild.name,
                  '修改子标签名',
                  'editTagChildName',
                  {
                    childTagIndex: childTagIndex,
                    tagIndex: tagIndex,
                  },
                  tag.id
                )
              "
              style="color: #2f75b5; font-size: 13px; font-weight: bold"
              >编辑子标签名</a
            >
          </div>
          <h4 class="text-dark mt-2">
            投资逻辑/信息更新：
            <a
              href="javascript:void(0)"
              @click="
                editData(
                  tagChild.content,
                  '修改投资逻辑/信息更新',
                  'editTagChildContent',
                  {
                    childTagIndex: childTagIndex,
                    tagIndex: tagIndex,
                  },
                  tag.id
                )
              "
              style="color: #2f75b5; font-size: 13px"
              >编辑内容</a
            >
          </h4>
          <div v-html="tagChild.content"></div>
          <h4 class="text-dark mt-2">
            绑定个股：
            <a
              href="javascript:void(0)"
              style="color: #2f75b5; font-size: 13px"
              @click="addStock(tagIndex, childTagIndex, tag.id)"
            >
              新增
            </a>
          </h4>
          <div
            v-for="(stock, stockIndex) in tagChild.stocks"
            style="
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 400px;
            "
            :style="{ color: stock.isHot ? '#bf0000' : '#2f75b5' }"
          >
            <div>{{ stock.name }}({{ stock.code }})</div>
            <div>
              <a
                href="javascript:void(0)"
                style="font-size: 13px; margin-right: 5px"
                :style="{ color: stock.isHot ? '#2f75b5' : '#bf0000' }"
                @click="setHot(stock.code, childTagIndex, tagIndex, tag.id)"
                >{{ stock.isHot ? "取消热点" : "设为热点" }}
              </a>
              <a
                @click="
                  deleteStock(stock.code, childTagIndex, tagIndex, tag.id)
                "
                href="javascript:void(0)"
                style="color: #bf0000; font-size: 13px; margin-right: 20px"
              >
                删除
              </a>
              <a
                @click="
                  moveStock('up', stockIndex, childTagIndex, tagIndex, tag.id)
                "
                style="color: #2f75b5; font-size: 20px; margin-right: 20px"
              >
                ↑
              </a>
              <a
                @click="
                  moveStock('down', stockIndex, childTagIndex, tagIndex, tag.id)
                "
                style="color: #2f75b5; font-size: 20px; margin-right: 20px"
              >
                ↓
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import ReviewEditor from "@/components/Review/dayReview/ReviewEditor";
import AddStock from "@/components/Review/dayReview/AddStock";
import { v4 as uuidv4 } from "uuid";
export default {
  components: { ReviewEditor },
  props: {
    oldContent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      editContent: null,
      activeTag: 999999,
      lockArr: [],
      lockWatcher: null,
    };
  },
  destroyed() {
    Bus.$off("editData");
    Bus.$off("addStock");
    Bus.$off("editDayReviewContent");
    window.Echo.private(
      "laravel_database_content.change." + this.editContent.id
    ).stopListening(".review.notice");
    window.Echo.private(
      "laravel_database_content.lock." + this.editContent.id
    ).stopListening(".review.notice");
    clearInterval(this.lockWatcher);
  },

  mounted() {
    //监听新增和修改事件
    Bus.$on("editDayReviewContent", (e) => {
      switch (e.type) {
        case "editTagChildName":
          this.editContent.content.tableData.labels[e.tagIndex].child[
            e.childTagIndex
          ].name = e.content;
          this.saveData(e.tagId);
          break;
        case "editTagName":
          this.editContent.content.tableData.labels[e.tagIndex].name =
            e.content;
          this.saveData(e.tagId);
          break;
        case "editIntro":
          let arr = JSON.parse(
            JSON.stringify(this.editContent.content.tableData.intro)
          );
          arr[e.introIndex] = e.content;
          this.$set(this.editContent.content.tableData, "intro", arr);
          this.saveData(e.introIndex, "edit", "intro");
          break;
        case "editTagChildContent":
          this.editContent.content.tableData.labels[e.tagIndex].child[
            e.childTagIndex
          ].content = e.content;
          this.saveData(e.tagId);
          break;
        case "addTag":
          let flag = true;
          this.editContent.content.tableData.labels.forEach((item) => {
            if (item.name == e.content) {
              flag = false;
            }
          });
          if (flag) {
            let newcontent = {
              id: uuidv4(),
              name: e.content,
              child: [],
            };
            this.editContent.content.tableData.labels.push(newcontent);
            console.log(this.editContent.content.tableData.labels);
            this.saveData(newcontent, "add", "tag");
          } else {
            this.showToast("标签名重复", "danger", 5000);
          }
          break;
        case "addChildTag":
          this.editContent.content.tableData.labels[e.tagIndex].child.push({
            name: e.content,
            content: "",
            stocks: [],
          });
          //添加后展开
          this.activeTag = e.tagIndex;
          this.saveData(e.tagId, "edit", "tag");
          break;
        case "addStock":
          let flag1 = true;
          this.editContent.content.tableData.labels[e.tagIndex].child[
            e.childTagIndex
          ].stocks.forEach((item) => {
            if (item.code == e.code) {
              flag1 = false;
            }
          });
          if (flag1) {
            this.editContent.content.tableData.labels[e.tagIndex].child[
              e.childTagIndex
            ].stocks.push({
              name: e.name,
              code: e.code,
              isHot: false,
            });
            this.showToast(
              "绑定股票成功，您可以继续选择其他股票绑定！",
              "success",
              5000
            );
            this.saveData(e.tagId, "edit", "tag");
          } else {
            this.showToast("股票重复", "danger", 5000);
          }

          break;
        case "addIntro":
          this.editContent.content.tableData.intro.push(e.content);
          this.saveData(e.content, "add", "intro");
          break;
        default:
          break;
      }
    });
  },
  methods: {
    getFatherSpan(item) {
      let span = 0;
      item.child.forEach((item) => {
        span += item.stocks.length;
      });
      return span;
    },
    moveStock(type, stockIndex, childTagIndex, tagIndex, tagId) {
      //移动股票
      let temp =
        this.editContent.content.tableData.labels[tagIndex].child[childTagIndex]
          .stocks[stockIndex];

      if (type == "up") {
        if (stockIndex == 0) {
          this.showToast("已经是第一个了", "warning", 5000);
          return;
        }

        this.$set(
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks,
          stockIndex,
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks[stockIndex - 1]
        );
        this.$set(
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks,
          stockIndex - 1,
          temp
        );
        this.saveData(tagId, "edit", "tag");
      } else {
        if (
          stockIndex ==
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks.length -
            1
        ) {
          this.showToast("已经是最后一个了", "warning", 5000);
          return;
        }

        this.$set(
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks,
          stockIndex,
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks[stockIndex + 1]
        );
        this.$set(
          this.editContent.content.tableData.labels[tagIndex].child[
            childTagIndex
          ].stocks,
          stockIndex + 1,
          temp
        );
        this.saveData(tagId, "edit", "tag");
      }
    },
    deleteStock(code, childTagIndex, tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      this.$layer.confirm("确定要删除这支绑定的股票吗？", (layerid) => {
        this.editContent.content.tableData.labels[tagIndex].child[
          childTagIndex
        ].stocks = this.editContent.content.tableData.labels[tagIndex].child[
          childTagIndex
        ].stocks.filter((item) => {
          return item.code != code;
        });
        this.saveData(tagId, "edit", "tag");
        this.$layer.close(layerid);
      });
    },
    deleteTagChild(childTagIndex, tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      this.$layer.confirm("确定要删除这个子标签吗？", (layerid) => {
        this.editContent.content.tableData.labels[tagIndex].child.splice(
          childTagIndex,
          1
        );
        this.saveData(tagId, "edit", "tag");
        this.$layer.close(layerid);
      });
    },
    deleteTag(tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      this.$layer.confirm("确定要删除这个标签吗？", (layerid) => {
        this.editContent.content.tableData.labels.splice(tagIndex, 1);
        this.saveData(tagId, "delete", "tag");
        this.$layer.close(layerid);
      });
    },
    deleteIntro(introIndex) {
      this.$layer.confirm("确定要删除这个点评吗？", (layerid) => {
        this.editContent.content.tableData.intro.splice(introIndex, 1);
        this.saveData(introIndex, "delete", "intro");
        this.$layer.close(layerid);
      });
    },
    editData(oldValue, title, type, extraInfo, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }

      this.$layer.iframe({
        content: {
          content: ReviewEditor,
          parent: this,
          data: {
            iframeData: {
              id: this.editContent.id,
              tagId: tagId,
              type: type,
              oldValue: oldValue,
              extraInfo: extraInfo,
            },
          },
        },
        area: ["1000px", "700px"],
        title: title,
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    addStock(tagIndex, childTagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      this.$layer.iframe({
        content: {
          content: AddStock,
          parent: this,
          data: {
            iframeData: {
              id: this.editContent.id,
              tagId: tagId,
              tagIndex: tagIndex,
              childTagIndex: childTagIndex,
            },
          },
        },
        area: ["600px", "200px"],
        title: "添加股票",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    setHot(code, childTagIndex, tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      this.editContent.content.tableData.labels[tagIndex].child[
        childTagIndex
      ].stocks.forEach((item) => {
        if (item.code == code) {
          item.isHot = !item.isHot;
        }
      });
      this.saveData(tagId, "edit", "tag");
    },
    setLock(tagId) {
      Review.setReviewLockStatus(tagId, this.editContent.id)
        .then((res) => {
          console.log("上锁成功：", res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    getLockStatus(tagId) {
      Review.getReviewLockStatus(tagId, this.editContent.id)
        .then((res) => {
          //console.log('查询锁定状态：', tagId, res.data);
          if (res.data) {
            if (this.lockArr.indexOf(tagId) == -1) {
              this.lockArr.push(tagId);
            }
          } else {
            if (this.lockArr.indexOf(tagId) != -1) {
              this.lockArr.splice(this.lockArr.indexOf(tagId), 1);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    saveData(target = "", type = "edit", position = "tag") {
      this.showLoading("保存修改中，请稍候");
      let newContent = {};
      if (position == "tag" && type == "edit") {
        newContent = this.editContent.content.tableData.labels.find(
          (item) => item.id == target
        );
      }
      if (position == "intro" && type == "edit") {
        newContent = this.editContent.content.tableData.intro[target];
      }
      if (type == "add") {
        newContent = target;
      }
      //console.log(target, newContent);
      Review.saveReviewData(
        this.editContent.content,
        {
          target: target,
          type: type,
          position: position,
          newcontent: newContent,
        },
        this.editContent.id
      )
        .then((res) => {
          //console.log('已保存内容', target);
          //Bus.$emit('refreshReviewDayList', {});
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    moveParentUp(tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      //移动父标签位置
      if (tagIndex == 0) {
        this.showToast("已经是第一个了", "warning", 5000);
        return;
      }
      let temp = this.editContent.content.tableData.labels[tagIndex];
      this.$set(
        this.editContent.content.tableData.labels,
        tagIndex,
        this.editContent.content.tableData.labels[tagIndex - 1]
      );
      this.$set(this.editContent.content.tableData.labels, tagIndex - 1, temp);
      this.saveData(tagId, "edit", "tag");
    },
    moveParentDown(tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      //移动父标签位置
      if (tagIndex == this.editContent.content.tableData.labels.length - 1) {
        this.showToast("已经是最后一个了", "warning", 5000);
        return;
      }
      let temp = this.editContent.content.tableData.labels[tagIndex];
      this.$set(
        this.editContent.content.tableData.labels,
        tagIndex,
        this.editContent.content.tableData.labels[tagIndex + 1]
      );
      this.$set(this.editContent.content.tableData.labels, tagIndex + 1, temp);
      this.saveData(tagId, "edit", "tag");
    },
    moveChildUp(childIndex, tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      //移动子标签位置
      if (childIndex == 0) {
        this.showToast("已经是第一个了", "warning", 5000);
        return;
      }
      let temp =
        this.editContent.content.tableData.labels[tagIndex].child[childIndex];
      this.$set(
        this.editContent.content.tableData.labels[tagIndex].child,
        childIndex,
        this.editContent.content.tableData.labels[tagIndex].child[
          childIndex - 1
        ]
      );
      this.$set(
        this.editContent.content.tableData.labels[tagIndex].child,
        childIndex - 1,
        temp
      );
      this.saveData(tagId, "edit", "tag");
    },
    moveChildDown(childIndex, tagIndex, tagId) {
      //检查是否上锁
      if (this.lockArr.indexOf(tagId) != -1) {
        this.showToast(
          "该父标签正在被他人编辑，请解锁后再尝试！",
          "warning",
          5000
        );
        return;
      }
      //移动子标签位置
      if (
        childIndex ==
        this.editContent.content.tableData.labels[tagIndex].child.length - 1
      ) {
        this.showToast("已经是最后一个了", "warning", 5000);
        return;
      }
      let temp =
        this.editContent.content.tableData.labels[tagIndex].child[childIndex];
      this.$set(
        this.editContent.content.tableData.labels[tagIndex].child,
        childIndex,
        this.editContent.content.tableData.labels[tagIndex].child[
          childIndex + 1
        ]
      );
      this.$set(
        this.editContent.content.tableData.labels[tagIndex].child,
        childIndex + 1,
        temp
      );
      this.saveData(tagId, "edit", "tag");
    },
  },
  watch: {
    oldContent: {
      handler(newVal, oldVal) {
        this.editContent = JSON.parse(JSON.stringify(newVal));
        //console.log(this.editContent);

        //监听锁定和内容更改
        window.Echo.channel(
          "laravel_database_content.change." + this.editContent.id
        ).listen(".review.notice", (e) => {
          //忽略自己的操作
          if (this.userStore.user_id == e.user_id) {
            return;
          }
          console.log(e);

          if (e.unit_data.position == "tag" && e.unit_data.type == "edit") {
            console.log("修改了一个标签");
            this.editContent.content.tableData.labels.forEach((item, index) => {
              if (item.id == e.unit_data.target) {
                this.$set(
                  this.editContent.content.tableData.labels,
                  index,
                  e.unit_data.newcontent
                );
              }
            });
          } else if (
            e.unit_data.position == "tag" &&
            e.unit_data.type == "add"
          ) {
            console.log("新增了一个标签");
            this.editContent.content.tableData.labels.push(
              e.unit_data.newcontent
            );
          } else if (
            e.unit_data.position == "tag" &&
            e.unit_data.type == "delete"
          ) {
            console.log("删除了一个标签");
            this.editContent.content.tableData.labels.forEach((item, index) => {
              if (item.id == e.unit_data.target) {
                this.editContent.content.tableData.labels.splice(index, 1);
              }
            });
          } else if (
            e.unit_data.position == "intro" &&
            e.unit_data.type == "edit"
          ) {
            console.log("修改了一条点评");
            this.$set(
              this.editContent.content.tableData.intro,
              e.unit_data.target,
              e.unit_data.newcontent
            );
          } else if (
            e.unit_data.position == "intro" &&
            e.unit_data.type == "add"
          ) {
            console.log("添加了一条点评");
            this.editContent.content.tableData.intro.push(
              e.unit_data.newcontent
            );
          } else if (
            e.unit_data.position == "intro" &&
            e.unit_data.type == "delete"
          ) {
            console.log("删除了一条点评");
            this.editContent.content.tableData.intro.splice(
              e.unit_data.target,
              1
            );
          }
        });
        window.Echo.channel(
          "laravel_database_content.lock." + this.editContent.id
        ).listen(".review.notice", (e) => {
          if (e.status) {
            //执行上锁操作
            if (this.lockArr.indexOf(e.unit) == -1) {
              this.lockArr.push(e.unit);
            }
            //console.log('上锁：', e.unit);
          } else {
            //执行解锁操作
            if (this.lockArr.indexOf(e.unit) != -1) {
              this.lockArr.splice(this.lockArr.indexOf(e.unit), 1);
            }
            //console.log('解锁：', e.unit);
          }
        });

        //心跳检测锁定状态
        this.editContent.content.tableData.labels.forEach((item) => {
          this.getLockStatus(item.id);
        });
        this.lockWatcher = setInterval(() => {
          this.editContent.content.tableData.labels.forEach((item) => {
            this.getLockStatus(item.id);
          });
        }, 5000);
      },
      deep: true,
    },
  },
};
</script>
<style>
.table_text {
  font-size: 14px;
  text-align: center;
  /* 让表格边线为单条线 */
  border-collapse: collapse !important;
  border: 1px solid #000;
}
.lockedData {
  background: linear-gradient(
    45deg,
    rgba(161, 161, 161, 0.5) 0,
    rgba(161, 161, 161, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(161, 161, 161, 0.5) 50%,
    rgba(161, 161, 161, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
}
</style>
