<template>
  <v-card class="mx-auto" max-width="800">
    <v-toolbar flat color="transparent">
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>自选个股</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.search.focus()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="py-0">
      <v-row align="center" justify="start">
        <v-col
          v-for="(selection, i) in selections"
          :key="selection.id"
          class="shrink"
        >
          <v-chip :disabled="loading" close @click:close="deleteCollect(i)">
            <!-- <v-icon left v-text="selection.icon"></v-icon> -->
            {{ selection.name }}
          </v-chip>
        </v-col>

        <v-col cols="12">
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            label="搜索"
            single-line
            @focus="getStockList"
            @input="getStockList"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-divider v-if="!allSelected"></v-divider>
    <v-card-text>
      <v-list height="300px">
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="JSON.stringify(selected).indexOf(item.stock_id) == -1"
            :key="index"
            :disabled="loading"
            @click="selected.push(item)"
          >
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="waring" text @click="closeW"> 关闭 </v-btn>
      <v-btn
        :disabled="!selected.length"
        :loading="loading"
        color="purple"
        text
        @click="next"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import User from "@/api/user.js";
import Stock from "@/api/stock.js";
import { mount } from "sortablejs";
import { json } from "d3";
export default {
  data() {
    return {
      items: [],
      loading: false,
      search: "",
      selected: [],
      timeout: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },

    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    show: {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true,
    },
  },

  methods: {
    init() {
      this.selected = [];
      this.loading = true;
      User.collect()
        .then((res) => {
          console.log(res.data);
          res.data.stocks.forEach((item) => {
            this.selected.push({
              id: item.id,
              name: item.stock.name,
              stock_id: item.stock_id,
            });
          });
          console.log(this.selected);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      this.loading = true;
      let collectStock = [];
      this.selected.forEach((item) => {
        collectStock.push(item.stock_id);
      });

      setTimeout(() => {
        User.stock({ stock_ids: collectStock })
          .then((res) => {
            console.log(res);
            this.search = "";
            this.selected = [];
            this.$emit("close");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 2000);
    },
    closeW() {
      this.$emit("closeW");
    },
    getStockList() {
      this.items = [];
      clearTimeout(this.timeout);
      // 设置新的定时器
      this.timeout = setTimeout(() => {
        // 在此处执行需要防抖的操作
        Stock.searchStockList(this.search, 0, 1)
          .then((res) => {
            res.data.forEach((item) => {
              this.items.push({ stock_id: item.id, name: item.name });
            });
            console.log(this.items);
          })

          .catch((err) => {
            console.log(err);
          });
      }, 1000); // 防抖的时间设置为300毫秒
    },
    deleteCollect(i) {
      this.loading = true;
      User.stockDelete(this.selected[i].id)
        .then((res) => {
          this.selected.splice(i, 1);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
