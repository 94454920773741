import Request from "@/components/request/js/index.js";

export default {
  //股票详情
  getStockDetail(id) {
    return Request().get("/api/stock_pools/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //股票详情K线
  getKLine(code) {
    return Request().post("/api/stock/close_prices", {
      header: {
        contentType: "application/json",
      },
      data: {
        code: code,
      },
    });
  },
  //股票详情PE
  getPEBand(code, start_date, end_date) {
    return Request().post("/api/stock/pe_pb_band", {
      header: {
        contentType: "application/json",
      },
      data: {
        code: code,
        start_date: start_date,
        end_date: end_date,
      },
    });
  },
  //股票详情因子得分详情
  getStockFactorDetail(stock_id, start_date, end_date) {
    return Request().post("/api/stock/stock_factor_rate", {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_id: stock_id,
        start_date: start_date,
        end_date: end_date,
      },
    });
  },
  //股票列表
  getStockList(name) {
    return Request().get("/api/stock/list", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
      },
    });
  },
  //股票搜索
  searchStockList(name, onlyA, onlyPool, not_us = 0) {
    return Request().get("/api/stock/search", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
        in_pool: onlyPool,
        cn: onlyA,
        not_us: not_us,
      },
    });
  },
  //申万3级行业列表
  getIndustryList() {
    return Request().get("/api/industry/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //申万2级行业列表
  getIndustryList2() {
    return Request().get("/api/industry/list/sw_level2", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //赛道列表
  getRaceList(isSelect = 0) {
    let data = {};
    if (isSelect == 1) {
      data.isSelect = 1;
    }
    return Request().get("/api/race/list", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //风格列表
  getStyleList() {
    return Request().get("/api/style/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //股票绑定的文件列表
  getFileList(id) {
    return Request().get("/api/stock/" + id + "/file", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //资料库文件列表
  getAllFileList(data) {
    return Request().get("/api/file/list", {
      header: {
        contentType: "application/json",
      },
      data: { ...data },
    });
  },
  //实时数据
  getRealtimeData(codes) {
    return Request().post("/api/real_time/daily", {
      header: {
        contentType: "application/json",
      },
      data: { codes: codes },
    });
  },
  //标签绑定的关键字列表
  getKeywordList(id) {
    return Request().get("/api/keywords/race/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
