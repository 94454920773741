<template>
  <vxe-pulldown ref="colEdit" destroy-on-close class="" transfer>
    <template #default>
      <vxe-button
        @click="$refs.colEdit.togglePanel()"
        size="medium"
        content="字段设置"
        status="my-primary"
        class="mx-1 px-1"
      ></vxe-button>
    </template>
    <template #dropdown>
      <div class="roles-dropdown" style="width: 500px; border: 1px solid #ccc">
        <div
          style="padding: 5px; display: flex; justify-content: space-between"
        >
          <p style="padding-top: 5px; color: #ff0000">修改后请及时保存</p>
          <div>
            <vxe-input
              v-model="newRowInput"
              placeholder="输入新字段"
              style="width: 100px"
            ></vxe-input>
            <vxe-button
              status="my-primary"
              @click="addRow()"
              size="small"
              content="新建字段"
              v-if="$commonHelpers.getPermissions('tagStore.editTag')"
            ></vxe-button>
          </div>

          <vxe-button
            status="my-danger"
            @click="saveColDisplay()"
            size="small"
            content="保存"
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          ></vxe-button>
        </div>
        <vxe-table
          auto-resize
          :data="appStore.tagDetail.labels"
          :checkbox-config="{ range: true, checkField: 'show' }"
          height="300px"
          ref="colEditTable"
          :edit-config="{
            trigger: 'click',
            mode: 'cell',
            icon: ' ',
            activeMethod: activeCellMethod,
          }"
        >
          <vxe-column
            field="name"
            title="字段名"
            :edit-render="{ name: 'input' }"
          >
            <template #edit="{ row }">
              <vxe-input v-model="row.name" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column
            field="width"
            width="60px"
            title="列宽"
            :edit-render="{ name: 'input' }"
          >
            <!-- <template #default="{ row }">
              {{ row.type === 1 ? "固定" : row.width }}
            </template> -->
            <template #edit="{ row }">
              <vxe-input
                v-model="row.width"
                type="integer"
                step="1"
                min="60"
              ></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="is_progress" title="进度条" width="80px">
            <template #default="{ row }">
              <vxe-switch
                v-model="row.is_progress"
                open-label="开"
                close-label="关"
                :open-value="1"
                :close-value="0"
              ></vxe-switch>
            </template>
          </vxe-column>
          <vxe-column type="checkbox" width="60px" title="显隐"></vxe-column>
          <vxe-column
            title="操作"
            type="seq"
            width="100"
            :resizable="false"
            show-overflow
          >
            <template #default="{ row }">
              <div>
                <a
                  href="javascript:;"
                  class="text-xs font-weight-normal text-typo"
                  style="margin-right: 5px"
                  @click="moveToUp(row)"
                  >上移</a
                >
                <a
                  href="javascript:;"
                  class="text-xs font-weight-normal text-typo mr-1"
                  style="margin-right: 5px"
                  @click="moveToDown(row)"
                  >下移</a
                >
                <a
                  href="javascript:;"
                  class="text-xs font-weight-normal text-red mr-1"
                  @click="deleteRow(row)"
                  v-if="row.type == 3"
                  >删除</a
                >
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";
import { set } from "d3";

export default {
  name: "GroupOrder",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      newRowInput: "",
    };
  },
  methods: {
    //设置默认宽度
    setDefaultWidth() {
      //console.log(this.appStore.tagDetail.labels);
      this.appStore.tagDetail.labels.forEach((item) => {
        if (!item.width) {
          switch (item.key) {
            case "grossprofitmargin":
              item.width = 70;
              break;
            case "debttoassets":
              item.width = 70;
              break;
            case "mkt_cap_ard_million":
              item.width = 70;
              break;
            case "pe_ttm":
              item.width = 60;
              break;
            case "pb":
              item.width = 50;
              break;
            case "ps":
              item.width = 50;
              break;
            case "netprofitmargin":
              item.width = 70;
              break;
            default:
              item.width = 100;
              break;
          }
        }
      });
    },
    //保存字段显隐的修改
    saveColDisplay() {
      this.showLoading("保存中，请稍候");

      this.setDefaultWidth();

      Tag.editTag(this.appStore.tagDetail.id, {
        labels: this.appStore.tagDetail.labels,
      })
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    //编辑自定义字段
    editRow(row) {
      this.appStore.tagDetail.tags.forEach((item) => {
        if (item.name === row.name) {
          item.info = row.info;
        }
      });
    },
    activeCellMethod({ row, column }) {
      if (row.type != 1) {
        return true;
      } else {
        return false;
      }
    },
    moveToUp(row) {
      const filteredIndex = this.appStore.tagDetail.labels.findIndex(
        (item) => item.name === row.name
      );
      if (filteredIndex === 0) {
        this.showToast("已经是第一位了", "danger", 3000);
        return;
      }
      //更新表格
      const temp = this.appStore.tagDetail.labels[filteredIndex];
      this.appStore.tagDetail.labels[filteredIndex] =
        this.appStore.tagDetail.labels[filteredIndex - 1];
      this.appStore.tagDetail.labels[filteredIndex - 1] = temp;

      this.$refs.colEditTable.loadData(this.appStore.tagDetail.labels);
    },
    moveToDown(row) {
      const filteredIndex = this.appStore.tagDetail.labels.findIndex(
        (item) => item.name === row.name
      );
      if (filteredIndex === this.appStore.tagDetail.labels.length - 1) {
        this.showToast("已经是最后一位了", "danger", 3000);
        return;
      }
      //更新表格
      const temp = this.appStore.tagDetail.labels[filteredIndex];
      this.appStore.tagDetail.labels[filteredIndex] =
        this.appStore.tagDetail.labels[filteredIndex + 1];
      this.appStore.tagDetail.labels[filteredIndex + 1] = temp;

      this.$refs.colEditTable.loadData(this.appStore.tagDetail.labels);
    },
    deleteRow(row) {
      const filteredIndex = this.appStore.tagDetail.labels.findIndex(
        (item) => item.name === row.name
      );
      this.appStore.tagDetail.labels.splice(filteredIndex, 1);
      this.$refs.colEditTable.loadData(this.appStore.tagDetail.labels);
    },
    addRow() {
      if (!this.newRowInput) {
        this.showToast("请输入字段名", "danger", 3000);
        return;
      }
      const filteredIndex = this.appStore.tagDetail.labels.findIndex(
        (item) => item.name === this.newRowInput
      );
      if (filteredIndex !== -1) {
        this.showToast("字段名已存在", "danger", 3000);
        return;
      }
      this.appStore.tagDetail.labels.unshift({
        width: 100,
        name: this.newRowInput,
        show: true,
      });
      this.$refs.colEditTable.loadData(this.appStore.tagDetail.labels);
      this.newRowInput = "";
    },
  },
  computed: {},
  watch: {
    //监听labels的变化
    "appStore.tagDetail.labels": {
      handler: function (val, oldVal) {
        if (val) {
          setTimeout(() => {
            this.setDefaultWidth();
            if (this.$refs.colEditTable) {
              this.$refs.colEditTable.loadData(this.appStore.tagDetail.labels);
            }
          }, 200);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped="scoped"></style>
