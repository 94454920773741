import { defineStore } from 'pinia';

export const useAppStore = defineStore({
  id: 'app',
  // 一个返回新状态的函数
  state: () => ({
    loadingVisible: false,

    drawer: true,
    mini: true,

    reviewTabList: [],

    printTitle: '',
    printContent: '',

    tagDetail: {},
    tempMonth:[]
  }),
  getters: {},
  actions: {},
});
