var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({'height': _vm.pageHeight+'px'})},[_c('vxe-table',{ref:"recommendStockTable",staticClass:"mytable-scrollbar",attrs:{"id":"recommendStockTable","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.tableLoading,"column-config":{ resizable: true },"height":"100%","size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"export-config":{},"filter-config":{ showIcon: false }}},[_c('vxe-column',{attrs:{"fixed":"left","width":"70","field":"stock_code","title":"股票代码"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_code ? row.stock_code : "-"))])])]}}])}),_c('vxe-column',{attrs:{"fixed":"left","width":"80","field":"stock_name","title":"股票名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_name ? row.stock_name : "-"))])])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"user_name","title":"推荐人","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'user_name'); }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var $panel = ref.$panel;
var column = ref.column;
return [_vm._l((column.filters),function(option,index){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.data),expression:"option.data"}],key:index,staticClass:"pa-2",attrs:{"type":"type","placeholder":"按回车确认筛选"},domProps:{"value":(option.data)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(option, "data", $event.target.value)},function($event){return $panel.changeOption($event, !!option.data, option)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $panel.confirmFilter()}}})]})]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.user_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"min-width":"150","field":"reason","title":"推荐理由"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.reason)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"rise_per","title":"预期空间"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s((row.rise_per * 100).toFixed(0) + "%"))])])]}}])}),_c('vxe-column',{attrs:{"width":"110","field":"created_at","title":"推荐时间"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.created_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"end_date","title":"结束时间"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.end_date)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"applies_focus","title":"推荐后涨幅","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_focus))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_focus)))])])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }