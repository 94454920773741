<template>
  <div>
    <!-- <vxe-toolbar class="" custom>
      <template v-slot:buttons>
        <div class="d-flex align-items-center">
          <vxe-input
            v-model="filterName"
            type="search"
            placeholder="搜文件"
            class="mx-1"
            size="medium"
            style="width: 100px"
          ></vxe-input>
        </div>
      </template>
    </vxe-toolbar> -->
    <vxe-table
      :data="tableData"
      :loading="bindFileTableLoading"
      border
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="fileTable"
      :column-config="{ resizable: true }"
      :height="fileTableHeight"
      size="mini"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
      :checkbox-config="{ trigger: 'cell', range: true }"
      align="center"
      :edit-config="{ trigger: 'click', mode: 'cell', icon: ' ' }"
    >
      <vxe-column min-width="200" field="topic" title="会议名">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.name }}
          </div>
        </template>
      </vxe-column>

      <vxe-column width="120" field="created_at" title="上传时间">
        <template #default="{ row }">
          {{ row.created_at }}
        </template>
      </vxe-column>
      <vxe-column
        title="操作"
        type="seq"
        width="80"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <a
            href="javascript:;"
            @click="previewFile(row)"
            class="text-xs font-weight-normal text-typo mr-1"
            >预览</a
          >
          <!-- <a
            href="javascript:;"
            @click="downloadFile(row)"
            class="text-xs font-weight-normal text-typo mr-1"
            >下载</a
          > -->
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-pager
      :current-page="pageInfo.currentPage"
      :page-size="pageInfo.pageSize"
      :total="this.pageInfo.totalResult"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
      @page-change="(value) => getPage(value)"
    >
    </vxe-pager>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Stock from "@/api/stock.js";
import XEUtils from "xe-utils";
export default {
  name: "BindFile",
  components: {},
  props: {
    currTag: "",
    stock: "",
    fileTableHeight: "",
  },

  data() {
    return {
      borderhover: false,

      currKey: -1,

      tableData: [],
      filterName: "",

      bindFileTableLoading: false,
      files: [],

      pageInfo: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
      },
    };
  },
  computed: {},
  mounted() {
    if (this.fileTableHeight == "") {
      this.fileTableHeight = document.body.offsetHeight - 740 + "px";
    }

    if (this.currTag) {
      this.getFileByTag();
    } else {
      this.getFileByStock();
    }
  },
  methods: {
    getFileByTag() {
      this.bindFileTableLoading = true;
      File.getFileByTag(this.pageInfo.currentPage, this.currTag.id, "外部会议")
        .then((res) => {
          this.tableData = res.data.data;
          this.pageInfo.totalResult = res.data.total;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.bindFileTableLoading = false;
        });
    },
    getFileByStock() {
      this.bindFileTableLoading = true;
      File.getFileByStock(this.pageInfo.currentPage, this.stock, "外部会议")
        .then((res) => {
          this.tableData = res.data.data;
          this.pageInfo.totalResult = res.data.total;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.bindFileTableLoading = false;
        });
    },
    getText(text) {
      text = text.replace(/<br>/g, "\n").replace(/<br\/>/g, "\n");
      return text;
    },
    getPage(value) {
      this.pageInfo.currentPage = value.currentPage;
      if (this.currTag) {
        this.getFileByTag();
      } else {
        this.getFileByStock();
      }
    },
    getFile(id) {
      return new Promise((resolve, reject) => {
        File.getFile(id)
          .then((res) => {
            resolve(res.data.url);
          })
          .catch((err) => {
            this.showToast(err.msg, "danger", 10000);
            reject();
          });
      });
    },
    async previewFile(row) {
      await this.getFile(row.id)
        .then((url) => {
          console.log(url);

          Bus.$emit("previewFile", { url: url, name: row.name });
        })
        .catch(() => {});
    },
    async downloadFile(row) {
      await this.getFile(row.id)
        .then((url) => {
          console.log(url);

          window.open(url);
        })
        .catch(() => {});
    },
  },
  watch: {
    currKey(val) {
      if (val == -1) {
        this.$tableHelpers.filterInput(this.$refs.fileTable, "tags", "");
      } else {
        this.$tableHelpers.filterInput(
          this.$refs.fileTable,
          "tags",
          this.tagArr[val]
        );
      }
    },
    currTag() {
      let data = JSON.parse(JSON.stringify(this.currTag.file));
      data.forEach((item, index) => {
        data[index].tags = item.tags.toString();
      });
      this.tableData = data;
    },
  },
};
</script>
<style scoped="scoped"></style>
