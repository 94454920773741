<template>
  <v-container fluid class="px-6 py-1">
    <v-card class="border-radius-xl mt-3 pt-0 pb-4">
      <StockList :tableHight="tableHeight"></StockList>
    </v-card>

    <v-card class="border-radius-xl mt-2 pt-0 pb-4">
      <UserList :tableHight="tableHeight"></UserList>
    </v-card>
  </v-container>
</template>

<script>
import StockList from '@/components/StockRecommend/StockList.vue';
import UserList from '@/components/StockRecommend/UserList.vue';

import Bus from '@/components/Bus/bus.js';
export default {
  name: 'StockRecommend',
  components: {
    StockList,
    UserList,
  },
  computed: {},
  data() {
    return {
      tableHeight: '500',
    };
  },
  mounted() {
    this.tableHeight = (document.body.clientHeight - 500) / 2;
  },
  methods: {},
};
</script>
