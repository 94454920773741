<template>
  <v-card class="mx-auto" max-width="800">
    <!-- <v-toolbar flat color="transparent">
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>自选标签</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.search.focus()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar> -->

    <v-container class="py-0">
      <div style="display: flex; justify-content: space-between">
        <TagListWithLevel
          ref="TagListWithLevel"
          :pageHeight="800"
          @tagClick="tagClick"
          :selectedIds="selectedIds"
        ></TagListWithLevel>

        <div>
          <vxe-input
            v-model="filterName"
            type="search"
            placeholder="标签搜索"
            @keyup="searchEvent"
            style="width: 200px"
          ></vxe-input>
          <vxe-table
            :data="selected"
            style="width: 200px"
            size="small"
            :height="620 + 'px'"
            :stripe="true"
          >
            <vxe-column
              field="name"
              :title="'已选择 (' + selected.length + '个)'"
            >
              <template #default="{ row }">
                <div
                  style="
                    cursor: pointer;
                    width: 100%;
                    height: 20px;
                    justify-content: space-between;
                  "
                  class="pa-1 d-flex"
                >
                  <span>
                    {{ row.name }}
                  </span>
                  <a
                    href="javascript:;"
                    @click="deleteCollect(row)"
                    class="text-xs font-weight-normal"
                    style="color: #cd0000"
                    >删</a
                  >
                </div>
              </template>
            </vxe-column>
          </vxe-table>
        </div>
      </div>

      <!-- <v-col cols="12">
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            label="搜索"
            single-line
            @focus="getStockList"
            @input="getStockList"
          ></v-text-field>
        </v-col> -->
    </v-container>

    <!-- <v-card-text>
      <v-list height="300px">
        <template v-for="item in items">
          <v-list-item
            v-if="!JSON.stringify(selected).includes(item.race_id)"
            :key="item.id"
            :disabled="loading"
            @click="selected.push(item)"
          >
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text> -->

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="danger" text @click="closeW"> 关闭 </v-btn>
      <!-- <v-btn
        :disabled="!selected.length"
        :loading="loading"
        color="purple"
        text
        @click="next"
      >
        保存
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import User from "@/api/user.js";
import Tag from "@/api/tag.js";
import TagListWithLevel from "@/components/TagStore/TagListWithLevel";
import { mount } from "sortablejs";
import { json } from "d3";
export default {
  components: {
    TagListWithLevel,
  },
  data() {
    return {
      items: [],
      loading: false,
      search: "",
      selected: [],
      selectedIds: [],
      filterName: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },

    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    show: {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true,
    },
  },

  methods: {
    init() {
      this.selected = [];
      this.loading = true;
      User.collect()
        .then((res) => {
          console.log(res.data);
          res.data.races.forEach((item) => {
            this.selected.push({
              id: item.id,
              name: item.race.name,
              race_id: item.race_id,
            });
            this.selectedIds.push(item.race_id);
          });
          console.log(this.selected);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    next() {
      this.loading = true;
      let collectStock = [];
      this.selected.forEach((item) => {
        collectStock.push(item.race_id);
      });

      setTimeout(() => {
        User.race({ race_ids: collectStock })
          .then((res) => {
            console.log(res);
            this.showToast("保存成功", "success", 3000);
            this.init();
            // this.search = "";
            // this.selected = [];
            //this.$emit("close");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 2000);
    },
    closeW() {
      this.$emit("closeW");
      this.$emit("close");
    },
    getStockList() {
      this.items = [];
      clearTimeout(this.timeout);
      // 设置新的定时器
      this.timeout = setTimeout(() => {
        // 在此处执行需要防抖的操作
        Tag.getRaceList({ search: this.search })
          .then((res) => {
            res.data.forEach((item) => {
              this.items.push({ race_id: item.id, name: item.name });
            });
            console.log(this.items);
          })

          .catch((err) => {
            console.log(err);
          });
      }, 1000); // 防抖的时间设置为300毫秒
    },
    deleteCollect(item) {
      this.loading = true;
      this.selected.forEach((element) => {
        if (element.race_id === item.race_id) {
          User.raceDelete(element.id)
            .then((res) => {
              this.selected.splice(this.selected.indexOf(element), 1);
              this.selectedIds.splice(
                this.selectedIds.indexOf(element.race_id)
              );
              this.showToast("删除成功", "success", 3000);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    tagClick(tag) {
      console.log(tag.row.id);
      tag = tag.row;
      //先检查是否已经选择
      if (this.selectedIds.includes(tag.id)) {
        this.showToast("已经收藏过该标签", "warning", 3000);
        return;
      }
      this.selectedIds.unshift(tag.id);
      this.selected.unshift({
        id: tag.id,
        name: tag.name,
        race_id: tag.id,
      });
      this.next();
    },
    searchEvent() {
      this.$refs.TagListWithLevel.searchEvent(this.filterName);
    },
  },
  computed: {},
};
</script>
