<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 120px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          :returnItem="true"
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :disableArr="[]"
          @getItem="getStockItem"
        ></StockSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0" style="width: 135px; font-size: 14px"
          >*推荐结束时间</label
        >
        <vxe-input
          v-model="end_date"
          placeholder="推荐结束时间"
          type="date"
          style="width: 100%"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center" style="height: 100px">
        <label class="pt-0 mr-3" style="width: 120px; font-size: 14px"
          >*预期空间(%)</label
        >
        <vxe-input
          v-model="rise_per"
          placeholder=""
          type="integer"
          step="1"
          style="width: 100%"
        >
          <template #suffix>
            <span>%</span>
          </template>
        </vxe-input>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0" style="width: 105px; font-size: 14px"
          >*推荐理由</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="*推荐理由"
          class="font-size-input border text-light-input border-radius-sm"
          auto-grow
          rows="1"
          v-model="reason"
        ></v-textarea>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import StockRecommend from "@/api/stockRecommend.js";

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
export default {
  name: "AddStock",
  components: {
    StockSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
  },
  data() {
    return {
      stock_code: "",
      end_date: "",
      rise_per: 0,
      reason: "",
    };
  },
  methods: {
    getStockItem(item) {
      this.stock_code = item.code;
    },
    submit() {
      //console.log(this.stock_code, this.rise_per, this.reason);
      if (!this.stock_code) {
        this.showToast("请先选择绑定的股票", "warning", 5000);
        return;
      }
      if (!this.end_date) {
        this.showToast("请选择推荐结束的时间", "warning", 5000);
        return;
      }
      if (this.rise_per === false || this.rise_per <= 0) {
        this.showToast("请输入正确的预期空间", "warning", 5000);
        return;
      }
      if (!this.reason) {
        this.showToast("请填写推荐理由", "warning", 5000);
        return;
      }
      this.showLoading("新增中，请稍候");
      StockRecommend.addStockRecommend({
        stock_code: this.stock_code,
        end_date: this.end_date,
        rise_per: (this.rise_per / 100).toFixed(2),
        reason: this.reason,
      })
        .then((res) => {
          this.showToast("新增成功", "success", 5000);
          Bus.$emit("refreshStockRecommendList", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style></style>
