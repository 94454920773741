import Request from '@/components/request/js/index.js';

export default {
  //波动率历史
  getHistory() {
    return Request().get('/api/history_vol', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //上传波动率历史
  uploadHistory(file) {
    return Request().upload('/api/history_vol', {
      header: {
        contentType: 'application/json',
      },
      data: {
        file: file,
      },
    });
  },
};
