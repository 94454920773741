var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"height":"50px","width":"100%","display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v(" 因子打分 "),(_vm.haveChanged)?_c('span',{staticStyle:{"color":"#ff0000","opacity":"0.7","font-size":"14px","font-weight":"800"}},[_vm._v("已修改数据请及时保存")]):_vm._e(),(!_vm.haveChanged)?_c('span',{staticStyle:{"color":"#344767","font-size":"14px","font-weight":"800"}},[_vm._v("(点击因子名筛选评分历史,点击当前分修改分数)")]):_vm._e()]),_c('div',{staticStyle:{"padding-right":"10px"}},[_c('vxe-button',{attrs:{"size":"small","content":"保存 (Ctrl+S)","status":"my-danger"},on:{"click":function($event){return _vm.save()}}})],1)]),_c('div',{staticStyle:{"height":"calc(100% - 50px)"}},[_c('vxe-table',{ref:"rateFactorList",staticStyle:{"width":"100%"},attrs:{"data":_vm.rateFactorList,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.rateFactorListLoading,"height":"100%","size":"small","sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"align":"center","tree-config":{ expandAll: true, reserve: false },"edit-config":{
          trigger: 'click',
          mode: 'row',
          activeMethod: _vm.activeCellMethod,
          showUpdateStatus: true,
          showStatus: true,
          icon: ' ',
        },"keep-source":""}},[_c('vxe-column',{attrs:{"width":"70","field":"groupName","title":"分组","tree-node":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(row.groupName)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"name","title":"因子名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.factorNameClick(row)}}},[_c('div',{staticStyle:{"word-break":"break-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"50","field":"rate.last_score","title":"上期分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.last_score != '')?_c('span',[_vm._v(_vm._s(row.last_score))]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"rate.score","title":"当前分","edit-render":{ name: 'input' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.score != '')?_c('span',{style:(_vm.getScoreColor(row, true))},[_vm._v(_vm._s(row.score))]):_c('span',{staticStyle:{"color":"#cccccc"}},[_vm._v("点击打分")])]}},{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"max":100,"min":0,"type":"number","placeholder":"请打分"},on:{"change":function($event){_vm.haveChanged = true}},model:{value:(row.score),callback:function ($$v) {_vm.$set(row, "score", $$v)},expression:"row.score"}})]}}])}),_c('vxe-column',{attrs:{"min-width":"60","field":"comment","title":"得分说明","edit-render":{ name: 'textarea' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.reason != '')?_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start"}},[_c('span',[_vm._v(_vm._s(row.reason))])]):_c('span',{staticStyle:{"color":"#cccccc"}},[_vm._v("点击填写评分说明")])]}},{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-textarea',{staticStyle:{"z-index":"10"},attrs:{"placeholder":"请输入说明","autosize":{ minRows: 1, maxRows: 5 }},on:{"change":function($event){return _vm.reasonChange(row)},"focus":function($event){return _vm.reasonFocus(row)},"blur":function (value, event) { return _vm.reasonBlur(value, row); }},model:{value:(row.reason),callback:function ($$v) {_vm.$set(row, "reason", $$v)},expression:"row.reason"}})]}}])}),_c('vxe-column',{attrs:{"width":"60","field":"attachments","title":"附件"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('vxe-pulldown',{directives:[{name:"show",rawName:"v-show",value:(!row.groupName),expression:"!row.groupName"}],ref:'attachmentsPanel' + row.id,staticClass:"ml-4",attrs:{"destroy-on-close":"","transfer":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('a',{staticClass:"text-xs font-weight-normal",style:({ color: _vm.getAttachmentsColor(row.attachments) }),attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.$refs['attachmentsPanel' + row.id].togglePanel()}}},[_vm._v("附件("+_vm._s(row.attachments ? row.attachments.length : "")+")")])]},proxy:true},{key:"dropdown",fn:function(){return [_c('div',{staticClass:"note-attachments-dropdown"},[_c('div',{staticStyle:{"height":"40px","padding":"5px","display":"flex","align-items":"center"}},[_c('vxe-button',{attrs:{"status":"my-primary"},on:{"click":function($event){return _vm.uploadAttachments(row.id)}}},[_vm._v("上传 ")]),_c('p',{staticStyle:{"margin-left":"5px","font-weight":"800","color":"#a4262c"}},[_vm._v(" 上传和删除文件后请及时保存当前打分 ")])],1),_c('vxe-table',{attrs:{"auto-resize":"","data":row.attachments,"height":"300px"}},[_c('vxe-column',{attrs:{"field":"name","title":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('Photoswipe',{attrs:{"options":{
                            allowPanToNext: true,
                            spacing: 0.12,
                            mainClass: 'pswp--custom-zindex',
                            bgOpacity: 0.8,
                            mouseUsed: true,
                            loop: true,
                            pinchToClose: true,
                            closeOnScroll: false,
                            closeOnVerticalDrag: false,
                            showHideOpacity: true,
                            focus: true,
                            escKey: true,
                            arrowKeys: true,
                          }}},[_c('img',{directives:[{name:"pswp",rawName:"v-pswp",value:(row.url),expression:"row.url"}],staticClass:"me-2",attrs:{"src":row.url}})])]}}],null,true)}),_c('vxe-column',{attrs:{"field":"size","title":"大小","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var row = ref.row;
return [_vm._v(" "+_vm._s((row.size / 1024).toFixed(2) + "KB")+" ")]}}],null,true)}),_c('vxe-column',{attrs:{"title":"操作","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var row1 = ref.row;
return [_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"color":"#344767","margin-right":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.downloadAttachment(row1.url)}}},[_vm._v("下载")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"color":"#d20000","margin-right":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteAttachment(row.id, row1.id)}}},[_vm._v("删除")])]}}],null,true)})],1)],1)]},proxy:true}],null,true)})]}}])})],1)],1),_c('div',{staticStyle:{"display":"none"}},[_c('v-file-input',{attrs:{"id":'attachmentSelect' + this.stockId,"accept":"image/png,image/jpeg","prepend-icon":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }