<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="mt-3">
        <a
          href="javascript:;"
          @click="dateChange(7)"
          class="text-xs font-weight-normal text-typo ml-2"
          >近一周</a
        >
        <a
          href="javascript:;"
          @click="dateChange(30)"
          class="text-xs font-weight-normal text-typo ml-2"
          >近1月</a
        >
        <a
          href="javascript:;"
          @click="dateChange(90)"
          class="text-xs font-weight-normal text-typo ml-2"
          >近1季度</a
        >
        <a
          href="javascript:;"
          @click="dateChange(180)"
          class="text-xs font-weight-normal text-typo ml-2"
          >近半年</a
        >
        <a
          href="javascript:;"
          @click="dateChange(365)"
          class="text-xs font-weight-normal text-typo ml-2"
          >近一年</a
        >
      </div>

      <div class="d-flex align-center justify-center mt-3">
        <label class="pt-0" style="width: 135px; font-size: 14px"
          >*推荐结束时间</label
        >
        <vxe-input
          v-model="start_date"
          placeholder="推荐开始时间"
          type="date"
          style="width: 100%"
        ></vxe-input>
      </div>
      <div class="d-flex align-center justify-center mt-3">
        <label class="pt-0" style="width: 135px; font-size: 14px"
          >*推荐结束时间</label
        >
        <vxe-input
          v-model="end_date"
          placeholder="推荐结束时间"
          type="date"
          style="width: 100%"
        ></vxe-input>
      </div>
      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 120px; font-size: 13px"
          >*研究行业</label
        >
        <Shenwan2Select
          v-model="sw_level2"
          class="my-2"
          placeholder="用户研究的行业"
        ></Shenwan2Select>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/Bus/bus.js';
import moment from 'moment';
import Shenwan2Select from '@/components/CommonSelect/Shenwan2Select.vue';
export default {
  name: 'AddStock',
  components: {
    Shenwan2Select,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: '',
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
  },
  data() {
    return {
      start_date: this.iframeData.start_date,
      end_date: this.iframeData.end_date,
      sw_level2: this.iframeData.sw_level2,
    };
  },
  methods: {
    getStockItem(item) {
      this.stock_code = item.code;
    },
    submit() {
      if (!this.start_date) {
        this.showToast('请选择推荐开始的时间', 'warning', 5000);
        return;
      }
      if (!this.end_date) {
        this.showToast('请选择推荐结束的时间', 'warning', 5000);
        return;
      }
      if (!this.sw_level2) {
        this.showToast('请选择行业', 'warning', 5000);
        return;
      }
      Bus.$emit('refreshStockRecommendUserList', {
        id: this.iframeData.id,
        start_date: this.start_date,
        end_date: this.end_date,
        sw_level2: this.sw_level2,
      });
      this.$layer.close(this.layerid);
    },
    dateChange(day) {
      this.end_date = moment().format('YYYY-MM-DD');
      this.start_date = moment().subtract(day, 'days').format('YYYY-MM-DD');
      this.getData();
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style></style>
