<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          :readonly="true"
          :name="iframeData.stock.name"
          :code="iframeData.stock_code"
          v-model="submitInfo.stock_id"
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :disableArr="[]"
        ></StockSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >关注人</label
        >
        <StaffSelect
          v-model="submitInfo.focus_user_ids"
          class="my-2"
          placeholder="设置关注人"
          multiple
        ></StaffSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 14px"
          >评级</label
        >
        <v-select
          v-model="submitInfo.level"
          :items="[
            { text: '核心', value: 1 },
            { text: '一级', value: 3 },
            { text: '二级', value: 4 },
          ]"
          color="rgba(0,0,0,.6)"
          class="
            input-style
            font-size-input
            text-light-input
            placeholder-light
            border-radius-sm
            select-style
          "
          outlined
          hide-details
          single-line
          height="36"
          placeholder="评级"
        >
        </v-select>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >调入日期</label
        >
        <vxe-input
          v-model="submitInfo.created_at"
          placeholder="调入日期"
          type="date"
          class="my-2"
          style="width: 100%"
        ></vxe-input>
      </div>

      <!-- <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >行业</label
        >
        <Shenwan3Select
          v-model="submitInfo.industry_id"
          class="my-2"
          placeholder="设置行业(申万三级)"
        ></Shenwan3Select>
      </div> -->

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 13px"
          >赛道标签</label
        >
        <RaceSelect
          v-model="submitInfo.races"
          class="my-2"
          placeholder="设置赛道标签"
        ></RaceSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >公司简介</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="简短描述公司"
          class="font-size-input border text-light-input border-radius-sm my-2"
          auto-grow
          rows="3"
          v-model="submitInfo.intro"
        ></v-textarea>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*调入原因</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="逻辑/业绩/催化剂/风险提示"
          class="font-size-input border text-light-input border-radius-sm my-2"
          auto-grow
          rows="3"
          v-model="submitInfo.reason"
        ></v-textarea>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import StockPool from "@/api/stockPool.js";

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
import StaffSelect from "@/components/CommonSelect/StaffSelect.vue";
import Shenwan3Select from "@/components/CommonSelect/Shenwan3Select.vue";
import RaceSelect from "@/components/CommonSelect/RaceSelect.vue";
export default {
  name: "StockAdd",
  components: {
    StockSelect,
    StaffSelect,
    Shenwan3Select,
    RaceSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData.industry_id);
    let raceArr = [];
    this.iframeData.stock.races.forEach((e) => {
      raceArr.push(e.id);
    });
    this.submitInfo.races = raceArr;

    let styleArr = [];
    this.iframeData.stock.styles.forEach((e) => {
      styleArr.push(e.id);
    });
    this.submitInfo.styles = styleArr;

    //console.log(raceArr,styleArr)
  },
  data() {
    return {
      submitInfo: {
        stock_id: this.iframeData.stock_id,
        focus_user_ids: this.iframeData.focus_user_ids.split(","),
        level: parseInt(this.iframeData.level),
        industry_id: this.iframeData.industry_id,
        races: [],
        created_at: this.iframeData.created_at,
        pool_type: 1,
        apply_type: 1,
        intro: this.iframeData.intro,
        reason: this.iframeData.reason,
      },
    };
  },
  methods: {
    submit() {
      //console.log(this.submitInfo);return;
      if (!this.submitInfo.stock_id) {
        this.showToast("请先选择股票", "warning", 5000);
        return;
      }
      //console.log(this.submitInfo);
      this.showLoading("申请调回中，请稍候");
      StockPool.applyStock(this.submitInfo)
        .then((res) => {
          this.showToast("申请调回成功", "success", 5000);
          Bus.$emit("refreshCoreStockPool", true);
          Bus.$emit("refreshApplyStockPool", true);
          Bus.$emit("refreshCoreStockPoolOut", true);
          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
