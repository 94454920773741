import Request from "@/components/request/js/index.js";

export default {
  //速记列表
  getNoteList(title = "", type, page, pre_page = 70) {
    return Request().get("/api/notice/list", {
      header: {
        contentType: "application/json",
      },
      data: {
        title: title,
        type: type,
        page: page,
        pre_page: pre_page,
      },
    });
  },
  //分类列表
  getNoteTypeList() {
    return Request().get("/api/notice/type_list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //速记详情
  getNoteContent(id) {
    return Request().get("/api/notice/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //新建速记
  newNote(title, type, content, stock_ids, tagIds) {
    return Request().post("/api/notice", {
      header: {
        contentType: "application/json",
      },
      data: {
        title: title,
        type: type,
        content: content,
        stock_ids: stock_ids,
        race_ids: tagIds,
      },
    });
  },
  //修改速记
  editNote(id, title, content, stock_ids, tagIds) {
    return Request().post("/api/notice/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        title: title,
        content: content,
        stock_ids: stock_ids,
        race_ids: tagIds,
      },
    });
  },
  //删除速记
  deleteNote(id) {
    return Request().post(`/api/notice/${id}/delete`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //主页笔记预览
  getDashboardNote() {
    return Request().get(`/api/notice/explore`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //发送速记评论
  comment(noteId, parent_id, comment) {
    return Request().post(`/api/notice/${noteId}/comment`, {
      header: {
        contentType: "application/json",
      },
      data: {
        content: comment,
        parent_id: parent_id,
      },
    });
  },
  //删除速记评论
  deleteComment(id) {
    return Request().post(`/api/notice/${id}/deleteComment`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //导出数据
  exportData(id) {
    return Request().post(`/api/notice/${id}/exportWeeklyMeeting`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
