<template>
  <vxe-pulldown ref="groupEdit" destroy-on-close class="" transfer>
    <template #default>
      <vxe-button
        @click="$refs.groupEdit.togglePanel()"
        size="medium"
        content="分组设定"
        status="my-primary"
        class="mx-1 px-1"
      ></vxe-button>
    </template>
    <template #dropdown>
      <div
        class="roles-dropdown"
        style="border: 1px solid #ccc; border-radius: 5px"
      >
        <div
          style="padding: 5px; display: flex; justify-content: space-between"
        >
          <p style="padding-top: 5px; color: #ff0000">修改后请及时保存</p>
          <vxe-button
            status="my-danger"
            @click="submit()"
            size="small"
            content="保存"
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          ></vxe-button>
        </div>
        <vxe-table
          auto-resize
          :data="appStore.tagDetail.tags"
          :checkbox-config="{ range: true, checkField: 'show' }"
          height="300px"
          ref="orderTable"
          :edit-config="{
            trigger: 'click',
            mode: 'cell',
            icon: ' ',
            activeMethod: activeCellMethod,
          }"
        >
          <vxe-column field="name" title="字段名" width="150">
            <template #default="{ row }">
              <div style="font-size: 12px">{{ row.name }}({{ row.order }})</div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="70"
            field="cagr"
            title="分组描述"
            :edit-render="{ name: 'input' }"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end" style="font-size: 12px">
                {{ row.info ? row.info : "点击修改" }}
              </div>
            </template>
            <template #edit="{ row }">
              <vxe-input
                v-model="row.info"
                placeholder="请输入描述"
                @blur="editRow(row)"
              ></vxe-input>
            </template>
          </vxe-column>
          <vxe-column
            title="操作"
            type="seq"
            width="70"
            :resizable="false"
            show-overflow
          >
            <template #default="{ row }">
              <div>
                <a
                  href="javascript:;"
                  class="text-xs font-weight-normal text-typo"
                  style="margin-right: 5px"
                  @click="moveToUp(row.order)"
                  >上移</a
                >
                <a
                  href="javascript:;"
                  class="text-xs font-weight-normal text-typo"
                  @click="moveToDown(row.order)"
                  >下移</a
                >
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";

export default {
  name: "GroupOrder",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    submit() {
      this.showLoading("保存中，请稍候");

      Tag.editTag(this.appStore.tagDetail.id, {
        tags: this.appStore.tagDetail.tags,
      })
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    moveToUp(order) {
      const filteredIndex = this.appStore.tagDetail.tags.findIndex(
        (item) => item.order === order
      );
      if (filteredIndex === 0) {
        this.showToast("已经是第一位了", "danger", 3000);
        return;
      }
      //更新表格
      const temp = this.appStore.tagDetail.tags[filteredIndex];
      this.appStore.tagDetail.tags[filteredIndex] =
        this.appStore.tagDetail.tags[filteredIndex - 1];
      this.appStore.tagDetail.tags[filteredIndex - 1] = temp;
      this.$refs.orderTable.reloadData(this.appStore.tagDetail.tags);
      //更新字段
      this.appStore.tagDetail.tags.forEach((item, index) => {
        item.order = index;
      });
    },
    moveToDown(order) {
      const filteredIndex = this.appStore.tagDetail.tags.findIndex(
        (item) => item.order === order
      );
      if (filteredIndex === this.appStore.tagDetail.tags.length - 1) {
        this.showToast("已经是最后一位了", "danger", 3000);
        return;
      }
      //更新表格
      const temp = this.appStore.tagDetail.tags[filteredIndex];
      this.appStore.tagDetail.tags[filteredIndex] =
        this.appStore.tagDetail.tags[filteredIndex + 1];
      this.appStore.tagDetail.tags[filteredIndex + 1] = temp;
      this.$refs.orderTable.reloadData(this.appStore.tagDetail.tags);
      //更新字段
      this.appStore.tagDetail.tags.forEach((item, index) => {
        item.order = index;
      });
    },
    //编辑自定义字段
    editRow(row) {
      this.appStore.tagDetail.tags.forEach((item) => {
        if (item.name === row.name) {
          item.info = row.info;
        }
      });
    },
    activeCellMethod({ row, column }) {
      return true;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
