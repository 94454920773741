<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'lemonMessageMd',
  inheritAttrs: false,
  //如果需要使用父组件的方法，可以使用注入。
  inject: ['IMUI'],
  render() {
    //lemon-message-basic 组件对气泡框、头像、事件等信息进行了公共的处理。
    return (
      <div>
        <lemon-message-basic
          props={{ ...this.$attrs }}
          scopedSlots={{
            content: (props) => {
              //返回HTML结构
              return (
                <v-md-preview
                  text={this.getContent()}
                  style="max-width:calc( 100vw - 300px);"
                ></v-md-preview>
              );
            },
          }}
        />
        <div domPropsInnerHTML={this.getRefData()}></div>
      </div>
    );
  },
  methods: {
    getContent() {
      if (!this.$attrs.message.fullContent) return this.$attrs.message.content;
      let content = this.$attrs.message.content;
      let refArr = this.$attrs.message.fullContent.details.sourceAttributions;

      if (refArr.length == 0) {
        content = content.replace(/\[\^\d+\^\]/g, '');
      }
      refArr.forEach((item, index) => {
        content = content.replaceAll(
          `[^${index + 1}^]`,
          `<a href="${item.seeMoreUrl}" target="_blank" title="${
            item.providerDisplayName
          }" style='background-color:#d1dbfa;color:#123bb6;padding:0px 3px;margin-left:3px;border-radius: 4px;'>${
            index + 1
          }</a>`
        );
      });
      return content;
    },
    getRefData() {
      if (!this.$attrs.message.fullContent) return '';
      let refArr = this.$attrs.message.fullContent.details.sourceAttributions;
      let refData = '';
      if (refArr.length > 0) {
        refData = '引用源：';
      }
      refArr.forEach((item, index) => {
        refData += `<a href="${item.seeMoreUrl}" target="_blank" title="${
          item.providerDisplayName
        }" style="background-color:#d1dbfa;color:#123bb6;padding:0px 3px;margin-left:3px;border-radius: 4px;text-decoration: none;">${
          index + 1
        }</a>`;
      });
      return refData;
    },
  },
};
</script>
<style>
.github-markdown-body {
  padding: 0;
  margin: 0;
  height: auto !important;
  line-height: 1.3 !important;
  font-size: 14px;
}
</style>
