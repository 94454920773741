var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"width":"100%"}},[_c('div',[_c('div',{staticStyle:{"width":"300px","height":"calc(100vh - 80px)","position":"fixed"}},[_c('vxe-input',{staticStyle:{"width":"300px"},attrs:{"type":"search","placeholder":"搜索周会","size":"medium","clearable":""},on:{"change":function($event){return _vm.getNoteList()}},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('div',{staticStyle:{"width":"300px","height":"calc(100vh - 130px)"}},[_c('vxe-table',{ref:"weekTable",staticClass:"mt-2",staticStyle:{"cursor":"pointer"},attrs:{"id":"weekTable","row-id":"id","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.tableLoading,"column-config":{ resizable: true },"height":"auto","size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"tooltip-config":{
              showAll: false,
              enterable: true,
              theme: 'dark',
            }},on:{"cell-click":_vm.cellClick}},[_c('vxe-column',{attrs:{"min-width":"100","field":"title","title":"标题"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start pl-1"},[_vm._v(" "+_vm._s(row.title)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"75","field":"created_at","title":"日期","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at.split(" ")[0])+" ")]}}])}),_c('vxe-column',{attrs:{"width":"50","field":"user_name","title":"作者"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.user_name)+" ")]}}])})],1)],1)],1),_c('div',{},[_c('div',{staticStyle:{"width":"calc(100vw - 300px)","margin-left":"310px"}},[_c('NoteContent',{staticStyle:{"width":"100%"},attrs:{"readItem":_vm.readItem}}),(_vm.readItem.id)?_c('NoteComment',{staticStyle:{"width":"calc(100vw - 400px)"},attrs:{"noteId":_vm.readItem.id}}):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }