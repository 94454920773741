<template>
  <v-container fluid class="px-3 py-1 stock-detail" style="width: 100%">
    <LoadingPage
      :visible="pageLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <v-tabs-items v-model="currentTab" v-if="!pageLoading">
      <v-tab-item :key="0">
        <!-- 基本信息 -->
        <BaseInfo :stockDetail="stockDetail"></BaseInfo>
      </v-tab-item>

      <v-tab-item :key="1">
        <!-- 文件库 -->
        <File :stockDetail="stockDetail"></File>
      </v-tab-item>

      <v-tab-item :key="2">
        <!-- 信息流 -->
        <InfoCenter :stockId="stockDetail.stock.id"></InfoCenter>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import BaseInfo from "@/components/StockDetail/BaseInfo";
import File from "@/components/StockDetail/File";
import Stock from "@/api/stock.js";
import InfoCenter from "@/views/InfoCenter.vue";
export default {
  name: "StockDetail",
  components: {
    BaseInfo,
    File,
    InfoCenter,
  },
  data() {
    return {
      currentTab: 0,
      pageLoading: true,
      stockDetail: null,
    };
  },
  mounted() {
    Bus.$emit("StockDetailTabReset", 0);
    Bus.$on("StockDetailTabChange", (e) => {
      this.currentTab = e;
    });

    this.getStockDetail();
  },
  methods: {
    getStockDetail() {
      this.pageLoading = true;
      Stock.getStockDetail(this.$route.params.id)
        .then((res) => {
          this.stockDetail = res.data;
          console.log(this.stockDetail);
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.pageLoading = false;
        });
    },
  },
};
</script>
<style>
.dataZone {
  display: flex;
  justify-content: space-between;
  width: 170px;
  font-size: 10px;
}

.dataCrad {
  border: 1px solid #cccccc;
  padding: 10px 5px 10px 5px;
}
.stock-detail .v-window {
  overflow: visible;
}
</style>
