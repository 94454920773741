import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
require("@/emoji/emjoymethod");
import "@/emoji/emjoy.css";

Vue.config.productionTip = false;

//滚动条优化
import vuescroll from "vuescroll";
//滚动条优化
Vue.use(vuescroll, {
  ops: {
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: false,
      keepShow: false,
      background: "#c1c1c1",
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: "6px",
      disable: false,
    },
  }, // 在这里设置全局默认配置
  //name: 'myScroll' // 在这里自定义组件名字，默认是vueScroll
});

//pinia状态管理
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

//vxe-table表格插件
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
VXETable.setup({
  version: 0,
  zIndex: 20000, // 想多高就设置多高
  table: {
    autoResize: true,
  },
});
Vue.use(VXETable);

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

//助手函数
import stockHelpers from "@/util/stockHelpers.js";
Vue.prototype.$stockHelpers = stockHelpers;
import commonHelpers from "@/util/commonHelpers.js";
Vue.prototype.$commonHelpers = commonHelpers;
import tableHelpers from "@/util/tableHelpers.js";
Vue.prototype.$tableHelpers = tableHelpers;
import aiHelpers from "@/util/aiHelpers.js";
Vue.prototype.$aiHelpers = aiHelpers;

//vue-layer
import layer from "vue-layer";
import "vue-layer/lib/vue-layer.css";
Vue.prototype.$layer = layer(Vue);

//axios
import axios from "axios";
Vue.prototype.$axios = axios;

//线上环境检测
import User from "@/api/user.js";
if (process.env.NODE_ENV !== "production") {
  console.log("开发环境");
  User.changeApi("http://192.168.10.102:80");
}

//Sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "#00aaff",
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

//echarts表格
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import LoadingPage from "@/components/LoadingPage.vue";
Vue.component("LoadingPage", LoadingPage);

import APlayer from "@moefe/vue-aplayer";
Vue.use(APlayer, {
  defaultCover: "https://github.com/u3u.png",
  productionTip: true,
});

import "@mdi/font/css/materialdesignicons.min.css";

import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import hljs from "highlight.js";
VueMarkdownEditor.use(vuepressTheme);
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VueMarkdownEditor);
Vue.use(VMdPreview);

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
