<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          @getCode="getCode"
          :returnCode="true"
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :disableArr="[]"
          :onlyA="true"
        ></StockSelect>
      </div>

      <div class="d-flex align-center justify-center" style="height: 100px">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*仓位</label
        >
        <Slider
          v-model="proportion"
          style="width: 100%"
          :min="10"
          :max="30"
          class="slider-blue"
          :format="format"
        />
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0" style="width: 100px; font-size: 14px"
          >调仓理由</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="调仓理由"
          class="font-size-input border text-light-input border-radius-sm"
          auto-grow
          rows="1"
          v-model="reason"
        ></v-textarea>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Slider from "@vueform/slider/dist/slider.vue2.js";
import Portfolio from "@/api/portfolio.js";

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
export default {
  name: "AddStock",
  components: {
    StockSelect,
    Slider,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
  },
  data() {
    return {
      stock_code: "",

      proportion: 10,
      reason: "",

      rules: [(v) => (v <= 30 && v >= 10) || "仓位需要在10%到30%之间"],
      format: function (value) {
        return `${value}%`;
      },
    };
  },
  methods: {
    getCode(code) {
      this.stock_code = code;
    },
    submit() {
      //console.log(this.iframeData.id,this.stock_code,1,this.reason,this.proportion);return;
      if (!this.stock_code) {
        this.showToast("请先选择绑定的股票", "warning", 5000);
        return;
      }
      // if(!this.reason){
      // 	this.showToast("请先填写调仓理由",'warning',5000);
      // 	return;
      // }
      this.showLoading("添加中，请稍候");
      Portfolio.restocking(
        this.iframeData.id,
        this.stock_code,
        1,
        this.reason,
        this.proportion / 100
      )
        .then((res) => {
          this.showToast("添加成功", "success", 5000);
          Bus.$emit("refreshPortfolioDetail", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.slider-blue {
  --slider-connect-bg: #409eff;
  --slider-tooltip-bg: #409eff;
  --slider-handle-ring-color: #3b82f630;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
