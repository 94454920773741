<template>
    <v-container fluid>
      <v-combobox
        v-model="model"
        :filter="filter"
        :hide-no-data="!search"
        :items="items"
        :search-input.sync="search"
        hide-selected
        label="输入关键字"
        multiple
        small-chips
        solo
        attach
      >
        <template v-slot:no-data>
          <v-list-item>
            <span class="subheading">新建选项</span>
            <v-chip
              :color="`indigo lighten-3`"
              label
              small
            >
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :color="`indigo lighten-3`"
            :input-value="selected"
            label
            small
          >
            <span class="pr-2">
              {{ item.text }}
            </span>
            <v-icon
              small
              @click="parent.selectItem(item)"
            >
              $delete
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
          <v-text-field
            v-if="editing === item"
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-chip
            v-else
            :color="`indigo lighten-3`"
            dark
            label
            small
          >
            {{ item.text }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-list-item-action @click.stop>
            <v-btn
              icon
              @click.stop.prevent="edit(index, item)"
            >
              <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-combobox>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      editingIndex: -1,
      items: [
        { header: '选择或创建一个选项' },
        
      ],
      model:[],
      nonce: 1,
      menu: false,
      
      x: 0,
      search: null,
      y: 0,
    }),
    props: {
        value: { 
            type: Array,
            default: () => []
        },
        
    },
    mounted () {
        if(this.value.length > 0){
            this.value.forEach(item => {
                this.model.push({
                    text: item
                })
                this.items.push({
                    text: item
                })
            })
        }
    },
    watch: {
      model (val, prev) {
        if (val.length === prev.length) return
        let temp = []
        this.model = val.map(v => {
            
          if (typeof v === 'string') {
            
            v = {
              text: v
              
            }

            this.items.push(v)

            this.nonce++
          }

          return v
        })
        this.model.forEach(item => {
            temp.push(item.text)
        })

        this.$emit('input', temp)
      },
    },

    methods: {
      edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
      filter (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },
    },
  }
</script>