<template>
	<vxe-pulldown ref="rolesPanel" destroy-on-close class="" transfer>
		<template #default>
			<vxe-button @click="$refs.rolesPanel.togglePanel();" size="medium" content="角色说明" status="my-warning" class="ml-0 mr-1"></vxe-button>
		</template>
		<template #dropdown>
			<div class="roles-dropdown">
				<vxe-table auto-resize :data="roleTable">
					<vxe-column field="name" title="角色名" width="120px"></vxe-column>
					<vxe-column field="desc" title="描述"></vxe-column>
				</vxe-table>
			</div>
		</template>
	</vxe-pulldown>
	
</template>
<script>

import User from '@/api/user.js';
export default {
	name: 'UserAdd',
	components: {
		
	},
	props: {
		
	},
	mounted() {
		this.getRoleList();
	},
	data() {
		return {
			roleTable:[],
		};
	},
	methods: {
		getRoleList(){
			User.getRoleList()
				.then(res => {
					this.roleTable = res.data;
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					
				});
		},
	},
	computed: {
		
	},
	watch: {
		
	},
};
</script>
<style scoped="scoped">
	.roles-dropdown {
		width: 400px;
		background-color: #fff;
		box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
	}
</style>
