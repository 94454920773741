<template>
  <div
    style="padding: 10px"
    v-html="this.iframeData.text"
    ref="codeContent"
  ></div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "previewCode",
  props: {
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      editorOptions: {
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: true,
        cursorStyle: "line",
        automaticLayout: true,
        glyphMargin: false,
      },
      editor: null,
    };
  },
  components: {},
  methods: {},
  mounted() {
    console.log(this.iframeData);
    setTimeout(() => {
      let newsOBJ = this.$refs.codeContent;
      //图片处理
      let imgs = newsOBJ.getElementsByTagName("img");
      imgs.forEach((item) => {
        //将图片的宽度设为400px
        item.style.width = "400px";
        item.onclick = () => {
          Bus.$emit("previewFile", {
            url: item.src,
            name: item.alt + ".jpg",
          });
        };
      });
    }, 1000);
  },
};
</script>
<style></style>
