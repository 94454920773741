<template>
  <v-container fluid class="px-6 py-6">
    <v-row class="flex justify-center">
      <v-col lg="8" cols="12">
        <v-card class="border-radius-xl card-shadow" style="position: relative">
          <TimeLineBar
            ref="timelineBar"
            @getDataList="getDataList"
            @toAddInfo="toAddInfo"
            @isShowAll="isShowAll"
          ></TimeLineBar>

          <vue-scroll
            ref="scroll"
            :refreshStart="refreshStart"
            :loadStart="loadStart"
            :noData="noData"
            class="timeline-scroll"
          >
            <v-card-text class="py-0" style="position: relative; z-index: 1">
              <v-timeline
                dense
                align-top
                class="timeline-line-color"
                v-if="timeline.length > 0"
              >
                <v-timeline-item
                  v-for="item in timeline"
                  :key="item.id"
                  small
                  class="timeline"
                  :id="item.id"
                >
                  <template v-slot:icon>
                    <v-avatar size="26" color="#ffffff" class="pt-1">
                      <v-icon :class="`text-dark`" size="16"
                        >mdi-calendar</v-icon
                      >
                    </v-avatar>
                  </template>

                  <v-card>
                    <v-card-title class="px-0 pt-0 pb-0 d-block">
                      <div class="align-center d-flex justify-space-between">
                        <div
                          class="
                            text-dark text-sm
                            font-weight-bold
                            mb-0
                            d-block d-flex
                            align-center
                          "
                          style="width: calc(100% - 150px)"
                        >
                          <div style="width: calc(100% - 100px)">
                            <v-icon
                              v-if="item.hot"
                              class="text-danger font-weight-black text-h4"
                              >mdi-fire</v-icon
                            >
                            <span
                              :class="
                                item.hot ? 'font-weight-black text-danger' : ''
                              "
                              ><span v-if="item.source"
                                >{{ item.source }} |
                              </span>
                              {{ item.title }}
                            </span>
                          </div>
                          <p
                            class="text-sm"
                            v-if="
                              item.content.length > 0 ||
                              item.attachments.length > 0 ||
                              (item.review && item.review.length > 0)
                            "
                            style="width: 100px"
                          >
                            <span
                              @click="item.showAll = !item.showAll"
                              class="cursor-pointer text-info ml-6 icon"
                              >{{ item.showAll ? "收起" : "展开"
                              }}<v-icon class="text-info">{{
                                item.showAll
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon></span
                            >
                          </p>
                        </div>
                        <div class="d-flex justify-end" style="width: 150px">
                          <vxe-button
                            status="my-primary"
                            @click="toEditInfo(item)"
                            size="mini"
                          >
                            编辑
                          </vxe-button>
                          <vxe-button
                            status="my-danger"
                            @click="deleteInfo(item.id)"
                            size="mini"
                          >
                            删除
                          </vxe-button>
                        </div>
                      </div>

                      <p class="text-secondary font-weight-bold text-xs mb-0">
                        <span>{{ item.date }} </span>
                      </p>
                    </v-card-title>
                    <v-card-text class="px-0">
                      <v-row v-if="item.showAll" class="mt-3">
                        <div>
                          <div>
                            <span>详情：</span>
                            <span v-html="getContent(item.content)"></span>
                          </div>
                          <div>
                            <span>复盘：</span>
                            <span v-html="getContent(item.review)"></span>
                          </div>
                        </div>

                        <v-carousel
                          v-if="item.attachments.length > 0"
                          class="mb-2"
                        >
                          <v-carousel-item
                            v-for="(img, i) in item.attachments"
                            :key="i"
                            :src="img"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                            @click.stop="showImgs(img)"
                          ></v-carousel-item>
                        </v-carousel>
                      </v-row>
                      <div class="mt-3">
                        <v-btn
                          :v-if="item.races"
                          v-for="race in item.races"
                          :key="race.id"
                          elevation="1"
                          small
                          :ripple="false"
                          height="21"
                          class="
                            border-radius-sm
                            text-xxs text-white
                            me-1
                            font-weight-bold
                            px-1
                            py-1
                            badge-font-size
                          "
                          :color="colorData[race.level]"
                          @click="$stockHelpers.openTagDetail(race.id)"
                          >{{ race.name }}</v-btn
                        >
                        <v-btn
                          :v-if="item.stocks"
                          v-for="stock in item.stocks"
                          :key="stock.id"
                          elevation="1"
                          small
                          :ripple="false"
                          height="21"
                          class="
                            border-radius-sm
                            text-xxs text-blue
                            me-1
                            font-weight-bold
                            px-1
                            py-1
                            badge-font-size
                          "
                          color="#eee"
                          @click="$stockHelpers.openStockDetail(stock.id)"
                          >{{ stock.name }}</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <div
                v-else
                style="
                  width: 100%;
                  height: calc(100% - 50px);
                  flex-direction: column;
                "
                class="d-flex justify-center align-center"
              >
                <img src="@/assets/img/empty.png" />
                <div style="color: #bfcee0">请先添加日历信息</div>
              </div>
            </v-card-text>
          </vue-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import moment from "moment";
import Timeline from "@/api/timeline.js";
import TimeLineBar from "@/components/TimeLine/TimeLineBar.vue";

import VueScroll from "@/components/pull-to-refresh/vue-scroll.vue";
export default {
  name: "TimeLine",
  components: { TimeLineBar, VueScroll },
  data() {
    return {
      // timeline: [
      //   {
      //     id: "",
      //     user_id: "",
      //     title: "",
      //     content: "",
      //     attachments: [],
      //     hot: 0,
      //     date: "",
      //     created_at: "",
      //     updated_at: "",
      //     races: [],
      //     stocks: [],
      //     showAll: false,
      //   },
      // ],

      currentLine: {},
      deleteId: "",
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
      month: "",
      search: "",
      hot: "",
      TimeLineData: [
        {
          id: "",
          user_id: "",
          title: "",
          content: "",
          attachments: [],
          hot: 0,
          date: "",
          created_at: "",
          updated_at: "",
          races: [],
          stocks: [],
          showAll: false,
        },
      ],
      noData: false,
      curPreMonth: "",
      curNextMonth: "",
      isLoading: false,
      today: moment().format("YYYY-MM-DD"),
      firstDay: moment().startOf("month").format("YYYY-MM-DD"),
      currentId: "",
      flag: false,
      first: true,
    };
  },
  computed: {
    timeline() {
      return this.TimeLineData;
    },
  },
  created() {
    this.getDataList();
    window.addEventListener("wheel", this.handleScroll);
  },
  mounted() {},
  methods: {
    getDataList(month, search, hot, next) {
      this.month = month;
      this.search = search;
      this.hot = hot;
      if (!month) {
        month = moment().format("YYYY-MM");
      }
      if (search == undefined) {
        search = "";
      }
      if (hot == undefined) {
        hot = "";
      }
      if (!next) {
        this.TimeLineData = [];
        this.curPreMonth = month;
        this.curNextMonth = month;
      }
      this.showLoading("获列表数据中，请稍候！");
      this.isLoading = true;
      Timeline.getTimelineList(month, search, hot)
        .then((res) => {
          res.data.forEach((element) => {
            element.showAll = false;
          });
          if (next == "pre") {
            this.TimeLineData = res.data.concat(this.TimeLineData);
          } else {
            this.TimeLineData = this.TimeLineData.concat(res.data);
          }

          this.$refs.timelineBar.setShow(false);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
          this.isLoading = false;
          if (this.first && this.timeline.length > 0) {
            this.getDay();
            this.first = false;
          }
        });
    },
    toAddInfo() {
      // window.removeEventListener("wheel", this.handleScroll);
      this.$router.push({
        name: "添加日历",
      });
    },
    toEditInfo(item) {
      window.localStorage.setItem("saveTimeLine", JSON.stringify(item));

      this.$router.push({ name: "添加日历", query: { id: item.id } });
    },
    deleteInfo(id) {
      this.deleteId = id;
      this.$layer.confirm(`是否要删除该条信息？`, (layerid) => {
        this.showLoading("操作中，请稍候");
        Timeline.deleteTimeline(this.deleteId)
          .then((res) => {
            this.timeline.splice(
              this.timeline.findIndex((item) => item.id === this.deleteId),
              1
            );
            this.showToast("删除成功！", "success", 1000);
          })
          .catch((err) => {
            console.log(err);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
            this.$layer.close(layerid);
          });
      });
    },

    isShowAll(show) {
      this.timeline.forEach((element) => {
        element.showAll = show;
      });
    },
    showImgs(img) {
      console.log(img);
      Bus.$emit("previewFile", {
        url: img,
        name: img.split("/")[5] + ".jpg",
      });
    },
    getContent(content) {
      if (content) {
        content = content.replace(/\n/g, "<br>");
        return content;
      } else {
        return "暂无";
      }
    },

    // 刷新开始
    refreshStart(done) {
      console.log("onPulldown");
      this.curPreMonth = this.preMonth(this.curPreMonth);
      setTimeout(() => {
        this.$refs.timelineBar.setDate(this.curPreMonth);
        this.getDataList(this.curPreMonth, this.search, this.hot, "pre");
        done();
      }, 1600);
    },
    // 加载开始
    loadStart(done) {
      console.log("onPullup");

      this.curNextMonth = this.nextMonth(this.curNextMonth);
      setTimeout(() => {
        this.$refs.timelineBar.setDate(this.curNextMonth);
        this.getDataList(this.curNextMonth, this.search, this.hot, "next");
        done();
      }, 1600);
    },
    //上月

    preMonth(date) {
      let startDate = moment(date)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM");
      return startDate;
    },
    //下月
    nextMonth(date) {
      let startDate = moment(date)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM");
      return startDate;
    },
    handleScroll(e) {
      if (!this.isLoading) {
        let body = document.querySelector(".my-scrollbar");

        // console.log(e, body.scrollTop, body.offsetHeight);
        // console.log(window.innerHeight);
        if (
          body.scrollTop + window.innerHeight >= body.offsetHeight &&
          e.wheelDeltaY < 0
        ) {
          this.$refs.scroll.trigger("load");
        }
        if (body.scrollTop <= 0 && e.wheelDeltaY > 0) {
          this.$refs.scroll.trigger("refresh");
        }
      }
    },
    getDay() {
      if (this.today == this.firstDay) {
        return false;
      }
      for (let i = this.timeline.length - 1; i >= 0; i--) {
        if (this.timeline[i].date == this.today) {
          this.currentId = this.timeline[i].id;
          this.flag = true;
          if (this.today != moment().format("YYYY-MM-DD")) {
            break;
          }
        }
      }
      if (this.flag) {
        let element = document.getElementById(this.currentId);
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
        this.flag = false;
      } else {
        this.today = moment(this.today)
          .subtract(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD");
        this.getDay();
      }
    },
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>
<style scoped>
.badge-danger {
  color: #fff !important;
  background-image: linear-gradient(310deg, #ea0606, #ff667c) !important;
}

.badge-success {
  color: #fff !important;
  background-image: linear-gradient(310deg, #17ad37, #98ec2d) !important;
}

.badge-info {
  color: #fff !important;
  background-image: linear-gradient(310deg, #2152ff, #21d4fd) !important;
}

.badge-warning {
  color: #fff !important;
  background-image: linear-gradient(310deg, #f53939, #fbcf33) !important;
}

.badge-primary {
  color: #fff !important;
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
}
</style>
