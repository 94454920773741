<template>
  <TableFilter
    @change="change"
    :returnId="returnId"
    :inputWidth="inputWidth"
    :colName="colName"
    :autoSave="autoSave"
    :selectData="selectData"
    :table="table"
    :placeholder="placeholder"
    :defaultSelect="defaultSelect"
  ></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter';
import Review from '@/api/review.js';
import Bus from '@/components/Bus/bus.js';
export default {
  name: '',
  props: {
    placeholder: '',
    table: '',
    colName: '',
    inputWidth: '',
    autoSave: true,
    returnId: false,
  },
  data() {
    return {
      selectData: [],
      inputValue: '',
      defaultSelect: '',
    };
  },
  components: {
    TableFilter,
  },
  mounted() {
    this.getList();
    Bus.$on('refreshReviewDayList', (e) => {
      this.getList();
    });
  },
  methods: {
    getList() {
      Review.getReviewList()
        .then((res) => {
          let arr = [];
          for (let item in res.data) {
            arr.push({ label: `${res.data[item]}`, value: item, id: item });
          }
          this.selectData = JSON.parse(JSON.stringify(arr));
          this.defaultSelect = this.selectData[0].label;
          this.$emit('change', this.selectData[0].value);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    change(val) {
      this.$emit('change', val);
    },
  },
  watch: {},
};
</script>
<style scoped></style>
