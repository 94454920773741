<template>
  <div style="width: 100%;">
		<LoadingPage :visible="tableLoading"></LoadingPage>
		<div style="width: 100%;" class="d-flex flex-wrap" v-show="!tableLoading">
			<v-card v-for="(item,index) in tableData1" :key="index" style="height: 100%;width: calc( 50% - 20px);margin: 10px 10px 10px 10px ;" class="border-radius-md pa-3">
				<div>{{index}}</div>
				<vxe-table
					:data="item"
					style="width: 100%"
					class="mytable-scrollbar"
					:border="false"
					resizable
					show-overflow
					show-header-overflow
					highlight-hover-row
					ref="bindStockTable"
					:loading="tableLoading"
					height="715px"
					size="small"
					:sort-config="{ trigger: 'cell', multiple: true }"
					:empty-render="{ name: 'NotData' }"
					align="center"
					:tree-config="{expandAll:true,reserve:false}"
				>
					<vxe-column fixed="left" type="seq" width="60" title="序号"></vxe-column>
					
					<vxe-column min-width="100" field="industry.name" title="申万二级">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{ row.industry?row.industry.name:'-' }}
							</div>
							
						</template>
					</vxe-column>
					
					<vxe-column
						min-width="70"
						title="涨跌幅"
						field="applies"
					>
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.applies)">{{ $stockHelpers.getPercent(row.applies) }}</span>
							</div>
							
						</template>
						
					</vxe-column>
					
					<vxe-column
						min-width="70"
						title="最大上涨"
						field="max_rose"
					>
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.max_rose)">{{ $stockHelpers.getPercent(row.max_rose) }}</span>
							</div>
							
						</template>
						
					</vxe-column>
					
					<vxe-column
						min-width="70"
						title="最大回撤"
						field="max_retreat"
					>
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.max_retreat)">{{ $stockHelpers.getPercent(row.max_retreat) }}</span>
							</div>
							
						</template>
						
					</vxe-column>
					
					
				</vxe-table>
			</v-card>
			
		</div>
		
		
	</div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from '@/api/review.js';
export default {
  props: {
    
  },
  data() {
    return {
			tableLoading:false,
			tableData1:[],
    };
  },
	mounted() {
		this.getCnIndustryStock();
	},
	methods: {
		getCnIndustryStock(){
			this.tableLoading=true;
			Review.getCnIndustryStock().then(res => {
				console.log(res.data);
				this.tableData1={
					'5日涨幅':res.data.rose_five,
					'5日跌幅':res.data.retreat_five,
					'10日涨幅':res.data.rose_ten,
					'10日跌幅':res.data.retreat_ten,
					'30日涨幅':res.data.rose_month,
					'30日跌幅':res.data.retreat_month,
					'90日涨幅':res.data.rose_ninety,
					'90日跌幅':res.data.retreat_ninety,
				};
			}).catch(err => {
				console.log(err);
				this.showToast(err.msg,'danger',10000);
				//this.loginError();
			})
			.finally(() => {
				this.tableLoading=false;
			});
		},
	},
};
</script>
<style scoped="scoped">
	
</style>
