<template>
  <div style="width: 100%">
    <LoadingPage :visible="tableLoading"></LoadingPage>

    <div style="width: 100%" class="d-flex flex-wrap" v-show="!tableLoading">
      <v-card
        style="height: 100%; width: calc(50% - 10px); margin: 10px 5px 10px 5px"
        class="border-radius-md pa-3"
      >
        <div>全A市场涨跌幅</div>
        <vxe-table
          :data="tableData1[0]"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          :height="tableHeight"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column min-width="100" field="industry.name" title="全市场">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.name ? row.name : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="5日" field="applies_five">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_five)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_five)
                      : row.applies_five
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="10日" field="applies_ten">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_ten)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_ten)
                      : row.applies_ten
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="30日" field="applies_month">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_month)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_month)
                      : row.applies_month
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="90日" field="applies_ninety">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_ninety)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_ninety)
                      : row.applies_ninety
                  }}</span
                >
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>

      <v-card
        style="height: 100%; width: calc(50% - 10px); margin: 10px 5px 10px 5px"
        class="border-radius-md pa-3"
      >
        <div>股票池涨跌幅</div>
        <vxe-table
          :data="tableData1[1]"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          :height="tableHeight"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column min-width="100" field="industry.name" title="股票池">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.name ? row.name : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="5日" field="applies_five">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_five)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_five)
                      : row.applies_five
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="10日" field="applies_ten">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_ten)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_ten)
                      : row.applies_ten
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="30日" field="applies_month">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_month)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_month)
                      : row.applies_month
                  }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="90日" field="applies_ninety">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  :style="
                    row.type == 'per'
                      ? $stockHelpers.getColor(row.applies_ninety)
                      : ''
                  "
                  >{{
                    row.type == 'per'
                      ? $stockHelpers.getPercent(row.applies_ninety)
                      : row.applies_ninety
                  }}</span
                >
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/Bus/bus.js';
import Review from '@/api/review.js';
export default {
  components: {},
  props: {},
  data() {
    return {
      tableLoading: false,
      tableData1: [],
      tableHeight: '500px',
    };
  },
  mounted() {
    this.tableHeight = document.body.clientHeight - 140 + 'px';
    this.getReviewMarkets();
  },
  methods: {
    getReviewMarkets() {
      this.tableLoading = true;
      Review.getReviewMarkets()
        .then((res) => {
          console.log(res.data);
          this.tableData1 = [res.data.full, res.data.pool];
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, 'danger', 10000);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style scoped="scoped"></style>
