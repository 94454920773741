var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-0 py-1"},[_c('h3',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.title))]),_c('vxe-table',{ref:"topList",staticClass:"my-scrollbar",staticStyle:{"width":"100%"},attrs:{"id":"topList","data":_vm.topList,"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.topLoading,"height":'280px',"size":"mini","sort-config":{
      trigger: 'cell',
      defaultSort: { field: 'pinyin', order: 'asc' },
      orders: ['desc', 'asc', null],
      multiple: true,
    },"scroll-x":{ gt: 30 },"scroll-y":{ mode: 'wheel', gt: 100 },"tooltip-config":{
      showAll: false,
      enterable: true,
      theme: 'dark',
    },"custom-config":{ storage: true },"filter-config":{ showIcon: false },"expand-config":{ trigger: 'row' }}},[_c('vxe-column',{attrs:{"title":"股票","type":"seq","min-width":"80","field":"index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.index ? row.index : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"35","field":"Score","title":"得分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.Score ? row.Score : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"35","field":"Vol_ratio","title":"量比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.Vol_ratio ? row.Vol_ratio : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"45","field":"Num_ratio","title":"笔数比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.Num_ratio ? row.Num_ratio : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"45","field":"normal_flow","title":"挂单比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.normal_flow ? row.normal_flow : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"55","field":"active_flow","title":"主动单比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.active_flow ? row.active_flow : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"55","field":"price_bias","title":"挂单价比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.price_bias ? row.price_bias : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"55","field":"momentum","title":"日内动量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.momentum ? row.momentum : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"55","field":"order_imba","title":"十档量比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.order_imba ? row.order_imba : "")+" ")])]}}])})],1),_c('h3',{staticClass:"text-dark"},[_vm._v("交易记录")]),_c('vxe-table',{ref:"tradeList",staticClass:"my-scrollbar",staticStyle:{"width":"100%"},attrs:{"id":"tradeList","data":_vm.tradeList,"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.tradeLoading,"height":'280px',"size":"mini","sort-config":{
      trigger: 'cell',
      defaultSort: { field: 'pinyin', order: 'asc' },
      orders: ['desc', 'asc', null],
      multiple: true,
    },"scroll-x":{ gt: 30 },"scroll-y":{ mode: 'wheel', gt: 100 },"tooltip-config":{
      showAll: false,
      enterable: true,
      theme: 'dark',
    },"custom-config":{ storage: true },"filter-config":{ showIcon: false },"expand-config":{ trigger: 'row' }}},[_c('vxe-column',{attrs:{"min-width":"80","field":"name","title":"股票"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.name ? row.name : "")+" "),(row.code)?_c('span',[_vm._v("("+_vm._s(row.code)+")")]):_vm._e()])]}}])}),_c('vxe-column',{attrs:{"title":"交易时间","type":"seq","min-width":"120","field":"local_time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.local_time ? row.local_time : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"45","field":"price","title":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.price ? row.price : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"45","field":"shares","title":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.shares ? row.shares : "")+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }