import Request from "@/components/request/js/index.js";
import { config } from "@/components/request/js/config.js";
export default {
  //获取调研路演统计
  getResearchStatistics() {
    return Request().get("/api/external/researchStatistics", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
