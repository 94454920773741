var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-table',{ref:"chartTable",staticClass:"mytable-scrollbar",staticStyle:{"width":"100%","min-height":"300px"},attrs:{"data":_vm.pageData,"border":false,"resizable":"","scroll-y":{ enabled: false },"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.tableLoading,"max-height":"100000","size":"small","sort-config":{ trigger: 'cell', multiple: false },"empty-render":{ name: 'NotData' },"align":"left"},on:{"sort-change":_vm.sortChangeEvent}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"100","field":"sec_name","title":"金股组合名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.sec_name ? row.sec_name : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"年化收益率","field":"annual_return","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.annual_return))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.annual_return)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"累计收益","field":"cum_return","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.cum_return))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.cum_return)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"波动率","field":"annual_volatility","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.annual_volatility)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"夏普","field":"sharpe_ratio","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.sharpe_ratio.toFixed(2))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"最大回撤","field":"max_drawdown","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.max_drawdown)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"超额收益率","field":"excess_rate","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.excess_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.excess_rate)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"近一周收益","field":"mom_w","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.mom_w))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.mom_w)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"近一月收益","field":"mom_m","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.mom_m))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.mom_m)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"近一季收益","field":"mom_q","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.mom_q))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.mom_q)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"近一年收益","field":"mom_y","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.mom_y))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.mom_y)))])])]}}])})],1),_c('vxe-pager',{attrs:{"current-page":_vm.pageInfo.currentPage,"page-size":_vm.pageInfo.pageSize,"total":_vm.pageInfo.totalResult,"layouts":['PrevPage', 'JumpNumber', 'NextPage', 'Total']},on:{"page-change":function (value) { return _vm.getPage(value, this$1.list, [_vm.$refs['chartTable']], _vm.pageInfo); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }