<template>
  <div style="width: 100%">
    <div style="width: 100%" v-show="pageLoading">
      <LoadingPage :visible="pageLoading"></LoadingPage>
    </div>
    <div v-show="!pageLoading">
      <div
        v-show="!readItem.id"
        style="
          width: 100%;
          height: 90vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <v-icon size="100" style="color: #aaaaaa">mdi-application-edit</v-icon>
        <p style="color: #aaaaaa; font-size: 25px">请选择要查看的笔记</p>
      </div>
      <div id="content" style="width: 100%" v-show="readItem.id">
        <div id="editor-container">
          <div id="title-container">
            <input
              value="请点击新建笔记或选择要查看的笔记"
              placeholder="请输入标题"
              v-model="title"
              :readonly="!isSelf"
            />
          </div>
          <Editor
            style="min-height: 200px; overflow-y: hidden; width: 100%"
            @onChange="onChange"
            :editorId="editorId"
            :defaultConfig="editorConfig"
            @onCreated="onCreated"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import Note from '@/api/note.js';

import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

import StockSelect from '@/components/CommonSelect/StockSelect.vue';
import TagSelect from '@/components/CommonSelect/TagSelect.vue';

import Bus from '@/components/Bus/bus.js';

const OSS = require('ali-oss');
import File from '@/api/file.js';

export default Vue.extend({
  name: 'NoteContent',
  components: { Editor, Toolbar, StockSelect, TagSelect },
  props: {
    readItem: {},
  },
  data() {
    return {
      ready: false,
      isSelf: false,
      pageLoading: false,

      today: '',
      title: '',
      content: '',
      bindStock: [],
      bindTag: [],
      comments: [],

      editor: {},
      editorId: `note-editor-text-area`, //【注意】编辑器 id ，要全局唯一
      toolbarConfig: {
        toolbarKeys: [
          'bold',
          'underline',
          'italic',
          'clearStyle',
          'color',
          'bgColor',
          '|',
          'fontSize',
          {
            key: 'group-justify', // 必填，要以 group 开头
            title: '对齐',
            menuKeys: [
              'justifyLeft',
              'justifyRight',
              'justifyCenter',
              'justifyJustify',
            ],
          },
          {
            key: 'group-link', // 必填，要以 group 开头
            title: '超链',
            menuKeys: ['insertLink', 'editLink', 'unLink', 'viewLink'],
          },
          {
            key: 'group-table', // 必填，要以 group 开头
            title: '表格',
            menuKeys: [
              'insertTable',
              'deleteTable',
              'insertTableRow',
              'deleteTableRow',
              'insertTableCol',
              'deleteTableCol',
              'tableHeader',
              'tableFullWidth',
            ],
          },
          'lineHeight',
          'divider',
          'blockquote',
          'headerSelect',
          'todo',
          'bulletedList',
          'numberedList',
        ],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        scroll: false,
        autoFocus: true,
        MENU_CONF: {
          uploadImage: {
            customUpload: (file, insertFn) => {
              this.customUpload(file, insertFn);
            },
          },
        },
      },
    };
  },
  mounted() {
    this.today = moment().format('YYYY-MM-DD');
  },
  beforeDestroy() {
    let editor = this.editor;
    if (editor == null) return;
    Bus.$off('refreshComment');

    // 【注意】组件销毁时，及时销毁编辑器
    editor.destroy();
  },
  methods: {
    //编辑器初始化
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.editor.disable();
      //console.log(this.editor);
    },
    customUpload(fileItem, insertFn) {
      //console.log(fileItem);
      File.getUploadSts()
        .then((res) => {
          let info = res.data;
          let client = new OSS({
            region: 'oss-cn-shanghai',
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: 'qinglitouyan',
          });

          let fileName = 'fileStore/' + info.uuid + '/' + fileItem.name;

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf('uploadId') != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                '/fileStore/' + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  let url = `${File.getApiUrl()}/api/file/${
                    info.uuid
                  }/downloadDirect`;
                  insertFn(url, '插图', url);
                })
                .catch((err) => {
                  console.log('err:', err);
                });
            })
            .catch((err) => {
              console.log('err:', err);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    onChange() {
      let div = document.getElementById('content');
      div.scrollTop = div.scrollHeight;
    },

    readNewItem() {
      this.pageLoading = true;
      Note.getNoteContent(this.readItem.id)
        .then((res) => {
          this.editor.enable();
          this.editor.clear();
          this.editor.clear();
          //this.editor.setHtml('<p></p>');
          //console.log(this.editor);

          let noteDetail = res.data;

          this.title = noteDetail.title;
          this.bindStock = noteDetail.stocks;
          this.bindTag = noteDetail.races;
          this.content = noteDetail.content;
          this.editor.dangerouslyInsertHtml(this.content);
          this.comments = noteDetail.comments;
          this.ready = true;

          if (noteDetail.user_id == this.userStore.user_id) {
            this.isSelf = true;
          } else {
            this.isSelf = false;
            this.editor.move(999999);
            this.editor.blur();
            this.editor.disable();
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, 'danger', 10000);
          this.pageLoading = false;
        })
        .finally(() => {});
    },
    //绑定新股票
    bindNewStock(newItem) {
      let haveAdd = false;
      this.bindStock.forEach((item) => {
        if (item.id == newItem) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        this.bindStock.push(newItem);
      } else {
        this.showToast('不能重复绑定同一个股票', 'danger', 5000);
      }
    },
    //绑定新标签
    bindNewTag(newItem) {
      console.log(newItem);
      let haveAdd = false;
      this.bindTag.forEach((item) => {
        if (item.id == newItem.id) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        this.bindTag.push(newItem);
      } else {
        this.showToast('不能重复绑定同一个标签', 'danger', 5000);
      }
    },
    //删除绑定的股票
    deleteStock(id) {
      this.bindStock.forEach((item, index) => {
        if (item.id == id) {
          this.bindStock.splice(index, 1);
        }
      });
    },
    //删除绑定的标签
    deleteTag(id) {
      this.bindTag.forEach((item, index) => {
        if (item.id == id) {
          this.bindTag.splice(index, 1);
        }
      });
    },
    deleteNote() {
      this.$layer.confirm('确定要删除笔记吗？', (layerid) => {
        this.showLoading('删除笔记中，请稍候');
        Note.deleteNote(this.readItem.id)
          .then((res) => {
            this.showToast('已成功删除笔记！', 'success', 5000);
            Bus.$emit('refreshNoteList', true);
            this.editor.enable();
            this.editor.clear();
            this.editor.clear();
            this.title = '';
            this.bindStock = [];
            this.ready = false;
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, 'danger', 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },

    print() {
      this.appStore.printTitle = this.title;
      this.appStore.printContent = this.content;
      let routeUrl = this.$router.resolve({ name: '打印' });
      window.open(routeUrl.href, '_blank');
    },
  },
  watch: {
    readItem(newValue) {
      if (newValue.id) {
        this.readNewItem();
      }
    },
  },
  computed: {
    bindTagIds() {
      if (!Array.isArray(this.bindTag)) {
        this.bindTag = [];
      }
      let ids = [];
      this.bindTag.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
    bindStockIds() {
      if (!Array.isArray(this.bindStock)) {
        this.bindStock = [];
      }
      let ids = [];
      this.bindStock.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
  },
});
</script>
<style>
#top-container {
  border-bottom: 1px solid #e8e8e8;
  padding-left: 50px;
}

#content {
  width: 100%;
  /* background-color: rgb(245, 245, 245); */
  overflow-y: auto;
  position: relative;
}

#editor-container {
  width: calc(100% - 110px);
  background-color: #fff;
  padding: 20px 50px 50px 50px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
}

#title-container {
  padding: 10px 0px 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}

#title-container input {
  font-size: 30px;
  border: 0;
  outline: none;
  width: 100%;
  line-height: 1;
}

#note-editor-text-area {
  min-height: 100%;
  margin-top: 20px;
}

.w-e-text-container [data-slate-editor] p {
  margin: 5px;
}

.note-bindStock-dropdown {
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
