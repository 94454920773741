<template>
	<div>
		<vxe-toolbar class="mx-5" custom :refresh="{query: getStockList}">>
			<template v-slot:buttons>
				<vxe-input v-model="filterName" type="search" placeholder="全文搜索"></vxe-input>
			</template>
		</vxe-toolbar>
		<vxe-table
			id="coreTable"
			ref="coreTable"
			:data="list"
			:border="false"
			resizable
			show-overflow
			show-header-overflow
			highlight-hover-row
			align="center"
			:loading="coreTableLoading"
			:column-config="{ resizable: true }"
			:height="tableHeight"
			size="small"
			:stripe="true"
			:sort-config="{ trigger: 'cell', multiple: true }"
			:scroll-y="{ mode: 'wheel', gt: 50 ,oSize:30}"
			:empty-render="{ name: 'NotData' }"
			:custom-config="{ storage: true, checkMethod: checkColumnMethod }"
			:tooltip-config="{ showAll: false, enterable: true ,theme:'dark' }"
		>

			<vxe-column fixed="left" min-width="100" field="stock_code" title="股票代码">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_code ? row.stock_code : '-' }}</a>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column fixed="left" min-width="80" field="stock_name" title="股票名称">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_name ? row.stock_name : '-' }}</a>
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="100" field="industry_name" title="申万三级" :filters="[{ data: ''}]" :filter-method="value => $tableHelpers.filterInputMethod(value,'industry_name')">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<div style="text-align: start;word-wrap: break-word;white-space: pre-wrap;">{{ row.industry_name }}</div>
					</div>
				</template>
				<template #filter="{ $panel, column }">
					<template v-for="(option, index) in column.filters">
						<input
							style="margin: 10px;width: 140px;height: 32px;"
							class="border px-3"
							type="type"
							:key="index"
							v-model="option.data"
							@input="$panel.changeOption($event, !!option.data, option)"
							@keyup.enter="$panel.confirmFilter()"
							placeholder="按回车确认筛选"
						/>
					</template>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="created_at" title="入池日期" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.created_at.split(' ')[0] }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="70" field="outDate" title="调出日期" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.outDate.split(' ')[0] }}
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="80" field="applies_focus" title="入池涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_focus)">{{ $stockHelpers.getPercent(row.applies_focus) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="60" field="reason" title="调入原因" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{row.reason}}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="50" title="操作" type="seq" :resizable="false" show-overflow>
				<template #default="{ row }">
					<div v-if="row.is_trans_out==0">
						<a href="javascript:;" @click="stockAddBack(row.id)" class="text-xs font-weight-normal text-typo">调回</a>
						<a href="javascript:;" @click="stockCreatedTimeEdit(row.id,row.created_at)" class="text-xs font-weight-normal text-typo" style="margin-left: 5px">修改</a>
						<a href="javascript:;" @click="stockDelete(row.id)" class="text-xs font-weight-normal text-typo" style="margin-left: 5px">删除</a>
					</div>
					<div v-else>
						申请调回中
					</div>
				</template>
			</vxe-column>
			
		</vxe-table>

	</div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";

import rate from '@/components/Rate/Rate.vue';
import StockPool from '@/api/stockPool.js';
import Stock from '@/api/stock.js';
import XEUtils from 'xe-utils';
import StockAdd from '@/components/StockPool/StockAdd.vue';
import StockEdit from '@/components/StockPool/StockEdit.vue';
import StockCreatedTimeEdit from '@/components/StockPool/StockCreatedTimeEdit.vue';
import StockAddBack from '@/components/StockPool/StockAddBack.vue';

export default {
	name: 'StockPool',
	props: {},
	data() {
		return {
			stockList: [],
			coreTableLoading: false,
			tableHeight: '500px',
			stockLevelMap: {
				1: '核心',
				// 2: "备选",
				3: '一级',
				4: '二级',
				5: '三级'
			},

			filterName: '',
			tableData: [],
		};
	},
	components: {
		rate,
		StockAdd,
		StockEdit,
		StockCreatedTimeEdit,
	},
	mounted() {
		this.tableHeight = document.body.offsetHeight - 140 + 'px';
		this.getStockList();
		
		Bus.$on('refreshCoreStockPoolOut', (e) => {
			this.getStockList();
		});
	},
	computed: {
		list() {
			const filterName = XEUtils.toValueString(this.filterName)
				.trim()
				.toLowerCase();
			if (filterName) {
				const filterRE = new RegExp(filterName, 'gi');
				const searchProps = [
					'stock_name',
					'stock_code',
					'level',
					'pinyin',
					'industry_name',
					'industry_pinyin',
					'styleGroupString',
					'stylePYString',
					'racesGroupString',
					'racesPYString',
					'focus_users',
					'research_user',
					'update_user'
				];
				const rest = this.tableData.filter(item =>
					searchProps.some(
						key =>
							XEUtils.toValueString(item[key])
								.toLowerCase()
								.indexOf(filterName) > -1
					)
				);
				return rest;
				// return rest.map(row => {
				// 	const item = Object.assign({}, row);
				// 	searchProps.forEach(key => {
				// 		item[key] = XEUtils.toValueString(item[key]).replace(filterRE, match => {
				// 			return `<span class="keyword-lighten">${match}</span>`;
				// 		});
				// 	});
				// 	return item;
				// });
			}
			return this.tableData;
		}
	},
	methods: {
		checkColumnMethod({ column }) {
			if (column.property === 'role') {
				return false;
			}
			return true;
		},
		getStockList() {
			this.coreTableLoading = true;
			StockPool.getStockPoolList(1,2)
				.then(res => {
					res.data.forEach(e => {
						//_self.currentInfoStkList.push(e.stockCode);

						e.styleGroupString = '';
						e.stylePYString = '';
						if (e.styles) {
							e.styles.forEach(a => {
								e.styleGroupString = e.styleGroupString = e.styleGroupString + a.name + ',';
								e.stylePYString = e.stylePYString = e.stylePYString + a.pinyin + ',';
							});
						}

						e.racesGroupString = '';
						e.racesPYString = '';
						if (e.races) {
							e.races.forEach(a => {
								e.racesGroupString = e.racesGroupString = e.racesGroupString + a.name + ',';
								e.racesPYString = e.racesPYString = e.racesPYString + a.pinyin + ',';
							});
						}
					});

					this.tableData = res.data;
				})
				.catch(err => {
					console.log(err);
					//this.loginError();
				})
				.finally(() => {
					this.coreTableLoading = false;
				});
		},

		filterCodeMethod({ option, row }) {
			//console.log(option.data);
			return row.stock_code.indexOf(option.data) > -1;
		},

		filterMarketMethod({ option, row }) {
			//console.log(option.value);
			return row.market.indexOf(option.value) > -1;
		},

		codeFilter(data) {
			const coreTable = this.$refs.coreTable;
			const column = coreTable.getColumnByField('stock.code');
			const option = column.filters[0];
			option.data = data;
			option.checked = true;
			coreTable.updateData();
		},

		openRate(row) {
			//console.log(row.id);
			//this.$layer.prompt(options, yes);
			this.$layer.iframe({
				content: {
					content: rate, //传递的组件对象
					parent: this, //当前的vue对象
					data: {
						id: { }
					} //props
				},
				area: ['80%', '80%'],
				title: '股票评分',
				shade: false,
				maxmin: true, //开启最大化最小化
				resize: true //是否允许拉伸，默认是不允许
			});
		},

		stockAdd(){
			this.$layer.iframe({
			  content: {
			    content: StockAdd,
			    parent: this,
			    data: {  }
			  },
			  area: ['600px', '650px'],
			  title: '调入新股票(带*为必填)',
			  maxmin: false,
			  shade: true,
				shadeClose: false,
			  cancel: () => { //关闭弹窗事件
			    
			  }
			});
		},
		stockAddBack(id){
			this.showLoading('获取股票已有信息中，请稍候');
			Stock.getStockDetail(id).then(res => {
				//console.log(res.data);
				this.$layer.iframe({
				  content: {
				    content: StockAddBack,
				    parent: this,
				    data: { iframeData:res.data }
				  },
				  area: ['600px', '650px'],
				  title: '调回股票(带*为必填)',
				  maxmin: false,
				  shade: true,
					shadeClose: false,
				  cancel: () => { //关闭弹窗事件
				    
				  }
				});
			}).catch(err => {
				console.log(err.msg);
				this.showToast(err.msg,'danger',10000);
			}).finally(() => {
				this.hideLoading();
			});
			
		},
		stockCreatedTimeEdit(id,created_at){
			console.log(id,created_at);
			this.$layer.iframe({
			  content: {
			    content: StockCreatedTimeEdit,
			    parent: this,
			    data: { 
						iframeData:{
							id:id,
							created_at:created_at,
						} ,
					}
			  },
			  area: ['600px', '600px'],
			  title: '修改调入时间(带*为必填)',
			  maxmin: false,
			  shade: true,
				shadeClose: false,
			  cancel: () => { //关闭弹窗事件
			    
			  }
			});
		},
		stockRemove(id){
			this.$layer.confirm('确定要申请调出吗？', layerid => {
				this.showLoading('申请调出中，请稍候');
				StockPool.removeStock(id).then(res => {
					this.showToast('已成功提交调出申请！','success',5000);
					Bus.$emit("refreshCoreStockPool", true);
					Bus.$emit("refreshApplyStockPool", true);
				}).catch(err => {
					console.log(err.msg);
					this.showToast(err.msg,'danger',10000);
				}).finally(() => {
					this.hideLoading();
				});
				this.$layer.close(layerid);
			});
		},
		stockDelete(id){
			this.$layer.confirm('确定要删除吗？打分记录等会一并删除，不可恢复！', layerid => {
				this.showLoading('正在删除中，请稍候');
				StockPool.deleteStock(id).then(res => {
					this.showToast('已成功删除该股票！','success',5000);
					this.getStockList();
				}).catch(err => {
					console.log(err.msg);
					this.showToast(err.msg,'danger',10000);
				}).finally(() => {
					this.hideLoading();
				});
				this.$layer.close(layerid);
			});
		},
	}
};
</script>
