<template>
	<div>
		<div style="width: 100%;" class="d-flex justify-start align-items-center">
			<h3 class="pt-1">PEBand</h3>
			<div class="ml-5">
				
				<vxe-radio-group v-model="type">
					<vxe-radio label="pe" content="PE"></vxe-radio>
					<vxe-radio label="pb" content="PB"></vxe-radio>
				</vxe-radio-group>
				
				<vxe-input class="ml-5" v-model="start_date" type="date" placeholder="起始日期" style="width: 135px;" clearable @change="getData()"></vxe-input>
				<span class=""> - </span>
				<vxe-input v-model="end_date" type="date" placeholder="截止日期" style="width: 135px;" clearable @change="getData()"></vxe-input>
				<a href="javascript:;" @click="dateChange(30)" class="text-xs font-weight-normal text-typo ml-4">一月</a>
				<a href="javascript:;" @click="dateChange(90)" class="text-xs font-weight-normal text-typo ml-2">三月</a>
				<a href="javascript:;" @click="dateChange(180)" class="text-xs font-weight-normal text-typo ml-2">半年</a>
				<a href="javascript:;" @click="dateChange(365)" class="text-xs font-weight-normal text-typo ml-2">一年</a>
			</div>
		</div>
		<div
		  style="width: 100%; height: 350px"
		  id="PeBandChartContainer"
		></div>
	</div>
</template>
<script>
import moment from 'moment';
import Stock from '@/api/stock.js';

export default {
	name: '',
	components: {},
	props: {
		stockDetail:'',
	},
	mounted() {
		this.end_date = moment().format("YYYY-MM-DD");
		this.start_date = moment().subtract(1, "years").format("YYYY-MM-DD");
		this.getData();
	},
	data() {
		return {
			data:{},
			type:'pe',
			start_date:'',
			end_date:'',
		};
	},
	methods: {
		getData(){
			Stock.getPEBand(this.stockDetail.stock_code,this.start_date,this.end_date).then(res => {
				//console.log(res.data);
				this.data=res.data;
				
				this.drawPeBandChart();
			}).catch(err => {
				console.log(err);
			})
		},
		// setPeriodPE(num) {
		//   this.end_date = moment().format("YYYY-MM-DD");
		//   this.start_date = moment().subtract(num, "years").format("YYYY-MM-DD");
		//   this.getPePbBand();
		// },
		// 绘制PE Band Chart 图形。
		drawPeBandChart(data) {
			let tempdata=this.data[this.type];
			
		  tempdata.peDates.forEach((e) => {
		    e.showSymbol = false;
				e.type="line";
		    if (e.name === "股价") {
		      e.color = "#f9aa33";
		    } else {
		      e.color = "#344955";
		    }
		  });
			//console.log(tempdata.peDates);
		  var dom = document.getElementById("PeBandChartContainer");
		  var myChart = this.$echarts.init(dom);
		  var option = {
		    title: {
		      text: "橙色线条为股价，灰蓝色线条为PEBand",
		      left: "center",
					top:10,
					textStyle: {
						fontWeight:'normal',
						fontSize: 15,
					}
		    },
		    tooltip: {
		      trigger: "axis",
		    },
		    grid: {
		      left: "8",
		      right: "8",
		      bottom: "8",
		      containLabel: true,
		    },
		    toolbox: {
		      feature: {
		        saveAsImage: {},
		      },
		      right: "50px",
		    },
		    xAxis: {
		      type: "category",
		      boundaryGap: false,
		      data: tempdata.tradeDate,
		    },
		    yAxis: {
		      type: "value",
		      min: function (value) {
		        //取最小值向下取整为最小刻度
		        return Math.floor(value.min);
		      },
		      max: function (value) {
		        //取最大值向上取整为最大刻度
		        return Math.ceil(value.max);
		      },
		    },
		    series: tempdata.peDates,
		  };
		  myChart.setOption(option, true);
		},
		dateChange(day){
			this.end_date = moment().format('YYYY-MM-DD');
			this.start_date = moment().subtract(day, 'days').format('YYYY-MM-DD');
			this.getData();
		}
	},
	watch: {
		type(newValue) {
			//console.log(newValue);
			this.drawPeBandChart();
		}
	},
};
</script>
<style scoped="scoped">
	
</style>
