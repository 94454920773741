// Imports
import {
  kebabCase
} from 'lodash'
import {
  leadingSlash,
  trailingSlash
} from '@/util/helpers'
import Vue from 'vue'

export function abort(code = 404) {
  return {
    name: 'FourOhFour',
    path: '*',
    component: () => error(code),
  }
}

export function error(code = 404) {
  return import(
    /* webpackChunkName: "error-[request]" */
    `@/views/${code}.vue`
  )
}

export function layout(layout = 'Default', children, path = '') {
  //const dir = kebabCase(layout)
	const dir = layout;
  return {
    children,
    component: () => import(
      /* webpackChunkName: "layout-[request]" */
      `@/views/Layout/${dir}`
    ),
    path,
  }
}

export function redirect(
  path = '*',
  rhandler,
) {
  if (typeof path === 'function') {
    rhandler = path
    path = '*'
  }

  return {
    path,
    redirect: to => {
      const rpath = rhandler(to)
      const url = rpath !== '' ?
        leadingSlash(trailingSlash(rpath)) :
        rpath

      return `/${url}`
    },
  }
}

export function route(name, component, path = '',meta={keepAlive:false}) {
  component = Object(component) === component ?
    component :
    {
      default: name.replace(' ', '')
    }

  const components = {}

  for (const [key, value] of Object.entries(component)) {
    components[key] = () => import(
      /* webpackChunkName: "views-[request]" */
      `@/views/${value}`
    )
  }

  return {
    name,
    components,
    path,
    meta:meta,
  }
}

function getPath(routesArray,name) {
  let path="0";
  for (let i = 0; i < routesArray.length; i++) {
    let item = routesArray[i];
    //console.log(0, item);
    if (item.hasOwnProperty("children")) {
      if (item.name == name) {
        path= item.path;
      } else {
        path= getPath(item.children,name);
      }
    } else {
      if (item.name == name) {
        path= item.path;
      } else {
        //console.log(item.name);
      }
    }
  }
  return path;
}

export function getPathFromName(router, name) {

  let allRoutes = router.options.routes;
  //console.log(name,allRoutes);
  let path = getPath(allRoutes,name);
  return path;
}
