<template>
  <v-autocomplete
    background-color="rgba(255,255,255,.9)"
    color="rgba(0,0,0,.6)"
    rounded-sm
    outlined
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    dense
    hide-details
    :placeholder="placeholder"
    attach
    class="
      tag-select
      input-icon
      font-size-input
      placeholder-lighter
      input-alternative
      border border-radius-sm
    "
    style="width: 100%"
  >
    <template slot="prepend-inner" v-if="showIcon">
      <v-icon color="rgba(0,0,0,.6)" size=".875rem"> fas fa-search </v-icon>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-content
          v-if="!data.item.disabled"
          class="pa-2"
          style="font-size: 14px"
        >
          【{{ getType(data.item.item) }}】 {{ data.item.text }}
        </v-list-item-content>
        <v-list-item-content
          v-else
          class="bg-black pa-2"
          style="font-size: 14px"
        >
          【{{ getType(data.item.item) }}】 {{ data.item.text }}
        </v-list-item-content>
      </template>
    </template>

    <template slot="append"> &nbsp; </template>
  </v-autocomplete>
</template>
<script>
import Stock from "@/api/stock.js";
export default {
  name: "",
  props: {
    value: "",
    placeholder: "",
    showIcon: false,
    returnItem: false,
    disableArr: [],
  },
  data() {
    return {
      model: "",
      search: "",
      items: [],
      isLoading: false,
    };
  },
  mounted() {
    this.model = this.value;
    this.getList();
  },
  methods: {
    submit() {},
    remove(item) {
      const index = this.model.indexOf(item);
      if (index >= 0) this.model.splice(index, 1);
    },
    getList() {
      this.items = [];
      this.isLoading = true;
      Stock.getRaceList()
        .then((res) => {
          let arr = [];
          res.data.forEach((item, index) => {
            item["type"] = "race";

            let disabledTag = false;
            if (this.disableArr.indexOf(item.id) > -1) {
              disabledTag = true;
            }
            arr.push({
              text: `${item.name}(${item.pinyin})`,
              value: item.id,
              item: item,
              disabled: disabledTag,
            });
          });
          this.items = this.items.concat(arr);
        })
        .catch((err) => {
          console.log(err.msg);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getType(item) {
      if (item.level == 1) {
        return "核心池";
      } else if (item.level == 2) {
        return "一级主题池";
      } else if (item.level == 3) {
        return "普通主题池";
      } else if (item.level == 0) {
        return "行业风格池";
      }
    },
  },
  watch: {
    value(val) {
      this.model = this.value;
    },
    model(val) {
      if (val) {
        let resItem = {};
        this.items.forEach((item) => {
          if (item.item.id == val) {
            resItem = item.item;
          }
        });
        this.$emit("getItem", resItem);
        this.getList();
      }

      this.$emit("input", val);
    },
    search(val) {
      // if (val) {
      //   let haveOld=false;
      //   this.items.forEach((item)=>{
      //     if(item.text.indexOf(val)!=-1){
      //       haveOld=true;
      //     }
      //   })
      //   if (!haveOld) {
      //     this.getList(val);
      //   }
      // }
    },
  },
};
</script>
<style>
.tag-select .v-list-item--active {
  color: rgba(0, 0, 0, 0.38);
  background: #eee;
}
</style>
