<template>
  <v-container>
    <div class="d-flex">
      <v-card
        class="bg-white border-radius-sm px-4 pt-4 mr-3"
        style="width: 480px"
      >
        <vxe-button status="my-primary" @click="triggerFileUpload" size="mini">
          图片、文件解析
        </vxe-button>
        <kimiFile
          ref="kimiFile"
          @returnFileContent="returnFileContent"
        ></kimiFile>

        <!-- <textarea
          v-model="aiRes"
          class="mt-2"
          style="width: 100%; border: 1px solid #ccc"
          rows="5"
        ></textarea> -->
        <div class="mt-2">
          <div
            v-for="item in aiList"
            style="border: 1px solid #ccc"
            class="pa-1 d-flex"
            @click="addToForm(item)"
          >
            <div style="width: 380px">
              <div>{{ item.date }}</div>
              <div>{{ item.title }}</div>
            </div>
            <div
              style="width: 80px"
              :style="{ color: item.isSave ? 'green' : 'red' }"
            >
              {{ item.isSave == true ? "已保存" : "未处理" }}
            </div>
          </div>
        </div>
      </v-card>
      <v-card
        class="bg-white border-radius-xl px-4 pt-4"
        style="width: calc(100% - 500px)"
      >
        <div>
          <h5 class="font-weight-bolder text-h5 text-typo mb-0">
            {{ isNewInfo ? "新增" : "编辑" }}
          </h5>
          <v-row class="mt-2">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >事件标题</label
              >
              <v-text-field
                v-model="title"
                required
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="事件标题"
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  mt-2
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="3">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >时间</label
              >
              <v-text-field
                v-model="date"
                required
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder="99.00"
                type="date"
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  mt-2
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >是否为重磅</label
              >
              <v-select
                v-model="hot"
                :items="coins"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="38"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >事件详情</label
              >
              <v-textarea
                v-model="content"
                placeholder="事件详情"
                clearable
                auto-grow
                outlined
                rows="3"
                counter="5000"
                row-height="25"
                shaped
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >复盘</label
              >
              <v-textarea
                v-model="review"
                placeholder="复盘"
                clearable
                auto-grow
                outlined
                rows="3"
                counter="5000"
                row-height="25"
                shaped
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >图片附件</label
              >
              <dropzone
                v-model="fileSingle"
                :multiple="multiple"
                ref="dropzone"
                @removedfile="removedfile"
                @addedfile="addedfile"
              >
              </dropzone>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >绑定股票</label
              >

              <vxe-pulldown
                ref="bindStockPanel"
                destroy-on-close
                style="width: 100%"
                transfer
                class="mt-2"
              >
                <template #default>
                  <v-col
                    style="padding: 0"
                    @click="
                      $refs.bindStockPanel.togglePanel();
                      dialog = false;
                    "
                  >
                    <v-combobox
                      v-model="bindStock"
                      :hide-no-data="!search"
                      hide-selected
                      label="请选择"
                      multiple
                      small-chips
                      solo
                      readonly
                    >
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.name }}
                          </span>
                          <v-icon small @click.stop="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </template>

                <template #dropdown>
                  <div class="note-bindStock-dropdown shadow-lg">
                    <StockSelect
                      :showIcon="true"
                      :returnItem="true"
                      @getItem="bindNewStock"
                      placeholder="搜索股票(选择后自动添加)"
                      :disableArr="bindStockIds"
                      style="width: 100%"
                    >
                    </StockSelect>
                    <vxe-table auto-resize :data="bindStock" height="300px">
                      <vxe-column field="name" title="股票名"></vxe-column>
                      <vxe-column field="code" title="代码"></vxe-column>
                      <vxe-column title="操作">
                        <template #default="{ row }">
                          <a
                            href="javascript:;"
                            @click="deleteStock(row.id)"
                            class="text-xs font-weight-normal text-typo mr-1"
                            style="color: #344767"
                            >删除</a
                          >
                        </template>
                      </vxe-column>
                    </vxe-table>
                  </div>
                </template>
              </vxe-pulldown>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >绑定标签</label
              >
              <vxe-pulldown
                ref="bindTagPanel"
                destroy-on-close
                style="width: 100%"
                class="mt-2"
                :transfer="true"
              >
                <template #default>
                  <v-col
                    style="padding: 0"
                    @click="
                      keyword = [];
                      currentTag = {};
                      $refs.bindTagPanel.togglePanel();
                      dialog = false;
                    "
                  >
                    <v-combobox
                      v-model="bindTag"
                      :hide-no-data="!search"
                      hide-selected
                      label="请选择"
                      multiple
                      small-chips
                      solo
                      readonly
                    >
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`${colorData[item.level]}`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.name }}
                          </span>
                          <v-icon small @click.stop="deleteTag(item.id)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </template>
                <template #dropdown>
                  <div class="note-bindStock-dropdown shadow-lg">
                    <v-row>
                      <v-col lg="4" md="4" sm="4" cols="12">
                        <TagSelect
                          :showIcon="true"
                          :returnItem="true"
                          @getItem="bindNewTag"
                          placeholder="搜索标签(选择后自动添加)"
                          :disableArr="bindTagIds"
                          style="width: 100%"
                        >
                        </TagSelect>
                        <vxe-table auto-resize :data="bindTag" height="300px">
                          <vxe-column field="name" title="标签名">
                            <template #default="{ row }">
                              <a
                                href="javascript:;"
                                @click="getKeyWord(row.id)"
                                class="font-weight-normal text-typo mr-1"
                                style="color: #344767"
                                >{{ row.name }}</a
                              >
                            </template>
                          </vxe-column>
                          <vxe-column field="type" title="类型" width="80px">
                            <template #default="{ row }">
                              {{ getTagType(row) }}
                            </template>
                          </vxe-column>
                          <vxe-column title="操作" width="80px">
                            <template #default="{ row }">
                              <a
                                href="javascript:;"
                                @click="deleteTag(row.id)"
                                class="
                                  text-xs
                                  font-weight-normal
                                  text-typo
                                  mr-1
                                "
                                style="color: #344767"
                                >删除</a
                              >
                            </template>
                          </vxe-column>
                        </vxe-table>
                      </v-col>
                      <v-col sm="8" md="8" lg="8" cols="12">
                        <vxe-toolbar>
                          <template v-slot:buttons>
                            <vxe-button
                              v-if="currentTag.name"
                              mode="text"
                              status="primary"
                              >当前标签： {{ currentTag.name }}</vxe-button
                            >
                            <vxe-button
                              status="success"
                              :disabled="!currentTag.name"
                              @click="addKeyWord()"
                              >新增关键字</vxe-button
                            >
                          </template>
                        </vxe-toolbar>
                        <vxe-table
                          border
                          keep-source
                          auto-resize
                          :data="keyword"
                          height="300px"
                          ref="paperTable"
                          row-id="name"
                          :checkbox-config="{
                            checkRowKeys: bindKeyWordsAll,
                          }"
                          :row-config="{ isCurrent: true, keyField: 'name' }"
                          @checkbox-change="togglePaperSelect"
                          @checkbox-all="toggleAllPaperSelect"
                          :edit-config="{
                            trigger: 'manual',
                            mode: 'cell',
                          }"
                        >
                          <vxe-table-column
                            type="checkbox"
                            width="60"
                          ></vxe-table-column>
                          <vxe-column
                            :edit-render="{
                              autofocus: '.vxe-input--inner',
                              autoselect: true,
                            }"
                            field="name"
                            title="名称"
                          >
                            <template #edit="{ row }">
                              <vxe-input
                                v-model="row.name"
                                type="text"
                                placeholder="请输入关键字"
                              ></vxe-input>
                            </template>
                          </vxe-column>
                          <vxe-column title="操作" width="300px" align="center">
                            <template #default="{ row }">
                              <a
                                v-if="!$refs.paperTable.isActiveByRow(row)"
                                href="javascript:;"
                                @click="editRowEvent(row)"
                                class="text-xs font-weight-normal text-typo"
                                style="color: #344767; margin-right: 10px"
                                >编辑</a
                              >
                              <a
                                v-if="$refs.paperTable.isActiveByRow(row)"
                                href="javascript:;"
                                @click="saveRowEvent(row)"
                                class="text-xs font-weight-normal text-typo"
                                style="color: #344767; margin-right: 10px"
                                >保存</a
                              >
                              <a
                                v-if="$refs.paperTable.isActiveByRow(row)"
                                href="javascript:;"
                                @click="cancelRowEvent(row)"
                                class="text-xs font-weight-normal text-typo"
                                style="color: #344767; margin-right: 10px"
                                >取消</a
                              >
                              <a
                                href="javascript:;"
                                @click="removeEvent(row)"
                                class="text-xs font-weight-normal text-typo"
                                style="color: #344767"
                                >删除</a
                              >
                            </template>
                          </vxe-column>
                        </vxe-table>
                      </v-col>
                    </v-row>
                  </div>
                  <v-dialog
                    v-model="dialog"
                    max-width="290"
                    attach
                    hide-overlay
                  >
                    <v-card>
                      <v-card-title class="text-h5"> 提示 </v-card-title>

                      <v-card-text> 确定删除该条数据？ </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialog = false"
                        >
                          取消
                        </v-btn>

                        <v-btn
                          color="green darken-1"
                          text
                          @click="
                            removeRow();
                            dialog = false;
                          "
                        >
                          确定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </vxe-pulldown>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >绑定关键字</label
              >

              <v-col
                style="padding: 0"
                @click="
                  keyword = [];
                  currentTag = {};
                  $refs.bindTagPanel.togglePanel();
                "
              >
                <v-combobox
                  v-model="bindKeyWords"
                  :hide-no-data="!search"
                  hide-selected
                  label="请选择"
                  multiple
                  small-chips
                  solo
                  readonly
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" label small>
                      <span class="pr-2">
                        {{ decodeURIComponent(item.value) }}
                      </span>
                      <v-icon small @click.stop="deleteKeyWord(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-end pb-5">
          <vxe-button status="my-danger" @click="back()" size="mini">
            取消
          </vxe-button>

          <vxe-button status="my-primary" @click="saveInfo" size="mini">
            提交
          </vxe-button>
        </div>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import moment from "moment";
import Timeline from "@/api/timeline.js";
import Dropzone from "@/views/Widgets/Dropzone.vue";
import StockSelect from "@/components/CommonSelect/StockSelect.vue";
import TagSelect from "@/components/CommonSelect/TagSelect.vue";
import kimiFile from "@/components/KimiFile.vue";
const OSS = require("ali-oss");
import File from "@/api/file.js";
import Stock from "@/api/stock.js";
import Tag from "@/api/tag.js";
import { findIndexOf } from "xe-utils";
import VXETable from "vxe-table";

export default {
  name: "AddInfo",
  components: {
    Dropzone,
    StockSelect,
    TagSelect,
    kimiFile,
  },
  data() {
    return {
      delRow: {},
      dialog: false,
      select: ["Florida", "FL"],
      e1: 1,
      fileSingle: [],
      coins: [
        { text: "是", value: 1 },
        { text: "否", value: 0 },
      ],
      bindStock: [],
      bindTag: [],
      bindKeyWords: [],
      activator: null,
      attach: null,
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
      editing: null,
      editingIndex: -1,
      items: [],
      nonce: 1,
      menu: false,
      model: [
        {
          text: "Foo",
          color: "blue",
        },
        {
          text: "Bar",
          color: "red",
        },
      ],
      x: 0,
      search: null,
      y: 0,
      rules: {
        required: (value) => !!value || "Required",
      },
      date: moment().format("YYYY-MM-DD"),
      content: "",
      review: "",
      isNewInfo: true,
      attachments: [],
      hot: 0,
      title: "",
      multiple: true,
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
      keyword: [],
      currentTag: {},

      aiRes: "",
      aiList: [],
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      this.isNewInfo = false;
      let saveTimeLine = JSON.parse(
        window.localStorage.getItem("saveTimeLine")
      );
      this.title = saveTimeLine.title;
      this.content = saveTimeLine.content;
      this.date = saveTimeLine.date;
      this.hot = saveTimeLine.hot;
      saveTimeLine.stocks.forEach((item) => {
        item.color =
          this.colors[Math.floor(Math.random() * this.colors.length)];
        this.bindStock.push(item);
      });
      saveTimeLine.races.forEach((item) => {
        item.color =
          this.colors[Math.floor(Math.random() * this.colors.length)];
        this.bindTag.push(item);
      });

      this.attachments = saveTimeLine.attachments;
      saveTimeLine.keywords.forEach((item) => {
        this.bindKeyWords.push({
          value: encodeURIComponent(item.value),
          race_id: item.race_id,
        });
      });

      // this.fileSingle = saveTimeLine.attachments;
    } else {
      this.isNewInfo = true;
    }
    setTimeout(() => {
      const dropVar = this.$refs.dropzone.dropzone;

      this.attachments &&
        this.attachments.length &&
        this.attachments.forEach((item) => {
          let mockFile = {
            name: item.split("/")[5],
            size: 12345,
            src: item,
            upload: { uuid: item.split("/")[5] },
          };
          // dropVar.addFile.call(dropVar, mockFile);//添加mock图片到显示区域
          // dropVar.options.thumbnail.call(dropVar, mockFile, item);//添加数据源给mock图片
          dropVar.displayExistingFile(mockFile, item);
        });
    }, 100);
  },
  methods: {
    //绑定新股票
    bindNewStock(newItem) {
      let haveAdd = false;
      this.bindStock.forEach((item) => {
        if (item.id == newItem.id) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        newItem.color =
          this.colors[Math.floor(Math.random() * this.colors.length)];
        this.bindStock.push(newItem);
      } else {
        this.showToast("不能重复绑定同一个股票", "danger", 5000);
      }
    },
    //绑定新标签
    bindNewTag(newItem) {
      console.log(newItem);
      let haveAdd = false;
      this.bindTag.forEach((item) => {
        if (item.id == newItem.id) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        this.bindTag.push(newItem);
        this.getKeyWord(newItem.id);
      } else {
        this.showToast("不能重复绑定同一个标签", "danger", 5000);
      }
    },
    //删除绑定的股票
    deleteStock(id) {
      this.bindStock.forEach((item, index) => {
        if (item.id == id) {
          this.bindStock.splice(index, 1);
        }
      });
    },
    //删除绑定的标签
    deleteTag(id) {
      this.keyword = [];
      this.currentTag = {};
      this.bindTag.forEach((item, index) => {
        if (item.id == id) {
          this.bindTag.splice(index, 1);
        }
      });
      let tempArr = [];
      this.bindKeyWords.forEach((item, index) => {
        if (item.race_id !== id) {
          tempArr.push(item);
        }
      });
      this.bindKeyWords = tempArr;
    },
    deleteKeyWord(item) {
      let value = item.value;
      this.bindKeyWords.forEach((item, index) => {
        if (item.value == value) {
          this.bindKeyWords.splice(index, 1);
        }
      });

      let table = this.$refs.paperTable;

      table &&
        this.keyword &&
        this.keyword.forEach((item) => {
          if (item.id == value) {
            table.setCheckboxRow(table.getRowById(item.name), false);
          }
        });
    },
    getTagType(item) {
      if (item.level == 1) {
        return "核心";
      } else if (item.level == 2) {
        return "一级主题";
      } else if (item.level == 3) {
        return "普通主题";
      } else if (item.level == 0) {
        return "行业风格";
      }
    },
    getKeyWord(id) {
      this.keyword = [];
      this.isLoading = true;
      Tag.getTagDetail(id)
        .then((res) => {
          this.currentTag = res.data;
          let arr = [];
          this.currentTag.keyword.forEach((item, index) => {
            arr.push({
              race_id: this.currentTag.id,
              name: item,
              id: encodeURIComponent(item),
            });
          });
          this.keyword = this.keyword.concat(arr);
          let table = this.$refs.paperTable;
          setTimeout(() => {
            this.keyword.forEach((item) => {
              this.bindKeyWords.forEach((items) => {
                if (items.value == item.id) {
                  table.setCheckboxRow(table.getRowById(item.name), true);
                }
              });
            });
          }, 100);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async saveInfo() {
      let that = this;

      if (this.fileSingle.length > 0) {
        await Promise.all(
          this.fileSingle.map((file, i) => {
            return this.uploadFile(file);
          })
        ).then((res) => {
          console.log(res);

          this.attachments = res;
          console.log(this.attachments);
        });
      }
      // return false
      let sendKeyWords = [];
      this.bindKeyWords.forEach((item) => {
        sendKeyWords.push({
          value: decodeURIComponent(item.value),
          race_id: item.race_id,
        });
      });

      if (this.isNewInfo) {
        this.showLoading("保存中，请稍候");
        let sendData = {
          date: this.date,
          title: this.title,
          hot: this.hot,
          content: this.content,
          review: this.review,
          stock_ids: this.bindStockIds,
          race_ids: this.bindTagIds,
          attachments: this.attachments,
          keywords: sendKeyWords,
        };
        Timeline.newTimeline(sendData)
          .then((res) => {
            this.showToast("保存成功", "success", 5000);

            //标记aiList该条信息为已保存
            this.aiList.forEach((item) => {
              if (item.date == this.date && item.title == this.title) {
                item.isSave = true;
              }
            });

            this.clearForm();

            window.localStorage.removeItem("saveTimeLine");
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      } else {
        this.showLoading("保存修改中，请稍候!");
        let editData = {
          date: this.date,
          title: this.title,
          hot: this.hot,
          review: this.review,
          content: this.content,
          stock_ids: this.bindStockIds,
          race_ids: this.bindTagIds,
          attachments: this.attachments,
          id: this.$route.query.id,
          keywords: sendKeyWords,
        };
        Timeline.editTimeline(editData)
          .then((res) => {
            window.localStorage.removeItem("saveTimeLine");
            this.$router.push({
              name: "投资日历",
            });
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },
    clearForm() {
      //清空表单
      this.date = "";
      this.title = "";
      this.hot = 0;
      this.content = "";
      this.review = "";
      this.bindStock = [];
      this.bindTag = [];
      this.attachments = [];
      this.bindKeyWords = [];
    },
    addToForm(item) {
      this.clearForm();
      this.date = item.date;
      this.title = item.title;
      this.content = item.content;
    },
    addedfile(file) {
      console.log(file);
    },
    removedfile(file) {
      console.log(file);
      this.attachments.forEach((item, index) => {
        if (item.split("/")[5] == file.name) {
          this.attachments.splice(index, 1);
        }
      });
    },

    uploadFile(fileItem) {
      console.log(fileItem);
      return new Promise((resolve, reject) => {
        if (fileItem.size == 12345) {
          resolve(fileItem.src);
        }
        // if(File.isFile(fileItem)){
        //     resolve(item.url)
        // }

        File.getUploadSts().then((res) => {
          let info = res.data;
          let client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: "qinglitouyan",
          });

          let fileName = "fileStore/" + info.uuid + "/" + fileItem.name;
          return new Promise((resolves, reject) => {
            // 调用 upload() 方法上传文件
            client
              .multipartUpload(fileName, fileItem, {
                progress: (p) => {
                  //获取进度条的值
                  //this.progress = (p * 100).toFixed(2);
                },
              })
              .then((result) => {
                //下面是如果对返回结果再进行处理，根据项目需要
                let url = result.res.requestUrls[0];
                if (url.indexOf("uploadId") != -1) {
                  url = /(.+(?=\?uploadId))/.exec(url)[0];
                }

                File.uploadFile(
                  info.uuid,
                  "/fileStore/" + info.uuid,
                  fileItem.name,
                  {}
                )
                  .then((res) => {
                    //console.log(res);
                    let url = `${File.getApiUrl()}/api/file/${
                      info.uuid
                    }/downloadDirect`;

                    resolve(url);
                  })
                  .catch((err) => {
                    console.log("err:", err);
                  });
              })
              .catch((err) => {
                console.log("err:", err);
              });
          });
        });
      });
    },
    back() {
      window.history.go(-1);
    },
    togglePaperSelect({ rowid, checked, row }) {
      console.log(row);

      if (checked) {
        this.bindKeyWords.push({ value: rowid, race_id: row.race_id });
      } else {
        this.bindKeyWords.forEach((item, index) => {
          if (item.value == rowid) {
            this.bindKeyWords.splice(index, 1);
          }
        });
      }
    },

    toggleAllPaperSelect({ records, checked }) {
      if (checked) {
        console.log(records);

        records.forEach((item) => {
          if (this.bindKeyWordsAll.indexOf(item.id) == -1) {
            this.bindKeyWords.push({ value: item.id, race_id: item.race_id });
          }
        });
      } else {
        // 注意取消全选时需要遍历当前表格数据来删除，records不管用
        this.keyword.forEach((item) => {
          this.bindKeyWords.forEach((items, index) => {
            if (items.value == item.id) {
              this.bindKeyWords.splice(index, 1);
            }
          });
        });
      }
    },
    async addKeyWord(row) {
      console.log(row);
      let record = {
        name: "row_" + new Date().getTime(),
        race_id: this.currentTag.id,
        id: encodeURIComponent("row_" + new Date().getTime()),
      };
      this.keyword.unshift(record);
      let { row: newRow } = await this.$refs.paperTable.insertAt(record, row);

      await this.$refs.paperTable.setActiveRow(
        this.$refs.paperTable.getRowById(newRow.name)
      );
    },
    saveTag() {
      let curtemp = [];
      this.$refs.paperTable.tableFullData.forEach((item, i) => {
        curtemp.push(item.name);
      });
      let submitInfo = {
        name: this.currentTag.name,
        level: this.currentTag.level,
        user_id: this.currentTag.user_id,
        is_show: this.currentTag.is_show,
        base_info: this.currentTag.base_info,
        keyword: curtemp,
      };
      Tag.editTag(this.currentTag.id, submitInfo)
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          this.getKeyWord(this.currentTag.id);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    setEdit(row) {
      console.log(row);
    },
    editRowEvent(row) {
      this.$refs.paperTable.setActiveRow(row);
    },
    saveRowEvent(row) {
      console.log(row);

      this.bindKeyWords.forEach((item, index) => {
        if (item.value == row.id) {
          item.value = row.name;
          row.id = encodeURIComponent(row.name);
          item.id = row.id;
        }
      });
      this.$refs.paperTable.clearActived().then(() => {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.saveTag();
        }, 300);
      });
    },
    cancelRowEvent(row) {
      const xTable = this.$refs.paperTable;
      xTable.clearActived().then(() => {
        // 还原行数据
        xTable.revertData(row);
      });
    },
    removeEvent(row) {
      this.dialog = true;
      this.delRow = row;
    },
    async removeRow() {
      const $table = this.$refs.paperTable;

      $table.remove(this.delRow);
      this.bindKeyWords.forEach((item, index) => {
        if (item.value == this.delRow.id) {
          this.bindKeyWords.splice(index, 1);
        }
      });
      this.saveTag();
      this.delRow = {};
    },

    async returnFileContent(content) {
      console.log("File content:", content);
      this.aiRes = "";
      this.showLoading("AI处理中，请稍候");
      this.$aiHelpers.chatWithAi(
        { name: "gpt-4o" },
        `把下面的内容用json格式整理出来，注意不要遗漏任何一条。
          格式：
          [{
              date: "yyyy-mm-dd",
              title: "事件十到二十字摘要",
              content: “事件原文”,
          }]
          ${content}
          `,
        (content) => {
          //console.log("Update:", content);
          this.aiRes += content;
        },
        () => {
          console.log("Finished");
          this.hideLoading();
          this.aiRes = this.aiRes.replace(/```json/g, "");
          this.aiRes = this.aiRes.replace(/```/g, "");
          this.aiList = JSON.parse(this.aiRes);
        },
        (error) => console.error("Error:", error)
      );
    },
    triggerFileUpload() {
      this.$refs.kimiFile.triggerFileUpload();
    },
  },
  computed: {
    bindTagIds() {
      if (!Array.isArray(this.bindTag)) {
        this.bindTag = [];
      }
      let ids = [];
      this.bindTag.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
    bindStockIds() {
      if (!Array.isArray(this.bindStock)) {
        this.bindStock = [];
      }
      let ids = [];
      this.bindStock.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
    bindKeyWordsAll() {
      if (!Array.isArray(this.bindKeyWords)) {
        this.bindKeyWords = [];
      }
      let ids = [];
      this.bindKeyWords.forEach((item) => {
        ids.push(item.value);
      });
      return ids;
    },
  },
};
</script>
