<template>
  <div class="MyBar" @scroll="BarScroll" ref="MyBar">
    <div
      class="MyBar-thumb"
      :style="{
        width: width + 'px',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "MyScrollBar",
  components: {},
  props: {
    width: {
      default: "0",
    },
    left: {
      default: "0",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 滚动条滚动事件
    BarScroll(event) {
      console.log(event);
      console.log(event.target.scrollLeft);
      this.$emit("scrollLeft", event.target.scrollLeft);
    },
    // 滚动条滚动到指定位置
    BarScrollToLeft(left) {
      this.$refs.MyBar.scrollLeft = left;
    },
  },
};
</script>

<style scoped>
.MyBar {
  height: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #fff;
  z-index: 10;
  overflow-x: scroll;
  overflow-y: hidden;
}
.MyBar .MyBar-thumb {
  height: 10px;
}
</style>
