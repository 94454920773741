<template>
  <div>
    <div :style="{'height': pageHeight+'px'}">
      <vxe-table
        id="recommendStockTable"
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="center"
        ref="recommendStockTable"
        :loading="tableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
        :filter-config="{ showIcon: false }"
      >
        <vxe-column fixed="left" width="70" field="stock_code" title="股票代码">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-typo"
                >{{ row.stock_code ? row.stock_code : "-" }}</a
              >
            </div>
          </template>
        </vxe-column>

        <vxe-column fixed="left" width="80" field="stock_name" title="股票名称">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-typo"
                >{{ row.stock_name ? row.stock_name : "-" }}</a
              >
            </div>
          </template>
        </vxe-column>

        <vxe-column
          width="70"
          field="user_name"
          title="推荐人"
          :filters="[{ data: '' }]"
          :filter-method="
            (value) => $tableHelpers.filterInputMethod(value, 'user_name')
          "
        >
          <template #filter="{ $panel, column }">
            <template v-for="(option, index) in column.filters">
              <input
                class="pa-2"
                type="type"
                :key="index"
                v-model="option.data"
                @input="$panel.changeOption($event, !!option.data, option)"
                @keyup.enter="$panel.confirmFilter()"
                placeholder="按回车确认筛选"
              />
            </template>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.user_name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="150" field="reason" title="推荐理由">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.reason }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="70" field="rise_per" title="预期空间">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ (row.rise_per * 100).toFixed(0) + "%" }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="110" field="created_at" title="推荐时间">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.created_at }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="80" field="end_date" title="结束时间">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.end_date }}
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_focus" title="推荐后涨幅" width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                $stockHelpers.getPercent(row.applies_focus)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column width="100" field="sw_level2_name" title="行业">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.sw_level2_name }}
              </div>
            </div>
          </template>
        </vxe-column> -->
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import StockRecommend from "@/api/stockRecommend.js";
export default {
  name: "StockRecommend",
  components: {},
  props: {
    pageHeight: 0,
  },
  computed: {
    list() {
      return this.tableData;
    },
  },
  data() {
    return {
      tableLoading: false,
      filterName: "",
      tableData: [],
    };
  },
  mounted() {
    this.getStockRecommendList();
    Bus.$on("refreshStockRecommendList", (e) => {
      this.getStockRecommendList();
    });
  },
  methods: {
    getStockRecommendList() {
      this.tableLoading = true;
      StockRecommend.getStockRecommendList()
        .then((res) => {
          //console.log(res.data);
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
