var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"px-3",attrs:{"custom":"","refresh":{ query: _vm.getUserList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{staticClass:"mr-1",attrs:{"type":"search","placeholder":"全文搜索"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('TypeFilter',{staticClass:"mr-1",attrs:{"colName":"type","placeholder":"账号类型","table":_vm.$refs.userTable,"inputWidth":"100px"}}),_c('StatusFilter',{staticClass:"mr-1",attrs:{"colName":"status","placeholder":"启用状态","table":_vm.$refs.userTable,"inputWidth":"100px"}}),_c('RoleDesc'),_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"size":"medium","content":"新增用户","status":"my-primary"},on:{"click":function($event){return _vm.userAdd()}}}),_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"size":"medium","content":"工作报表","status":"my-primary"},on:{"click":function($event){return _vm.kpiExport()}}})]},proxy:true}])}),_c('vxe-table',{ref:"userTable",attrs:{"id":"userTable","row-id":"id","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.userTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight,"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 50, oSize: 30 },"empty-render":{ name: 'NotData' },"filter-config":{ showIcon: false }}},[_c('vxe-column',{attrs:{"width":"150","field":"name","title":"用户名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"150","field":"base","title":"名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.base)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"150","field":"depot","title":"代号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.depot)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"150","field":"contact","title":"联系方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.contact)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"type","title":"账号类型","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'type'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('span',{style:({ color: row.type == '内部' ? '#ffaa00' : '#000000' })},[_vm._v(_vm._s(row.type))])])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"status","title":"启用状态","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'status'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"100","field":"rolesArr","title":"角色","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'rolesArr'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"text-align":"start","word-wrap":"break-word","white-space":"pre-wrap"}},_vm._l((row.rolesArr),function(role,index){return _c('v-chip',{key:index,staticClass:"mr-1 px-2 py-0",staticStyle:{"height":"16px","opacity":"0.8","margin-right":"1px"},attrs:{"dense":"","small":"","dark":"","color":_vm.getRoleColor(role)}},[_vm._v(" "+_vm._s(role)+" ")])}),1)])]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"150","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.editUser(row)}}},[_vm._v("修改")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.banUser(row)}}},[_vm._v(" "+_vm._s(row.status == "正常" ? "禁用" : "启用")+" ")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px","color":"red"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteUser(row.id)}}},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }