<template>
  <div>
    <v-container fluid class="px-2 py-2">
      <div v-if="currentTab == 1">
        <div style="opacity: 0.9" class="mt-2 d-flex align-center">
          <vxe-radio-group v-model="currType" size="mini">
            <vxe-radio-button
              :label="thisType.label"
              :content="thisType.content"
              v-for="thisType in infoType"
              v-if="thisType.show"
            ></vxe-radio-button>
          </vxe-radio-group>

          <vxe-input
            v-model="filterName"
            type="search"
            placeholder="全文搜索"
            class="ml-1"
            size="mini"
            @change="filterNameChange"
            v-if="isCenter"
          ></vxe-input>
        </div>
        <div class="mt-2" v-if="loading">
          <v-skeleton-loader
            v-for="item in 20"
            v-bind="attrs"
            type="article, actions"
          >
          </v-skeleton-loader>
        </div>

        <div v-if="currType == '热点'">
          <Hotspot></Hotspot>
        </div>
        <div v-if="!loading && currType != '热点'">
          <v-card
            class="border-radius-md mt-2"
            v-for="(item, index) in list"
            :key="index"
            style="margin-top: 10px; font-size: 13px"
          >
            <div class="pa-1">
              <div style="width: 100%" class="d-flex pa-1">
                <NewsDisplay
                  :content="item.content"
                  style="line-height: 18px; width: 70%"
                ></NewsDisplay>
                <div style="width: calc(30% - 10px); border: 1px solid #ccc">
                  <div class="ma-2">
                    <h3 class="text-dark">AI速读</h3>
                    <div
                      class="mb-2"
                      style="
                        font-size: 14px;
                        color: #1890ff;
                        border-radius: 3px;
                        margin-bottom: 2px;
                        margin-right: 3px;
                        padding: 3px;
                        background-color: #e1eaf7;
                      "
                    >
                      {{ item.abstract }}
                    </div>
                    <h3 class="text-dark">AI关键词</h3>
                    <div class="d-flex" style="flex-wrap: wrap; opacity: 0.8">
                      <span
                        v-for="keyword in item.keywords.split(',')"
                        style="
                          font-size: 14px;
                          border-radius: 3px;
                          margin-bottom: 2px;
                          margin-right: 3px;
                          padding: 3px;
                        "
                      >
                        {{ keyword }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pa-1" style="border-top: 1px solid #ccc; color: #aaa">
                {{ item.from_user }} | {{ item.create_date }}
              </div>
              <div class="pa-1 d-flex" style="flex-wrap: wrap; opacity: 0.8">
                <span
                  v-for="(race, index) in item.race_keywords"
                  :key="race.id"
                  style="
                    cursor: pointer;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 3px;
                    margin-bottom: 2px;
                    margin-right: 3px;
                    padding: 3px;
                  "
                  :style="{
                    backgroundColor:
                      race.color == `#e8f5e9` ? `#00b294` : race.color,
                  }"
                  @click="$stockHelpers.openTagDetail(race.id)"
                >
                  {{ race.name }}
                </span>
                <span
                  v-for="(stock, index) in item.stock_keywords"
                  :key="stock.id"
                  style="
                    cursor: pointer;
                    font-size: 14px;
                    color: #1890ff;
                    border-radius: 3px;
                    margin-bottom: 2px;
                    margin-right: 3px;
                    padding: 3px;
                    background-color: #e1eaf7;
                  "
                  @click="$stockHelpers.openStockDetail(stock.id)"
                >
                  {{ stock.name }}
                </span>
              </div>
            </div>
          </v-card>
          <vxe-pager
            background
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            :total="totalResult"
            :layouts="[
              'PrevJump',
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'NextJump',
              'FullJump',
              'Total',
            ]"
            @page-change="handlePageChange"
          >
          </vxe-pager>
        </div>
      </div>
      <HomeContent v-else :tagId="tagId" :stockId="stockId" />
    </v-container>
  </div>
</template>
<script>
import Ai from "@/api/ai.js";
import ShowMoreText from "@/components/ShowMoreText";
import HomeContent from "@/components/HomePage/HomeContent";
import Bus from "@/components/Bus/bus.js";
import Vue from "vue";
import Hotspot from "@/components/ResonanceContrast/Hotspot/Hotspot";
import NewsDisplay from "@/components/InfoCenter/NewsDisplay";

export default {
  name: "StockRate",
  props: {
    stockId: "",
    tagId: "",
  },
  components: {
    ShowMoreText,
    Hotspot,
    NewsDisplay,
    HomeContent,
  },
  data() {
    return {
      //是否是主页的信息中心
      currentTab: 0,
      isCenter: true,
      loading: false,
      infoType: [
        {
          label: "全部消息",
          content: "全部消息",
          show: true,
        },
        {
          label: "我的关注",
          content: "我的关注",
          show: true,
        },
        {
          label: "热点",
          content: "热点",
          show: true,
        },
        {
          label: "调研邀请",
          content: "调研",
          show: true,
        },
        {
          label: "电话会预告",
          content: "电话会",
          show: true,
        },
        {
          label: "点评",
          content: "点评",
          show: true,
        },
        {
          label: "资讯",
          content: "资讯",
          show: true,
        },
        {
          label: "FAQ",
          content: "问答",
          show: true,
        },
        {
          label: "核心标签相关",
          content: "核心标签相关",
          show: true,
        },
      ],
      currType: "全部消息",
      filterName: "",

      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      list: [],
      currentPage: 1,
      pageSize: 20,
      totalResult: 0,
    };
  },
  mounted() {
    Bus.$on("infoCenterChange", (e) => {
      this.currentTab = e;
      //console.log(e);
      window.scrollTo(0, 0);
    });
    if (this.stockId || this.tagId) {
      console.log(this.tagId, this.stockId);
      this.isCenter = false;
      //infoType中的核心标签相关不显示
      this.infoType.filter((item) => {
        if (
          item.label == "核心标签相关" ||
          item.label == "我的关注" ||
          item.label == "热点"
        ) {
          item.show = false;
        }
      });
    }
    this.getList();
  },

  methods: {
    handlePageChange({ type, currentPage, pageSize, $event }) {
      this.currentPage = currentPage;
      //this.pageSize = pageSize;
      this.getList();
    },
    getList() {
      this.list = [];
      scrollTo(0, 0);
      let filters = {};
      if (this.currType !== "全部消息") {
        if (this.currType == "核心标签相关") {
          filters.is_level_1 = 1;
        } else if (this.currType == "我的关注") {
          filters.is_focus = 1;
        } else {
          filters.type = [this.currType];
        }
      }
      if (this.filterName !== "") {
        filters.search = this.filterName;
      }

      if (this.stockId) {
        filters.stock_id = this.stockId;
      }
      if (this.tagId) {
        filters.race_id = this.tagId;
      }
      this.loading = true;
      Ai.getInfoFlow(this.currentPage, filters)
        .then((res) => {
          if (this.filterName != "") {
            //遍历res.data.data，将res.data.data.content中的关键词高亮
            res.data.data.forEach((item) => {
              item.content = item.content.replace(
                new RegExp(this.filterName, "g"),
                `<span style="background-color:yellow">${this.filterName}</span>`
              );
            });
          }
          this.list = res.data.data;
          this.totalResult = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getScoreColor(item) {
      if (parseInt(item.score) > parseInt(item.last_score)) {
        return "text-red lighten-1 font-weight-bolder";
      } else if (parseInt(item.score) < parseInt(item.last_score)) {
        return "text-teal lighten-1 font-weight-bolder";
      } else {
        return "text-dark lighten-1 font-weight-bolder";
      }
    },
    getContent(item1) {
      return '<b class="text-dark" style="font-size: 15px;">评分理由</b>:' +
        item1.reason
        ? item1.reason
        : "无";
    },
    filterNameChange: Vue.prototype.$commonHelpers.throttle(function (...args) {
      this.currentPage = 1;
      this.getList();
      //console.log(args);
    }, 1000),
  },
  watch: {
    currType(newValue) {
      if (newValue == "热点") {
      } else {
        this.currentPage = 1;
        this.getList();
      }
    },
  },
};
</script>
<style scoped="scoped"></style>
