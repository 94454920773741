<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :returnItem="true"
          :disableArr="[]"
          @getItem="bindNewStock"
        ></StockSelect>
      </div>
      <div>
        已选择的股票
        <ul>
          <li v-for="stock in stock_list">
            {{ stock.name }}
            <a
              class="ml-4 text-red"
              href="javascript:void(0)"
              @click="deleteStock(stock.id)"
              >删除</a
            >
          </li>
        </ul>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
export default {
  name: "StockAdd",
  components: {
    StockSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
  },
  data() {
    return {
      stock_list: [],
    };
  },
  methods: {
    bindNewStock(item) {
      //检查id是否已经存在
      if (this.stock_list.find((i) => i.id == item.id)) {
        this.showToast("请勿重复添加", "warning", 5000);
        return;
      }
      this.stock_list.push(item);
    },
    deleteStock(id) {
      this.stock_list = this.stock_list.filter((item) => {
        return item.id != id;
      });
    },
    submit() {
      if (this.stock_list.length == 0) {
        this.showToast("请先选择绑定的股票", "warning", 5000);
        return;
      }
      //console.log(this.submitInfo);
      this.showLoading("保存中，请稍候");
      let arr = [];
      this.iframeData.data.stock.forEach((i) => {
        i.children.forEach((item) => {
          arr.push(item.id);
        });
      });
      this.stock_list.forEach((item) => {
        arr.push(item.id);
      });
      Tag.bindStock(this.iframeData.data.id, arr)
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshTagDetail", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
