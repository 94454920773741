import Vue from "vue";
import File from "@/api/file.js";
import Bus from "@/components/Bus/bus.js";
import AiAnalysis from "@/components/AiAnalysis";
import AiAnalysisIframe from "@/components/AiAnalysisIframe";
import AiAnalysisMeeting from "@/components/AiAnalysisMeeting";
import meetingPreview from "@/components/MeetingPreview/meetingPreview";
import router from "@/router";
import axios from "axios";

const throttle = (fn, delay = 100) => {
  let timer = null;
  return function () {
    if (timer) return;
    timer = setTimeout(() => {
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
};

const downloadFile = function (id) {
  Vue.prototype.showLoading("下载中，请稍候");
  File.downloadFile(id)
    .then((res) => {
      window.open(res.data.url);
    })
    .catch((err) => {
      console.log(err.msg);
    })
    .finally(() => {
      Vue.prototype.hideLoading();
    });
};

const previewFile = function (id) {
  Vue.prototype.showLoading("获取文件中，请稍候");
  File.downloadFile(id)
    .then((res) => {
      Bus.$emit("previewFile", { url: res.data.url, name: res.data.name });
    })
    .catch((err) => {
      console.log(err.msg);
    })
    .finally(() => {
      Vue.prototype.hideLoading();
    });
};

const getPermissions = function (permission) {
  //console.log(Vue.prototype.userStore);
  if (Vue.prototype.userStore) {
    let permissions = Vue.prototype.userStore.permissions;
    if (permissions.indexOf(permission) != -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const getRoles = function (roles) {
  //console.log(Vue.prototype.userStore);
  if (Vue.prototype.userStore) {
    let haveRoles = Vue.prototype.userStore.roles;
    let res = false;
    roles.forEach((role) => {
      if (haveRoles.indexOf(role) != -1) {
        res = true;
      }
    });
    return res;
  } else {
    return false;
  }
};

const openAiAnalysis = function (title, content, ApiKey, _this) {
  Vue.prototype.$layer.iframe({
    content: {
      content: AiAnalysis, //传递的组件对象
      parent: _this, //当前的vue对象
      data: {
        iframeData: {
          content: content,
          ApiKey: ApiKey,
        },
      },
    },
    area: ["800px", "600px"],
    title: title,
    shade: false,
    maxmin: true, //开启最大化最小化
    resize: true, //是否允许拉伸，默认是不允许
  });
};

const openAiAnalysisIframe = function (title, url, _this) {
  Vue.prototype.$layer.iframe({
    content: {
      content: AiAnalysisIframe, //传递的组件对象
      parent: _this, //当前的vue对象
      data: {
        iframeData: {
          url: url,
        },
      },
    },
    area: ["800px", "600px"],
    title: title,
    shade: false,
    maxmin: true, //开启最大化最小化
    resize: true, //是否允许拉伸，默认是不允许
  });
};

//会议预览
const openMeetintg = function (data, _this) {
  Vue.prototype.$layer.iframe({
    content: {
      content: meetingPreview, //传递的组件对象
      parent: _this, //当前的vue对象
      data: {
        iframeData: {
          data: data,
        },
      },
    },
    area: ["1280px", "720px"],
    title: "会议预览--" + data.title,
    shade: false,
    maxmin: true, //开启最大化最小化
    resize: false, //是否允许拉伸，默认是不允许
  });
};
//会议AI纪要
const openAiAnalysisMeeting = (url, _this) => {
  //获取会议内容
  let text = "";
  axios
    .get(url)
    .then((res) => {
      let currentSi = 0;
      res.data.forEach((item) => {
        let si = item.si;
        if (si != currentSi) {
          currentSi = si;
          text += `\n说话人${item.speaker}:`;
        }
        text += item.onebest;
      });

      //路由跳转到实验室
      router.push({
        name: "实验室",
        params: {
          text: text,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      this.responseContent = "获取会议内容失败，请关闭小窗后重试！";
    })
    .finally(() => {
      console.log("finally");
    });

  // Vue.prototype.$layer.iframe({
  //   content: {
  //     content: AiAnalysisMeeting, //传递的组件对象
  //     parent: _this, //当前的vue对象
  //     data: {
  //       iframeData: {
  //         url: url,
  //       },
  //     },
  //   },
  //   area: ["800px", "600px"],
  //   title: "AI会议纪要",
  //   shade: false,
  //   maxmin: true, //开启最大化最小化
  //   resize: true, //是否允许拉伸，默认是不允许
  // });
};

export default {
  throttle,
  downloadFile,
  previewFile,
  getPermissions,
  getRoles,
  openAiAnalysis,
  openAiAnalysisIframe,
  openMeetintg,
  openAiAnalysisMeeting,
};
