<template>
  <div class="ml-4">
    <a
      class="pl-4"
      v-if="item.display"
      v-for="(item, index) in tabList"
      :key="item.url"
      :ripple="true"
      @click="openUrl(item.url)"
      style="background-color: transparent; width: auto"
      :elevation="0"
    >
      <span
        style="font-size: 15px"
        class="pa-0 un-active-tab"
        :style="index === 0 || index === 1 ? 'color: #cf0000' : ''"
        >{{ item.title }}</span
      >
    </a>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "AppBarResonanceContrast",
  props: {},
  components: {},
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          title: "信息流",
          url: "InfoCenter",
          display: true,
        },
        {
          title: "投资日历",
          url: "TimeLine",
          display: true,
        },
        {
          title: "标签共振",
          url: "ResonanceContrast/MomentumQingli",
          display: true,
        },
        {
          title: "每日复盘",
          url: "Review/dayReview",
          display: true,
        },
        {
          title: "量化",
          url: "Quant",
          display: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    openUrl(url) {
      this.$router.push({ path: url });
    },
  },
  watch: {
    currentTab(val) {
      Bus.$emit("AiTabChange", val);
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
}
.un-active-tab {
  color: #7e7e7e;
}
</style>
