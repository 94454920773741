var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LoadingPage',{staticStyle:{"width":"100%","height":"100vh"},attrs:{"visible":_vm.tableLoading}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableLoading),expression:"!tableLoading"}],staticClass:"px-0 py-1"},[_vm._l((_vm.list),function(item){return _c('div',{key:item.id},[_c('v-card',{staticClass:"border-radius-md pa-3 mb-3"},[_c('h3',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',[_vm._v("理由："+_vm._s(item.reason == "nan" ? "暂无" : item.reason))]),_c('vxe-table',{ref:"bindStockTable",refInFor:true,staticClass:"mytable-scrollbar",staticStyle:{"width":"100%"},attrs:{"data":item.data,"border":false,"resizable":"","scroll-y":{ enabled: false },"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.tableLoading,"max-height":"100000","size":"small","sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"align":"left"}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"80","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.code ? row.code : "-")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"fixed":"left","min-width":"80","field":"name","title":"股票名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.name ? row.name : "-")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"100","field":"m_days_n_boards","title":"是否连板"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.num)?_c('span',{staticStyle:{"background-color":"#fff1f1","border":"1px solid #e80000","color":"#e80000","padding":"2px 5px"}},[_vm._v(_vm._s(row.num))]):_vm._e()])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"70","title":"涨幅","field":"shares_range","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.shares_range))},[_vm._v(_vm._s(row.shares_range + "%"))])])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"300","field":"races","title":"标签"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"d-flex",staticStyle:{"flex-wrap":"wrap"}},_vm._l((row.races),function(race,index){return (race.level !== 0)?_c('span',{key:race.id,staticClass:"px-1 py-0",staticStyle:{"cursor":"pointer","font-size":"10px","color":"#fff","border-radius":"3px","margin-bottom":"1px","margin-right":"1px"},style:({
                      backgroundColor:
                        race.color == "#e8f5e9" ? "#00b294" : race.color,
                    }),on:{"click":function($event){return _vm.$stockHelpers.openTagDetail(race.id)}}},[_vm._v(" "+_vm._s(race.name)+" ")]):_vm._e()}),0)])]}}],null,true)}),_c('vxe-column',{attrs:{"title":"解读","width":"700","field":"expound"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.expound)+" ")]}}],null,true)})],1)],1)],1)}),_c('div',{staticStyle:{"color":"#ccc","font-size":"12px"}},[_vm._v(" 更新时间："+_vm._s(_vm.list[0] ? _vm.list[0].data[0].updated_on : "")+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }