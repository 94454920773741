<template>
  <div class="uploaderMain" v-show="mainDisplay">
    <div v-show="!uploaderShow" class="uploaderMini" @click="toggleUploader()">
      <div class="miniContent">
        <div class="uploaderText">共{{ fileArr.length }}上传</div>
        <!-- <div class="uploaderText">已完成:2</div> -->
      </div>
    </div>

    <div v-show="uploaderShow" class="uploaderNormal elevation-5">
      <div class="uploaderHeader elevation-2">
        <div style="font-size: 16px; padding-left: 20px">上传管理</div>
        <div>
          <v-btn
            style="margin-right: 20px"
            color="primary"
            dense
            x-small
            outlined
            @click="toggleUploader()"
            >最小化</v-btn
          >
          <v-btn
            style="margin-right: 20px"
            color="primary"
            dense
            x-small
            outlined
            @click="hideMain()"
            >关闭</v-btn
          >
        </div>
      </div>
      <div>
        <v-list
          subheader
          two-line
          style="padding: 0px; height: 250px; overflow: scroll"
        >
          <upload-worker
            v-for="item in fileArr"
            :key="item.taskId"
            :fileItem="item"
          ></upload-worker>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import UploadWorker from '@/components/Uploader/UploadWorker.vue';
export default {
  name: 'Uploader',
  components: { UploadWorker },
  props: {
    canClick: Boolean,
  },
  data() {
    return {
      mainDisplay: false,
      uploaderShow: false,

      fileArr: [],
    };
  },
  mounted() {
    Bus.$on('addFile', (e) => {
      console.log(e);
      e.file.forEach((item) => {
        item.taskId = item.name + Math.floor(Math.random() * (1000 - 1)) + 1;
        item.info = e.info;
        item.uploadType = e.type;

        this.fileArr.unshift(item);
        this.mainDisplay = true;
        this.toggleUploader('max');
      });
    });
    Bus.$on('toggleUploader', (e) => {
      this.toggleUploader(e);
    });
    Bus.$on('fileUpload', (e) => {
      this.addFiles(e.folder, e.isTeam);
    });
  },
  methods: {
    toggleUploader(status = '') {
      //console.log(this.canClick);
      if (status == '') {
        if (!this.canClick) {
          return false;
        }
        if (this.uploaderShow) {
          this.$emit('min');
        } else {
          this.$emit('max');
        }
        this.uploaderShow = !this.uploaderShow;
      } else if (status == 'max') {
        this.$emit('max');
        this.uploaderShow = true;
      } else if (status == 'min') {
        this.$emit('min');
        this.uploaderShow = false;
      }
    },
    hideMain() {
      this.mainDisplay = false;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.uploaderMain {
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  left: calc(100vw - 200px);
  z-index: 20001;
}
.uploaderMini {
  width: 80px;
  height: 40px;
  background-color: #3f51b5;
  opacity: 0.7;
  display: inline-block;
}
.miniContent {
  width: 78px;
  height: 38px;
  margin: 1px 0px 0px 1px;
  border: 1px solid #ffffff;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.uploaderText {
  color: #fff;
  font-size: 12px;
}

.uploaderNormal {
  width: 600px;
  height: 300px;
  background-color: #fff;
}

.uploaderHeader {
  height: 50px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
