<template>
  <div style="width: 400px; height: 300px; margin: 10px">
    <div style="width: 100%; display: flex; justify-content: center">
      {{ chartData.industry_name }}
    </div>
    <div
      style="width: 400px; height: 270px"
      :id="chartData.industry_name + 'RadarChart'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "RadarChart",
  components: {},
  props: {
    chartData: {
      type: Object,
      default: () => {
        return [];
      },
    },
    chartData1: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      tableLoading: true,
      list: [],
      myChart: null,
    };
  },
  mounted() {
    let chartDom = document.getElementById(
      this.chartData.industry_name + "RadarChart"
    );
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    this.initChart();
  },

  methods: {
    initChart() {
      let option = {
        radar: {
          shape: "circle",
          radius: 100,
          indicator: [
            { name: "趋势强度(近一周)", max: 1 },
            { name: "趋势强度(近一季度)", max: 1 },
            { name: "拥挤度(近一周)", max: 1 },
            { name: "拥挤度(近一季度)", max: 1 },
            { name: "赚钱效应(近5日)", max: 1 },
            { name: "北向资金(近5日)", max: 1 },
            { name: "盈利预测增速(近5日)", max: 1 },
          ],
        },
        tooltip: {
          formatter: (params) => {
            console.log(params);
            return `
              趋势强度(近一周) : ${params.data.value[0]}<br/>
              趋势强度(近一季度) : ${params.data.value[1]}<br/>
              拥挤度(近一周) : ${params.data.value[2]}<br/>
              拥挤度(近一季度) : ${params.data.value[3]}<br/>
              赚钱效应(近5日) : ${params.data.value[4]}<br/>
              北向资金(近5日) : ${params.data.value[5]}<br/>
              盈利预测增速(近5日) : ${params.data.value[6]}<br/>
            `;
          },
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: [
                  this.chartData.wtrend_score,
                  this.chartData.qtrend_score,
                  this.chartData.wcrowd_score,
                  this.chartData.qcrowd_score,
                  this.chartData.money_effect,
                  this.chartData.north_flow,
                  this.chartData.cagr,
                ],
                name: "得分",
              },
            ],
          },
          {
            type: "radar",
            data: [
              {
                value: [
                  this.chartData1.wtrend_score,
                  this.chartData1.qtrend_score,
                  this.chartData1.wcrowd_score,
                  this.chartData1.qcrowd_score,
                  this.chartData1.money_effect,
                  this.chartData1.north_flow,
                  this.chartData1.cagr,
                ],
                name: "得分",
              },
            ],
            lineStyle: {
              type: "dashed",
              color: "#aaaaaa",
            },
            itemStyle: {
              color: "#aaaaaa",
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
  watch: {},
};
</script>
