<template>
  <div>
    <!-- <img style="width: 100vw;height:100vh;position:fixed;z-index:1000000;pointer-events: none;opacity: 0.02;" src="https://img1.baidu.com/it/u=700675537,3936578503&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656090000&t=b6b9df14ffca36f90289b23bbbfa0784" alt=""> -->
    <router-view></router-view>

    <loading ref="loading" />
    <toast ref="toast"></toast>
    <Preview ref="preview"></Preview>

    <img id="hideViewer" style="display: none" src="" alt="" />
  </div>
</template>

<script>
import Vue from "vue";
import Preview from "@/components/Preview/Preview";
import { VXETable } from "vxe-table";
import emptyImg from "@/assets/img/empty.png";
import loading from "@/components/Loading";
import User from "@/api/user.js";
import toast from "@/components/Toast";
import Bus from "@/components/Bus/bus.js";

import { useAppStore } from "@/store/app";
import { useUserStore } from "@/store/user";

import "viewerjs/dist/viewer.css";
export default {
  components: {
    loading,
    toast,
    Preview,
  },
  beforeCreate() {
    //装载全局变量
    // Vue.prototype.appStore=useAppStore();
    // Vue.prototype.userStore=useUserStore();
  },
  mounted() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      Vue.prototype.isPc = false;
    } else {
      Vue.prototype.isPc = true;
    }
    if (bIsIpad || bIsIphoneOs) {
      Vue.prototype.isIos = true;
    }

    Vue.prototype.$pageHeight = 500;
    let timer = 0;
    timer = setInterval(() => {
      if (document.body.offsetHeight != 0) {
        Vue.prototype.$pageHeight = document.body.offsetHeight;
        clearInterval(timer);
      }
    }, 100);
    Vue.prototype.showToast = (txt, type = "info", timeout = 2000) => {
      this.$refs.toast.showToast(txt, type, timeout);
    };

    Vue.prototype.appStore = useAppStore();
    Vue.prototype.userStore = useUserStore();
    //部分store持久化
    let l1 = localStorage.getItem("appStore");
    if (l1) {
      this.appStore.$patch(JSON.parse(l1));
    }
    let l2 = localStorage.getItem("userStore");
    if (l2) {
      this.userStore.$patch(JSON.parse(l2));
    }

    this.appStore.$subscribe((_, state) => {
      localStorage.setItem("appStore", JSON.stringify(state));
    });
    this.userStore.$subscribe((_, state) => {
      localStorage.setItem("userStore", JSON.stringify(state));
    });

    // 创建一个空内容渲染
    VXETable.renderer.add("NotData", {
      // 空内容模板
      renderEmpty() {
        return [
          <span>
            <img style="width:200px;" src={emptyImg} />
            <p>没有更多的数据</p>
          </span>,
        ];
      },
    });
    //监听文件操作
    Bus.$on("previewFile", (e) => {
      this.$refs.preview.preview(e.url, e.name);
    });
    //持续刷新用户信息
    setInterval(() => {
      if (this.userStore.token) {
        User.getUserInfo()
          .then((res) => {
            this.userStore.name = res.data.base;
            this.userStore.user_id = res.data.id;

            let roles = [];
            res.data.roles.forEach((item) => {
              roles.push(item.name);
            });
            this.userStore.roles = roles;
            //console.log(roles);
            this.userStore.permissions = res.data.permissions;
          })
          .catch((err) => {
            console.log(err);
            //this.loginError();
          });
      }
    }, 10000);
  },
};
</script>
<style>
.vl-notify-mask {
  z-index: 20000;
}

.vl-notify {
  z-index: 20001 !important;
}

div.v-input__append-inner {
  margin-top: 4px !important;
}

.v-label {
  font-size: 14px !important;
  color: #c2c6cd !important;
}

.theme--my-primary {
  color: #344767 !important;
  border: 2px solid #344767 !important;
}

.theme--my-danger {
  color: #a4262c !important;
  border: 2px solid #a4262c !important;
}

.theme--my-warning {
  color: #ff8c00 !important;
  border: 2px solid #ff8c00 !important;
}

.theme--my-info {
  color: #0078d4 !important;
  border: 2px solid #0078d4 !important;
}

.theme--my-default {
  color: #afafaf !important;
  border: 2px solid #afafaf !important;
}

/*滚动条整体部分*/
.my-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/*滚动条的轨道*/
.my-scrollbar ::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/*滚动条里面的小方块，能向上向下移动*/
.my-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.my-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.my-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

/*边角，即两个滚动条的交汇处*/
.my-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.pswp--custom-zindex {
  z-index: 999999999 !important;
}

/* 让tab背景色透明 */
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
.v-application {
  line-height: 1.2;
}

.viewer-container {
  z-index: 999999999 !important;
}

.theme--light.v-application code {
  background-color: transparent !important;
}
</style>
