<template>
	<div>
		<!-- <span class="text-body" style="font-size: 12px;"></span> -->
		<div style="width:100%; height: 250px" id="lineContainer"></div>
		
	</div>
</template>
<script>


export default {
	name: 'YieldChart',
	components: {
		
	},
	props: {
		benchmarkType:'',
		portfolioDetail:{},
	},
	mounted() {
		this.getLineData();
	},
	data() {
		return {
			
		};
	},
	methods: {
		getLineData(){
			let xAxisData = [ ];
			let seriesData = [];
			
			let portfolioData=[];
			this.portfolioDetail.positions.forEach(item => {
				xAxisData.unshift(item.trade_date);
				portfolioData.unshift(  ((parseFloat(item.day_profit)-1)*100).toFixed(2)  );
			});
			seriesData.push({
				name:'组合收益率',
				color:'red',
				data: portfolioData,
				type: 'line',
				smooth: true,
				symbol: 'none'
			})
			//console.log(this.portfolioDetail.benchmark_pos);
			
			// this.portfolioDetail.benchmark_pos[this.benchmarkType].forEach(item => {
			// 	//xAxisData.unshift(item.trade_date);
			// 	seriesData[1].push(  ((parseFloat(item.income))*100).toFixed(2)  );
			// });
			
			// this.portfolioDetail.benchmark_pos.forEach(item => {
			// 	//xAxisData.unshift(item.trade_date);
			// 	let thisArr=[];
			// 	item.forEach((thisItem)=>{
			// 		thisArr.push(  ((parseFloat(thisItem.income))*100).toFixed(2)  );
			// 	})
			// 	seriesData.push(  thisArr  );
			// });
			let legend=['组合收益率'];
			let counter=0;
			let legendSelect={};
			for(let i in this.portfolioDetail.benchmark_pos){
				let thisArr=[];
				legend.push(i);
				if(counter>1){
					legendSelect[i]=false
				}
				
				this.portfolioDetail.benchmark_pos[i].forEach((item)=>{
					thisArr.push(  ((parseFloat(item.income))*100).toFixed(2)  );
				})
				seriesData.push({
					name:i,
					data: thisArr,
					type: 'line',
					smooth: true,
					symbol: 'none'
				})
				counter++;
			}
			//console.log(legendSelect);
			this.showLine(xAxisData, seriesData,legend,legendSelect);
		},
		showLine(xAxisData, seriesData,legend,legendSelect) {
			var dom = document.getElementById('lineContainer');
			var myChart = this.$echarts.init(dom);
		
			var option = {
				// title: {
				// 	text: '净值走势',
				// 	left: 'center'
				// },
				legend: {
					data: legend,
					selected: legendSelect,
				},
				
				grid: {
					left: '10',
					right: '10',
					bottom: '10',
					containLabel: true,
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross'
					},
					formatter: (params)=>{
						//console.log(params);
						let text='';
						if(params[1]){
							return `${params[0].seriesName}:${params[0].value}%<br>${params[1].seriesName}:${params[1].value}%<br>超额收益:${(params[0].value-params[1].value).toFixed(2)}%`;
						}else{
							return `${params[0].seriesName}:${params[0].value}%`;
						}
						
					},
				},
				xAxis: {
					name: '日期',
					type: 'category',
					data: xAxisData,
					//splitNumber: 10,
					axisTick: {
						show: true,
						lineStyle: {
							type: 'solid'
						}
					},
					splitLine: {
						//网格线
						show: true
					},
				},
				yAxis: [
					{
						name: '组合收益率',
						type: 'value',
						max: function(value) {
							return (value.max + 5).toFixed(2);
						},
						min: function(value) {
							return (value.min - 5).toFixed(2);
						},
						splitLine: {
							//网格线
							show: true
						},
						axisLabel: {
							formatter: '{value} %'
						},
						splitNumber: 9
					},
				],
				series: seriesData,
				dataZoom: [
					{
						type: 'slider',
						start: 0,
						end: 100,
						bottom: '0px'
					}
				]
			};
			myChart.setOption(option, true);
		}
	},
	computed: {},
	watch: {
		benchmarkType(val){
			this.getLineData();
		},
	}
};
</script>
<style scoped="scoped"></style>
