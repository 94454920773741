<template>
  <div>
    <iframe
      src="https://chatgpt.lhotsetech.com/chat/share?shareId=1r1qctqcgx1zgwuiksbv1tz4"
      frameborder="0"
      style="width: 100%; margin-top: 20px"
      :style="{ height: pageHeight + 'px' }"
    ></iframe>
  </div>
</template>

<script>
import Vue from "vue";
import lemonMessageMd from "@/components/Ai/lemonMessageMd";
Vue.component(lemonMessageMd.name, lemonMessageMd);

const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);
import { v4 as uuidv4 } from "uuid";
import Ai from "@/api/ai.js";
export default {
  name: "Chat",
  components: {},
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      user: {
        id: "1",
        displayName: "Knight",
        avatar: require("@/assets/img/user/user.png"),
      },
      conversationId: null,
      parentMessageId: null,

      conversationCounter: 0,
    };
  },
  mounted() {
    const { IMUI } = this.$refs;
    IMUI.initEditorTools([]);
    IMUI.initContacts([
      {
        id: "2",
        displayName: "ChatGPT",
        avatar: require("@/assets/img/logos/chatlogo.png"),
        type: "single",
        index: "T",
        unread: 0,
        lastSendTime: getTime(),
        lastContent: "你好123",
      },
    ]);

    setTimeout(() => {
      IMUI.changeContact("2");
    }, 1000);
  },
  methods: {
    clearConversation() {
      this.parentMessageId = null;
      this.conversationId = null;
      this.conversationCounter = 0;
    },
    sendMsg(message) {
      //console.log(this.conversationId);
      Ai.ask({
        type: "Official",
        message: message.content,
        conversationId: this.conversationId,
        parentMessageId: this.parentMessageId,
      })
        .then((res) => {
          console.log(res.data);
          if (!res.data.hasOwnProperty("response")) {
            this.$refs.IMUI.updateMessage({
              id: message.id,
              status: "failed",
            });
            const data = {
              id: uuidv4(),
              status: "succeed",
              type: "text",
              sendTime: getTime(),
              content: "抱歉，服务器出错了，请稍后再试！",
              toContactId: "2",
              fromUser: {
                //如果 id == this.user.id消息会显示在右侧，否则在左侧
                id: "2",
                displayName: "ChatGPT",
                avatar: require("@/assets/img/logos/chatlogo.png"),
              },
            };
            this.$refs.IMUI.appendMessage(data);
            this.$refs.IMUI.messageViewToBottom();
          } else {
            this.conversationCounter++;
            if (this.conversationCounter > 4) {
              const data = {
                id: uuidv4(),
                status: "succeed",
                type: "text",
                sendTime: getTime(),
                content: "由于接口限制，当前回话已有5轮，将清空上下文记忆！",
                toContactId: "2",
                fromUser: {
                  //如果 id == this.user.id消息会显示在右侧，否则在左侧
                  id: "2",
                  displayName: "ChatGPT",
                  avatar: require("@/assets/img/logos/chatlogo.png"),
                },
              };
              this.$refs.IMUI.appendMessage(data);
              this.$refs.IMUI.messageViewToBottom();
              this.clearConversation();
            } else {
              this.conversationId = res.data.response.conversationId;
              this.parentMessageId = res.data.response.id;
            }

            this.$refs.IMUI.updateMessage({
              id: message.id,
              status: "succeed",
            });
            const data = {
              id: uuidv4(),
              status: "succeed",
              type: "md",
              sendTime: getTime(),
              content: res.data.response.text,
              toContactId: "2",
              fromUser: {
                //如果 id == this.user.id消息会显示在右侧，否则在左侧
                id: "2",
                displayName: "ChatGPT",
                avatar: require("@/assets/img/logos/chatlogo.png"),
              },
            };
            this.$refs.IMUI.appendMessage(data);
            this.$refs.IMUI.messageViewToBottom();
          }
        })
        .catch((err) => {
          this.$refs.IMUI.updateMessage({
            id: message.id,
            status: "failed",
          });
          const data = {
            id: uuidv4(),
            status: "succeed",
            type: "text",
            sendTime: getTime(),
            content: "抱歉，服务器出错了，请稍后再试！",
            toContactId: "2",
            fromUser: {
              //如果 id == this.user.id消息会显示在右侧，否则在左侧
              id: "2",
              displayName: "ChatGPT",
              avatar: require("@/assets/img/logos/chatlogo.png"),
            },
          };
          this.$refs.IMUI.appendMessage(data);
          this.$refs.IMUI.messageViewToBottom();
        });
    },
    handleMessageClick(e, key, message) {
      if (key == "status" && message.status === "failed") {
        this.$refs.IMUI.updateMessage({
          id: message.id,
          status: "going",
        });
        this.sendMsg(message);
      }
    },
    handleSend(message, next, file) {
      //console.log(message, next, file);
      this.sendMsg(message);
    },
    handlePullMessages(contact, next) {
      const { currentContactId } = this.$refs.IMUI;
      const otherUser = {
        id: contact.id,
        avatar: contact.avatar,
        displayName: contact.displayName,
      };
      const message = (content, fromUser = this.user) => {
        return {
          id: uuidv4(),
          status: "succeed",
          type: "text",
          sendTime: getTime(),
          content,
          toContactId: currentContactId,
          fromUser,
        };
      };

      const messages = [
        message(
          "你好我是青骊实验室的聊天AI，基于ChatGPT。您可以问我任何问题！\n请注意，目前本功能仅供试用，消息发送失败时请点击重新发送。",
          otherUser
        ),
        message(
          "发送内容请不要高于1000字。长文本的回复速度较慢，可能在1到2分钟！回复的消息如果不完整时，请发送“继续”，系统会回复您剩余的消息！",
          otherUser
        ),
        message(
          "ChatGPT是离线AI，仅包含2021年以前的互联网数据，如需实时数据，请使用实验室的Bing功能！（目前仅对管理员开放）",
          otherUser
        ),
        // message('来'),
      ];
      next(messages, true);
    },
  },
};
</script>
<style>
.chatCover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}
.lemon-editor__tool {
  height: 10px;
  border-top: 1px solid #ddd;
}
.v-md-hljs-js {
  padding: 0px !important;
}
</style>
