var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"mx-3",attrs:{"custom":"","refresh":{ query: _vm.getPortfolioList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(
            _vm.$commonHelpers.getPermissions('portfolio.external') &&
            _vm.$commonHelpers.getPermissions('portfolio.insiders')
          )?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.isOut ? '服务机构' : '研究员'))]):_vm._e(),(
            !(
              _vm.$commonHelpers.getPermissions('portfolio.external') &&
              _vm.$commonHelpers.getPermissions('portfolio.insiders')
            )
          )?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.isOut ? '组合' : '组合'))]):_vm._e(),_c('vxe-input',{staticClass:"mx-1",attrs:{"type":"search","placeholder":"搜索组合名或管理人"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('vxe-select',{staticClass:"mx-1",staticStyle:{"width":"130px"},attrs:{"size":"medium","placeholder":"业绩基准","status":"my-primary"},model:{value:(_vm.benchmarkType),callback:function ($$v) {_vm.benchmarkType=$$v},expression:"benchmarkType"}},_vm._l((_vm.benchmarkTypeMap),function(item,index){return _c('vxe-option',{key:index,attrs:{"value":item,"label":'基准:' + item}})}),1),(!_vm.$commonHelpers.getPermissions(['组合观察员']))?_c('vxe-button',{staticClass:"mx-1",attrs:{"size":"medium","content":"新建组合","status":"my-primary"},on:{"click":function($event){return _vm.portfolioAdd()}}}):_vm._e(),_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"size":"medium","content":"导出","status":"my-primary"},on:{"click":function($event){return _vm.exportTable()}}})]},proxy:true}])}),_c('div',{style:({ height: _vm.tableHight + 'px' })},[_c('vxe-table',{ref:"portfolioTable",staticClass:"mytable-scrollbar",attrs:{"id":"portfolioTable","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.portfolioTableLoading,"column-config":{ resizable: true },"height":"100%","size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 100 },"empty-render":{ name: 'NotData' },"export-config":{}}},[_c('vxe-column',{attrs:{"fixed":"left","width":"200","field":"name","title":"组合名称","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",style:({ backgroundColor: row.is_real == 0 ? '#ddd' : '' }),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openPortfolio(row.id)}}},[_vm._v(" "+_vm._s(row.is_real == 0 ? '(旧版已停用)' : '')+_vm._s(row.name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"user_name","title":"服务机构"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.user_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"user_contact","title":"联系方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.user_contact)+" ")]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"created_at","title":"创建时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(row.created_at.split(' ')[0])+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"90","field":"position.income_rate","title":"累计收益率","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.position.income_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.position.income_rate)))])])]}}])}),_c('vxe-column',{attrs:{"field":"position.day_rate","title":"日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.position.day_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.position.day_rate)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_five","title":"5日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_five))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_five)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_month","title":"20日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_month))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_month)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_60","title":"60日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_60))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_60)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_120","title":"120日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_120))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_120)))])])]}}])}),_c('vxe-column',{attrs:{"field":"applies_the_year","title":"今年以来涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.applies_the_year))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.applies_the_year)))])])]}}])}),_c('vxe-column',{attrs:{"field":"sharp_ratio","title":"夏普比率(成立以来)","sortable":"","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.sharp_ratio)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"max_retreat","title":"最大回撤","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.max_retreat))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.max_retreat)))])])]}}])}),_c('vxe-column',{attrs:{"field":"benchmark","title":'业绩基准(' + _vm.benchmarkType + ')',"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.benchmark[_vm.benchmarkType]))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.benchmark[_vm.benchmarkType])))])])]}}])}),_c('vxe-column',{attrs:{"field":"excess_return","title":"超额收益","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.excess_return[_vm.benchmarkType]))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.excess_return[_vm.benchmarkType])))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }