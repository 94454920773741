<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; min-width: 500px; width: 80%">
      <div class="d-flex align-center justify-center">
        <label
          class="mr-3 white-space-nowrap"
          style="width: 88px; font-size: 14px"
          >分组名</label
        >
        <!-- <vxe-input
          v-model="groupName"
          placeholder="分组名"
          type="text"
          class="my-2"
          style="width: 100%"
        ></vxe-input> -->
        <v-combobox
          v-model="groupName"
          :items="getSelectItem()"
          label="选择或直接输入分组"
          multiple
          chips
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              :color="`${item.color} lighten-3`"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "@/api/tag.js";
import Bus from "@/components/Bus/bus.js";

import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { SlateEditor } from "@wangeditor/editor";

export default {
  name: "StockGroupEdit",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.groupName = this.iframeData.oldGroupName;
  },

  data() {
    return {
      groupName: "",
    };
  },
  methods: {
    submit() {
      let groupStr = JSON.parse(JSON.stringify(this.groupName)).join(",");
      this.showLoading("保存中，请稍候");
      Tag.editGroupName(
        this.iframeData.tagId,
        this.iframeData.stockIds,
        groupStr
      )
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshTagList", true);
          Bus.$emit("refreshTagDetail", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
    getSelectItem() {
      let arr = [];
      this.appStore.tagDetail.tags.forEach((item) => {
        arr.push(item.name);
      });
      return arr;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
