<template>
  <div :class="[borderhover ? 'draggle-area-active' : '']" ref="draggleArea">
    <vxe-toolbar class="" custom>
      <template v-slot:buttons>
        <div class="d-flex align-items-center">
          <vxe-button
            transfer
            size="medium"
            status="my-primary"
            class="px-0 mx-1"
          >
            <template #default class="abc">Excel导入</template>
            <template #dropdowns>
              <vxe-button
                type="text"
                @click="downloadMuban()"
                v-if="$commonHelpers.getPermissions('tagStore.editTag')"
                >下载模板</vxe-button
              >
              <vxe-button
                type="text"
                @click="clickUpload()"
                v-if="$commonHelpers.getPermissions('tagStore.editTag')"
                >上传导入</vxe-button
              >
            </template>
          </vxe-button>

          <div class="mt-2">EXCEL导入记录</div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table
      :data="tableData"
      :loading="bindFileTableLoading"
      border
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="fileTable"
      :column-config="{ resizable: true }"
      :height="fileTableHeight"
      size="mini"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
      :checkbox-config="{ trigger: 'cell', range: true }"
      align="center"
      :edit-config="{ trigger: 'click', mode: 'cell', icon: ' ' }"
    >
      <vxe-column width="80" field="file.user_name" title="更新人">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.file.user_name }}
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="200"
        field="change_reason"
        title="更新描述"
        :edit-render="{ name: 'textarea' }"
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div
            class="d-flex justify-start"
            :style="{
              color: row.change_reason ? '#000000' : '#ff0000',
            }"
          >
            {{ row.change_reason ? row.change_reason : "点击编辑更新描述" }}
          </div>
        </template>

        <template #edit="{ row }">
          <vxe-textarea
            style="z-index: 10"
            placeholder="请输入底稿更新描述"
            v-model="row.change_reason"
            :autosize="{ minRows: 1, maxRows: 5 }"
            @blur="(value, event) => reasonBlur(value, row)"
          >
          </vxe-textarea>
        </template>
      </vxe-column>

      <vxe-column width="150" field="file.created_at" title="更新时间" sortable>
        <template #default="{ row }">
          {{ row.file.created_at }}
        </template>
      </vxe-column>
      <vxe-column
        title="操作"
        type="seq"
        width="95"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <a
            href="javascript:;"
            @click="$commonHelpers.previewFile(row.file.id)"
            class="text-xs font-weight-normal text-typo mr-1"
            >预览</a
          >
          <a
            href="javascript:;"
            @click="$commonHelpers.downloadFile(row.file.id)"
            class="text-xs font-weight-normal text-typo mr-1"
            >下载</a
          >
        </template>
      </vxe-column>
    </vxe-table>

    <a ref="muban" style="display: none" href="/标签模板.xlsx">下载模板</a>
    <input
      style="display: none"
      ref="uploadInput"
      type="file"
      @change="startUpload"
    />
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Tag from "@/api/tag.js";
import XEUtils from "xe-utils";
export default {
  name: "BindFile",
  components: {},
  props: {
    stockId: "",
    currTag: "",
    fileTableHeight: "",
  },

  data() {
    return {
      borderhover: false,

      tableData: [],
      filterName: "",

      bindFileTableLoading: false,
      files: [],

      multiDisplay: false,
      fileNameChange: false,
    };
  },
  computed: {},
  beforeDestroy() {
    let dropbox = this.$refs.draggleArea;
    dropbox.removeEventListener("drop", this.enentDrop, false);
    dropbox.removeEventListener("dragleave", this.dragleave, false);
    dropbox.removeEventListener("dragenter", this.dragenter, false);
    dropbox.removeEventListener("dragover", this.dragover, false);
  },

  mounted() {
    let dropbox = this.$refs.draggleArea;
    dropbox.addEventListener("drop", this.enentDrop, false);
    dropbox.addEventListener("dragleave", this.dragleave, false);
    dropbox.addEventListener("dragenter", this.dragenter, false);
    dropbox.addEventListener("dragover", this.dragover, false);
  },
  methods: {
    downloadMuban() {
      this.$refs.muban.click();
    },

    getFileList() {
      this.bindFileTableLoading = true;
      Tag.excelHistory(this.currTag.id)
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.bindFileTableLoading = false;
        });
    },

    upload(file) {
      //检查重复
      let haveSameName = false;
      let fileFilter = [];
      file.forEach((selectItem) => {
        let have = false;
        this.tableData.forEach((oldItem) => {
          if (oldItem.name == selectItem.name) {
            haveSameName = true;
            have = true;
          }
        });
        if (!have) {
          fileFilter.push(selectItem);
        }
      });
      if (haveSameName) {
        this.showToast("您选择的文件中有重复文件，已帮您跳过", "warning", 5000);
      }
      //过滤重名后如果有文件才上传
      if (fileFilter.length > 0) {
        if (this.stockId) {
          Bus.$emit("addFile", {
            file: fileFilter,
            type: "stockFile",
            info: { stockId: this.stockId },
          });
        } else if (this.currTag) {
          Bus.$emit("addFile", {
            file: fileFilter,
            type: "tagFile",
            info: {
              tagType: "race",
              tagId: this.currTag.id,
            },
          });
        }
      }
    },
    startUpload() {
      this.showLoading("导入中，请稍候！");
      let file = this.$refs.uploadInput.files[0];
      Tag.uploadExcel(this.currTag.id, file)
        .then((res) => {
          console.log(res.data);
          this.showToast("导入成功，请等待数据刷新！", "success", 10000);
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
          this.$refs.uploadInput.value = "";
        });
    },
    clickUpload() {
      this.$refs.uploadInput.click();
    },
    reasonBlur(value, row) {
      console.log(value, row);

      //this.showLoading("保存描述中，请稍候");
      Tag.excelReason(row.id, value.value)
        .then((res) => {
          this.showToast("保存描述成功", "success", 5000);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          //this.hideLoading();
        });
    },

    dragenter(e) {
      this.borderhover = true;
      this.preventDefault(e);
    },
    dragleave(e) {
      this.borderhover = false;
      this.preventDefault(e);
    },
    dragover(e) {
      this.borderhover = true;
      this.preventDefault(e);
    },
    enentDrop(e) {
      this.borderhover = false;

      let fileData = e.dataTransfer.files;
      //console.log(fileData);
      let fileArr = [];
      fileData.forEach((item) => {
        if (item.type) {
          fileArr.push(item);
        }
      });
      this.files = fileArr;
      this.preventDefault(e);
    },
    preventDefault(e) {
      e.stopPropagation();
      e.preventDefault(); //必填字段
    },
  },
  watch: {
    files(value) {
      if (value) {
        this.upload(value);
      }
    },

    currTag() {
      this.getFileList();
    },
  },
};
</script>
<style scoped="scoped">
.myOption {
  color: #000000;
}

.draggle-area-active {
  border: 1px solid #bababa;
}
</style>
