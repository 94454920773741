<template>
  <div>
    <Editor
      style="min-height: 50px"
      :editorId="editorContent"
      :defaultConfig="editorConfig"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import XEUtils from 'xe-utils';

import Bus from '@/components/Bus/bus.js';
import '@wangeditor/editor/dist/css/style.css';
import { Editor } from '@wangeditor/editor-for-vue';
export default {
  name: 'EditorDisplay',
  props: {
    editorId: {
      type: String,
      default: '',
    },
    editorContent: {
      type: String,
      default: '',
    },
    printContent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  data() {
    return {
      editor: {},
      editorConfig: { placeholder: '暂无详情' },
    };
  },
  methods: {
    //编辑器初始化
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      setTimeout(() => {
        this.editor.clear();
        this.editor.dangerouslyInsertHtml(this.editorContent);
        this.editor.move(999999);
        this.editor.blur();
        this.editor.disable();

        let arr = this.editor.getElemsByType('image');
        arr.forEach((item) => {
          let el = document.getElementById(item.id);
          el.onclick = () => {
            Bus.$emit('previewFile', {
              url: item.src,
              name: item.alt + '.jpg',
            });
          };
        });
      }, 2000);
      if (this.printContent) {
        this.showLoading('10秒内处理完毕，请稍候！');
        setTimeout(() => {
          this.hideLoading();
          setTimeout(() => {
            this.hideLoading();
            window.print();
          }, 1000);
        }, 10000);
      }
    },
  },
};
</script>
<style scoped="scoped"></style>
