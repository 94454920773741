//骊阅标签观点
<template>
  <v-list-item-content class="pb-0">
    <v-row
      class="d-flex align-center"
      no-gutters
      @click="preview(str.data.content, str.data.title + '.direct')"
    >
      <div>
        <div>
          <span>{{ str.data.user_name }}</span>
          <b> | </b>
          <span class="font-weight-bold text-dark" style="opacity: 0.5"
            >骊阅标签观点
          </span>
        </div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.from }}
        </span>
      </div>
    </v-row>
    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
      @click="preview(str.data.content, str.data.title + '.direct')"
    >
      {{ str.data.title }}
    </v-list-item-title>
    <!-- <v-list-item-subtitle>
      <v-row no-gutters v-html="str.data.content" class="desc"></v-row>
    </v-list-item-subtitle> -->
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
      style="color: #191a1d; line-height: 22px"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
      </v-row>
      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  mounted() {
    //console.log(this.str);
  },
  methods: {
    preview(content, title) {
      Bus.$emit("previewFile", { url: content, name: title });
    },
  },
};
</script>
