<template>
  <div style="width: 100%">
    <LoadingPage :visible="tableLoading"></LoadingPage>
    <div style="width: 100%" class="d-flex flex-wrap" v-show="tableLoading">
      <v-card
        v-for="(item, index) in tableData1"
        :key="index"
        style="
          height: 100%;
          width: calc(50% - 20px);
          margin: 10px 10px 10px 10px;
        "
        class="border-radius-md pa-3"
      >
        <div>{{ index }}</div>
        <vxe-table
          :data="item"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          height="730px"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column
            fixed="left"
            type="seq"
            width="60"
            title="序号"
          ></vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.code"
            title="股票代码"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.code ? row.stock.code : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.name ? row.stock.name : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="100" field="industry.name" title="申万三级">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.industry ? row.industry.name : "-" }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="styles" title="风格">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level === 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="170" field="races" title="赛道">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level !== 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="涨跌幅">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  v-if="index == '5日涨幅'"
                  :style="$stockHelpers.getColor(row.applies_five)"
                  >{{ $stockHelpers.getPercent(row.applies_five) }}</span
                >
                <span
                  v-if="index == '5日跌幅'"
                  :style="$stockHelpers.getColor(row.applies_five)"
                  >{{ $stockHelpers.getPercent(row.applies_five) }}</span
                >
                <span
                  v-if="index == '10日涨幅'"
                  :style="$stockHelpers.getColor(row.applies_ten)"
                  >{{ $stockHelpers.getPercent(row.applies_ten) }}</span
                >
                <span
                  v-if="index == '10日跌幅'"
                  :style="$stockHelpers.getColor(row.applies_ten)"
                  >{{ $stockHelpers.getPercent(row.applies_ten) }}</span
                >
                <span
                  v-if="index == '30日涨幅'"
                  :style="$stockHelpers.getColor(row.applies_month)"
                  >{{ $stockHelpers.getPercent(row.applies_month) }}</span
                >
                <span
                  v-if="index == '30日跌幅'"
                  :style="$stockHelpers.getColor(row.applies_month)"
                  >{{ $stockHelpers.getPercent(row.applies_month) }}</span
                >
                <span
                  v-if="index == '90日涨幅'"
                  :style="$stockHelpers.getColor(row.applies_ninety)"
                  >{{ $stockHelpers.getPercent(row.applies_ninety) }}</span
                >
                <span
                  v-if="index == '90日跌幅'"
                  :style="$stockHelpers.getColor(row.applies_ninety)"
                  >{{ $stockHelpers.getPercent(row.applies_ninety) }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            field="market_value_million"
            min-width="70"
            title="市值(亿)"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.market_value_million }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>

      <v-card
        v-for="(item, index) in tableData2"
        :key="index"
        style="
          height: 100%;
          width: calc(50% - 20px);
          margin: 10px 10px 10px 10px;
        "
        class="border-radius-md pa-3"
      >
        <div>{{ index }}</div>
        <vxe-table
          :data="item"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          height="730px"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column
            fixed="left"
            type="seq"
            width="60"
            title="序号"
          ></vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.code"
            title="股票代码"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.code ? row.stock.code : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.name ? row.stock.name : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="100" field="industry.name" title="申万三级">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.industry ? row.industry.name : "-" }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="styles" title="风格">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level === 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="170" field="races" title="赛道">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level !== 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="入池涨跌幅">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                  $stockHelpers.getPercent(row.applies_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column field="retreat_focus" min-width="70" title="最大回撤">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.retreat_focus)">{{
                  $stockHelpers.getPercent(row.retreat_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column field="loss_focus" min-width="70" title="最大亏损">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                  $stockHelpers.getPercent(row.loss_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            field="market_value_million"
            min-width="70"
            title="市值(亿)"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.market_value_million }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="调入日期">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span>{{ row.created_at.split(" ")[0] }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="存续期(天)">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span>{{ row.import_day }}</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>

      <v-card
        v-for="(item, index) in tableData3"
        :key="index"
        style="
          height: 100%;
          width: calc(50% - 20px);
          margin: 10px 10px 10px 10px;
        "
        class="border-radius-md pa-3"
      >
        <div>{{ index }}</div>
        <vxe-table
          :data="item"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="bindStockTable"
          :loading="tableLoading"
          height="730px"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column
            fixed="left"
            type="seq"
            width="60"
            title="序号"
          ></vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.code"
            title="股票代码"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.code ? row.stock.code : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.name ? row.stock.name : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="100" field="industry.name" title="申万三级">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.industry ? row.industry.name : "-" }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="styles" title="风格">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level === 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="170" field="races" title="赛道">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level !== 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="回撤">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.retreat_month)">{{
                  $stockHelpers.getPercent(row.retreat_month)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            field="market_value_million"
            min-width="70"
            title="市值(亿)"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.market_value_million }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
export default {
  props: {},
  data() {
    return {
      tableLoading: false,
      tableData1: [],
      tableData2: [],
      tableData3: [],
    };
  },
  mounted() {
    this.getSingleStocks();
  },
  methods: {
    getSingleStocks() {
      this.tableLoading = true;
      Review.getSingleStocks()
        .then((res) => {
          //console.log(res.data);
          this.tableData1 = {
            "5日涨幅": res.data.five_days_up,
            "5日跌幅": res.data.five_days_down,
            "10日涨幅": res.data.ten_days_up,
            "10日跌幅": res.data.ten_days_down,
            "30日涨幅": res.data.month_days_up,
            "30日跌幅": res.data.month_days_down,
            "90日涨幅": res.data.ninety_days_up,
            "90日跌幅": res.data.ninety_days_down,
          };
          this.tableData2 = {
            入池以来涨幅: res.data.focus_days_up,
            入池以来跌幅: res.data.focus_days_down,
            入池以来回撤: res.data.retreat_focus,
          };
          this.tableData3 = {
            "30日回撤": res.data.retreat_month,
          };
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style scoped="scoped"></style>
