<template>
  <div
    ref="thisBox"
    class="shadow hoverBox"
    v-show="display"
    :style="{ width: width, height: height, left: positionX, top: positionY }"
    style="position: fixed; border-radius: 5px"
  >
    <div v-html="content"></div>
    <slot name="content"> </slot>
  </div>
</template>
<script>
export default {
  props: {
    auth: Boolean,
  },
  data() {
    return {
      display: false,
      content: '',

      width: '100px',
      height: '100px',

      positionX: 0,
      positionY: 0,
    };
  },
  methods: {
    show(positionX, positionY, width, height, content) {
      if (this.display == false) {
        this.display = true;
        this.content = content;
        this.width = width;
        this.height = height;
        setTimeout(() => {
          //console.log(this.$refs.thisBox.offsetHeight);

          let bodyHeight = document.body.offsetHeight;
          let bodyWidth = document.body.offsetWidth;
          //console.log(positionY + this.$refs.thisBox.offsetHeight, bodyHeight);
          if (this.$refs.thisBox.offsetHeight + positionY + 100 > bodyHeight) {
            this.positionY =
              bodyHeight - this.$refs.thisBox.offsetHeight - 30 + 'px';
            //console.log(this.positionY + ".....................");
          } else {
            this.positionY = positionY + 'px';
          }
          this.positionX = positionX + 120 + 'px';
        }, 200);

        //console.log(bodyHeight,bodyWidth);
      }
    },
    hide() {
      this.display = false;
    },
  },
};
</script>
<style scoped="scoped">
.hoverBox {
  background-color: #fff;
  z-index: 10000;
}
</style>
