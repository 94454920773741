//点评
<template>
  <v-list-item-content class="pb-0">
    <v-row
      class="d-flex align-center"
      no-gutters
      @click="preview(str.data.content, str.data.abstract + '.direct')"
    >
      <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.from }}
        </span>
        <!-- <b>&nbsp;|&nbsp;</b>
        <span class="font-weight-bold text-dark" style="opacity: 0.5"
          >点评</span
        > -->
      </div>
    </v-row>
    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
      @click="preview(str.data.content, str.data.abstract + '.direct')"
    >
      {{ str.data.abstract }}
    </v-list-item-title>
    <!-- <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      {{ str.data.content }}
    </v-list-item-subtitle> -->
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <!-- <v-btn-toggle dense borderless v-model="tab" class="zc-toggle"> -->
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            mb-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-blue
            me-1
            font-weight-bold
            px-1
            py-1
            mb-1
            badge-font-size
          "
          v-for="sector in str.data.stocks"
          :key="sector.id"
          @click="$stockHelpers.openStockDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
        <!-- </v-btn-toggle> -->
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  methods: {
    getContent(content) {
      let finalContent = content;
      //将大于1个的连续<br>替换为一个<br>，避免过多空行
      finalContent = finalContent.replace(/<br>{1,}/g, "<br>");
      //将大于1个的连续\n替换为一个<br>，避免过多空行
      finalContent = finalContent.replace(/\n{1,}/g, "<br>");
      return finalContent;
    },
    preview(content, title) {
      Bus.$emit("previewFile", { url: this.getContent(content), name: title });
    },
  },
};
</script>
<style scoped>
.badge-danger {
  color: #fff !important;
  background-image: linear-gradient(310deg, #ea0606, #ff667c) !important;
}

.badge-success {
  color: #fff !important;
  background-image: linear-gradient(310deg, #17ad37, #98ec2d) !important;
}

.badge-info {
  color: #fff !important;
  background-image: linear-gradient(310deg, #2152ff, #21d4fd) !important;
}

.badge-warning {
  color: #fff !important;
  background-image: linear-gradient(310deg, #f53939, #fbcf33) !important;
}

.badge-primary {
  color: #fff !important;
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
}
</style>
