<template>
  <v-container fluid class="px-1 py-0" style="">
    <div class="d-flex pt-0" style="height: 100%">
      <div style="width: 400px; height: calc(100vh - 50px); position: fixed">
        <div
          style="
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
          class="d-flex px-2"
        >
          <vxe-input
            v-model="keywords"
            type="search"
            placeholder="搜索笔记"
            size="medium"
            style="width: 150px"
            clearable
            @change="getData(true)"
          ></vxe-input>
          <vxe-select
            v-model="activeType"
            placeholder="选择分类"
            style="width: 150px"
            @change="typeChange()"
          >
            <vxe-option
              :value="item.value"
              :label="item.label"
              v-for="item in typeArr"
            ></vxe-option>
          </vxe-select>
        </div>
        <div style="height: calc(100% - 80px)">
          <vxe-table
            id="noteTable"
            row-id="id"
            ref="noteTable"
            :data="list"
            :border="false"
            resizable
            show-overflow
            show-header-overflow
            highlight-hover-row
            align="center"
            :loading="listLoading"
            :column-config="{ resizable: true }"
            height="auto"
            size="small"
            :stripe="true"
            :sort-config="{ trigger: 'cell', multiple: true }"
            :empty-render="{ name: 'NotData' }"
            :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
            @scroll="tableScroll"
            @cell-click="cellClick"
            style="cursor: pointer"
          >
            <vxe-column min-width="100" field="title" title="标题">
              <template #header="{ column }">
                <div style="word-wrap: break-word; white-space: pre-wrap">
                  {{ column.title }}
                </div>
              </template>
              <template #default="{ row }">
                <div class="d-flex justify-start pl-1">
                  {{ row.title }}
                </div>
              </template>
            </vxe-column>

            <vxe-column width="75" field="created_at" title="日期">
              <template #default="{ row }">
                {{ row.created_at.split(" ")[0] }}
              </template>
            </vxe-column>

            <vxe-column width="60" field="type" title="类型">
              <template #default="{ row }">
                {{ getType(row.type) }}
              </template>
            </vxe-column>

            <vxe-column width="50" field="user_name" title="作者">
              <template #default="{ row }">
                {{ row.user_name }}
              </template>
            </vxe-column>
          </vxe-table>
        </div>
      </div>
      <div style="">
        <div style="width: calc(100vw - 500px); margin-left: 410px">
          <note
            style="width: 100%"
            :typeArr="typeArr"
            :readItem="readItem"
            @newNoteChange="newNoteChange"
            ref="note"
          ></note>
          <NoteComment :noteId="readItem.id" v-if="readItem.id && !isNewNote">
          </NoteComment>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import note from "@/components/Note/Note";
import Note from "@/api/note.js";
import moment from "moment";
import Bus from "@/components/Bus/bus.js";
import NoteComment from "@/components/Note/NoteComment";
export default {
  props: {},
  components: {
    note,
    NoteComment,
  },
  data() {
    return {
      currPage: 1,
      isEnd: false,

      keywords: "",
      typeArr: [],
      activeType: "all",
      activeTypeArr: [],
      list: [],
      listLoading: false,

      readItem: {},
      today: "",

      isNewNote: false,
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.getTypeList(true, true);
    Bus.$on("refreshNoteList", (e) => {
      this.getData(true);
    });
  },
  beforeDestroy() {
    Bus.$off("refreshNoteList");
  },
  // 在当前路由改变但组件被复用时调用
  async beforeRouteLeave(to, from, next) {
    let res = await this.$refs.note.beforeRouteUpdate();
    if (res) {
      next();
    }
  },
  methods: {
    typeChange() {
      if (this.activeType == "all") {
        this.activeTypeArr = [];
        for (let i = 0; i < this.typeArr.length; i++) {
          this.activeTypeArr.push(this.typeArr[i].value);
        }
      } else {
        this.activeTypeArr = [this.activeType];
      }
      //console.log(this.activeType);
      //console.log(this.activeTypeArr);
      this.getData(true);
    },
    getType(type) {
      let typeName = "";
      //console.log(type);
      for (let i = 0; i < this.typeArr.length; i++) {
        if (this.typeArr[i].value == type) {
          typeName = this.typeArr[i].label;
        }
      }
      return typeName;
    },
    getTypeList() {
      this.listLoading = true;
      Note.getNoteTypeList()
        .then((res) => {
          this.getData(true, true);
          this.typeArr.push({
            label: "全部",
            value: "all",
          });
          for (let item in res.data) {
            this.typeArr.push({
              label: res.data[item],
              value: item,
            });
            this.activeTypeArr.push(item);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this.listLoading = false;
        });
    },
    getData(refresh = true, isFirstLoad = false) {
      this.listLoading = true;
      if (refresh) {
        this.currPage = 1;
      }
      Note.getNoteList(this.keywords, this.activeTypeArr, this.currPage)
        .then((res) => {
          if (refresh) {
            this.list = [];
            this.isEnd = false;
            this.readItem = {};
          }
          if (res.data.data.length == 0) {
            this.isEnd = true;
          }
          this.list = this.list.concat(res.data.data);
          if (isFirstLoad) {
            //this.showStock=this.tableData[0];
          }
          if (this.$route.params.id) {
            console.log(this.$route.params.id);
            this.showLoading("正在获取内容，请稍候");
            Note.getNoteContent(this.$route.params.id)
              .then((res) => {
                this.readItem = res.data;
              })
              .catch((err) => {
                console.log(err);
                this.showToast(err.msg, "danger", 10000);
              })
              .finally(() => {
                this.hideLoading();
              });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    tableScroll({ scrollTop, scrollHeight, bodyHeight }) {
      //console.log(scrollTop, scrollHeight , bodyHeight);
      //console.log(this.$refs.noteTable.$el.offsetHeight);
      if (
        scrollTop + this.$refs.noteTable.$el.offsetHeight >
          scrollHeight - 100 &&
        this.listLoading == false &&
        !this.isEnd
      ) {
        //到底了触发下一页
        this.currPage++;
        this.getData(false);
      }
    },
    cellClick({ row }) {
      this.readItem = row;
    },
    newNoteChange(data) {
      this.isNewNote = data;
    },
  },
};
</script>

<style></style>
