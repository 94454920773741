<template>
  <div>
		暂未开放
	</div>
</template>
<script>
export default {
  props: {
    
  },
  data() {
    return {
			
    };
  },
	methods: {
		
	},
};
</script>
<style scoped="scoped">
	
</style>
