var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"draggleArea",class:[_vm.borderhover ? 'draggle-area-active' : '']},[_c('vxe-toolbar',{attrs:{"custom":""},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('vxe-button',{staticClass:"px-0 mx-1",attrs:{"transfer":"","size":"medium","status":"my-primary"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Excel导入")]},proxy:true},{key:"dropdowns",fn:function(){return [(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadMuban()}}},[_vm._v("下载模板")]):_vm._e(),(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.clickUpload()}}},[_vm._v("上传导入")]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"mt-2"},[_vm._v("EXCEL导入记录")])],1)]},proxy:true}])}),_c('vxe-table',{ref:"fileTable",attrs:{"data":_vm.tableData,"loading":_vm.bindFileTableLoading,"border":"","resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","column-config":{ resizable: true },"height":_vm.fileTableHeight,"size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-x":{ gt: 30 },"scroll-y":{ mode: 'wheel', gt: 100 },"empty-render":{ name: 'NotData' },"filter-config":{ showIcon: false },"checkbox-config":{ trigger: 'cell', range: true },"align":"center","edit-config":{ trigger: 'click', mode: 'cell', icon: ' ' }}},[_c('vxe-column',{attrs:{"width":"80","field":"file.user_name","title":"更新人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.file.user_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"min-width":"200","field":"change_reason","title":"更新描述","edit-render":{ name: 'textarea' }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start",style:({
            color: row.change_reason ? '#000000' : '#ff0000',
          })},[_vm._v(" "+_vm._s(row.change_reason ? row.change_reason : "点击编辑更新描述")+" ")])]}},{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-textarea',{staticStyle:{"z-index":"10"},attrs:{"placeholder":"请输入底稿更新描述","autosize":{ minRows: 1, maxRows: 5 }},on:{"blur":function (value, event) { return _vm.reasonBlur(value, row); }},model:{value:(row.change_reason),callback:function ($$v) {_vm.$set(row, "change_reason", $$v)},expression:"row.change_reason"}})]}}])}),_c('vxe-column',{attrs:{"width":"150","field":"file.created_at","title":"更新时间","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.file.created_at)+" ")]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"95","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.previewFile(row.file.id)}}},[_vm._v("预览")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.downloadFile(row.file.id)}}},[_vm._v("下载")])]}}])})],1),_c('a',{ref:"muban",staticStyle:{"display":"none"},attrs:{"href":"/标签模板.xlsx"}},[_vm._v("下载模板")]),_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.startUpload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }