import Request from '@/components/request/js/index.js';

export default {
  //股票池的股票详情
  getStockDetail(id) {
    return Request().get('/api/stock_pools/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //股票池列表
  //type:1 股票池 2 外部股票池 3 OTC池
  getStockPoolList(type = 1, status = 1) {
    return Request().get('/api/stock_pools/list', {
      header: {
        contentType: 'application/json',
      },
      data: {
        type: type,
        status: status,
      },
    });
  },
  //申请调入
  applyStock(data) {
    return Request().post('/api/stock_pools/apply', {
      header: {
        contentType: 'application/json',
      },
      data: {
        ...data,
      },
    });
  },
  //申请调出
  removeStock(id, pool_type) {
    return Request().post('/api/stock_pools/apply_out', {
      header: {
        contentType: 'application/json',
      },
      data: {
        id: id,
        pool_type: pool_type,
      },
    });
  },
  //批量调出
  multiRemoveStock(ids) {
    return Request().post('/api/stock_pools/audit/out', {
      header: {
        contentType: 'application/json',
      },
      data: {
        ids: ids,
      },
    });
  },
  //修改股票
  editStock(id, data) {
    return Request().post('/api/stock_pools/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {
        ...data,
      },
    });
  },

  //申请列表
  getApplyList(data) {
    return Request().get('/api/stock_pools/apply/list', {
      header: {
        contentType: 'application/json',
      },
      data: {
        ...data,
      },
    });
  },
  //撤销申请
  cancelApply(id) {
    return Request().post('/api/stock_pools/cancel_apply/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //申请通过
  passApply(id) {
    return Request().post('/api/stock_pools/audit/pass/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //申请拒绝
  refuseApply(id) {
    return Request().post('/api/stock_pools/audit/refuse/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //调出池删除股票
  deleteStock(id) {
    return Request().post('/api/stock_pools/' + id + '/delete', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //导出股票池
  exportExcel() {
    return Request().get('/api/stock_pools/export', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
};
