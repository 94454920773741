import Request from "@/components/request/js/index.js";

export default {
  //标签池
  getTagsList() {
    return Request().get("/api/tags/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //赛道列表
  getRaceList(params) {
    let url = "";
    if (params) {
      url = "/api/race/list?search=" + params.search;
    } else {
      url = "/api/race/list";
    }
    return Request().get(url, {
      header: {
        contentType: "application/json",
      },
    });
  },
  //风格列表
  getStyleList() {
    return Request().get("/api/style/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //添加赛道
  addRace(data) {
    return Request().post("/api/race", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //添加风格
  addStyle(data) {
    return Request().post("/api/style", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //获取标签详情
  getTagDetail(id) {
    return Request().get("/api/race/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //修改标签
  editTag(id, data) {
    return Request().post("/api/race/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "PATCH",
        ...data,
      },
    });
  },
  //绑定股票
  bindStock(tagId, ids) {
    return Request().post("/api/race/" + tagId + "/bind", {
      header: {
        contentType: "application/json",
      },
      data: {
        ids: ids,
      },
    });
  },
  //修改分组
  editGroupName(id, stock_ids, groupName) {
    let url = "";

    return Request().post(`/api/race/${id}/tags`, {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_ids: stock_ids,
        tag: groupName,
      },
    });
  },
  //设为重点
  setImportant(id, stock_id, is_important) {
    return Request().post(`/api/race/${id}/important`, {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_id: stock_id,
        is_important: is_important,
      },
    });
  },
  //赛道标签排序
  setOrder(id, stock) {
    return Request().post(`/api/race/${id}/order`, {
      header: {
        contentType: "application/json",
      },
      data: {
        data: stock,
      },
    });
  },
  //修改股票加入理由
  editStockDesc(id, stock_ids, reason) {
    return Request().post(`/api/race/${id}/reason`, {
      header: {
        contentType: "application/json",
      },
      data: {
        stock_id: stock_ids,
        reason: reason,
      },
    });
  },
  //删除标签
  deleteTag(id) {
    return Request().post("/api/race/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "DELETE",
      },
    });
  },
  //导出标签
  tagExport() {
    return Request().get("/api/race/export", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //导出标签带层级
  tagExportWithChildren() {
    return Request().get("/api/race/tree/export", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //保存标签股票自定义字段
  editTagStockRow(data) {
    return Request().post("/api/race/save_label", {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "PATCH",
        ...data,
      },
    });
  },
  //获取标签树
  //type 1:赛道 2:风格
  getTagTree() {
    return Request().get("/api/race/tree", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },

  //更新标签树
  editTagTree(data) {
    return Request().post("/api/race/tree", {
      header: {
        contentType: "application/json",
      },
      data: {
        data: data,
      },
    });
  },
  //导入excel
  uploadExcel(id, file) {
    return Request().upload("/api/race/" + id + "/import", {
      header: {
        contentType: "application/json",
      },
      data: {
        file: file,
      },
    });
  },
  //底稿excel列表
  excelHistory(id) {
    return Request().get("/api/race/" + id + "/excelHistory", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //底稿excel更新描述
  excelReason(id, change_reason) {
    return Request().post("/api/race/" + id + "/historyReason", {
      header: {
        contentType: "application/json",
      },
      data: {
        change_reason: change_reason,
      },
    });
  },
  //导出kpi
  kpiExport() {
    return Request().get("/api/race/exportStatistics", {
      header: {
        //contentType: "application/json",
      },
      data: {},
    });
  },
};
