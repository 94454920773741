<template>
  <div>
    <div v-if="tableLoading">
      <v-skeleton-loader
        type="table"
        :loading="tableLoading"
      ></v-skeleton-loader>
    </div>
    <div v-if="!tableLoading" style="display: flex; flex-wrap: wrap">
      <div v-for="item in chartData">
        <span class="ml-4">{{ item.sw_level }}</span>
        <div>
          <NHNLChart :nhnl="item" :swName="item.sw_level"></NHNLChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import NHNLChart from "./NHNLChart.vue";
export default {
  name: "FanChart",
  components: { NHNLChart },
  props: {},
  data() {
    return {
      chartData: [],
      tableLoading: false,
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.tableLoading = true;
      ResonanceContrast.getNHNL()
        .then((res) => {
          this.chartData = res.data;
          console.log(this.chartData);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  watch: {
    fanDays: {
      handler: function (val, oldVal) {
        this.getChartData();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
