<template>
  <div style="padding-left: 14px;white-space: pre-wrap;word-break:break-all;">
    <div v-for="comment in comments">
      <span style="font-weight: 1000;font-size: 14px;">{{ comment.user_name }}</span>
      回复 <span style="font-weight: 1000;font-size: 14px;">{{ parentUserName }}</span>:
      {{ comment.content }}
      <span>
        <a href="javascript:;" @click="clickReply(comment.id, comment.user_name)" class="text-dark"
          style="font-size: 12px;text-decoration: none;">
          <i class="fa fa-reply" aria-hidden="true"></i>
        </a>
        <a href="javascript:;" @click="deleteComment(comment.id)" class="text-dark ml-2"
          style="font-size: 12px;text-decoration: none;" v-if="comment.user_id == userStore.user_id">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </span>
      <div class="d-flex align-items-center" style="width: 100%;" v-if="replyId == comment.id">

        <vxe-textarea v-model="replyContent" placeholder="请填写您的评论" :autosize="{ minRows: 1, maxRows: 1 }">
        </vxe-textarea>

        <vxe-button style="width: 60px;margin-left: 10px;" @click="replyComment()" status="my-primary" size="mini">评论
        </vxe-button>

      </div>
      <ReplyComment :comments="comment.children" :parentUserName="comment.user_name" :noteId="noteId"
        :parentId="comment.id"></ReplyComment>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Note from '@/api/note.js';

import Bus from "@/components/Bus/bus.js";
import ReplyComment from "@/components/Note/ReplyComment";

export default Vue.extend({
  name: "ReplyComment",
  components: { ReplyComment },
  props: {
    noteId: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    parentUserName: {
      type: String,
      default: '',
    },
    comments: [],

  },
  data() {
    return {
      content: '',
      replyId: '',
      replyContent: '',
    };
  },
  mounted() {

  },
  methods: {
    clickReply(commentId, userName) {
      this.replyId = commentId;
      this.replyContent = ``;
    },
    replyComment() {
      this.showLoading('发送评论中，请稍候');
      //console.log(parentId);
      Note.comment(this.noteId, this.replyId, this.replyContent).then(res => {
        this.showToast('回复成功！', 'success', 2000);
        this.replyContent = '';
        this.replyId = '';
        Bus.$emit('refreshComment', res.data);
      }).catch(err => {
        console.log(err.msg);
        this.showToast(err.msg, 'danger', 10000);
      }).finally(() => {
        this.hideLoading();
      });
    },
    deleteComment(id) {
      this.$layer.confirm('确定要删除这个评论吗？', {
        shade: true,
        shadeClose: false,
      }, layerid => {
        this.showLoading('删除评论中，请稍候');
        Note.deleteComment(id).then(res => {
          this.showToast('删除成功！', 'success', 2000);
          Bus.$emit('refreshComment', res.data);
        }).catch(err => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        }).finally(() => {
          this.hideLoading();
        });
        this.$layer.close(layerid);
      });

    },
  },
  watch: {

  },
  computed: {

  }
});
</script>
<style>
</style>
