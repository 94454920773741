<template>
  <div class="momentum-timeline">
    <h3 class="text-dark mt-2" style="opacity: 0.8">阶段表现</h3>
    <div
      v-if="!tableLoading"
      style="width: 100%; display: flex; justify-content: center"
    >
      <vxe-table
        :data="chartData"
        style="width: 100%; margin-top: 20px"
        class="mytable-scrollbar"
        :border="false"
        resizable
        :scroll-y="{ enabled: false }"
        show-header-overflow
        highlight-hover-row
        ref="chartTable"
        max-height="100000"
        size="small"
        height="250px"
        :empty-render="{ name: 'NotData' }"
        align="center"
      >
        <vxe-column fixed="left" min-width="80" field="date" title="日期">
          <template #default="{ row }">
            <div class="d-flex justify-start flex-column">
              {{ row.date ? row.date : "-" }}
              <p>{{ weekArr[row.week - 1] }}</p>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="100"
          title=""
          :field="'Mid' + index"
          v-for="(item, index) in chartData[0].data"
          :key="index"
        >
          <template #default="{ row }">
            <div
              class="d-flex justify-center flex-column tagItem"
              :style="{
                backgroundColor: getBGColor(row.data[index].value),
              }"
              @mouseout="tagLeave"
              @mouseenter="(event) => tagEnter(event, row.data[index])"
              @click="clickItem(row.data[index])"
              :class="{ active: row.data[index].active }"
            >
              <span @mouseenter="(event) => tagEnter(event, row.data[index])">
                {{ row.data[index].name }}</span
              >
              <span @mouseenter="(event) => tagEnter(event, row.data[index])">
                {{ $stockHelpers.getPercent(row.data[index].value) }}</span
              >
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column min-width="70" title="" field="MOM_3D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              
              <span> {{ row.data[1].name }}</span>
              <span> {{ row.data[1].value }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="" field="MOM_5D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              
              <span> {{ row.data[2].name }}</span>
              <span> {{ row.data[2].value }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="" field="MOM_10D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[3].name }}</span>
              <span> {{ row.data[3].value }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="" field="MOM_20D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[4].name }}</span>
              <span> {{ row.data[4].value }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="" field="MOM_60D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[5].name }}</span>
              <span> {{ row.data[5].value }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="70" title="" field="MOM_60D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[6].name }}</span>
              <span> {{ row.data[6].value }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="70" title="" field="MOM_60D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[7].name }}</span>
              <span> {{ row.data[7].value }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="70" title="" field="MOM_60D" >
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[8].name }}</span>
              <span> {{ row.data[8].value }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="70" title="" field="MOM_60D">
          <template #default="{ row }">
            <div class="d-flex justify-end flex-column">
              <span> {{ row.data[9].name }}</span>
              <span> {{ row.data[9].value }}</span>
            </div>
          </template>
        </vxe-column> -->
      </vxe-table>

      <!-- <v-timeline dense style="width: 1200px">
        <v-timeline-item
          small
          v-for="day in chartData"
          :key="day.date"
          
          class="pa-0"
        >
          <template v-slot:icon>
            <div
              style="
                background-color: #344767;
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 5px;
              "
            >
              &nbsp;
            </div>
          </template>
          <v-row class="pt-0 mt-0">
            <v-col cols="1">
              <strong>{{ day.date }}</strong>
              <p>{{ weekArr[day.week - 1] }}</p>
            </v-col>
            <v-col cols="11" style="padding: 0">
              <div class="tagGroup">
                <div
                  v-for="tag in day.data"
                  :key="tag.name"
                  class="tagItem"
                  @mouseout="tagLeave"
                  @click="clickItem(tag)"
                  :class="{ active: tag.active }"
                >
                  <div
                    style="
                      font-size: 12px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      width: 100%;
                      height: 100%;
                    "
                    :style="{
                      backgroundColor: getBGColor(tag.value),
                    }"
                    @mouseenter="(event) => tagEnter(event, tag)"
                  >
                    <span
                      style="text-align: center"
                      @mouseenter="(event) => tagEnter(event, tag)"
                      >{{ tag.name }}</span
                    >
                    <span @mouseenter="(event) => tagEnter(event, tag)">{{
                      $stockHelpers.getPercent(tag.value)
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline> -->
    </div>
    <div v-if="tableLoading" class="mt-2">
      <v-skeleton-loader v-for="item in 2" v-bind="attrs" type="article">
      </v-skeleton-loader>
    </div>

    <hoverBox ref="tagHover">
      <template slot="content">
        <div
          class="px-3 py-3"
          style="
            width: 200px;
            height: 100%;
            font-size: 13px;
            white-space: pre-line;
          "
        >
          <span style="font-weight: bolder"
            >{{ currTag.name }}:<span
              :style="$stockHelpers.getColor(currTag.value)"
              >{{ $stockHelpers.getPercent(currTag.value) }}</span
            ></span
          >
          <div>
            <div v-for="stock in currTag.stocks" :key="stock.name">
              {{ stock.name }} :
              <span :style="$stockHelpers.getColor(stock.value)"
                >{{ $stockHelpers.getPercent(stock.value) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </hoverBox>
  </div>
</template>
<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import hoverBox from "@/components/HoverBox";
import { tsvFormatRows } from "d3";
export default {
  name: "MomTimeLine",
  components: { hoverBox },
  props: {
    currentBarTab: 0,
    typeId: "",
  },
  data() {
    return {
      tableLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      MOM_typeArr: [1, 3, 5, 10, 20, 60],
      MOM_type: 1,
      weekArr: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      chartData: [{ data: [] }],
      currTag: {
        name: "",
        value: 0,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableLoading = true;
      ResonanceContrast.getMomTimeLine(this.typeId, this.MOM_type)
        .then((res) => {
          res.data.forEach((day) => {
            day.data.forEach((tag) => {
              tag.active = false;
            });
          });
          this.chartData = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getBGColor(value) {
      if (value > 0) {
        let opacity = 1 - Math.abs(0.1 - value) / 0.1;
        if (value > 0.1) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(0.1 - value) / 0.1 - 1;
        if (value < -0.1) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    //显示弹窗
    tagEnter(event, tagData) {
      this.currTag = tagData;
      this.$refs.tagHover.show(
        event.clientX - 50,
        event.clientY - 100,
        "",
        "",
        ``
      );
    },
    //关闭弹窗
    tagLeave() {
      this.$refs.tagHover.hide();
    },
    clickItem(currTag) {
      if (!currTag.active) {
        currTag.active = true;

        this.chartData.forEach((day) => {
          day.data.forEach((tag) => {
            if (currTag.name != tag.name) {
              tag.active = false;
            } else {
              tag.active = true;
            }
          });
        });
      } else {
        this.chartData.forEach((day) => {
          day.data.forEach((tag) => {
            tag.active = false;
          });
        });
      }
      //console.log(currTag.name);
    },
  },
  watch: {
    currentBarTab() {
      this.MOM_type = this.MOM_typeArr[this.currentBarTab];
      this.getData();
    },
  },
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}

.tagGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.tagItem {
  width: 100px;
  height: 45px;
  color: #000;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
}
.tagItem.active::before {
  width: 100px;
  height: 45px;
  border: 3px solid #ff0000 !important;
  content: "";
  position: absolute;
  animation: shining 1s ease-in-out infinite;
  z-index: 10;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
<style>
.momentum-timeline .v-timeline-item__dot {
  width: 0px !important;
  height: 0px !important;
}
</style>
