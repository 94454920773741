//互动易
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.from }}
        </span>
        &nbsp; | &nbsp;
        <span class="font-weight-bold text-dark" style="opacity: 0.5">
          <span>
            {{ str.data.stocks[0] ? str.data.stocks[0].name : "" }}
          </span>
        </span>
      </div>
    </v-row>
    <!-- <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
    >
      {{ str.data.abstract }}
    </v-list-item-title> -->
    <v-list-item-content
      class="text"
      style="color: #191a1d"
      v-html="getContent(str.data.content)"
    >
    </v-list-item-content>
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-blue
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.stocks"
          :key="sector.id"
          @click="$stockHelpers.openStockDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  mounted() {},
  methods: {
    getContent(content) {
      return content.replace("【答】", "</br>【答】");
    },
  },
};
</script>
