<template>
	<TableFilter :inputWidth="inputWidth" :colName="colName" :autoSave="false" :selectData="selectData" :table="table" :placeholder="placeholder"></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter'
import Stock from '@/api/stock.js';
export default {
	name: '',
	props: {
		placeholder: '',
		table:'',
		colName:'',
		inputWidth:'',
	},
	data() {
		return {
			selectData: [
				{ label:'核心', value: '核心' },
				{ label:'一级', value: '一级' },
				{ label:'二级', value: '二级' },
			],
			inputValue: '',
		};
	},
	components: {
		TableFilter,
	},
	mounted() {
		
	},
	methods: {
		
	},
	watch: {
		
	}
};
</script>
<style scoped>
	
</style>
