<template>
  <div class="v-application bg-white" style="width: 100%">
    <vxe-table
      ref="xTable"
      border
      stripe
      show-overflow
      :data="msgList"
      :loading="loading"
      height="100%"
      style="width: 100%"
      highlight-current-row
      @cell-dblclick="handleCellDblclick"
      :sort-config="{ trigger: 'cell', multiple: false }"
    >
      <vxe-table-column
        field="title"
        title="标题"
        width="200"
      ></vxe-table-column>
      <vxe-table-column field="content" title="内容" min-width="200">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                text-align: start;
                word-wrap: break-word;
                white-space: pre-wrap;
                font-size: 11px;
              "
              v-html="row.content"
            ></div>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="created_at"
        title="创建时间"
        width="200"
        sortable
      ></vxe-table-column>
    </vxe-table>

    <vxe-modal
      v-model="showDetails"
      title="查看详情"
      width="600"
      height="400"
      :mask="false"
      :lock-view="true"
      resize
      :transfer="true"
    >
      <template v-slot>
        <div v-html="currentContent"></div>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Message from "@/api/message.js";
export default {
  name: "AiAnalysis",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.getMessageList();
  },
  data() {
    return {
      loading: true,
      msgList: [],
      currentContent: "",
      showDetails: false,
    };
  },
  methods: {
    submit() {},
    getMessageList() {
      this.loading = true;
      Message.getMessageList(1)
        .then((res) => {
          console.log(res);
          this.msgList = res.data;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCellDblclick({ row }) {
      console.log(row);
      this.currentContent = row.content;
      this.showDetails = true;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style></style>
