var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-1",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"text-body text-h6 font-weight-bolder"},[_vm._v("持股明细"),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" (仓位:"+_vm._s(_vm.$stockHelpers.getPercent(_vm.portfolioDetail.proportion))+")")])]),_c('div',[_c('vxe-button',{attrs:{"size":"small","content":"导出持仓","status":"my-primary"},on:{"click":function($event){return _vm.exportStock()}}}),_c('vxe-button',{attrs:{"size":"small","content":"添加成分股","status":"my-danger"},on:{"click":function($event){return _vm.addStock()}}})],1)]),_c('vxe-table',{ref:"tradingTable",staticClass:"mytable-scrollbar",attrs:{"id":"tradingTable","data":_vm.portfolioDetail.holdings,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","height":_vm.tableHeight,"column-config":{ resizable: true },"size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 100 },"empty-render":{ name: 'NotData' },"export-config":{}}},[_c('vxe-column',{attrs:{"width":"80","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.code ? row.code : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"stock[0].name","title":"股票名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.stock[0].name ? row.stock[0].name : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"sw_industry.industry_name","title":"申万三级","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.sw_industry ? row.sw_industry.industry_name : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"income_rate","title":"总收益率","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.income_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.income_rate)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"day_rate","title":"日涨幅","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.day_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.day_rate)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"wait_pos_ratio","title":"待交易仓位","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.wait_pos_ratio)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"proportion","title":"仓位","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.proportion)))])])]}}])}),_c('vxe-column',{attrs:{"width":"100","title":"操作"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isSelf)?_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.restocking(row)}}},[_vm._v("调仓")]):_vm._e(),(!_vm.isSelf)?_c('a',{staticClass:"text-xs font-weight-normal",staticStyle:{"color":"#ccc"},attrs:{"href":"javascript:;"}},[_vm._v("调仓")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }