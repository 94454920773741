<template>
  <v-autocomplete
    background-color="rgba(255,255,255,.9)"
    color="rgba(0,0,0,.6)"
    rounded-sm
    outlined
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    dense
    hide-details
    clearable
    :placeholder="placeholder"
    class="
      input-icon
      font-size-input
      placeholder-lighter
      input-alternative
      border border-radius-sm
      pa-0
    "
    style="width: 100%"
  >
    <template slot="prepend-inner" v-if="showIcon">
      <v-icon color="rgba(0,0,0,.6)" size=".875rem"> fas fa-search </v-icon>
    </template>

    <template slot="append"> &nbsp; </template>
  </v-autocomplete>
</template>
<script>
import Stock from '@/api/stock.js';
export default {
  name: '',
  props: {
    value: '',
    placeholder: '',
    showIcon: false,
  },
  data() {
    return {
      model: '',
      search: '',
      items: [],
      isLoading: false,
    };
  },
  mounted() {
    //console.log(this.value);
    this.model = this.value;
    this.getList();
  },
  methods: {
    submit() {},
    remove(item) {
      const index = this.model.indexOf(item);
      if (index >= 0) this.model.splice(index, 1);
    },
    getList() {
      this.isLoading = true;
      Stock.getIndustryList2()
        .then((res) => {
          let arr = [];
          res.data.forEach((item, index) => {
            arr.push({ text: `${item.name}`, value: item.id });
          });
          this.items = this.items.concat(arr);
        })
        .catch((err) => {
          console.log(err.msg);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    model(val) {
      this.$emit('input', val);
    },
    search(val) {},
  },
};
</script>
