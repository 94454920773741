import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: 'user',
  // 一个返回新状态的函数
  state: () => ({
    name: '',
		token:'',
		user_id:'',
		roles:'',
		permissions:'',
  }),
  getters: {
    
  },
  actions: {
    
  },
})