var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-1",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"text-body text-h6 font-weight-bolder"},[_vm._v("交易明细")]),_c('div',[_c('vxe-button',{attrs:{"size":"small","content":"导出明细","status":"my-primary"},on:{"click":function($event){return _vm.exportStock()}}})],1)]),_c('vxe-table',{ref:"tradingHistoryTable",staticClass:"mytable-scrollbar",attrs:{"id":"tradingHistoryTable","data":_vm.portfolioDetail.trades,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","height":_vm.tableHeight,"column-config":{ resizable: true },"size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 100 },"empty-render":{ name: 'NotData' },"export-config":{},"edit-config":{ trigger: 'click', mode: 'cell', activeMethod: _vm.activeCellMethod, showStatus: true, icon: ' ' }}},[_c('vxe-column',{attrs:{"width":"80","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.code ? row.code : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"stock[0].name","title":"股票名称"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.stock.length!=0 ? row.stock[0].name : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"60","field":"direction","title":"买卖"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('span',{style:(_vm.getDirectionColor(row.direction))},[_vm._v(_vm._s(row.direction == 1 ? '买入' : '卖出'))])])]}}])}),_c('vxe-column',{attrs:{"width":"70","field":"trade_val","title":"成交价"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.trade_val)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"order_at","title":"下单时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.order_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"created_at","title":"委托时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.created_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"updated_at","title":"交易时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(" "+_vm._s(row.updated_at)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"120","field":"trade_type","title":"成交状态"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(_vm._s(_vm.getTradeType(row.trade_type)))])])]}}])}),_c('vxe-column',{attrs:{"width":"200","field":"trade_comment","title":"成交明细"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.trade_comment)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"200","field":"comment","title":"备注(点击单元格可修改)","edit-render":{ name: 'textarea' }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(_vm._s(column.title))])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.comment!='')?_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.comment)+" ")]):_c('span',{staticStyle:{"color":"#cccccc"}},[_vm._v("点击填写备注")])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-textarea',{staticStyle:{"z-index":"10"},attrs:{"placeholder":"请输入备注","autosize":{ minRows: 1, maxRows: 5 }},on:{"change":function($event){_vm.commentHaveChanged = true},"blur":function (value, event) { return _vm.commentBlur(value, row); }},model:{value:(row.comment),callback:function ($$v) {_vm.$set(row, "comment", $$v)},expression:"row.comment"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"60","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.trade_type == '0')?_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.cancelTrade(row.id)}}},[_vm._v("撤单")])]):_c('div',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }