<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择股票</label
        >
        <StockSelect
          @getItem="getItem"
          :returnItem="true"
          style="width: 100%"
          class="my-2"
          placeholder="*输入股票名称或代码"
          :disableArr="[]"
        ></StockSelect>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
import Review from "@/api/review.js";
export default {
  name: "AddStock",
  components: {
    StockSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.setLock();
    this.lockInterval = setInterval(() => {
      this.setLock();
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.lockInterval);
    this.setUnlock();
  },
  data() {
    return {
      stockItem: null,
      lockInterval: null,
    };
  },
  methods: {
    setLock() {
      Review.setReviewLock(this.iframeData.tagId, this.iframeData.id)
        .then((res) => {
          console.log("上锁成功");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    setUnlock() {
      Review.setReviewUnlock(this.iframeData.tagId, this.iframeData.id)
        .then((res) => {
          console.log("解锁成功");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    getItem(item) {
      //console.log(item);
      this.stockItem = item;
    },
    submit() {
      if (!this.stockItem) {
        this.showToast("请先选择绑定的股票", "warning", 5000);
        return;
      }
      Bus.$emit("editDayReviewContent", {
        type: "addStock",
        code: this.stockItem.code,
        name: this.stockItem.name,
        tagIndex: this.iframeData.tagIndex,
        childTagIndex: this.iframeData.childTagIndex,
        tagId: this.iframeData.tagId,
      });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.slider-blue {
  --slider-connect-bg: #409eff;
  --slider-tooltip-bg: #409eff;
  --slider-handle-ring-color: #3b82f630;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
