var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LoadingPage',{staticStyle:{"width":"100%","height":"100vh"},attrs:{"visible":_vm.tableLoading}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableLoading),expression:"!tableLoading"}],staticClass:"px-0 py-1"},[_vm._l((_vm.list),function(item){return _c('div',{key:item.id},[_c('v-card',{staticClass:"border-radius-md pa-3 mb-3"},[_c('h3',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticStyle:{"font-size":"14px","margin-left":"10px"},style:(_vm.$stockHelpers.getColor(item.pct_chg))},[_vm._v("当日涨跌幅:"+_vm._s(_vm.$stockHelpers.getPercent(item.pct_chg)))])]),_c('p',[_vm._v(" 理由："+_vm._s(item.description == "nan" ? "暂无" : item.description)+" ")]),_c('vxe-table',{ref:"bindStockTable",refInFor:true,staticClass:"mytable-scrollbar",staticStyle:{"width":"100%"},attrs:{"data":item.stocks,"border":false,"resizable":"","scroll-y":{ enabled: false },"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.tableLoading,"max-height":"100000","size":"small","sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"align":"left"}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"80","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.code ? row.code : "-")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"fixed":"left","min-width":"80","field":"prod_name","title":"股票名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.prod_name ? row.prod_name : "-")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"100","field":"m_days_n_boards","title":"是否连板","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.m_days_n_boards)?_c('span',{staticStyle:{"background-color":"#fff1f1","border":"1px solid #e80000","color":"#e80000","padding":"2px 5px"}},[_vm._v(_vm._s(row.m_days_n_boards))]):_vm._e()])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"70","title":"收盘价","field":"cur_price","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.px_change_rate))},[_vm._v(_vm._s(row.cur_price))])])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"70","title":"涨幅","field":"px_change_rate","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{style:(_vm.$stockHelpers.getColor(row.px_change_rate))},[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.px_change_rate)))])])]}}],null,true)}),_c('vxe-column',{attrs:{"field":"circulation_value","min-width":"70","title":"流通市值(亿)","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.circulation_value ? (row.circulation_value / 100000000).toFixed(2) : "")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"70","title":"换手率","field":"turnover_ratio","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.turnover_ratio ? _vm.$stockHelpers.getPercent(row.turnover_ratio) : "-")+" ")])]}}],null,true)}),_c('vxe-column',{attrs:{"min-width":"300","field":"races","title":"标签"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"d-flex",staticStyle:{"flex-wrap":"wrap"}},_vm._l((row.races),function(race,index){return (race.level !== 0)?_c('span',{key:race.id,staticClass:"px-1 py-0",staticStyle:{"cursor":"pointer","font-size":"10px","color":"#fff","border-radius":"3px","margin-bottom":"1px","margin-right":"1px"},style:({
                      backgroundColor:
                        race.color == "#e8f5e9" ? "#00b294" : race.color,
                    }),on:{"click":function($event){return _vm.$stockHelpers.openTagDetail(race.id)}}},[_vm._v(" "+_vm._s(race.name)+" ")]):_vm._e()}),0)])]}}],null,true)}),_c('vxe-column',{attrs:{"title":"解读","width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.description)+" ")]}}],null,true)})],1)],1)],1)}),_c('div',{staticStyle:{"color":"#ccc","font-size":"12px"}},[_vm._v(" 更新时间："+_vm._s(_vm.list[0] ? _vm.list[0].updated_on : "")+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }