<template>
  <div id="maskOver">
    <div
      style="
        width: 120vw;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999999999;
        pointer-events: none;
        opacity: 0.02;
        font-weight: 900;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;
      "
    >
      <span
        style="margin: 20px; font-size: 40px; transform: rotate(-30deg)"
        v-for="item in 500"
        :key="item"
        >LG-{{ userStore ? userStore.name : "未登录" }}</span
      >
    </div>
    <router-view :key="$route.path" />
  </div>
</template>
<script>
import $ from "jquery";
var _this;

export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    _this = this;
  },
};
</script>
<style scoped>
#maskOver {
  position: relative;
}
</style>
