<template>
  <div>
    <div
      style="
        display: flex;

        align-items: center;
        width: 100%;
      "
      class="mt-9"
    >
      <div>
        <h3 class="text-dark" style="opacity: 0.8">行业全视角雷达</h3>
      </div>
      <vxe-button
        @click="isTable = !isTable"
        size="small"
        :content="isTable ? '切换到图表显示' : '切换到表格显示'"
        status="my-danger"
        class="ml-5"
        style="z-index: 12"
      ></vxe-button>
    </div>
    <div v-if="isTable">
      <h4 class="text-dark mt-1" style="opacity: 0.8">全视角数据表格</h4>
      <vxe-table
        :data="list[0]"
        style="width: 100%; min-height: 300px"
        class="mytable-scrollbar"
        :border="false"
        resizable
        :scroll-y="{ enabled: false }"
        show-overflow
        show-header-overflow
        highlight-hover-row
        ref="chartTable"
        :loading="tableLoading"
        max-height="100000"
        size="small"
        :sort-config="{ trigger: 'cell', multiple: false }"
        :empty-render="{ name: 'NotData' }"
        align="left"
      >
        <vxe-column
          fixed="left"
          min-width="100"
          field="industry_name"
          title="行业名称"
        >
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.industry_name ? row.industry_name : "-" }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="趋势强度(近一周)"
          field="wtrend_score"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.wtrend_score }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="趋势强度(近一季度)"
          field="qtrend_score"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.qtrend_score }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="拥挤度(近一周)"
          field="wcrowd_score"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.wcrowd_score }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="拥挤度(近一季度)"
          field="qcrowd_score"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.qcrowd_score }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="赚钱效应(近5日)"
          field="money_effect"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.money_effect }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="北向资金(近5日)"
          field="north_flow"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.north_flow }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="70"
          title="盈利预测增速(近5日)"
          field="cagr"
          sortable
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.cagr }}
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="70" title="总分" field="total_score" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.total_score }}
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div v-if="!isTable">
      <h4 class="text-dark mt-1" style="opacity: 0.8">全视角雷达图</h4>
      <div style="display: flex; flex-wrap: wrap; justify-content: center">
        <div v-for="(item, index) in list[0]" :key="item.industry_name">
          <RadarChart
            :chartData="item"
            :chartData1="list[1][index]"
          ></RadarChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import RadarChart from "./RadarChart.vue";
export default {
  name: "RadarTable",
  components: { RadarChart },
  data() {
    return {
      tableLoading: true,
      list: [],
      isTable: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableLoading = true;
      ResonanceContrast.getRadarTable()
        .then((res) => {
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  watch: {},
};
</script>
