<template>
  <div>
    <v-container fluid class="px-1 py-1">
      <v-card class="border-radius-xl mt-3 pb-2">
        <vxe-toolbar
          class="px-3"
          custom
          :refresh="{ query: getResearchList }"
          ref="coreToolbar"
        >
          <template v-slot:buttons>
            <vxe-button
              @click="addResearch()"
              size="medium"
              content="新建调研"
              class="ml-0 mr-1"
              status="my-primary"
            >
            </vxe-button>

            <UserFilter
              colName="user_name"
              placeholder="申请人"
              :table="$refs.coreTable"
              inputWidth="100px"
              class="mr-1"
            ></UserFilter>

            <UserFilter
              colName="audit_name"
              placeholder="审核人"
              :table="$refs.coreTable"
              inputWidth="100px"
              class="mr-1"
            ></UserFilter>

            <StatusFilter
              colName="status_name"
              placeholder="流程状态"
              :table="$refs.coreTable"
              inputWidth="100px"
              class="mr-1"
            ></StatusFilter>
          </template>
        </vxe-toolbar>

        <vxe-table
          id="coreTable"
          row-id="id"
          ref="coreTable"
          :data="tableData"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          align="center"
          :loading="coreTableLoading"
          :column-config="{ resizable: true }"
          :height="tableHeight"
          size="small"
          :stripe="true"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
          :empty-render="{ name: 'NotData' }"
          :custom-config="{ storage: true }"
          :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
          :filter-config="{ showIcon: false }"
          :export-config="{}"
        >
          <vxe-column
            width="100"
            field="user_name"
            title="申请人"
            sortable
            :filters="[{ data: '' }]"
            :filter-method="
              (value) => $tableHelpers.filterInputMethod(value, 'user_name')
            "
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    word-break: keep-all;
                    white-space: pre-line;
                    text-align: start;
                  "
                >
                  {{ row.user_name }}
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column width="80" field="created_at" title="申请时间" sortable>
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span
                  :style="
                    $stockHelpers.getDateColor(row.created_at.split(' ')[0])
                  "
                  >{{ row.created_at.split(' ')[0] }}</span
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="80"
            field="research_date"
            title="调研时间"
            sortable
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span>
                  {{ row.research_date }}
                </span>
              </div>
            </template>
          </vxe-column>

          <vxe-column width="80" field="stock_code" title="股票代码" sortable>
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock_id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock_code ? row.stock_code : '-' }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column width="80" field="stock_name" title="股票名称" sortable>
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock_id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock_name ? row.stock_name : '-' }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column width="100" field="file_id" title="申请附件">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-center">
                <a
                  href="javascript:;"
                  @click="$commonHelpers.previewFile(row.file_id)"
                  class="text-xs font-weight-normal text-typo mr-1"
                  >预览</a
                >
                <a
                  href="javascript:;"
                  @click="$commonHelpers.downloadFile(row.file_id)"
                  class="text-xs font-weight-normal text-typo mr-1"
                  >下载</a
                >
                <a
                  href="javascript:;"
                  @click="uploadFile('before', row.id, true)"
                  class="text-xs font-weight-normal text-typo mr-1"
                  >上传</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="comment" title="申请说明">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.comment ? row.comment : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="100"
            field="audit_name"
            title="审核人"
            sortable
            :filters="[{ data: '' }]"
            :filter-method="
              (value) => $tableHelpers.filterInputMethod(value, 'audit_name')
            "
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    word-break: keep-all;
                    white-space: pre-line;
                    text-align: start;
                  "
                >
                  {{ row.audit_name ? row.audit_name : '-' }}
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column width="80" field="audit_at" title="审核时间" sortable>
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.audit_at ? row.audit_at.split(' ')[0] : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="audit_comment" title="审核批注">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.audit_comment ? row.audit_comment : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column width="100" field="research_file_id" title="调研纪要">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-center">
                <a
                  href="javascript:;"
                  @click="$commonHelpers.previewFile(row.research_file_id)"
                  class="text-xs font-weight-normal text-typo mr-1"
                  v-if="row.research_file_id"
                >
                  预览
                </a>
                <a
                  href="javascript:;"
                  @click="$commonHelpers.downloadFile(row.research_file_id)"
                  class="text-xs font-weight-normal text-typo mr-1"
                  v-if="row.research_file_id"
                >
                  下载
                </a>
                <a
                  href="javascript:;"
                  @click="
                    uploadFile(
                      'after',
                      row.id,
                      row.research_file_id ? true : false
                    )
                  "
                  class="text-xs font-weight-normal text-typo mr-1"
                  >上传</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="80"
            field="status_name"
            title="流程进度"
            :filters="[{ data: '' }]"
            :filter-method="
              (value) => $tableHelpers.filterInputMethod(value, 'status_name')
            "
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-center">
                <span v-if="row.status === 0">
                  <span
                    v-if="!$commonHelpers.getPermissions('research.apply')"
                    style="color: #0000ff"
                    >待审核</span
                  >
                  <span v-else>
                    <a
                      href="javascript:;"
                      @click="apply(row.id)"
                      class="text-xs font-weight-normal text-typo mr-1"
                      >审核</a
                    >
                  </span>
                </span>
                <span v-if="row.status === 1">
                  <span
                    style="color: #008000"
                    v-if="$commonHelpers.getPermissions('research.apply')"
                    >已通过</span
                  >
                  <span v-else>
                    <a
                      href="javascript:;"
                      @click="finishResearch(row.id)"
                      class="text-xs font-weight-normal text-typo mr-1"
                      >标记完成</a
                    >
                  </span>
                </span>
                <span v-if="row.status === 2" style="color: #800000"
                  >已驳回</span
                >
                <span v-if="row.status === 3" style="color: #008000"
                  >已完成</span
                >
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>

      <v-file-input
        id="ResearchAttachmentSelect"
        v-model="fileSelect"
        prepend-icon=""
        style="display: none"
      >
      </v-file-input>
    </v-container>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import Research from '@/api/research.js';
import UserFilter from '@/components/StockPool/filter/UserFilter';
import StatusFilter from '@/components/Research/StatusFilter';
import ApplyResearch from '@/components/Research/ApplyResearch';
import AddResearch from '@/components/Research/AddResearch';

const OSS = require('ali-oss');
import File from '@/api/file.js';
export default {
  name: 'Research',
  components: { UserFilter, StatusFilter },
  computed: {},
  data() {
    return {
      tableHeight: '500',
      coreTableLoading: false,
      tableData: [],
      fileSelect: null,
      uploadApplyId: null,
      uploadType: null,
    };
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 160;
    this.getResearchList();

    Bus.$on('refreshResearchList', (e) => {
      this.getResearchList();
    });
  },
  methods: {
    //获取调研列表
    getResearchList() {
      this.coreTableLoading = true;
      Research.getResearchList()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.coreTableLoading = false;
        });
    },
    //审核
    apply(id) {
      this.$layer.iframe({
        content: {
          content: ApplyResearch,
          parent: this,
          data: { iframeData: { id: id } },
        },
        area: ['600px', '350px'],
        title: '审核调研申请',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    //新建调研
    addResearch() {
      this.$layer.iframe({
        content: {
          content: AddResearch,
          parent: this,
          data: { iframeData: {} },
        },
        area: ['600px', '500px'],
        title: '新建调研申请(*为必填项)',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    //完成调研
    finishResearch(id) {
      this.$layer.confirm('确定标记该调研为已完成吗？', (layerid) => {
        this.showLoading('处理中，请稍候');
        Research.finishResearch(id)
          .then((res) => {
            this.showToast('已成功标记该项目为已完成！', 'success', 5000);
            Bus.$emit('refreshResearchList', true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, 'danger', 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    //上传文件
    uploadFile(type, id, haveOld) {
      this.uploadType = type;
      this.uploadApplyId = id;
      if (type == 'before') {
        this.$layer.confirm(
          '您确定要上传申请附件吗？上传会覆盖掉已有文件！',
          (layerid) => {
            document.getElementById('ResearchAttachmentSelect').click();
            this.$layer.close(layerid);
          }
        );
      } else if (type == 'after') {
        if (haveOld) {
          this.$layer.confirm(
            '您确定要上传申请附件吗？上传会覆盖掉已有文件！',
            (layerid) => {
              document.getElementById('ResearchAttachmentSelect').click();
              this.$layer.close(layerid);
            }
          );
        } else {
          document.getElementById('ResearchAttachmentSelect').click();
        }
      }
    },
    uploadWorker(fileItem) {
      this.showLoading('上传中，请稍候');
      File.getUploadSts()
        .then((res) => {
          let info = res.data;
          let client = new OSS({
            region: 'oss-cn-shanghai',
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: 'qinglitouyan',
          });

          let fileName = 'fileStore/' + info.uuid + '/' + fileItem.name;
          this.fileName = fileItem.name + ' (正在上传) ';

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf('uploadId') != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }
              //申请附件和调研纪要是两种完成逻辑
              if (this.uploadType == 'before') {
                File.uploadFile(
                  info.uuid,
                  '/fileStore/' + info.uuid,
                  fileItem.name,
                  {}
                )
                  .then((res) => {
                    console.log('oss上传成功');
                    //上传完成后，修改对应调研
                    let editData = {};
                    if (this.uploadType == 'before') {
                      editData.file_id = info.uuid;
                      editData.file_name = fileItem.name;
                    } else {
                      editData.research_file_id = info.uuid;
                      editData.research_file_name = fileItem.name;
                    }
                    this.showLoading('处理中，请稍候');
                    Research.editResearch(this.uploadApplyId, editData)
                      .then((res) => {
                        console.log('已成功修改调研的文件');
                        this.showToast('已成功上传文件！', 'success', 5000);
                        Bus.$emit('refreshResearchList', true);
                      })
                      .catch((err) => {
                        console.log(err.msg);
                        this.showToast(err.msg, 'danger', 10000);
                      })
                      .finally(() => {
                        this.hideLoading();
                      });
                  })
                  .catch((err) => {
                    console.log('err:', err);
                    this.hideLoading();
                  })
                  .finally(() => {});
              } else {
                console.log('oss上传成功');
                //上传完成后，修改对应调研
                let editData = {};
                if (this.uploadType == 'before') {
                  editData.file_id = info.uuid;
                  editData.file_name = fileItem.name;
                } else {
                  editData.research_file_id = info.uuid;
                  editData.research_file_name = fileItem.name;
                }
                this.showLoading('处理中，请稍候');
                Research.editResearch(this.uploadApplyId, editData)
                  .then((res) => {
                    console.log('已成功修改调研的文件');
                    this.showToast('已成功上传文件！', 'success', 5000);
                    Bus.$emit('refreshResearchList', true);
                  })
                  .catch((err) => {
                    console.log(err.msg);
                    this.showToast(err.msg, 'danger', 10000);
                  })
                  .finally(() => {
                    this.hideLoading();
                  });
              }
            })
            .catch((err) => {
              this.showToast('上传失败！', 'danger', 5000);
              console.log('err:', err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.hideLoading();
          this.showToast('上传失败，获取上传前置信息失败！', 'danger', 5000);
        });
    },
  },
  watch: {
    fileSelect(value) {
      if (value) {
        this.uploadWorker(value);
      }
    },
  },
};
</script>
