<template>
	<div>
		<div class="pa-1" style="display: flex;justify-content: space-between;">
			<span class="text-body text-h6 font-weight-bolder">交易明细</span>
			<div>
				<vxe-button @click="exportStock()" size="small" content="导出明细" status="my-primary"></vxe-button>
			</div>
		</div>
		<vxe-table
			id="tradingHistoryTable"
			class="mytable-scrollbar"
			:data="portfolioDetail.trades"
			:border="false"
			resizable
			show-overflow
			show-header-overflow
			highlight-hover-row
			align="center"
			ref="tradingHistoryTable"
			:height="tableHeight"
			:column-config="{ resizable: true }"
			size="mini"
			:stripe="true"
			:sort-config="{ trigger: 'cell', multiple: true }"
			:scroll-y="{ mode: 'wheel', gt: 100 }"
			:empty-render="{ name: 'NotData' }"
			:export-config="{}"
			:edit-config="{ trigger: 'click', mode: 'cell', activeMethod: activeCellMethod, showStatus: true, icon: ' ' }"
		>
		
			<vxe-column width="80" field="code" title="股票代码">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.code ? row.code : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="80" field="stock[0].name" title="股票名称">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.stock.length!=0 ? row.stock[0].name : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="60" field="direction" title="买卖">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-center">
						<span :style="getDirectionColor(row.direction)">{{ row.direction == 1 ? '买入' : '卖出' }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="70" field="trade_val" title="成交价">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{row.trade_val}}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="120" field="order_at" title="下单时间" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.order_at }}
					</div>
				</template>
				
			</vxe-column>

			<vxe-column width="120" field="created_at" title="委托时间" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.created_at }}
					</div>
				</template>
				
			</vxe-column>
			
			<vxe-column width="120" field="updated_at" title="交易时间" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<span>
							{{ row.updated_at }}
						</span>
					</div>
				</template>
				
			</vxe-column>
			
			<vxe-column width="120" field="trade_type" title="成交状态">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<span>{{ getTradeType(row.trade_type) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="200" field="trade_comment" title="成交明细">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{row.trade_comment}}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="200" field="comment" title="备注(点击单元格可修改)" :edit-render="{ name: 'textarea' }">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div v-if="row.comment!=''" class="d-flex justify-start">
						{{row.comment}}
					</div>
					<span v-else="row.comment=='' && row.trade_type == '0'" style="color: #cccccc;">点击填写备注</span>
				</template>
				<template #edit="{ row }">

					<vxe-textarea style="z-index: 10;" placeholder="请输入备注" v-model="row.comment"
						:autosize="{ minRows: 1, maxRows: 5 }"
						@change="commentHaveChanged = true"
						@blur="(value, event) => commentBlur(value, row)">
					</vxe-textarea>

				</template>
			</vxe-column>
			
			<vxe-column title="操作" type="seq" width="60" :resizable="false" show-overflow>
				<template #default="{ row }">
					<div v-if="row.trade_type == '0'">
						<a href="javascript:;" @click="cancelTrade(row.id)" class="text-xs font-weight-normal text-typo" style="margin-left: 5px">撤单</a>
					</div>
					<div v-else>-</div>
				</template>

				
			</vxe-column>

		</vxe-table>
		
	</div>
</template>
<script>
import Portfolio from '@/api/portfolio.js';
import Bus from '@/components/Bus/bus.js';

export default {
	name: 'TradingHistory',
	components: {
		
	},
	props: {
		portfolioDetail:{},
	},
	mounted() {
		//this.tableHeight = document.body.offsetHeight - 600 + 'px';
	},
	data() {
		return {
			tableHeight:'500px',
			commentHaveChanged:false,
		};
	},
	methods: {
		getTradeType(val) {
			//交易状态 1：成功 2：部成部撤 3：失败
			if (val == '1') {
				return '交易成功';
			} else if (val == '2') {
				return '仅部分交易成功';
			} else if (val == '3') {
				return '交易失败';
			} else if (val == '0') {
				return '等待交易';
			}
		},
		getDirectionColor(val) {
			if (val == 1) {
				return 'color:red;';
			} else {
				return 'color:green;';
			}
		},
		exportStock(){
			this.$refs.tradingHistoryTable.openExport({  })
		},
		cancelTrade(id){
			this.$layer.confirm('确定撤单吗？', layerid => {
				this.showLoading('撤单中，请稍候');
				Portfolio.cancelTrade(id).then(res => {
					this.showToast('撤单成功！','success',5000);
					Bus.$emit("refreshPortfolioDetail", true);
				}).catch(err => {
					console.log(err.msg);
					this.showToast(err.msg,'danger',10000);
				}).finally(() => {
					this.hideLoading();
				});
				this.$layer.close(layerid);
			});

			
		},
		//是否可编辑
		activeCellMethod({ row, column }) {
			//console.log(row.trade_type);
			if (row.trade_type == '0') {
				return true;
			} else {
				return false;
			}
		},
		//备注编辑完成
		commentBlur(value, row) {
			if (this.commentHaveChanged) {
				this.commentHaveChanged = false;
				this.showLoading('保存备注中，请稍候');
				Portfolio.updateTradeComment(row.id, value.value).then(res => {
					this.showToast('保存备注成功！','success',5000);
					//Bus.$emit("refreshPortfolioDetail", true);
				}).catch(err => {
					console.log(err.msg);
					this.showToast(err.msg,'danger',10000);
				}).finally(() => {
					this.hideLoading();
				});
			}
		},
	},
	computed: {},
	watch: {
		
	}
};
</script>
<style scoped="scoped"></style>
