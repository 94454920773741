<template>
  <div>
    <v-container fluid class="px-6 py-1">
      <v-tabs-items v-model="currentTab">
        <!-- <v-tab-item :key="0">
          <iframe
            style="border: 0px; width: 100%; height: calc(100vh - 100px)"
            src="https://static-e3ed79ac-125c-466f-ae3c-4671c60e16e0.bspapp.com/%E6%B3%A2%E5%8A%A8%E7%8E%87%E6%9B%B2%E9%9D%A2.html"
          ></iframe>
        </v-tab-item> -->

        <v-tab-item :key="0">
          <HistoryOfVolatility></HistoryOfVolatility>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import HistoryOfVolatility from '@/components/Volatility/HistoryOfVolatility';
export default {
  name: 'VolatilitySurfaces',
  components: { HistoryOfVolatility },
  data() {
    return {
      currentTab: 0,
    };
  },
  mounted() {
    Bus.$on('VolatilitySurfacesTabChange', (e) => {
      this.currentTab = e;
      //console.log(e);
    });
  },
};
</script>
