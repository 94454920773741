<template>
  <div class="px-1 py-1" style="width: 100%; height: 100%">
    <div style="display: flex; height: 100%">
      <div style="width: 300px; position: fixed">
        <div style="width: 100%">
          <h4 class="text-dark">功能模块：上市公司公告分析</h4>
        </div>
        <vxe-textarea
          class="mt-3"
          v-model="text"
          placeholder="输入待分析的公告"
          size="mini"
          style="width: 100%"
          rows="10"
          show-word-count
        ></vxe-textarea>

        <vxe-button class="mt-2" status="primary" @click="submit"
          >提交</vxe-button
        >

        <h4 class="text-dark mt-5">使用场景示例</h4>
        <h5 class="text-dark">输入内容：</h5>
        <div>
          <vxe-textarea
            value="关于增加经营范围并修订《公司章程》的议案 
              各位股东及股东代表：
              一、拟变更公司经营范围情况 
              基于公司发展战略和经营实际状况，为进一步拓展业务，拟对公司经营范围
              进行增项，同时根据上海市市场监督管理部门关于全面使用经营范围规范化目录
              的具体要求做出调整，具体如下：
              原经营范围 调整后经营范围 
              城市及道路照明工程专业施工，建筑装
              修装饰建设工程专业设计及施工，建筑智能
              化建设工程专业设计及施工，园林及古建筑
              建设工程专业设计及施工，建筑幕墙建设工
              程专业设计及施工，环保及节能科技领域技
              术服务，承接各类泛光照明设计、安装、调
              试，经销各类特种灯泡、灯泡、灯具、电器
              箱、触发器、音像制品制作，文艺创作与表
              演，广告发布，照明工程设计，从事货物及
              技术进出口业务。【依法须经批准的项目，
              经相关部门批准后方可开展经营活动】
              许可项目：建设工程施工；建设工程设
              计；建设工程施工（除核电站建设经营、民
              用机场建设）；建筑智能化系统设计；音像
              制品制作；营业性演出。（依法须经批准的
              项目，经相关部门批准后方可开展经营活
              动，具体经营项目以相关部门批准文件或许
              可证件为准） 
              一般项目：园林绿化工程施工；技术服
              务、技术开发、技术咨询、技术交流、技术
              转让、技术推广；储能技术服务；城乡市容
              管理；照明器具销售；照明器具生产专用设
              备销售；文艺创作；广告发布；技术进出
              口；货物进出口；太阳能发电技术服务；光
              伏设备及元器件销售；太阳能热利用装备销
              售；太阳能热利用产品销售；建筑装饰材料
              销售；太阳能热发电装备销售；光伏设备及
              元器件制造；太阳能热发电产品销售；半导
              体器件专用设备制造；半导体器件专用设备
              罗曼股份 2023 年第一次临时股东大会会议资料 
              7
              销售；电气设备销售；机械电气设备销售；
              机械设备销售；电子元器件与机电组件设备
              销售；智能车载设备销售；工业控制计算机
              及系统销售。（除依法须经批准的项目外，
              凭营业执照依法自主开展经营活动） 
              本次经营范围的变更最终以市场监督管理部门核准的结果为准。
              二、修订《公司章程》具体情况 
              鉴于前述经营范围发生变更事项，公司拟对《公司章程》中对应的经营范围
              条款进行修订，具体修订情况如下：
              修订前 修订后 
              第十三条 经依法登记，公司经营范围
              是：城市及道路照明工程专业施工，建筑装
              修装饰建设工程专业设计及施工，建筑智能
              化建设工程专业设计及施工，园林及古建筑
              建设工程专业设计及施工，建筑幕墙建设工
              程专业设计及施工，环保及节能科技领域技
              术服务，承接各类泛光照明设计、安装、调
              试，经销各类特种灯泡、灯泡、灯具、电器
              箱、触发器，音像制品制作，文艺创作与表
              演，广告发布，照明工程设计，从事货物和
              技术进出口业务。【依法须经批准的项目，
              经相关部门批准后方可开展经营活动】
              第十三条 经依法登记，公司经营范
              围是：许可项目：建设工程施工；建设工程
              设计；建设工程施工（除核电站建设经营、
              民用机场建设）；建筑智能化系统设计；音
              像制品制作；营业性演出。（依法须经批准
              的项目，经相关部门批准后方可开展经营活
              动，具体经营项目以相关部门批准文件或许
              可证件为准） 
              一般项目：园林绿化工程施工；技术服
              务、技术开发、技术咨询、技术交流、技术
              转让、技术推广；储能技术服务；城乡市容
              管理；照明器具销售；照明器具生产专用设
              备销售；文艺创作；广告发布；技术进出
              口；货物进出口；太阳能发电技术服务；光
              伏设备及元器件销售；太阳能热利用装备销
              售；太阳能热利用产品销售；建筑装饰材料
              销售；太阳能热发电装备销售；光伏设备及
              元器件制造；太阳能热发电产品销售；半导
              罗曼股份 2023 年第一次临时股东大会会议资料 
              8
              体器件专用设备制造；半导体器件专用设备
              销售；电气设备销售；机械电气设备销售；
              机械设备销售；电子元器件与机电组件设备
              销售；智能车载设备销售；工业控制计算机
              及系统销售。（除依法须经批准的项目外，
              凭营业执照依法自主开展经营活动）
              具体经营范围表述以上海市市场监督管理部门核准的内容为准。除上述条款
              修订外，《公司章程》其他条款不变。
              以上议案提请公司股东大会审议，并提请股东大会授权公司管理层办理《公
              司章程》备案、营业执照变更登记等相关事宜。
              请各位股东及股东代表审议。"
            readonly
            size="mini"
            style="width: 100%; font-size: 12px"
            rows="6"
            show-word-count
          ></vxe-textarea>
        </div>
        <h5 class="text-dark">处理结果：</h5>
        <div style="font-size: 12px; padding: 8px">
          这段文字描述了一家公司变更其经营范围的情况。具体而言，该公司拟对其经营范围进行增项，并根据上海市市场监督管理部门的要求做出调整。变更后的经营范围包括许可项目（如建设工程施工、音像制品制作等）和一般项目（如园林绿化工程施工、文艺创作等）。<br />
          作为一名基金经理，对这个公司变更经营范围的情况有以下关注点：<br />
          1.变更后的经营范围：需要了解变更后公司的主营业务是什么，以及这种变更是否对公司的经营有利。<br />
          2.市场需求：需要评估变更后公司所处行业的市场需求情况，以确定公司的发展前景。<br />
          3.监管情况：需要了解相关部门对变更后公司经营范围的监管情况，以确保公司经营合法。<br />
          总的来说，公司变更经营范围可能影响公司的经营状况和发展前景，因此基金经理需要对此进行关注。<br />
        </div>
      </div>
      <div
        class="pa-1"
        style="
          width: calc(100% - 450px);
          margin-left: 310px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        :style="{ minHeight: pageHeight - 100 + 'px' }"
      >
        <div
          style="width: 100%; min-height: 100%"
          v-if="responseContent && !loading"
        >
          <div
            v-html="responseContent"
            style="white-space: pre-line; width: 100%; padding: 20px"
          ></div>
          <vxe-button
            style="margin: 20px"
            v-if="responseContent"
            status="primary"
            @click="nextParagraph"
            >下一段</vxe-button
          >
          <span style="color: #bbb; font-size: 13px"
            >（获取被分段的文本或者让AI解释更多）</span
          >
        </div>
        <div
          class="text-dark"
          style="font-size: 30px; opacity: 0.7"
          v-if="!responseContent || loading"
        >
          <div v-if="!loading">
            <span style="font-weight: bolder">AI</span>
            <span style="font-weight: lighter"> Assistant For </span>
            <span style="color: #d80d18; font-weight: bolder">Knight</span>
          </div>
          <div v-if="loading" class="is-shining">
            <span style="font-weight: bolder">AI</span>
            <span style="font-weight: lighter"> is </span>
            <span style="color: #d80d18; font-weight: bolder">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ai from '@/api/ai.js';
export default {
  name: 'FocusInText',
  components: {},
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      loading: false,
      text: '',
      responseContent: '',
      conversationId: '',
      parentMessageId: '',
    };
  },
  mounted() {},
  methods: {
    submit() {
      if (!this.text) {
        this.showToast('请输入待处理的文本！', 'warning', 5000);
        return;
      }
      this.loading = true;
      Ai.ask({
        type: 'unOfficial',
        promptPrefix:
          '我是一名基金经理。列出下面的上市公司公告中需要我关注的重点内容，尤其是与股价和投资决策相关的内容，并总结和解释原因，用中文回答我。',
        message: this.text,
      })
        .then((res) => {
          console.log(res.data);
          this.conversationId = res.data.response.conversationId;
          this.parentMessageId = res.data.response.id;
          if (!res.data.hasOwnProperty('response')) {
            this.showToast(
              '服务器处理失败，请重新点击提交按钮！',
              'danger',
              5000
            );
          } else {
            this.responseContent = res.data.response.text;
          }
        })
        .catch((err) => {
          this.showToast(
            '服务器处理失败，请重新点击提交按钮！',
            'danger',
            5000
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    nextParagraph() {
      this.loading = true;
      Ai.ask({
        type: 'Official',
        message: '继续',
        conversationId: this.conversationId,
        parentMessageId: this.parentMessageId,
      })
        .then((res) => {
          console.log(res.data);
          if (!res.data.hasOwnProperty('response')) {
            this.showToast(
              '下一段获取失败，请重新点击下一段按钮按钮！',
              'danger',
              5000
            );
          } else {
            this.responseContent += res.data.response.text;
          }
        })
        .catch((err) => {
          this.showToast(
            '下一段获取失败，请重新点击下一段按钮按钮！',
            'danger',
            5000
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.is-shining {
  animation: shining 1s ease-in-out infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
