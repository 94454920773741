import { render, staticRenderFns } from "./Trading.vue?vue&type=template&id=ca94d816&scoped=true"
import script from "./Trading.vue?vue&type=script&lang=js"
export * from "./Trading.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca94d816",
  null
  
)

export default component.exports