import { render, staticRenderFns } from "./MomDetailTable.vue?vue&type=template&id=20e7e818&scoped=true"
import script from "./MomDetailTable.vue?vue&type=script&lang=js"
export * from "./MomDetailTable.vue?vue&type=script&lang=js"
import style0 from "./MomDetailTable.vue?vue&type=style&index=0&id=20e7e818&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e7e818",
  null
  
)

export default component.exports