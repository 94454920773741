<template>
  <div>
    <div
      :id="'mainFanChart_' + iframeData.parent"
      :ref="'mainFanChart_' + iframeData.parent"
      style="height: 540px; width: 780px; z-index: 10"
    ></div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import FanChartChild from "./FanChartChild.vue";
export default {
  name: "FanChart",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      myChart: null,
      chartData: [],
      legends: [],
    };
  },
  mounted() {
    let chartDom = this.$refs["mainFanChart_" + this.iframeData.parent];
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    this.getChartData();

    this.myChart.getZr().on("click", (params) => {
      //console.log(params.topTarget.type);
      if (params.topTarget) {
        //这里折线图的线和点是不同组件，获取seriesIndex方法不同
        let seriesIndex;
        //点击线
        if (params.topTarget.type === "ec-polyline") {
          seriesIndex = params.topTarget.parent.parent.__ecComponentInfo.index;
        }
        //点击拐点
        else if (params.topTarget.type === "path") {
          //console.log(params.target.parent.parent);
          seriesIndex =
            params.target.parent.parent.parent.__ecComponentInfo.index;
        }
        //点击markline
        else if (params.topTarget.type === "ec-line") {
          //console.log(params.target.parent.parent.parent);
          if (params.target.parent.parent.__ecComponentInfo.index) {
            seriesIndex = params.target.parent.parent.__ecComponentInfo.index;
          } else {
            seriesIndex =
              params.target.parent.parent.parent.__ecComponentInfo.index;
          }
        }
        //得到点击的seriesIndex就可以写业务代码了
        if (seriesIndex) {
          //console.log(this.chartData[seriesIndex][0].industry_id);
          this.$layer.iframe({
            content: {
              content: FanChartChild,
              parent: this,
              data: {
                iframeData: {
                  parent: this.chartData[seriesIndex][0].industry_id,
                },
              },
            },
            area: ["800px", "600px"],
            title:
              this.chartData[seriesIndex][0].industry_name +
              "--子级行业相对轮动图",
            maxmin: true,
            shade: true,
            shadeClose: false,
            cancel: () => {
              //关闭弹窗事件
            },
          });
        }
      }
    });

    this.myChart.on("legendselectchanged", (obj) => {
      console.log(obj);
      //点击之后所有被选中的图例
      const selectedobj = Object.keys(obj.selected).filter(
        (item) => obj.selected[item]
      );
      //点击的图例是否在 所有被选中的图例 中，也就是判断当前点击是选中操作还是取消操作
      const flag = selectedobj.indexOf(obj.name) > -1;
      //当是取消操作 && 取消前所有图例都是选中状态
      //选中当前图例，取消选中剩余图例
      if (!flag && selectedobj.length + 1 === this.legends.length) {
        for (let i = 0; i < this.legends.length; i++) {
          // 显示当前legend 关闭非当前legend
          if (obj.name === this.legends[i].name) {
            this.myChart.dispatchAction({
              type: "legendSelect",
              name: this.legends[i].name,
            });
          } else {
            this.myChart.dispatchAction({
              type: "legendUnSelect",
              name: this.legends[i].name,
            });
          }
        }
      }
      //当是取消操作 && 取消后就没有图例是选中状态
      //选中所有图例
      if (!flag && !selectedobj.length) {
        for (let i = 0; i < this.legends.length; i++) {
          this.myChart.dispatchAction({
            type: "legendAllSelect",
          });
        }
      }
    });
  },
  methods: {
    getChartData() {
      this.tableLoading = true;
      ResonanceContrast.getFanChart(this.iframeData.parent)
        .then((res) => {
          console.log(res.data);
          //过滤数组长度小于4的数据
          this.chartData = res.data.filter((item) => item.length > 3);
          this.initChart();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    initChart() {
      let series = [];
      this.chartData.forEach((item) => {
        this.legends.push({
          name: item[0].industry_name,
          icon: "circle",
        });
        //console.log(item);
        let currSeries = {
          name: item[0].industry_name,
          type: "line",
          // color: [
          //   '#096631',
          //   '#0f86c3',
          //   '#ffdf01',
          //   '#dd801e',
          //   '#b84747',
          //   '#9758e0',
          //   '#eecf85',
          //   '#0c424b',
          //   '#31baff',
          //   '#533527',
          //   '#7dc9ce',
          //   '#2ec2d7',
          // ],
          data: [
            {
              value: [item[0].relative_strength, item[0].relative_strength_mom],
              trade_date: item[0].trade_date,
              industry_name: item[0].industry_name,
              tag: item[0].tag,
            },
            {
              value: [item[1].relative_strength, item[1].relative_strength_mom],
              trade_date: item[1].trade_date,
              industry_name: item[1].industry_name,
              tag: item[1].tag,
            },
            {
              value: [item[2].relative_strength, item[2].relative_strength_mom],
              trade_date: item[2].trade_date,
              industry_name: item[2].industry_name,
              tag: item[2].tag,
            },
            {
              value: [item[3].relative_strength, item[3].relative_strength_mom],
              trade_date: item[3].trade_date,
              industry_name: item[3].industry_name,
              tag: item[3].tag,
            },
            {
              value: [item[4].relative_strength, item[4].relative_strength_mom],
              trade_date: item[4].trade_date,
              industry_name: item[4].industry_name,
              tag: item[4].tag,
            },
            {
              value: [item[5].relative_strength, item[5].relative_strength_mom],
              trade_date: item[5].trade_date,
              industry_name: item[5].industry_name,
              tag: item[5].tag,
              symbolSize: 2,
            },
          ],
          markLine: {
            zlevel: 0,
            silent: true,
            data: [
              [
                {
                  coord: [
                    item[4].relative_strength,
                    item[4].relative_strength_mom,
                  ],
                },
                {
                  coord: [
                    item[5].relative_strength,
                    item[5].relative_strength_mom,
                  ],
                },
              ],
            ],
            symbol: ["none", "arrow"],
            emphasis: { disabled: false },
          },
          emphasis: {
            focus: "series",
            blurScope: "coordinateSystem",
          },
          tooltip: {
            padding: 10,
            borderWidth: 1,
            // triggerOn: 'none', //* 提示框触发的条件,这个配置会覆盖echarts默认的鼠标事件*
            trigger: "item",
            formatter: (params) => {
              //console.log(params);
              return `
              <b>标签</b> : ${params.data.industry_name}<br/>
              <b>趋势</b> : ${params.data.tag}<br/>
              <b>相对强弱</b> : ${params.data.value[0].toFixed(2)}<br/>
              <b>相对强弱动量</b> : ${params.data.value[1].toFixed(2)}<br/>
              <b>日期</b> : ${params.data.trade_date}<br/>
              `;
            },
          },
        };
        series.push(currSeries);
      });

      let option = {
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "value",
        },
        series: series,
        legend: {
          type: "scroll",
          data: this.legends,
          zlevel: 100,
          right: "10px",
          top: "20px",
          orient: "vertical",
        },
        tooltip: {
          padding: 10,
          borderWidth: 1,
          // triggerOn: 'none',
          trigger: "item",
          formatter: (params) => {
            return "123";
          },
        },
        grid: {
          top: "20px",
          left: "20px",
          right: "100px",
          bottom: "20px",
          containLabel: true,
        },
        graphic: [
          {
            type: "group",
            left: 50,
            top: 30,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#fef1f3",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "走强",
                  fill: "#c12c1f",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            right: 130,
            top: 30,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#fef1f3",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "领涨",
                  fill: "#c12c1f",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            left: 50,
            bottom: 50,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#eefff6",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "领跌",
                  fill: "#09b3bc",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
          {
            type: "group",
            right: 130,
            bottom: 50,
            children: [
              {
                type: "rect",
                style: {
                  fill: "#eefff6",
                },
                shape: {
                  width: 50,
                  height: 30,
                  r: 6,
                },
                zlevel: 10,
                x: 0,
                y: 0,
              },
              {
                type: "text",
                style: {
                  text: "走弱",
                  fill: "#09b3bc",
                  textAlign: "center",
                  textVerticalAlign: "middle",
                  fontSize: 17,
                },
                zlevel: 11,
                x: 25,
                y: 17,
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
