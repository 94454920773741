import Request from '@/components/request/js/index.js';

export default {
  //获取股票推荐列表
  getStockRecommendList() {
    return Request().get('/api/stock_recommend', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //新增股票推荐
  addStockRecommend(data) {
    return Request().post('/api/stock_recommend', {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },
  //修改股票推荐
  editStockRecommend(id, data) {
    return Request().post('/api/stock_recommend/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },
  //删除股票推荐
  deleteStockRecommend(id) {
    return Request().post('/api/stock_recommend/' + id + '/delete', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //结束股票推荐
  endStockRecommend(id) {
    return Request().post('/api/stock_recommend/' + id + '/end', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //获取研究员列表
  getUserList(newData = null) {
    let data = {};
    if (newData) {
      data.data = newData;
    }
    return Request().post('/api/stock_recommend/user_income', {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },
};
