import { render, staticRenderFns } from "./Bing.vue?vue&type=template&id=c3d390b2"
import script from "./Bing.vue?vue&type=script&lang=js"
export * from "./Bing.vue?vue&type=script&lang=js"
import style0 from "./Bing.vue?vue&type=style&index=0&id=c3d390b2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
