import Request from "@/components/request/js/index.js";
import { dataURItoBlob } from "dropzone";

export default {
  //时间轴列表
  getTimelineList(month,search,hot) {
    let data = {}
    if(hot){
      data = {date: month,search: encodeURIComponent(search),hot:hot}
    }else{
      data = {date: month,search: encodeURIComponent(search)}
    }
    return Request().get("/api/timeline/list", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //时间轴新增
  newTimeline(data) {
    return Request().post('/api/timeline', {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },

  //修改时间轴
  editTimeline(data) {
    return Request().post('/api/timeline/' + data.id, {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },
   //删除时间轴
   deleteTimeline(id) {
    return Request().post(`/api/timeline/${id}/delete`, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
 
};
