<template>
  <div>
    <v-container fluid class="px-6 py-1">
      <v-tabs-items v-model="currentTab">
        <v-tab-item :key="0">
          <!-- <iframe
            style="border: 0px; width: 100%; height: calc(100vh - 100px)"
            src="https://static-e3ed79ac-125c-466f-ae3c-4671c60e16e0.bspapp.com/%E6%AF%8F%E6%97%A5%E6%A6%82%E5%BF%B5%E8%B7%9F%E8%B8%AA.html"
          ></iframe> -->
          <Momentum ref="momentum"></Momentum>
        </v-tab-item>

        <v-tab-item :key="1">
          <Hotspot></Hotspot>
        </v-tab-item>

        <v-tab-item :key="2">
          <GoldStock></GoldStock>
        </v-tab-item>

        <v-tab-item :key="3">
          <Radar></Radar>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Momentum from "@/components/ResonanceContrast/Momentum/Momentum";
import Hotspot from "@/components/ResonanceContrast/Hotspot/Hotspot";
import GoldStock from "@/components/ResonanceContrast/GoldStock/GoldStock";
import Radar from "@/components/ResonanceContrast/Radar/Radar";
export default {
  name: "ResonanceContrast",
  components: { Momentum, Hotspot, GoldStock, Radar },
  data() {
    return {
      currentTab: 0,
      pageHeight: 500,
    };
  },
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    Bus.$on("ResonanceContrastTabChange", (e) => {
      this.currentTab = e;
      //console.log(e);
    });
    if (this.$route.params.id) {
      //console.log(this.$route.params.id);
      if (this.$route.params.id == "MomentumQingli") {
        this.currentTab = 0;
        setTimeout(() => {
          this.$refs.momentum.jumpTo(4);
        }, 500);
      }
    }
  },
};
</script>
