<template>
  <v-container fluid class="px-6 py-1">
    <v-card
      class="border-radius-xl mt-3 pt-0 pb-4"
      v-if="this.$commonHelpers.getPermissions('portfolio.external')"
    >
      <PortfolioList :tableHight="tableHeight" :isOut="true"></PortfolioList>
    </v-card>

    <v-card
      class="border-radius-xl mt-2 pt-0 pb-4"
      v-if="this.$commonHelpers.getPermissions('portfolio.insiders')"
    >
      <PortfolioList :tableHight="tableHeight" :isOut="false"></PortfolioList>
    </v-card>
  </v-container>
</template>

<script>
import PortfolioList from '@/components/Portfolio/PortfolioList';

import Bus from '@/components/Bus/bus.js';
export default {
  name: 'Portfolio',
  components: {
    PortfolioList,
  },
  computed: {},
  data() {
    return {
      tableHeight: '500',
      isAdmin:
        this.$commonHelpers.getPermissions('portfolio.external') &&
        this.$commonHelpers.getPermissions('portfolio.insiders'),
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.tableHeight = (document.body.offsetHeight - 500) / 2;
    } else {
      this.tableHeight = document.body.offsetHeight - 160;
    }
  },
  methods: {},
};
</script>
