import Request from "@/components/request/js/index.js";

export default {
  //更改api地址
  changeApi(url) {
    Request().setBaseUrl(url);
  },
  //获取封面
  getCover() {
    return Request().get("/api/today", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //登录
  login(name, password) {
    return Request().post("/api/auth/login", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
        password: password,
      },
    });
  },
  //获取用户列表
  getUserList(type = 0, status = -1) {
    let data = {};
    if (type !== 0) {
      data.type = type;
    }
    if (status !== -1) {
      data.status = status;
    }
    return Request().get("/api/user/list", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //获取用户信息
  getUserInfo() {
    return Request().get("/api/user/info", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //删除用户
  deleteUser(id) {
    return Request().post("/api/user/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "DELETE",
      },
    });
  },
  //禁用或启用用户
  banUser(id, status) {
    return Request().post("/api/user/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "PATCH",
        status: status,
      },
    });
  },
  //角色列表
  getRoleList() {
    return Request().get("/api/role/list", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //新增用户
  newUser(info) {
    return Request().post("/api/user", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...info,
      },
    });
  },
  //修改用户
  editUser(id, info) {
    return Request().post("/api/user/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "PATCH",
        ...info,
      },
    });
  },
  //更改密码
  changePassword(name, old_password, password) {
    return Request().post("/api/user/password", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
        old_password: old_password,
        password: password,
      },
    });
  },
  //首页信息流
  getInfoList(data) {
    return Request().post("/api/index", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //自选个股标签列表
  collect() {
    return Request().get("/api/collect", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },

  //收藏股票
  stock(data) {
    return Request().post("/api/collect/stock", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //收藏标签
  race(data) {
    return Request().post("/api/collect/race", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //删除股票
  stockDelete(id) {
    return Request().post(`/api/collect/stock/${id}/delete`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //删除标签
  raceDelete(id) {
    return Request().post(`/api/collect/race/${id}/delete`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
