<template>
  <div class="px-0 py-1">
    <h3 class="text-dark">{{ title }}</h3>
    <vxe-table
      id="topList"
      style="width: 100%"
      :data="topList"
      class="my-scrollbar"
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="topList"
      :loading="topLoading"
      :height="'280px'"
      size="mini"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
      :custom-config="{ storage: true }"
      :filter-config="{ showIcon: false }"
      :expand-config="{ trigger: 'row' }"
    >
      <vxe-column title="股票" type="seq" min-width="80" field="index">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.index ? row.index : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="35" field="Score" title="得分">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.Score ? row.Score : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="35" field="Vol_ratio" title="量比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.Vol_ratio ? row.Vol_ratio : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="45" field="Num_ratio" title="笔数比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.Num_ratio ? row.Num_ratio : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="45" field="normal_flow" title="挂单比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.normal_flow ? row.normal_flow : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="55" field="active_flow" title="主动单比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.active_flow ? row.active_flow : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="55" field="price_bias" title="挂单价比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.price_bias ? row.price_bias : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="55" field="momentum" title="日内动量">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.momentum ? row.momentum : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="55" field="order_imba" title="十档量比">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.order_imba ? row.order_imba : "" }}
          </div>
        </template>
      </vxe-column>
    </vxe-table>

    <h3 class="text-dark">交易记录</h3>
    <vxe-table
      id="tradeList"
      style="width: 100%"
      :data="tradeList"
      class="my-scrollbar"
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tradeList"
      :loading="tradeLoading"
      :height="'280px'"
      size="mini"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
      :custom-config="{ storage: true }"
      :filter-config="{ showIcon: false }"
      :expand-config="{ trigger: 'row' }"
    >
      <vxe-column min-width="80" field="name" title="股票">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.name ? row.name : "" }}
            <span v-if="row.code">({{ row.code }})</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        title="交易时间"
        type="seq"
        min-width="120"
        field="local_time"
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.local_time ? row.local_time : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="45" field="price" title="价格">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.price ? row.price : "" }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="45" field="shares" title="数量">
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.shares ? row.shares : "" }}
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Quant from "@/api/quant";
import Bus from "@/components/Bus/bus.js";
import RaceFilter from "@/components/StockPool/filter/RaceFilter";
import XEUtils from "xe-utils";
export default {
  name: "Quant",
  props: {
    title: "",
  },
  components: { RaceFilter },
  data() {
    return {
      topLoading: false,
      tradeLoading: false,
      topList: [],
      tradeList: [],
      showMulti: false,
      filterName: "",
    };
  },
  mounted() {
    this.getData();
    Bus.$on("refreshQuantData", (e) => {
      this.getData();
    });
  },
  computed: {},
  methods: {
    getData() {
      this.topLoading = true;
      Quant.getTopList("normalType").then((res) => {
        this.topList = [
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
          { index: "123" },
        ];
        this.topLoading = false;
      });
      this.tradeLoading = true;
      Quant.getTradeList("normalType").then((res) => {
        console.log(res.data.data);
        this.tradeList = res.data.data;
        this.tradeLoading = false;
      });
      this.topList = [];
    },
  },
};
</script>
<style scoped></style>
