var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-1 py-1",attrs:{"fluid":""}},[_c('v-card',{staticClass:"border-radius-xl mt-3 pb-2"},[_c('vxe-toolbar',{ref:"coreToolbar",staticClass:"px-3",attrs:{"custom":"","refresh":{ query: _vm.getResearchList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"size":"medium","content":"新建调研","status":"my-primary"},on:{"click":function($event){return _vm.addResearch()}}}),_c('UserFilter',{staticClass:"mr-1",attrs:{"colName":"user_name","placeholder":"申请人","table":_vm.$refs.coreTable,"inputWidth":"100px"}}),_c('UserFilter',{staticClass:"mr-1",attrs:{"colName":"audit_name","placeholder":"审核人","table":_vm.$refs.coreTable,"inputWidth":"100px"}}),_c('StatusFilter',{staticClass:"mr-1",attrs:{"colName":"status_name","placeholder":"流程状态","table":_vm.$refs.coreTable,"inputWidth":"100px"}})]},proxy:true}])}),_c('vxe-table',{ref:"coreTable",attrs:{"id":"coreTable","row-id":"id","data":_vm.tableData,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.coreTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight,"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 50, oSize: 30 },"empty-render":{ name: 'NotData' },"custom-config":{ storage: true },"tooltip-config":{ showAll: false, enterable: true, theme: 'dark' },"filter-config":{ showIcon: false },"export-config":{}}},[_c('vxe-column',{attrs:{"width":"100","field":"user_name","title":"申请人","sortable":"","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'user_name'); }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.user_name)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"created_at","title":"申请时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('span',{style:(_vm.$stockHelpers.getDateColor(row.created_at.split(' ')[0]))},[_vm._v(_vm._s(row.created_at.split(' ')[0]))])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"research_date","title":"调研时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(" "+_vm._s(row.research_date)+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"stock_code","title":"股票代码","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_code ? row.stock_code : '-'))])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"stock_name","title":"股票名称","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.stock_name ? row.stock_name : '-'))])])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"file_id","title":"申请附件"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.previewFile(row.file_id)}}},[_vm._v("预览")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.downloadFile(row.file_id)}}},[_vm._v("下载")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.uploadFile('before', row.id, true)}}},[_vm._v("上传")])])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"comment","title":"申请说明"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.comment ? row.comment : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"audit_name","title":"审核人","sortable":"","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'audit_name'); }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticStyle:{"word-break":"keep-all","white-space":"pre-line","text-align":"start"}},[_vm._v(" "+_vm._s(row.audit_name ? row.audit_name : '-')+" ")])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"audit_at","title":"审核时间","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.audit_at ? row.audit_at.split(' ')[0] : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"audit_comment","title":"审核批注"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.audit_comment ? row.audit_comment : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"100","field":"research_file_id","title":"调研纪要"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[(row.research_file_id)?_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.previewFile(row.research_file_id)}}},[_vm._v(" 预览 ")]):_vm._e(),(row.research_file_id)?_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$commonHelpers.downloadFile(row.research_file_id)}}},[_vm._v(" 下载 ")]):_vm._e(),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.uploadFile(
                    'after',
                    row.id,
                    row.research_file_id ? true : false
                  )}}},[_vm._v("上传")])])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"status_name","title":"流程进度","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'status_name'); }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                  var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center"},[(row.status === 0)?_c('span',[(!_vm.$commonHelpers.getPermissions('research.apply'))?_c('span',{staticStyle:{"color":"#0000ff"}},[_vm._v("待审核")]):_c('span',[_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.apply(row.id)}}},[_vm._v("审核")])])]):_vm._e(),(row.status === 1)?_c('span',[(_vm.$commonHelpers.getPermissions('research.apply'))?_c('span',{staticStyle:{"color":"#008000"}},[_vm._v("已通过")]):_c('span',[_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.finishResearch(row.id)}}},[_vm._v("标记完成")])])]):_vm._e(),(row.status === 2)?_c('span',{staticStyle:{"color":"#800000"}},[_vm._v("已驳回")]):_vm._e(),(row.status === 3)?_c('span',{staticStyle:{"color":"#008000"}},[_vm._v("已完成")]):_vm._e()])]}}])})],1)],1),_c('v-file-input',{staticStyle:{"display":"none"},attrs:{"id":"ResearchAttachmentSelect","prepend-icon":""},model:{value:(_vm.fileSelect),callback:function ($$v) {_vm.fileSelect=$$v},expression:"fileSelect"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }