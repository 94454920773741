<template>
  <div>
    <v-container fluid class="px-6 py-1">
      <v-tabs-items v-model="currentTab">
        <v-tab-item :key="0">
          <Additional></Additional>
        </v-tab-item>

        <v-tab-item :key="1">
          <Lifting></Lifting>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import Additional from '@/components/AdditionalAndLifting/Additional';
import Lifting from '@/components/AdditionalAndLifting/Lifting';
export default {
  name: 'AdditionalAndLifting',
  components: { Additional, Lifting },
  data() {
    return {
      currentTab: 0,
    };
  },
  mounted() {
    Bus.$on('AdditionalAndLiftingTabChange', (e) => {
      this.currentTab = e;
      //console.log(e);
    });
  },
};
</script>
