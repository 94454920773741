<template>
  <div v-html="appStore.printContent"></div>
</template>

<script>
export default {
  name: 'StockDetail',
  components: {},
  data() {
    return {};
  },
  mounted() {
    console.log(this.appStore.printContent);
    this.showLoading('请稍候！');
    setTimeout(() => {
      this.hideLoading();
      setTimeout(() => {
        this.hideLoading();
        window.print();
      }, 500);
    }, 500);
  },
  methods: {},
};
</script>
<style>
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>
