<template>
  <div class="px-0 py-1">
    <v-card
      class="card-shadow border-radius-md"
      style="position: fixed; width: 300px"
    >
      <div class="px-4 py-3">
        <h3 class="text-dark pt-2 px-2">功能模块</h3>
        <v-list>
          <v-list-item-group class="border-radius-sm" v-model="currentTab">
            <v-list-item
              class="px-2 border-radius-sm mb-1"
              v-for="(item, index) in menu"
              :key="index"
              @click="currentTab = index"
            >
              <v-list-item-content class="py-0">
                <a class="text-decoration-none">
                  <div class="d-flex flex-column">
                    <span class="mb-1 text-body text-sm">{{ item.title }}</span>
                  </div>
                </a>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card>

    <v-card
      class="border-radius-md pa-3 mb-3"
      style="width: calc(100vw - 320px); margin-left: 320px"
      :style="{ minHeight: pageHeight + 'px' }"
    >
      <FocusInText
        v-if="currentTab === 0"
        :pageHeight="pageHeight"
      ></FocusInText>

      <AnnouncementAnalysis
        v-if="currentTab === 1"
        :pageHeight="pageHeight"
      ></AnnouncementAnalysis>
    </v-card>
  </div>
</template>

<script>
import FocusInText from "@/components/Ai/Assistant/FocusInText";
import AnnouncementAnalysis from "@/components/Ai/Assistant/AnnouncementAnalysis";

export default {
  name: "Assistant",
  components: { FocusInText, AnnouncementAnalysis },
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      currentTab: 0,
      menu: [
        {
          title: "AI总结会议纪要",
        },
        {
          title: "公告分析",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style></style>
