<template>
  <div class="">
    <div
      style="overflow: hidden"
      :style="{ maxHeight: displayMore ? '999999px' : '100px' }"
    >
      <span
        class="desc"
        ref="content"
        v-html="content"
        style="white-space: pre-line"
      ></span>
    </div>
    <a
      href="javascript:;"
      v-show="showButton"
      style="color: #d80d18"
      v-text="displayMore ? '收起' : '显示全文'"
      @click="displayMore = !displayMore"
    ></a>
  </div>
</template>

<script>
export default {
  name: 'ShowMoreText',
  props: {
    content: '',
  },
  data() {
    return {
      showButton: false,
      displayMore: false,
    };
  },
  mounted() {
    this.getShowMore();
  },
  methods: {
    getShowMore() {
      const height = this.$refs.content ? this.$refs.content.clientHeight : 0; //根据元素的clientheight来判断
      if (height > 85) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
      //console.log(this.showButton);
    },
  },
  watch: {},
};
</script>

<style>
.desc {
  /* max-height: 80px; */
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-climp: 4;
  overflow: hidden;
}
</style>
