<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*账号</label
        >
        <vxe-input
          v-model="submitInfo.name"
          style="width: 100%"
          placeholder="用于登录的用户名"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*密码</label
        >
        <vxe-input
          v-model="submitInfo.password"
          style="width: 100%"
          placeholder="登录用的密码"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*名称</label
        >
        <vxe-input
          v-model="submitInfo.base"
          style="width: 100%"
          placeholder="用户在系统里的名称"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >代号</label
        >
        <vxe-input
          v-model="submitInfo.depot"
          style="width: 100%"
          placeholder="用户在系统里的代号,用于信息安全与合规"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >联系方式</label
        >
        <vxe-input
          v-model="submitInfo.contact"
          style="width: 100%"
          placeholder="用户的联系方式"
        ></vxe-input>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >研究行业</label
        >
        <Shenwan2Select
          v-model="submitInfo.sw_level2"
          class="my-2"
          placeholder="用户研究的行业，非必选"
        ></Shenwan2Select>
      </div>

      <div class="d-flex align-center justify-center mt-2">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >*角色</label
        >
        <vxe-select
          v-model="submitInfo.roles"
          style="width: 100%"
          placeholder="用户将拥有角色对应的权限"
          multiple
        >
          <vxe-option
            :value="item.id"
            :label="item.name"
            v-for="item in roleArr"
          ></vxe-option>
        </vxe-select>
      </div>

      <div class="my-4 d-flex justify-space-between" style="width: 100%">
        <RoleDesc></RoleDesc>
        <div>
          <vxe-button status="primary" @click="submit">提交</vxe-button>
          <vxe-button status="warning" @click="cancel">取消</vxe-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Bus from '@/components/Bus/bus.js';
import StockPool from '@/api/stockPool.js';
import User from '@/api/user.js';
import RoleDesc from '@/components/Admin/userManage/RoleDesc';
import Shenwan2Select from '@/components/CommonSelect/Shenwan2Select.vue';
export default {
  name: 'UserAdd',
  components: {
    RoleDesc,
    Shenwan2Select,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: '',
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.getRoleList();
  },
  data() {
    return {
      isOtc: this.iframeData.isOtc,
      submitInfo: {
        name: '',
        password: '111111',
        type: 1,
        base: '',
        depot: '',
        contact: '',
        sw_level2: '',
        roles: [],
      },
      roleArr: [],
    };
  },
  methods: {
    submit() {
      //console.log(this.submitInfo);return;
      if (
        !this.submitInfo.name ||
        !this.submitInfo.base ||
        !this.submitInfo.password
      ) {
        this.showToast('请将信息填写完整！', 'warning', 5000);
        return;
      }
      if (this.submitInfo.roles.length == 0) {
        this.showToast('请选择角色！', 'warning', 5000);
        return;
      }
      if (
        this.submitInfo.roles.indexOf('5ce8b104-3490-492d-9c23-b16f6e305c60') !=
        -1
      ) {
        this.submitInfo.type = 2;
      }
      //console.log(this.submitInfo);return;
      this.showLoading('保存中，请稍候');
      User.newUser(this.submitInfo)
        .then((res) => {
          this.showToast('保存成功', 'success', 5000);
          Bus.$emit('refreshUserList', true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
    getRoleList() {
      User.getRoleList()
        .then((res) => {
          this.roleArr = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
