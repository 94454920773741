import Request from "@/components/request/js/index.js";
import { config } from "@/components/request/js/config.js";
export default {
  //取监控列表
  getTopList(type) {
    return Request().get("/api/external/Top10", {
      header: {
        contentType: "application/json",
      },
      data: {
        type: type,
      },
    });
  },
  //取交易列表
  getTradeList(type) {
    return Request().get("/api/external/AllOrders", {
      header: {
        contentType: "application/json",
      },
      data: {
        type: type,
      },
    });
  },
  //获取形态列表
  getStockPattern() {
    return Request().get(`/api/external/hcStockPattern`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
