<template>
	<v-container fluid class="px-1 py-0">
		<v-card class=" border-radius-xl mt-3 pb-2">

			<v-tabs-items v-model="currentTab">
				
				<v-tab-item
					:key="0"
				>
					<UserManage></UserManage>
				</v-tab-item>
				
				<v-tab-item
					:key="1"
				>
					<RateManage></RateManage>
				</v-tab-item>
				
				<!-- <v-tab-item
					:key="2"
				>
					<FactorManage></FactorManage>
				</v-tab-item> -->
				
			</v-tabs-items>
			
		</v-card>
		
		
	</v-container>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import FactorManage from "@/components/Admin/factorManage/factorManage";
import RateManage from "@/components/Admin/rateManage/rateManage";
import UserManage from "@/components/Admin/userManage/userManage";

export default {
	name: 'Manage',
	components: {FactorManage,RateManage,UserManage,},
	data() {
		return {
			currentTab:this.$commonHelpers.getPermissions('manage.user')?0:1,
			
		}
	},
	computed:{
		//动态引入组件
		
	},
	mounted() {
		Bus.$on('adminTabChange', e => {
			this.currentTab=e;
		});
	},
	methods: {
		
	},
};
</script>
