<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 100%">
      <div class="">
        <div style="width: 100%; border: 1px solid #ccc">
          <Toolbar
            editorId="ReviewEditor"
            :editor="editor"
            :defaultConfig="toolbarConfig"
          />
        </div>

        <Editor
          editorId="ReviewEditor"
          style="height: 500px; overflow-y: hidden; border: 1px solid #ccc"
          :defaultConfig="editorConfig"
          @onCreated="onCreated"
        />
      </div>

      <div class="my-4 mx-2">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Review from "@/api/review.js";
import Bus from "@/components/Bus/bus.js";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { SlateEditor } from "@wangeditor/editor";

import File from "@/api/file.js";
const OSS = require("ali-oss");
export default {
  name: "ReviewEditor",
  components: { Editor, Toolbar },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    if (this.iframeData.tagId) {
      this.setLock();
      this.lockInterval = setInterval(() => {
        this.setLock();
      }, 3000);
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器

    if (this.iframeData.tagId) {
      clearInterval(this.lockInterval);
      this.setUnlock();
    }
  },
  data() {
    return {
      submitInfo: {},
      lockInterval: null,

      editor: null,
      toolbarConfig: {
        toolbarKeys: [
          "bold",
          "underline",
          "italic",
          "clearStyle",
          "color",
          "bgColor",
          "|",
          "fontSize",
          {
            key: "group-justify", // 必填，要以 group 开头
            title: "对齐方式",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-link", // 必填，要以 group 开头
            title: "超级链接",
            menuKeys: ["insertLink", "editLink", "unLink", "viewLink"],
          },
          {
            key: "group-table", // 必填，要以 group 开头
            title: "表格",
            menuKeys: [
              "insertTable",
              "deleteTable",
              "insertTableRow",
              "deleteTableRow",
              "insertTableCol",
              "deleteTableCol",
              "tableHeader",
              "tableFullWidth",
            ],
          },
          "lineHeight",
          "divider",
          "blockquote",
          "headerSelect",
          "todo",
          "bulletedList",
          "numberedList",
        ],
      },
      editorConfig: {
        placeholder: "请输入内容",
        MENU_CONF: {
          uploadImage: {
            customUpload: (file, insertFn) => {
              this.customUpload(file, insertFn);
            },
          },
        },
      },
    };
  },
  methods: {
    setLock() {
      Review.setReviewLock(this.iframeData.tagId, this.iframeData.id)
        .then((res) => {
          console.log("上锁成功");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    setUnlock() {
      Review.setReviewUnlock(this.iframeData.tagId, this.iframeData.id)
        .then((res) => {
          console.log("解锁成功");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    submit() {
      if (this.editor.getText().length == 0) {
        this.showToast("请输入内容", "danger", 5000);
        return;
      }
      Bus.$emit("editDayReviewContent", {
        type: this.iframeData.type,
        content: this.editor.getHtml().replace("<p><br></p>", ""),
        tagId: this.iframeData.tagId,
        ...this.iframeData.extraInfo,
      });

      this.$layer.close(this.layerid);
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.editor.restoreSelection();
      const endPoint = SlateEditor.end(this.editor, []);
      this.editor.select(endPoint);
      this.editor.dangerouslyInsertHtml(this.iframeData.oldValue);
      //console.log(this.iframeData.oldValue);
    },
    customUpload(fileItem, insertFn) {
      //console.log(fileItem);
      File.getUploadSts()
        .then((res) => {
          this.status = "正在上传中";

          let info = res.data;
          let client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: "qinglitouyan",
          });

          let fileName = "fileStore/" + info.uuid + "/" + fileItem.name;

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
                this.editor.showProgressBar((p * 100).toFixed(2));
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf("uploadId") != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                "/fileStore/" + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  let url = `${File.getApiUrl()}/api/file/${
                    info.uuid
                  }/downloadDirect`;
                  insertFn(url, "插图", url);
                })
                .catch((err) => {
                  console.log("err:", err);
                });
            })
            .catch((err) => {
              console.log("err:", err);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
