<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-show="!tableLoading">
      <v-card class="border-radius-md pa-3 mb-3">
        <vxe-table
          :data="list"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          :scroll-y="{ enabled: false }"
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="AdditionalTable"
          :loading="tableLoading"
          max-height="100000"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :cell-style="cellStyle"
        >
          <vxe-column fixed="left" min-width="70" field="code" title="股票代码">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span v-if="row.in_pool">
                  <a
                    href="javascript:;"
                    @click="$stockHelpers.openStockDetail(row.in_pool)"
                    class="text-xs"
                    style="color: #00006f; font-weight: bolder"
                    >{{ row.code ? row.code : '-' }}</a
                  >
                </span>
                <span v-else>
                  <span>
                    {{ row.code ? row.code : '' }}
                  </span>
                </span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="70"
            field="sec_name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <span v-if="row.in_pool">
                  <a
                    href="javascript:;"
                    @click="$stockHelpers.openStockDetail(row.in_pool)"
                    class="text-xs"
                    style="color: #00006f; font-weight: bolder"
                    >{{ row.sec_name ? row.sec_name : '-' }}</a
                  >
                </span>
                <span v-else>
                  <span>
                    {{ row.sec_name ? row.sec_name : '' }}
                  </span>
                </span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="行业" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.industry_name }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="70"
            field="unlock_date"
            title="解禁日期"
            sortable
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.unlock_date ? row.unlock_date : '-' }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="收盘价" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.close }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="增发价格" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.holder_price_fellowon }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="股权激励行权价" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.holder_price_stockbasedcompensation }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="100"
            title="解禁股占总股本比例"
            sortable
            field="unlocknum_totalnum_ratio"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span>{{
                  $stockHelpers.getPercent(row.unlocknum_totalnum_ratio)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="70"
            title="解禁市值"
            sortable
            field="unlock_marketcap"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span>{{
                  $stockHelpers.getPercent(row.unlock_marketcap)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="200" title="解禁股份性质" sortable>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.unlock_type }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </v-card>
      <span style="color: #ccc; font-size: 12px">
        更新日期:{{ list[0] ? list[0].trade_date : '无' }}</span
      >
    </div>
  </div>
</template>

<script>
import AdditionalAndLifting from '@/api/additionalAndLifting.js';
export default {
  name: 'Additional',
  components: {},
  data() {
    return {
      tableLoading: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      AdditionalAndLifting.getLifting()
        .then((res) => {
          //console.log(res.data);
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, 'danger', 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    cellStyle({ row, rowIndex, column }) {
      //console.log(column.property);
      if (column.property === 'unlocknum_totalnum_ratio') {
        return {
          backgroundColor: row.unlock_color,
          color: '#000000',
        };
      }
    },
  },
};
</script>
