import Request from '@/components/request/js/index.js';

export default {
  //增发
  getAdditional() {
    return Request().get('/api/seoimplementation', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //解禁
  getLifting() {
    return Request().get('/api/details_of_companies_with_rsu', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
};
