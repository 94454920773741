<template>
	<div class="v-application bg-white" style="width: 100%;display: flex;justify-content: center;">
		<div style="min-height: 100%!important;width: 500px;">
			
			<div class="d-flex align-center justify-center pt-2">
				<label class="pt-0 mr-3" style="width: 100px;font-size: 14px;">股票</label>
				<div style="width: 100%;font-size: 14px;" class="pt-0">{{iframeData.stock.name}}-{{iframeData.stock.code}}</div>
			</div>
			
			<div class="d-flex align-center justify-center pt-2" style="height: 100px;">
				<label class="pt-0 mr-3" style="width: 100px;font-size: 14px;">*调至仓位</label>
				<Slider
					v-model="proportion"
					style="width: 100%;"
					:min="0"
					:max="30"
					class="slider-blue"
					:format="format"
				/>
			</div>
			
			<div class="d-flex align-center justify-center">
				<label class="pt-0" style="width: 100px;font-size: 14px;">调仓理由</label>
				<v-textarea
					outlined
					color="rgba(0,0,0,.6)"
					placeholder="调仓理由"
					class="
						font-size-input
						border
						text-light-input
						border-radius-sm
					"
					auto-grow
					rows="1"
					v-model="reason"
				></v-textarea>
			</div>
			
			<div class="my-4">
				<vxe-button status="primary" @click="submit">提交</vxe-button>
				<vxe-button status="warning" @click="cancel">取消</vxe-button>
			</div>
		</div>
	</div>
	
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Slider from '@vueform/slider/dist/slider.vue2'
import Portfolio from '@/api/portfolio.js';

import StockSelect from '@/components/CommonSelect/StockSelect.vue';
export default {
	name: 'AddStock',
	components: {
		StockSelect,Slider,
	},
	props: {
		layerid: {//自动注入的layerid
			type: String,
			default: ''
		},
		iframeData: {//传递的数据
			type: Object,
			default: () => {
				return {};
			}
		},
	},
	mounted() {
		//console.log(this.iframeData);
		this.oldProportion=this.iframeData.proportion*100;
		this.proportion=this.iframeData.proportion*100;
	},
	data() {
		return {
			stock_code:'',
			
			oldProportion:10,
			proportion:10,
			reason:'',

			format: function (value) {
				return `${value.toFixed(0)}%`
			}
		};
	},
	methods: {
		
		submit(){
			// if(!this.reason){
			// 	this.showToast("请先填写调仓理由",'warning',5000);
			// 	return;
			// }
			let direction=1;
			if(this.proportion<this.oldProportion){
				direction=0;
			}else if(this.proportion==this.oldProportion){
				this.showToast("仓位无变化，请重新调整",'warning',5000);
				return;
			}
			if(this.proportion<10&&this.proportion!=0){
				this.showToast("仓位必须大于10%，或者调整到0%清仓",'warning',5000);
				return;
			}
			//console.log(this.iframeData.id,this.iframeData.stock.code,direction,this.reason,this.proportion/100);return;
			this.showLoading('调仓中，请稍候');
			Portfolio.restocking(this.iframeData.id,this.iframeData.stock.code,direction,this.reason,this.proportion/100).then(res => {
				this.showToast('保存成功，调仓结果会在结算后显示','success',5000);
				Bus.$emit("refreshPortfolioDetail", true);
				
				this.$layer.close(this.layerid);
			}).catch(err => {
				console.log(err.msg);
				this.showToast(err.msg,'danger',10000);
			}).finally(() => {
				this.hideLoading();
			});
		},
		cancel () {
			this.$layer.close(this.layerid);
		},
	},
	computed: {
		
	},
	watch: {
		
	},
};
</script>
<style>
	.slider-blue {
	  --slider-connect-bg: #409eff;
	  --slider-tooltip-bg: #409eff;
	  --slider-handle-ring-color: #3B82F630;
	}
</style>
<style src="@vueform/slider/themes/default.css"></style>
