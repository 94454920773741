<template>
  <div>
    <div
      :id="chartId"
      style="height: 300px; width: calc(100vw - 170px); z-index: 10"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'GoldStockChart',
  components: {},
  props: {
    chartData: [],
    chartId: '',
    dateArr: '',
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    let chartDom = document.getElementById(this.chartId);
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: 'ZH',
    });
  },
  methods: {
    initChart() {
      let series = [];
      this.chartData.forEach((item) => {
        let thisSeries = {
          name: item.name,
          type: 'line',
          data: item.data,
          endLabel: {
            show: true,
            formatter: function (params) {
              //console.log(params);
              return params.seriesName;
            },
          },
          labelLayout: {
            moveOverlap: 'shiftY',
          },
          emphasis: {
            focus: 'series',
          },
        };
        series.push(thisSeries);
      });
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '20',
          right: '110',
          bottom: '20',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateArr,
        },
        yAxis: {
          name: 'Price',
          type: 'value',
        },
        series: series,
        tooltip: {
          order: 'valueDesc',
          trigger: 'axis',
        },
      };
      this.myChart.setOption(option);
    },
  },
  watch: {
    chartData: {
      handler(newValue, oldValue) {
        if (newValue) {
          setTimeout(() => {
            this.initChart();
          }, 500);
        }
        //console.log(newValue);
      },
      immediate: true,
    },
  },
};
</script>
