<template>
  <div class="px-0 py-1" style="width: 100%">
    <div v-if="stockDetail.id" style="width: 100%; display: flex">
      <div style="width: calc(100% - 600px)" class="mr-3">
        <v-card class="border-radius-md mt-4 pa-4">
          <KLine :stockDetail="stockDetail"></KLine>
        </v-card>
        <v-card class="border-radius-md mt-4 pa-4">
          <PEBand :stockDetail="stockDetail"></PEBand>
        </v-card>
        <v-card class="border-radius-md my-4 py-0 pr-4">
          <Score :stockDetail="stockDetail"></Score>
        </v-card>
      </div>

      <div style="width: 700px">
        <v-card class="border-radius-md mt-4 pa-4">
          <div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div style="z-index: 10">
                <span class="text-body text-h5 font-weight-bolder">{{
                  stockDetail.stock.name
                }}</span>
                <span class="text-body text-h5 mx-3">{{
                  stockDetail ? stockDetail.stock_code : ""
                }}</span>
                <a
                  href="javascript:;"
                  @click="openRate()"
                  class="font-weight-normal mr-2"
                  style="color: #ed524e"
                  >评分</a
                >
                <!-- <a
                  href="javascript:;"
                  v-if="$commonHelpers.getPermissions('AI.AiAnalysis')"
                  @click="AiAnalysis(stockDetail.stock.name)"
                  class="font-weight-normal"
                  style="color: #ed524e"
                  >AI解析</a
                > -->
              </div>

              <div style="display: flex">
                <v-chip
                  v-for="item in stockDetail.stock.races"
                  dense
                  small
                  dark
                  :color="item.color"
                  class="mr-1 px-2 py-0"
                  style="height: 20px; opacity: 0.8"
                  :style="{ color: item.level == 3 ? '#000' : '#fff' }"
                  @click="$stockHelpers.openTagDetail(item.id)"
                >
                  {{ item.name }}
                </v-chip>

                <v-chip
                  v-for="item in stockDetail.stock.styles"
                  dense
                  small
                  dark
                  :color="item.color"
                  class="mr-1 px-2 py-0"
                  style="height: 20px; opacity: 0.8"
                  @click="$stockHelpers.openTagDetail(item.id)"
                >
                  {{ item.name }}
                </v-chip>
              </div>
            </div>

            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              "
            >
              <div
                style="
                  height: 230px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <div
                  style="
                    width: 280px;
                    height: 350px;
                    margin-top: 0px;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <div
                    style="width: 350px; height: 350px"
                    id="radarContainer"
                  ></div>

                  <div style="font-size: 12px">
                    基本面:{{ this.stockDetail.fundamentals }} &nbsp; 量价:{{
                      this.stockDetail.amount_price
                    }}
                    &nbsp; 催化剂:{{ this.stockDetail.catalyst }} &nbsp;
                  </div>
                </div>

                <div
                  style="max-width: 340px"
                  class="d-flex justify-center align-items-center"
                >
                  <div
                    style="
                      font-size: 12px;
                      white-space: pre-line;
                      height: 230px;
                      overflow-y: scroll;
                      padding-top: 10px;
                    "
                  >
                    <span style="font-weight: 800">调入理由：</span
                    >{{ stockDetail.reason ? stockDetail.reason : "暂无"
                    }}<br />
                    <span style="font-weight: 800">公司简介：</span>
                    {{ stockDetail.intro ? stockDetail.intro : "暂无" }}
                  </div>
                </div>
              </div>

              <div style="min-width: 500px">
                <vxe-table
                  border
                  resizable
                  show-overflow
                  show-header-overflow
                  highlight-hover-row
                  ref="windTable"
                  :column-config="{ resizable: true }"
                  :height="200"
                  size="mini"
                  :stripe="true"
                  :sort-config="{ trigger: 'cell', multiple: true }"
                  :scroll-x="{ gt: 30 }"
                  :scroll-y="{ mode: 'wheel', gt: 100 }"
                  :empty-render="{ name: 'NotData' }"
                >
                  <vxe-column
                    min-width="90"
                    field="4"
                    title="关键指标"
                  ></vxe-column>

                  <!-- <vxe-column min-width="70" field="0" :title="windData[0][0]"></vxe-column> -->
                  <vxe-column
                    min-width="70"
                    field="1"
                    :title="windData[1][0]"
                  ></vxe-column>
                  <vxe-column
                    min-width="70"
                    field="2"
                    :title="windData[2][0]"
                  ></vxe-column>
                  <vxe-column
                    min-width="70"
                    field="3"
                    :title="windData[3][0]"
                  ></vxe-column>
                </vxe-table>
              </div>
            </div>

            <div
              style="display: flex; justify-content: space-between"
              class="mt-3"
            >
              <div class="dataCrad" v-for="i in 3">
                <div
                  v-for="(item, index) in dailyData[i - 1]"
                  :key="index"
                  class="dataZone"
                >
                  <div class="mb-1">{{ index }}</div>
                  <div
                    class="mb-1"
                    v-if="typeof item == 'number'"
                    :style="$stockHelpers.getColor(item)"
                  >
                    {{ $stockHelpers.getPercent(item) }}
                  </div>
                  <div class="mb-1" v-else>{{ item }}</div>
                </div>
              </div>
            </div>

            <div class="mt-2 pa-8" style="border: 1px solid #ccc">
              <div
                style="
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                "
                class="py-2 d-flex"
              >
                <span style="font-weight: bolder">调研情况</span>
              </div>
              <div
                class="pb-2 d-flex"
                style="
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  font-size: 12px;
                "
              >
                <span>
                  正在进行中：{{ stockDetail.research_info.in_process }}
                  <span class="px-2">|</span>
                  近30天已完成：{{ stockDetail.research_info.month_finish }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div v-if="!stockDetail.hasOwnProperty('id')">
      <div class="d-flex justify-center align-center pa-8">
        <span class="text-body text-h5 mx-3">该股票未入池</span>
      </div>
    </div>
    <div v-if="stockDetail.id === 0">
      <div class="d-flex justify-center align-center pa-8">
        <span class="text-body text-h5 mx-3">该股票无权限查看</span>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import StockPool from "@/api/stockPool.js";
import KLine from "@/components/StockPool/KLine.vue";
import PEBand from "@/components/StockPool/PEBand.vue";
import Score from "@/components/StockPool/Score.vue";
export default {
  name: "StockDetail",
  components: {
    KLine,
    PEBand,
    Score,
  },
  props: {
    stockDetail: {},
  },
  data() {
    return {
      pageLoading: true,
      dailyData: [],
      windData: [[], [], [], [], ["", "", "", ""]],
    };
  },
  mounted() {
    //this.getStockDetail();
    if (this.stockDetail.id) {
      setTimeout(() => {
        this.setStockDetail();
        this.initRadar();

        this.intWind();
      }, 1000);
    }
  },
  methods: {
    setStockDetail() {
      this.dailyData.push({
        "1 Day涨跌幅": parseFloat(this.stockDetail.applies_day),
        "5 Day涨跌幅": parseFloat(this.stockDetail.applies_five),
        "20 Day涨跌幅": parseFloat(this.stockDetail.applies_20),
        "60 Day涨跌幅": parseFloat(this.stockDetail.applies_60),
        "120 Day涨跌幅": parseFloat(this.stockDetail.applies_120),
        入池涨跌幅: parseFloat(this.stockDetail.applies_focus),
        入池日期: this.stockDetail.created_at.split(" ")[0],
      });
      this.dailyData.push({
        量比: this.stockDetail.volume_ratio,
        换手率: this.stockDetail.turnover_rate,
        "成交额(亿)": this.stockDetail.turnover_million + "",
        "总市值(亿)": this.stockDetail.market_value_million + "",
        "PE(动)": this.stockDetail.pe,
        PB: this.stockDetail.pb,
        PS: this.stockDetail.ps,
      });
      this.dailyData.push({
        PEG: this.stockDetail.peg,
        ROE: this.stockDetail.roe,
        毛利率: this.stockDetail.gross_profit_margin,
        净利率: this.stockDetail.net_profit_margin,
        负债率: this.stockDetail.debt_ratio,
        "商誉(亿)": this.stockDetail.goodwill_million + "",
        质押比: this.stockDetail.pledge_ratio,
      });
    },
    intWind() {
      let tmp = [[], [], [], [], []];
      let windDataTmp = JSON.parse(this.stockDetail.wind_predict);
      //console.log(windDataTmp)
      if (Array.isArray(windDataTmp)) {
        windDataTmp.forEach((item, index) => {
          tmp[0].push(item.fy0);
          tmp[1].push(item.fy1);
          tmp[2].push(item.fy2);
          tmp[3].push(item.fy3);
          tmp[4].push(item.title);
        });
        this.windData = tmp;
        let windTable = [[], [], [], [], [], [], []];
        tmp.forEach((tmpItem, tmpIndex) => {
          tmpItem.forEach((item, index) => {
            if (index != 0) {
              //console.log(item);
              windTable[index - 1].push(item);
            }
          });
        });
        this.$refs.windTable.loadData(windTable);
      }
    },
    // 绘制雷达图
    initRadar() {
      let radarData = [
        { name: "基本面:" + this.stockDetail.fundamentals, max: 100 },
        { name: "量价:" + this.stockDetail.amount_price, max: 100 },
        { name: "催化剂:" + this.stockDetail.catalyst, max: 100 },
      ];
      let radarValue = [
        this.stockDetail.fundamentals,
        this.stockDetail.amount_price,
        this.stockDetail.catalyst,
      ];

      let dom = document.getElementById("radarContainer");
      let myChart = this.$echarts.init(dom);
      //   var app = {};
      let option = {
        // title: {
        //   text: "得分分布"
        // },
        grid: {
          left: "10",
          right: "10",
          bottom: "10",
          containLabel: true,
        },
        tooltip: {
          // 	formatter: (params)=>{
          // return params;
          // 	},
        },
        radar: {
          // shape: 'circle',
          radius: 50,
          center: ["35%", "50%"],
          axisName: {
            color: "#fff",
            backgroundColor: "#999",
            borderRadius: 3,
            padding: [3, 5],
          },
          indicator: radarData,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: radarValue,
                name: "得分",
              },
            ],
          },
        ],
      };
      myChart.setOption(option, true);
    },
    openRate() {
      this.$stockHelpers.openRate(
        this.stockDetail.stock.id,
        this.stockDetail.stock.name,
        this
      );
    },
    AiAnalysis(stock) {
      //console.log(stock);
      let now = new Date(); // 创建一个Date对象，表示当前时间
      let year = now.getFullYear(); // 获取当前年份，四位数表示
      this.$commonHelpers.openAiAnalysis(
        "AI个股解析",
        `按规则分析股票“${stock}”，注意数据来源，尽量用官方数据，尽量使用${year}年左右的最新数据。首先用一句话总结这家上市公司的投资价值，尽量让基金经理马上感兴趣。然后从以下几点分析这家公司。公司治理、行业空间、盈利能力、估值维度、个股情绪、 公司经营或管理变化或业务数据预期差。`,
        "bing",
        this
      );
    },
  },
};
</script>
<style>
.dataZone {
  display: flex;
  justify-content: space-between;
  width: 170px;
  font-size: 10px;
}

.dataCrad {
  border: 1px solid #cccccc;
  padding: 10px 5px 10px 5px;
}
</style>
