<template>
  <TableFilter
    :inputWidth="inputWidth"
    :colName="colName"
    :autoSave="false"
    :selectData="selectData"
    :table="table"
    :placeholder="placeholder"
  ></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter';
import Stock from '@/api/stock.js';
export default {
  name: 'TypeFilter',
  props: {
    placeholder: '',
    table: '',
    colName: '',
    inputWidth: '',
  },
  data() {
    return {
      selectData: [
        { label: '内部', value: '内部' },
        { label: '服务机构', value: '服务机构' },
      ],
      inputValue: '',
    };
  },
  components: {
    TableFilter,
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
