<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; width: 500px">
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >是否批准</label
        >
        <vxe-radio-group v-model="status" style="width: 100%" class="my-2">
          <vxe-radio label="1" content="通过"></vxe-radio>
          <vxe-radio label="2" content="驳回"></vxe-radio>
        </vxe-radio-group>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 80px; font-size: 13px"
          >审核批注</label
        >
        <v-textarea
          outlined
          color="rgba(0,0,0,.6)"
          placeholder="审核批注"
          class="font-size-input border text-light-input border-radius-sm my-2"
          auto-grow
          rows="3"
          v-model="audit_comment"
        ></v-textarea>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Bus from '@/components/Bus/bus.js';
import Research from '@/api/research.js';
export default {
  name: 'StockAdd',
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: '',
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {
      status: '1',
      audit_comment: '',
    };
  },
  methods: {
    submit() {
      this.showLoading('保存中，请稍候');
      Research.applyResearch(
        this.iframeData.id,
        this.status,
        this.audit_comment
      )
        .then((res) => {
          this.showToast('保存成功', 'success', 5000);
          Bus.$emit('refreshResearchList', true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
