<template>
  <div style="width: 100%;">
		<LoadingPage :visible="tableLoading"></LoadingPage>
		<div style="width: 100%;" class="d-flex flex-wrap" v-show="!tableLoading">
			<v-card v-for="(item,index) in tableData1" :key="index" style="height: 100%;width: calc( 100%);margin: 10px 0px 10px 1px ;" class="border-radius-md pa-3">
				<div>{{index}}</div>
				<vxe-table
					:data="item"
					style="width: 100%"
					class="mytable-scrollbar"
					:border="false"
					resizable
					show-overflow
					show-header-overflow
					highlight-hover-row
					ref="bindStockTable"
					:loading="tableLoading"
					height="340px"
					size="small"
					:sort-config="{ trigger: 'cell', multiple: true }"
					:empty-render="{ name: 'NotData' }"
					align="center"
					:tree-config="{expandAll:true,reserve:false}"
				>
					<vxe-column min-width="80" field="name" title="规模区间">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{row.name}}
							</div>
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="count" title="个数">
						<template #default="{ row }">
							<div class="d-flex justify-end">
								{{row.count}}
							</div>
						</template>
					</vxe-column>
					
					<vxe-column
						min-width="60"
						title="涨跌数"
						field="rose"
					>
						<template #default="{ row }">
							<span style="color: red;">{{ row.rose}}</span> / <span style="color: green;">{{row.fall}}</span>
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="avg_gains" title="平均涨幅">
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.avg_gains)">{{ $stockHelpers.getPercent(row.avg_gains) }}</span>
							</div>
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="rose_name" title="最大涨幅个股">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{row.rose_name}}
							</div>
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="max_gains" title="最大涨幅">
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.max_gains)">{{ $stockHelpers.getPercent(row.max_gains) }}</span>
							</div>
							
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="rose_industry" title="申万三级">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{row.rose_industry}}
							</div>
							
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="fall_name" title="最大跌幅个股">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{row.fall_name}}
							</div>
							
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="max_retreat" title="最大跌幅">
						<template #default="{ row }">
							<div class="d-flex justify-end">
								<span :style="$stockHelpers.getColor(row.max_retreat)">{{ $stockHelpers.getPercent(row.max_retreat) }}</span>
							</div>
						</template>
					</vxe-column>
					
					<vxe-column min-width="80" field="fall_industry" title="申万三级">
						<template #default="{ row }">
							<div class="d-flex justify-start">
								{{row.fall_industry}}
							</div>
							
						</template>
					</vxe-column>
					
					
				</vxe-table>
			</v-card>
			
		</div>
		
		
	</div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from '@/api/review.js';
export default {
  props: {
    
  },
  data() {
    return {
			tableLoading:false,
			tableData1:[],
    };
  },
	mounted() {
		this.getIndustryAppliesOrderByMarketValue();
	},
	methods: {
		getIndustryAppliesOrderByMarketValue(){
			this.tableLoading=true;
			Review.getIndustryAppliesOrderByMarketValue().then(res => {
				console.log(res.data);
				this.tableData1={
					'近5日涨跌幅排名':res.data.five,
					'近10日涨跌幅排名':res.data.ten,
					'近30日涨跌幅排名':res.data.month,
					'近90日涨跌幅排名':res.data.ninety,
				};
			}).catch(err => {
				console.log(err);
				this.showToast(err.msg,'danger',10000);
				//this.loginError();
			})
			.finally(() => {
				this.tableLoading=false;
			});
		},
	},
};
</script>
<style scoped="scoped">
	
</style>
