<template>
  <div class="d-flex justify-center">
    <div class="d-flex">
      <div style="width: 350px">
        <h4 class="text-dark px-4 pt-4">
          <div
            class="v-avatar"
            style="
              height: 24px;
              min-width: 24px;
              width: 24px;
              background-color: #344767;
            "
          >
            <span class="text-white">1</span>
          </div>
          选择一个评分人
        </h4>

        <vxe-toolbar class="mx-5">
          <template v-slot:buttons>
            <vxe-input
              v-model="filterName"
              type="search"
              placeholder="用户搜索"
              @keyup="searchEvent"
            ></vxe-input>
          </template>
        </vxe-toolbar>

        <div class="px-4 pb-3 d-flex">
          <div class="px-4" style="margin-left: -50px">
            <div
              class="pb-1 text-dark"
              style="text-align: center; cursor: pointer"
              @click="scrollTo('all')"
            >
              全
            </div>
            <div
              class="pb-1 text-dark"
              style="text-align: center; cursor: pointer"
              v-for="item in pinyin"
              @click="scrollTo(item)"
            >
              {{ item }}
            </div>
          </div>
          <vxe-table
            style="width: 200px"
            :data="userList"
            class="my-scrollbar"
            border
            show-overflow
            show-header-overflow
            highlight-hover-row
            ref="userTable"
            :loading="userTableLoading"
            :height="pageHeight - 190 + 'px'"
            size="small"
            :stripe="true"
            :sort-config="{
              trigger: 'cell',
              multiple: true,
              defaultSort: { field: 'pinyin', order: 'asc' },
            }"
            :scroll-x="{ gt: 30 }"
            :scroll-y="{ mode: 'wheel', gt: 100 }"
            @cell-click="userClick"
            :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
            :filter-config="{ showIcon: false }"
          >
            <vxe-column
              fixed="left"
              field="pinyin"
              title="拼音"
              sortable
              :visible="false"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start pl-2">
                  {{ row.pinyin }}
                </div>
              </template>
            </vxe-column>
            <vxe-column
              fixed="left"
              field="first_pinyin"
              title="首字母"
              sortable
              :filters="[{ data: '' }]"
              :filter-method="
                (value) =>
                  $tableHelpers.filterInputMethod(value, 'first_pinyin')
              "
              :visible="false"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start pl-2">
                  {{ row.first_pinyin }}
                </div>
              </template>
            </vxe-column>
            <vxe-column fixed="left" field="base" title="用户">
              <template #default="{ row }">
                <div class="d-flex justify-start pl-2">
                  {{ row.base }}
                </div>
              </template>
            </vxe-column>
          </vxe-table>

          <v-icon style="font-size: 100px">mdi-chevron-double-right</v-icon>
        </div>
      </div>

      <div style="width: 370px" class="mr-8">
        <h4 class="text-dark px-4 pt-4">
          <div class="d-flex justify-space-between">
            <div>
              <div
                class="v-avatar"
                style="
                  height: 24px;
                  min-width: 24px;
                  width: 24px;
                  background-color: #344767;
                "
              >
                <span class="text-white">2</span>
              </div>
              <span>选择关联的股票({{ bindStockList.length }})</span>
            </div>
            <span style="color: #d80d18"
              >评分人：{{ currUser ? currUser.base : '未选择' }}</span
            >
          </div>

          <div class="d-flex justify-space-between py-2">
            <vxe-button
              @click="openStockSelect()"
              size="small"
              content="关联新股票"
              class=""
              status="my-primary"
            ></vxe-button>
            <vxe-button
              @click="multiDeleteStock()"
              size="small"
              content="批量删除"
              class=""
              status="my-danger"
            ></vxe-button>
          </div>
        </h4>

        <div class="pl-4 pb-3 d-flex">
          <vxe-table
            style="width: 370px"
            :data="bindStockList"
            class="my-scrollbar"
            align="center"
            :border="false"
            show-overflow
            show-header-overflow
            highlight-hover-row
            ref="bindStockTable"
            :loading="bindStockTableLoading"
            :height="pageHeight - 180 + 'px'"
            size="small"
            :stripe="true"
            :sort-config="{ trigger: 'cell', multiple: true }"
            :scroll-x="{ gt: 30 }"
            :scroll-y="{ mode: 'wheel', gt: 100 }"
            @cell-click="stockClick"
            :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
            :checkbox-config="{ range: true }"
          >
            <vxe-column field="multi" type="checkbox" title="批量" width="80">
            </vxe-column>

            <vxe-column field="code" width="70" title="代码">
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <span>{{ row.code }}</span>
                </div>
              </template>
            </vxe-column>

            <vxe-column field="name" width="80" title="股票名">
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <span>{{ row.name }}</span>
                </div>
              </template>
            </vxe-column>

            <vxe-column
              title="删除"
              type="seq"
              min-width="68"
              :resizable="false"
              show-overflow
            >
              <template #default="{ row }">
                <a
                  @click="deleteStock([row.id])"
                  class="text-xs font-weight-normal text-typo"
                >
                  <v-icon style="color: #d80d18">mdi-delete</v-icon>
                </a>
              </template>
            </vxe-column>
          </vxe-table>

          <v-icon style="font-size: 100px">mdi-chevron-double-right</v-icon>
        </div>
      </div>

      <div style="width: 450px">
        <h4 class="text-dark px-4 pt-4">
          <div class="d-flex justify-space-between">
            <div>
              <div
                class="v-avatar"
                style="
                  height: 24px;
                  min-width: 24px;
                  width: 24px;
                  background-color: #344767;
                "
              >
                <span class="text-white">3</span>
              </div>
              <span>分配因子</span>
            </div>
            <span style="color: #d80d18" class=""
              >股票：{{ currStock ? currStock.name : '未选择' }}</span
            >
          </div>
          <div class="mt-2 d-flex justify-space-between">
            <div>改动因子后请及时保存</div>
            <vxe-button
              @click="saveFactor()"
              size="small"
              content="保存"
              class=""
              status="my-danger"
            ></vxe-button>
          </div>

          <div class="d-flex justify-space-between py-2">
            <vxe-table
              :data="factorList"
              row-id="id"
              style="width: 100%"
              :border="false"
              resizable
              show-overflow
              show-header-overflow
              highlight-hover-row
              ref="factorList"
              :loading="factorTableLoading"
              :height="pageHeight - 180 + 'px'"
              size="small"
              :sort-config="{ trigger: 'cell', multiple: true }"
              :empty-render="{ name: 'NotData' }"
              align="center"
              :tree-config="{ expandAll: true, reserve: false }"
              keep-source
              :checkbox-config="{ checkField: 'has', trigger: 'row' }"
            >
              <vxe-column field="multi" type="checkbox" title="批量" width="80">
              </vxe-column>

              <vxe-column width="70" field="groupName" title="分组" tree-node>
                <template #default="{ row }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ row.groupName }}
                  </div>
                </template>
              </vxe-column>

              <vxe-column min-width="120" field="name" title="因子名称">
                <template #header="{ column }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ column.title }}
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="d-flex justify-start">
                    <div
                      style="
                        word-break: break-all;
                        white-space: pre-line;
                        text-align: start;
                      "
                    >
                      {{ row.name }}
                    </div>
                  </div>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/Bus/bus.js';
import User from '@/api/user.js';
import Rate from '@/api/rate.js';
import StockSelect from '@/components/Admin/rateManage/BindStockAdd';
import XEUtils from 'xe-utils';
export default {
  props: {},
  data() {
    return {
      userTableLoading: false,
      filterName: '',
      pageHeight: 0,

      bindStockTableLoading: false,

      factorTableLoading: false,
      factorList: [],
      checkFactorKeys: [],

      userList: [],
      userData: [],

      bindStockList: [],

      currUser: '',
      currStock: '',
      pinyin: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
    };
  },
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    this.getUserList();

    Bus.$on('refreshUserBindStock', (e) => {
      this.getUserBindStock();
    });
  },
  methods: {
    getUserList() {
      this.filterName = '';
      this.userTableLoading = true;
      User.getUserList(1)
        .then((res) => {
          this.userList = res.data;
          this.userData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.userTableLoading = false;
        });
    },
    searchEvent() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, 'gi');
        const searchProps = ['base'];
        this.userList = this.userData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
      } else {
        this.userList = this.userData;
      }
    },
    //点击一个用户，获取他绑定的股票
    userClick({ row }) {
      this.currUser = row;
      this.getUserBindStock();
    },
    getUserBindStock() {
      this.bindStockTableLoading = true;
      Rate.getUserBindStock(this.currUser.id)
        .then((res) => {
          this.bindStockList = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.bindStockTableLoading = false;
        });
    },
    //点击一个绑定的股票，获取该股票的因子
    stockClick({ row }) {
      this.currStock = row;
      //console.log(row);
      this.factorTableLoading = true;
      Rate.getAdminStockFactor(this.currStock.id, this.currUser.id)
        .then((res) => {
          let result = [];
          XEUtils.each(
            XEUtils.groupBy(res.data, 'parent_category_name'),
            (childs, key) => {
              if (key != 'null') {
                result.push({
                  groupName: key,
                  children: childs,
                });
              } else {
                result.push({
                  groupName: '未分组',
                  children: childs,
                });
              }
            }
          );
          //console.log(result);

          let finalResult = [];
          result.forEach((item) => {
            if (item.groupName == '基本面') {
              finalResult[0] = item;
            } else if (item.groupName == '催化剂') {
              finalResult[1] = item;
            } else if (item.groupName == '量价') {
              finalResult[3] = item;
            }
          });

          this.factorList = finalResult;

          //设置启用状态
          let activeFactor = [];
          res.data.forEach((i) => {
            if (i.has == 1) {
              activeFactor.push(i.id);
            }
          });
          this.checkFactorKeys = activeFactor;

          setTimeout(() => {
            this.$refs.factorList.setAllTreeExpand(true);
          }, 100);
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.factorTableLoading = false;
        });
    },
    //批量删除绑定的股票
    multiDeleteStock() {
      let selectRecords = this.$refs.bindStockTable.getCheckboxRecords();
      if (selectRecords.length < 1) {
        this.showToast('请先选择项目', 'warning', 5000);
        return;
      }
      let arr = [];
      selectRecords.forEach((i) => {
        arr.push(i.id);
      });
      this.deleteStock(arr);
    },
    //删除用户绑定的股票
    deleteStock(idArr) {
      this.$layer.confirm('确定要删除绑定的股票吗？', (layerid) => {
        this.showLoading('删除中，请稍候');
        Rate.deleteUserBindStock(this.currUser.id, idArr)
          .then((res) => {
            this.showToast('已完成批量删除！', 'success', 5000);
            this.getUserBindStock();
          })
          .catch((err) => {
            console.log(err);
            this.showToast(err.msg, 'error', 5000);
            //this.loginError();
          })
          .finally(() => {
            this.hideLoading();
          });

        this.$layer.close(layerid);
      });
    },
    //关联新股票
    openStockSelect() {
      if (!this.currUser) {
        this.showToast('请先选择评分人', 'warning', 5000);
        return;
      }
      this.$layer.iframe({
        content: {
          content: StockSelect,
          parent: this,
          data: {
            iframeData: {
              bindStockList: this.bindStockList,
              currUser: this.currUser,
            },
          },
        },
        area: ['500px', '80vh'],
        title: '新增绑定股票(打勾后点击关联按钮)',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    //保存
    saveFactor() {
      let submitInfo = [];
      this.factorList.forEach((i1) => {
        if (i1.children) {
          i1.children.forEach((i2) => {
            if (i2.has) {
              submitInfo.push(i2.id);
            }
          });
        }
      });
      this.showLoading('分配因子中，请稍候');
      Rate.bindFactorToStock(this.currUser.id, this.currStock.id, submitInfo)
        .then((res) => {
          this.showToast('已完成分配！', 'success', 5000);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, 'error', 5000);
          //this.loginError();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    scrollTo(firstLetter) {
      if (firstLetter != 'all') {
        firstLetter = firstLetter.toLowerCase();
      } else {
        firstLetter = '';
      }
      this.$tableHelpers.filterInput(
        this.$refs.userTable,
        'first_pinyin',
        firstLetter
      );
    },
  },
};
</script>
<style scoped="scoped"></style>
