<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-show="!tableLoading">
      <div v-for="item in list" :key="item.id">
        <v-card class="border-radius-md pa-3 mb-3">
          <h3>
            {{ item.name }}
          </h3>
          <p>理由：{{ item.reason == "nan" ? "暂无" : item.reason }}</p>
          <vxe-table
            :data="item.data"
            style="width: 100%"
            class="mytable-scrollbar"
            :border="false"
            resizable
            :scroll-y="{ enabled: false }"
            show-overflow
            show-header-overflow
            highlight-hover-row
            ref="bindStockTable"
            :loading="tableLoading"
            max-height="100000"
            size="small"
            :sort-config="{ trigger: 'cell', multiple: true }"
            :empty-render="{ name: 'NotData' }"
            align="left"
          >
            <vxe-column
              fixed="left"
              min-width="80"
              field="code"
              title="股票代码"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  {{ row.code ? row.code : "-" }}
                </div>
              </template>
            </vxe-column>

            <vxe-column
              fixed="left"
              min-width="80"
              field="name"
              title="股票名称"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  {{ row.name ? row.name : "-" }}
                </div>
              </template>
            </vxe-column>

            <vxe-column
              min-width="100"
              field="m_days_n_boards"
              title="是否连板"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <span
                    v-if="row.num"
                    style="
                      background-color: #fff1f1;
                      border: 1px solid #e80000;
                      color: #e80000;
                      padding: 2px 5px;
                    "
                    >{{ row.num }}</span
                  >
                </div>
              </template>
            </vxe-column>

            <!-- <vxe-column
              min-width="70"
              title="收盘价"
              field="cur_price"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  <span :style="$stockHelpers.getColor(row.px_change_rate)">{{
                    row.cur_price
                  }}</span>
                </div>
              </template>
            </vxe-column> -->

            <vxe-column
              min-width="70"
              title="涨幅"
              field="shares_range"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  <span :style="$stockHelpers.getColor(row.shares_range)">{{
                    row.shares_range + "%"
                  }}</span>
                </div>
              </template>
            </vxe-column>

            <vxe-column min-width="300" field="races" title="标签">
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <div class="d-flex" style="flex-wrap: wrap">
                    <span
                      v-for="(race, index) in row.races"
                      v-if="race.level !== 0"
                      :key="race.id"
                      class="px-1 py-0"
                      style="
                        cursor: pointer;
                        font-size: 10px;
                        color: #fff;
                        border-radius: 3px;
                        margin-bottom: 1px;
                        margin-right: 1px;
                      "
                      :style="{
                        backgroundColor:
                          race.color == `#e8f5e9` ? `#00b294` : race.color,
                      }"
                      @click="$stockHelpers.openTagDetail(race.id)"
                    >
                      {{ race.name }}
                    </span>
                  </div>
                </div>
              </template>
            </vxe-column>

            <vxe-column title="解读" width="700" field="expound">
              <template #default="{ row }" class="px-1">
                {{ row.expound }}
              </template>
            </vxe-column>
          </vxe-table>
        </v-card>
      </div>
      <div style="color: #ccc; font-size: 12px">
        更新时间：{{ list[0] ? list[0].data[0].updated_on : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
export default {
  name: "Movements",
  components: {},
  data() {
    return {
      tableLoading: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      ResonanceContrast.getMovements()
        .then((res) => {
          //console.log(res.data);
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
