<template>
  <div class="">
    <div style="auto">
      <vxe-table
        :auto-resize="true"
        :data="tableData"
        header-cell-class-name="col-red"
        :row-class-name="rowClassName"
        :cell-style="cellStyle"
        :cell-class-name="cellClassName"
        class="mytable-scrollbar"
        show-header-overflow
        :border="false"
        highlight-hover-row
        ref="bindStockTable"
        :loading="bindStockTableLoading"
        size="small"
        :empty-render="{ name: 'NotData' }"
        align="center"
        :tree-config="{ expandAll: true, reserve: false }"
        @scroll="tableScroll"
      >
        <vxe-column
          width="150"
          field="code"
          title="股票代码"
          tree-node
          fixed="left"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>

          <template #default="{ row }">
            <div class="d-flex justify-start">
              <span
                v-if="row.groupName"
                style="font-weight: bolder; font-size: 12px"
              >
                {{ row.groupName }}
              </span>
              <span v-if="!row.groupName && row.pool">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.pool.id)"
                  class="text-xs"
                  style="font-weight: bolder; text-decoration-line: none"
                  :style="{ color: getStockColor(row) }"
                  >{{ row.code ? row.code : "-" }}</a
                >
              </span>
              <span v-else-if="!row.groupName && !row.pool">
                <span :style="{ color: getStockColor(row) }">
                  {{ row.code ? row.code : "" }}
                </span>
              </span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="60" field="name" title="股票名称" fixed="left">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <span v-if="row.pool">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.pool.id)"
                  class="text-xs"
                  style="font-weight: bolder; text-decoration-line: none"
                  :style="{ color: getStockColor(row) }"
                  >{{ row.name ? row.name : "-" }}
                </a>
              </span>
              <span v-else>
                <span :style="{ color: getStockColor(row) }">
                  {{ row.name ? row.name : "" }}
                </span>
              </span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="400" max-width="600" field="reason" title="描述">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div
              style="
                overflow-wrap: break-word;
                white-space: pre-wrap;
                text-align: left;
              "
            >
              {{ row.reason }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="70" field="close" title="股价">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span
                v-if="row.daily"
                :style="$stockHelpers.getColor(row.applies.applies_day)"
              >
                {{ row.daily.close ? row.daily.close.toFixed(2) : "" }}
              </span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="60" field="applies_day" title="涨幅">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span v-if="row.applies">{{
                $stockHelpers.getPercent(row.applies.applies_day)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="60" field="applies_five" title="5日">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span v-if="row.applies">{{
                $stockHelpers.getPercent(row.applies.applies_five)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column width="60" field="applies_20" title="20日">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span v-if="row.applies">{{
                $stockHelpers.getPercent(row.applies.applies_20)
              }}</span>
            </div>
          </template>
        </vxe-column> -->

        <!-- 自定义列 -->
        <vxe-column
          v-for="(label, index) in appStore.tagDetail.labels"
          :field="`label_data.${label.key}`"
          :title="label.name"
          width=""
          :min-width="
            label.name.length * 16 > 100 ? label.name.length * 16 : 100
          "
          v-if="label.show == 1 && label.name.indexOf('一致预期') == -1"
          :key="`label_data.${label.key}`"
        >
          <template #default="{ row }">
            <div v-if="row.code" style="height: 100%">
              <div
                class="d-flex justify-end"
                v-if="label.type !== 1"
                style="z-index: 1"
              >
                {{ addColStyle(row.label_data[label.key]) }}
              </div>
              <div
                class="d-flex justify-end"
                v-if="label.type === 1"
                style="z-index: 1"
              >
                {{ addColStyle(getColData(label.key, row)) }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-colgroup
          title="一致预期营业收入/亿"
          width="210"
          field="west_sales"
          v-if="getColDisplay('west_sales')"
        >
          <vxe-column
            width="60"
            field="west_sales_fy1"
            :title="'' + maxBenchmark"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_sales_fy1
                      ? row.daily.west_sales_fy1
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_sales_fy2"
            :title="'' + (maxBenchmark + 1)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_sales_fy2
                      ? row.daily.west_sales_fy2
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_sales_fy3"
            :title="'' + (maxBenchmark + 2)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_sales_fy3
                      ? row.daily.west_sales_fy3
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
        </vxe-colgroup>

        <vxe-colgroup
          title="一致预期净利润/亿"
          width="210"
          field="west_netprofit"
          v-if="getColDisplay('west_netprofit')"
        >
          <vxe-column
            width="60"
            field="west_netprofit_fy1"
            :title="'' + maxBenchmark"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_netprofit_fy1
                      ? row.daily.west_netprofit_fy1
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_netprofit_fy2"
            :title="'' + (maxBenchmark + 1)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_netprofit_fy2
                      ? row.daily.west_netprofit_fy2
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_netprofit_fy3"
            :title="'' + (maxBenchmark + 2)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_netprofit_fy3
                      ? row.daily.west_netprofit_fy3
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
        </vxe-colgroup>

        <vxe-colgroup
          title="一致预期EPS"
          width="210"
          field="west_eps"
          v-if="getColDisplay('west_eps')"
        >
          <vxe-column
            width="60"
            field="west_eps_fy1"
            :title="'' + maxBenchmark"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_eps_fy1
                      ? row.daily.west_eps_fy1
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_eps_fy2"
            :title="'' + (maxBenchmark + 1)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_eps_fy2
                      ? row.daily.west_eps_fy2
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_eps_fy3"
            :title="'' + (maxBenchmark + 2)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_eps_fy3
                      ? row.daily.west_eps_fy3
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
        </vxe-colgroup>

        <vxe-colgroup
          title="一致预期PE"
          width="210"
          field="estpe"
          v-if="getColDisplay('estpe')"
        >
          <vxe-column width="60" field="estpe_fy1" :title="'' + maxBenchmark">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.estpe_fy1
                      ? row.daily.estpe_fy1
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="estpe_fy2"
            :title="'' + (maxBenchmark + 1)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.estpe_fy2
                      ? row.daily.estpe_fy2
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="estpe_fy3"
            :title="'' + (maxBenchmark + 2)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.estpe_fy3
                      ? row.daily.estpe_fy3
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
        </vxe-colgroup>

        <vxe-colgroup
          title="一致预期PS"
          width="210"
          field="west_ps"
          v-if="getColDisplay('west_ps')"
        >
          <vxe-column width="60" field="west_ps_fy1" :title="'' + maxBenchmark">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_ps_fy1
                      ? row.daily.west_ps_fy1
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_ps_fy2"
            :title="'' + (maxBenchmark + 1)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_ps_fy2
                      ? row.daily.west_ps_fy2
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            width="60"
            field="west_ps_fy3"
            :title="'' + (maxBenchmark + 2)"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end" v-if="row.daily">
                {{
                  row.daily["fy" + maxBenchmark]
                    ? row.daily.west_ps_fy3
                      ? row.daily.west_ps_fy3
                      : "-"
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
        </vxe-colgroup>
      </vxe-table>
      <MyScrollBar
        :width="ScrollBarWidth"
        v-if="isShowBar"
        @scrollLeft="scrollToLeft"
        ref="MyScrollBar"
      ></MyScrollBar>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import rate from "@/components/Rate/Rate.vue";
import BindStockAdd from "@/components/TagStore/BindStockAdd.vue";
import StockGroupEdit from "@/components/TagStore/StockGroupEdit.vue";
import StockDescEdit from "@/components/TagStore/StockDescEdit.vue";
import XEUtils from "xe-utils";
import Tag from "@/api/tag.js";
import StockPoolAdd from "@/components/StockPool/StockAdd.vue";
import MyScrollBar from "@/components/TagStore/ScrollBar.vue";
export default {
  name: "Dashboard",
  components: {
    MyScrollBar,
  },
  props: {
    currTag: {},
  },
  data() {
    return {
      tableData: [],
      labels: [],
      bindStockTableLoading: false,
      pageHeight: 0,

      date: new Date(),
      rowChange: false,
      columns: [],
      label_max_data: [],

      maxBenchmark: 0,
      ScrollBarWidth: 0,
      isShowBar: false,
    };
  },

  mounted() {
    let that = this;
    this.pageHeight = document.body.offsetHeight;

    if (this.appStore.tagDetail.labels) {
      this.labels = this.appStore.tagDetail.labels;
      setTimeout(() => {
        this.handleProgressData();
        this.tableData = this.appStore.tagDetail.stock;
      }, 100);

      setTimeout(() => {
        this.handleProgressData();
        this.$refs.bindStockTable.setAllTreeExpand(true);

        this.columns = this.$refs.bindStockTable.getColumns();
        this.setBar();
        window.addEventListener("resize", function (e) {
          that.setBar();
        });
      }, 1000);
    }
  },
  methods: {
    setBar() {
      this.ScrollBarWidth = document.querySelector(
        ".mytable-scrollbar .vxe-table--body"
      ).offsetWidth;
      console.log(this.ScrollBarWidth);
      console.log(window.innerWidth);
      if (
        this.ScrollBarWidth <
        document.querySelector(".mytable-scrollbar").offsetWidth
      ) {
        this.isShowBar = false;
      } else {
        this.isShowBar = true;
      }
    },
    getStockColor(row) {
      if (row.is_important) {
        return "#ff0000";
      } else if (row.pool) {
        return "#0000ee";
      } else {
        return "#000000";
      }
    },
    activeCellMethod({ row, column }) {
      if (!row.groupName) {
        return true;
      } else {
        return false;
      }
    },
    getStockStyle(value, max = 0.1) {
      if (value > 0) {
        let opacity = 1 - Math.abs(max - value) / max;
        if (value > max) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(max - value) / max - 1;
        if (value < -max) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    rowClassName({ row, rowIndex }) {
      //console.log(row);
      if (row.groupName) {
        return "group-row";
      } else {
        return "";
      }
    },
    cellStyle({ row, rowIndex, column }) {
      //console.log(column);
      if (!row.groupName) {
        if (column.property === "applies_day") {
          return {
            backgroundColor: this.getStockStyle(row.applies.applies_day, 0.1),
            color: "#000",
          };
        } else if (column.property === "applies_five") {
          return {
            backgroundColor: this.getStockStyle(row.applies.applies_five, 0.2),
            color: "#000",
          };
        } else if (column.property === "applies_20") {
          return {
            backgroundColor: this.getStockStyle(row.applies.applies_20, 0.4),
            color: "#000",
          };
          appStore.tagDetail.labels[labelKey];
        }

        if (column.property.indexOf("label_data") != -1) {
          let labelKey = column.property.split(".")[1];
          //找到对应的label
          let thisLabel = this.labels.find((i) => i.key == labelKey);
          if (thisLabel.is_progress) {
            //获取当前列的值
            let value;
            if (thisLabel.type == 1) {
              value = parseFloat(row.daily[thisLabel.key]);
            } else {
              value = parseFloat(row.label_data[thisLabel.key]);
            }
            //获取value相对于在label_max_data中的最大值的比例、
            let max = this.label_max_data.find(
              (i) => i.key == thisLabel.key
            ).max;
            let percent = ((value / max) * 100).toFixed(2) + "%";
            if (percent != "NaN%") {
              return {
                backgroundImage: "linear-gradient(90deg,#8cc6ff,#ebf5ff)",
                backgroundSize: percent + " 100%",
                backgroundPosition: "left",
                border: "1px solid #E8EAEC",
              };
            } else {
              return {
                border: "1px solid #E8EAEC",
              };
            }
          }
        }
      }
    },
    cellClassName({ row, rowIndex, column }) {
      return "";
    },
    getColData(colName, row) {
      return row.daily[colName];
      // switch (colName) {
      //   case "grossprofitmargin":
      //     return row.daily.grossprofitmargin;
      //     break;
      //   case "debttoassets":
      //     return row.daily.debttoassets;
      //     break;
      //   case "mkt_cap_ard_million":
      //     return row.daily.mkt_cap_ard_million;
      //     break;
      //   case "pe_ttm":
      //     return row.daily.pe_ttm;
      //     break;
      //   case "pb":
      //     return row.daily.pb;
      //     break;
      //   case "netprofitmargin":
      //     return row.daily.netprofitmargin;
      //     break;
      //   case "ps":
      //     return row.daily.ps;
      //     break;
      //   default:
      //     return "";
      //     break;
      //}
    },
    getColDisplay(colName) {
      let display = false;
      this.labels.forEach((i) => {
        if (i.key == colName && i.show == 1) {
          display = true;
        }
      });
      return display;
    },
    //处理进度条所需数据
    handleProgressData() {
      this.label_max_data = [];
      //将数据从树形结构转换为一维数组
      let stockList = [];
      this.appStore.tagDetail.stock.forEach((item) => {
        if (item.children) {
          //数组合并
          stockList = stockList.concat(item.children);
        }
      });
      //循环查找列中的最大值，放入label_max_data中
      this.appStore.tagDetail.labels.forEach((item) => {
        if (item.is_progress) {
          //console.log(item.key);
          let labelDataList = [];
          stockList.forEach((i) => {
            if (item.type == 1) {
              if (!Number.isNaN(parseFloat(i.daily[item.key]))) {
                labelDataList.push(parseFloat(i.daily[item.key]));
              }
            } else {
              if (!Number.isNaN(parseFloat(i.label_data[item.key]))) {
                labelDataList.push(parseFloat(i.label_data[item.key]));
              }
            }
          });
          let max = Math.max(...labelDataList);
          this.label_max_data.push({ key: item.key, max: max });
        }
      });
    },
    //处理数据
    handleData(val) {
      this.tableData = [];
      this.bindStockTableLoading = true;
      console.log("正在处理数据中");
      this.labels = val.labels;
      this.columns = this.$refs.bindStockTable.getColumns();

      setTimeout(() => {
        this.handleProgressData();
        //计算fy123
        this.maxBenchmark = 0;
        this.appStore.tagDetail.stock.forEach((group) => {
          if (group.children) {
            group.children.forEach((stock) => {
              if (stock.daily) {
                if (stock.daily.fy_bench) {
                  this.maxBenchmark = Math.max(
                    this.maxBenchmark,
                    parseInt(stock.daily.fy_bench)
                  );
                  stock.daily["fy" + stock.daily.fy_bench] = "fy1";
                  stock.daily["fy" + (parseInt(stock.daily.fy_bench) + 1)] =
                    "fy2";
                  stock.daily["fy" + (parseInt(stock.daily.fy_bench) + 2)] =
                    "fy3";
                }
              }
            });
          }
        });
        console.log(this.appStore.tagDetail.stock);

        this.tableData = this.appStore.tagDetail.stock;
      }, 100);

      //处理进度条所需数据
      setTimeout(() => {
        this.bindStockTableLoading = false;
        this.$refs.bindStockTable.setAllTreeExpand(true);
      }, 1000);
    },
    addColStyle(data) {
      //如果是数字类型
      if (typeof data == "number") {
        return data.toFixed(2);
      } else {
        return data;
      }
    },
    //监听滚动条位置
    tableScroll(e) {
      this.$refs.MyScrollBar.BarScrollToLeft(e.scrollLeft);
    },
    //滚动到指定位置
    scrollToLeft(left) {
      console.log(left);
      this.$refs.bindStockTable.scrollTo(left);
    },
  },
  watch: {},
};
</script>

<style scoped>
.roles-dropdown {
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
<style>
.col-red {
  background-color: #be0000;
  color: #fff;
}
.is--line {
  color: #be0000;
}
.group-row {
  background-color: #ddd;
}
</style>
<style>
.vxe-table--header-wrapper {
  position: sticky !important;
  top: 0;
  z-index: 2;
}
.vxe-table--body-wrapper {
  z-index: 0;
}
</style>
