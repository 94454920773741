var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LoadingPage',{staticStyle:{"width":"100%","height":"100vh"},attrs:{"visible":_vm.tableLoading}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableLoading),expression:"!tableLoading"}],staticClass:"px-0 py-1"},[_c('v-card',{staticClass:"border-radius-md pa-3 mb-3"},[_c('vxe-table',{ref:"AdditionalTable",staticClass:"mytable-scrollbar",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":false,"resizable":"","scroll-y":{ enabled: false },"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","loading":_vm.tableLoading,"max-height":"100000","size":"small","sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"align":"center","cell-style":_vm.cellStyle}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"70","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.in_pool)?_c('span',[_c('a',{staticClass:"text-xs",staticStyle:{"color":"#00006f","font-weight":"bolder"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.in_pool)}}},[_vm._v(_vm._s(row.code ? row.code : '-'))])]):_c('span',[_c('span',[_vm._v(" "+_vm._s(row.code ? row.code : '')+" ")])])])]}}])}),_c('vxe-column',{attrs:{"fixed":"left","min-width":"70","field":"sec_name","title":"股票名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.in_pool)?_c('span',[_c('a',{staticClass:"text-xs",staticStyle:{"color":"#00006f","font-weight":"bolder"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.in_pool)}}},[_vm._v(_vm._s(row.sec_name ? row.sec_name : '-'))])]):_c('span',[_c('span',[_vm._v(" "+_vm._s(row.sec_name ? row.sec_name : '')+" ")])])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","field":"issue_date","title":"发行日期","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.issue_date ? row.issue_date : '-')+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"发行价格","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.issue_price)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"增发日收盘价","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.issue_day_close)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"最新收盘价","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.last_close)+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"自发行价涨幅","sortable":"","field":"chg_against_ipoprice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.chg_against_ipoprice)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"发行折价率","sortable":"","field":"discount_rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',[_vm._v(_vm._s(_vm.$stockHelpers.getPercent(row.discount_rate)))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"70","title":"申万三级","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.industry_name)+" ")])]}}])})],1)],1),_c('span',{staticStyle:{"color":"#ccc","font-size":"12px"}},[_vm._v(" 更新日期:"+_vm._s(_vm.list[0] ? _vm.list[0].trade_date : '无'))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }