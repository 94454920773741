<template>
  <div style="height: 360px; width: 100%" class="d-flex">
    <v-list
      flat
      subheader
      style="
        width: 300px;
        height: 100%;
        overflow-y: scroll;
        background-color: #f0f0f0;
      "
      class="border-radius-md"
    >
      <div class="pa-5" v-for="item in 20" v-if="listLoading">
        <v-skeleton-loader type="paragraph" style=""></v-skeleton-loader>
      </div>

      <v-list-item-group
        v-model="activeArr"
        multiple
        active-class="active-factor"
        v-if="!listLoading"
      >
        <template v-for="(item, index) in data">
          <v-list-item :key="item.name">
            <template v-slot:default="{ active }">
              <v-list-item-content style="padding: 10px">
                <v-list-item-title>
                  <span style="font-size: 12px; font-weight: 800">
                    {{ item.name }} | {{ item.score }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action
                style="margin: 0px"
                @click.stop="historyClick(item)"
              >
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider v-if="index < data.length - 1" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <div class="pl-5 py-2" style="width: calc(100% - 300px); height: 340px">
      <div class="">
        <vxe-button
          @click="selectAllFactor()"
          size="medium"
          :content="activeArr.length == data.length ? '因子反选' : '因子全选'"
          class="ml-0 mr-2"
        ></vxe-button>
        <vxe-input
          class=""
          v-model="start_date"
          type="date"
          placeholder="起始日期"
          style="width: 135px"
          clearable
          @change="getScoreData()"
        ></vxe-input>
        <span class=""> - </span>
        <vxe-input
          v-model="end_date"
          type="date"
          placeholder="截止日期"
          style="width: 135px"
          clearable
          @change="getScoreData()"
        ></vxe-input>

        <a
          href="javascript:;"
          @click="dateChange(30)"
          class="text-xs font-weight-normal text-typo ml-4"
          >一月</a
        >
        <a
          href="javascript:;"
          @click="dateChange(90)"
          class="text-xs font-weight-normal text-typo ml-2"
          >三月</a
        >
        <a
          href="javascript:;"
          @click="dateChange(180)"
          class="text-xs font-weight-normal text-typo ml-2"
          >半年</a
        >
        <a
          href="javascript:;"
          @click="dateChange(365)"
          class="text-xs font-weight-normal text-typo ml-2"
          >一年</a
        >
      </div>

      <div style="width: 100%; height: 320px" id="scoreContainer"></div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Stock from "@/api/stock.js";
export default {
  name: "",
  components: {},
  props: {
    stockDetail: "",
  },
  mounted() {
    this.end_date = moment().format("YYYY-MM-DD");
    this.start_date = moment().subtract(1, "years").format("YYYY-MM-DD");
    this.getScoreData();
  },
  data() {
    return {
      activeArr: [0],
      listLoading: false,
      data: [],
      date: [],
      seriesData: [],
      start_date: "",
      end_date: "",
    };
  },
  methods: {
    selectAllFactor() {
      if (this.activeArr.length == this.data.length) {
        this.activeArr = [];
        this.makeData();
      } else {
        this.activeArr = [];
        this.data.forEach((item, index) => {
          this.activeArr.push(index);
        });

        this.makeData();
      }
    },
    getScoreData() {
      this.listLoading = true;
      Stock.getStockFactorDetail(
        this.stockDetail.stock.id,
        this.start_date,
        this.end_date
      )
        .then((res) => {
          //console.log(res.data);
          this.data = res.data;

          this.activeArr = [0];
          this.makeData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    makeData() {
      let tmpData = [];
      this.data.forEach((item, index) => {
        if (item.date.length != 0) {
          this.date = item.date;
        }
        item.type = "line";
        if (this.activeArr.indexOf(index) != -1) {
          tmpData.push(item);
        }
      });
      this.seriesData = tmpData;
      this.showLine();
    },
    showLine() {
      var dom = document.getElementById("scoreContainer");
      var myChart = this.$echarts.init(dom);
      //var app = {};
      var option = {
        title: {
          text: "多因子得分走势",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "20",
          right: "40",
          bottom: "8",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.date,
        },
        yAxis: {
          type: "value",
          scale: true,
        },
        series: this.seriesData,
      };
      myChart.setOption(option, true);
    },
    dateChange(day) {
      this.end_date = moment().format("YYYY-MM-DD");
      this.start_date = moment().subtract(day, "days").format("YYYY-MM-DD");
      this.getScoreData();
    },
    historyClick(item) {
      //console.log(item);
      this.$stockHelpers.openRate(
        this.stockDetail.stock.id,
        this.stockDetail.stock.name,
        this,
        {
          id: item.id,
          name: item.name,
        }
      );
    },
  },
  watch: {
    activeArr(newValue) {
      //console.log(newValue);
      this.makeData();
    },
  },
};
</script>
<style scoped="scoped">
.active-factor {
  background-color: #d9d9d9;
}
</style>
