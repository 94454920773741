<template>
  <div>
    <div style="width: 100%">
      <v-card class="border-radius-md ma-2 pa-4">
        <h3 class="text-body text-h5 font-weight-bolder pl-1">文件库</h3>
        <BindFile
          :stockId="stockDetail.stock.id"
          style="width: calc(100%)"
          :fileTableHeight="300"
        ></BindFile>
      </v-card>
      <v-card class="border-radius-md ma-2 pa-4">
        <h3 class="text-body text-h5 font-weight-bolder pl-1">研报库</h3>
        <ResearchPaper
          :stock="stockDetail.stock.code"
          style="width: calc(100%)"
        ></ResearchPaper>
      </v-card>
    </div>
    <v-card class="border-radius-md ma-2 pa-4" style="width: 100%">
      <h3 class="text-body text-h5 font-weight-bolder pl-1">电话会</h3>
      <TelephoneMeeting :stock="stockDetail.stock.code"></TelephoneMeeting>
    </v-card>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import StockPool from "@/api/stockPool.js";
import BindFile from "@/components/BindFile";
// import ResearchPaper from "@/components/StockDetail/ResearchPaper";
import ResearchPaper from "@/components/TagStore/ResearchPaper";
import TelephoneMeeting from "@/components/TagStore/TelephoneMeeting";
export default {
  name: "StockDetail",
  components: {
    BindFile,
    ResearchPaper,
    TelephoneMeeting,
  },
  props: {
    stockDetail: {},
  },
  data() {
    return {};
  },
  mounted() {
    //this.getStockDetail();
  },
  methods: {
    getStockDetail() {
      this.pageLoading = true;
      Stock.getStockDetail(this.$route.params.id)
        .then((res) => {
          this.stockDetail = res.data;
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.pageLoading = false;
        });
    },
  },
};
</script>
<style>
.dataZone {
  display: flex;
  justify-content: space-between;
  width: 170px;
  font-size: 10px;
}

.dataCrad {
  border: 1px solid #cccccc;
  padding: 10px 5px 10px 5px;
}
</style>
