<template>
  <v-autocomplete
    background-color="rgba(255,255,255,.9)"
    color="rgba(0,0,0,.6)"
    rounded-sm
    outlined
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-details
    :placeholder="placeholder"
    :clearable="!readonly"
    :readonly="readonly"
    :multiple="multiple"
    attach
    class="
    stock-select
      input-icon
      font-size-input
      placeholder-lighter
      input-alternative
      border border-radius-sm
    "
    style="width: 180px"
  >
    <template slot="prepend-inner" v-if="showIcon">
      <v-icon color="rgba(0,0,0,.6)" size=".875rem"> fas fa-search </v-icon>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-content
          v-if="!data.item.disabled"
          class="pa-2"
          style="font-size: 14px"
        >
          {{ data.item.showText }}
        </v-list-item-content>
        <v-list-item-content
          v-else
          class="bg-black pa-2"
          style="font-size: 14px"
        >
          {{ data.item.showText }}
        </v-list-item-content>
      </template>
    </template>

    <template slot="append"> &nbsp; </template>
  </v-autocomplete>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
export default {
  name: "stock-select",
  props: {
    name: "",
    code: "",
    value: "",
    placeholder: "",
    returnCode: false,
    returnItem: false,
    showIcon: false,
    //禁止掉股票池中的
    onlyPool: false,
    onlyNotPool: false,
    onlyNotQuantPool: false,
    onlyOtcPool: false,
    onlyNotOtcPool: false,
    readonly: false,
    //禁止某些股票
    disableArr: [],
    multiple: false,
    //只能选A股
    onlyA: false,
    //只能非美股
    notUS: false,
  },
  data() {
    return {
      model: "",
      search: "",
      items: [],
      isLoading: false,
    };
  },
  mounted() {
    this.search = this.name;
    if (this.value) {
      this.model = this.value;
      console.log(this.value);
    }
    //this.getStockList('');
    Bus.$on("refreshCoreStockPool", (e) => {
      this.items = [];
    });
  },
  methods: {
    submit() {},
    getStockList(val) {
      this.items = []
      this.isLoading = true;
      Stock.searchStockList(val, this.onlyA ? 1 : 0, 0, this.notUS ? 1 : 0)
        .then((res) => {
          let arr = [];
          res.data.forEach((item, index) => {
            if (this.onlyNotPool) {
              if (item.in_pool.indexOf(1) != -1) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (已在股票池中)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              }
            } else if (this.onlyNotQuantPool) {
              if (item.in_quant !== 0) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (已在量化池中)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              }
            } else if (this.onlyPool) {
              if (item.in_pool.indexOf(1) != -1) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (未在股票池中)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              }
            } else if (this.onlyOtcPool) {
              if (item.in_pool.indexOf(3) != -1) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (未在OTC池中)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              }
            } else if (this.onlyNotOtcPool) {
              if (item.in_pool.indexOf(3) == -1) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (已在OTC池中)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              }
            } else if (this.disableArr.length > 0) {
              if (this.disableArr.indexOf(item.id) == -1) {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code}`,
                  value: item.id,
                  disabled: false,
                  item: item,
                });
              } else {
                arr.push({
                  text: `${item.name} | ${item.code} (${item.pinyin})`,
                  showText: `${item.name} | ${item.code} (已被选)`,
                  value: item.id,
                  disabled: true,
                  item: item,
                });
              }
            } else {
              arr.push({
                text: `${item.name} | ${item.code} (${item.pinyin})`,
                showText: `${item.name} | ${item.code}`,
                value: item.id,
                disabled: false,
                item: item,
              });
            }
          });
          this.items = this.items.concat(arr);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    // code(val){
    // 	console.log(val);
    // 	this.model=val;
    // },
    model(val) {
      //console.log(val);
      this.search = this.name;
      if (this.returnCode) {
        let resCode = "";
        this.items.forEach((item) => {
          if (item.value == val) {
            resCode = item.item.code;
            return;
          }
        });
        this.$emit("getCode", resCode);
      }
      if (this.returnItem) {
        let resItem = "";
        this.items.forEach((item) => {
          if (item.item.id == val) {
            resItem = item.item;
          }
        });
        if (resItem) {
          this.$emit("getItem", resItem);
        }
      }
      this.getStockList(val)
      this.$emit("input", val);
    },
    search(val) {
      if (val) {
        let haveOld = false;
        this.items.forEach((item) => {
          if (item.text.indexOf(val) != -1) {
            haveOld = true;
          }
        });
        if (!haveOld) {
          this.getStockList(val);
        }
      }
    },
  },
};
</script>
<style>
  .stock-select .v-list-item--active{
    color: rgba(0, 0, 0, .38);
    background: #eee;
  }
</style>