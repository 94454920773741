import { render, staticRenderFns } from "./StockAddBack.vue?vue&type=template&id=3e557c6d&scoped=true"
import script from "./StockAddBack.vue?vue&type=script&lang=js"
export * from "./StockAddBack.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e557c6d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VSelect,VTextarea})
