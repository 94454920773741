<template>
  <div style="width: 100%;height:99%">
    <iframe style="width:100%;height:100%" :src="this.iframeData.url"></iframe>
  </div>
</template>
<script>
  export default {
    props: {
      iframeData: {//传递的数据
      	type: Object,
      	default: () => {
      		return {};
      	}
      },
    },
    components: {
      
    },
    data() {
      return {
        
      }
    },
    mounted() {
      
    },
    computed: {

    },
    methods: {

    }
  };
</script>
<style>
</style>
