<template>
	<div>
		<vxe-toolbar class="mx-5" custom :refresh="{query: getStockList}">>
			<template v-slot:buttons>
				<vxe-input v-model="filterName" type="search" placeholder="全文搜索"></vxe-input>
			</template>
		</vxe-toolbar>
		<vxe-table
			id="coreTable"
			ref="coreTable"
			:data="list"
			:border="false"
			resizable
			show-overflow
			show-header-overflow
			highlight-hover-row
			align="center"
			:loading="coreTableLoading"
			:column-config="{ resizable: true }"
			:height="tableHeight"
			size="small"
			:stripe="true"
			:sort-config="{ trigger: 'cell', multiple: true }"
			:scroll-y="{ mode: 'wheel', gt: 50 ,oSize:30}"
			:empty-render="{ name: 'NotData' }"
			:custom-config="{ storage: true, checkMethod: checkColumnMethod }"
			:tooltip-config="{ showAll: false, enterable: true ,theme:'dark' }"
		>

			<vxe-column fixed="left" min-width="100" field="stock_code" title="股票代码">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_code ? row.stock_code : '-' }}</a>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column fixed="left" min-width="80" field="stock_name" title="股票名称">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_name ? row.stock_name : '-' }}</a>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="created_at" title="入池日期" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.created_at.split(' ')[0] }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="70" field="outDate" title="调出日期" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.outDate.split(' ')[0] }}
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="70" field="market_value_million" title="市值/亿" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.market_value_million ? row.market_value_million : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="current_market_million" title="流通(亿)" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.current_market_million ? row.current_market_million : '-' }}
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="80" field="close_price" title="收盘价" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.close_price ? row.close_price : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="applies_day" title="当日涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_day)">{{ $stockHelpers.getPercent(row.applies_day) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="70" field="volume_ratio" title="量比" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span>{{ row.volume_ratio }}</span>
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="70" field="volatility" title="波动率" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.volatility ? row.volatility : '-' }}
					</div>
				</template>
			</vxe-column>

			<vxe-column min-width="70" field="applies_five" title="5日涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_five)">{{ $stockHelpers.getPercent(row.applies_five) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="applies_20" title="20日涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_20)">{{ $stockHelpers.getPercent(row.applies_20) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="applies_60" title="60日涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_60)">{{ $stockHelpers.getPercent(row.applies_60) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="applies_focus" title="入池涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.applies_focus)">{{ $stockHelpers.getPercent(row.applies_focus) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="pe_ttm" title="PE-TTM" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.pe_ttm ? row.pe_ttm : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="50" field="pb" title="PB" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						{{ row.pb ? row.pb : '-' }}
					</div>
				</template>
			</vxe-column>

		</vxe-table>
	</div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";

import rate from '@/components/Rate/Rate.vue';
import StockPool from '@/api/stockPool.js';
import Stock from '@/api/stock.js';
import XEUtils from 'xe-utils';
import StockAdd from '@/components/StockPool/StockAdd.vue';
import StockEdit from '@/components/StockPool/StockEdit.vue';

export default {
	name: 'StockPool',
	props: {},
	data() {
		return {
			stockList: [],
			coreTableLoading: false,
			tableHeight: '500px',
			stockLevelMap: {
				1: '核心',
				// 2: "备选",
				3: '一级',
				4: '二级',
				5: '三级'
			},

			filterName: '',
			tableData: [],
		};
	},
	components: {
		rate,
		StockAdd,
		StockEdit,
	},
	mounted() {
		this.tableHeight = document.body.offsetHeight - 140 + 'px';
		this.getStockList();
		
		Bus.$on('refreshCoreStockPool', (e) => {
			this.getStockList();
		});
	},
	computed: {
		list() {
			const filterName = XEUtils.toValueString(this.filterName)
				.trim()
				.toLowerCase();
			if (filterName) {
				const filterRE = new RegExp(filterName, 'gi');
				const searchProps = [
					'stock_name',
					'stock_code',
					'level',
					'pinyin',
					'industry_name',
					'industry_pinyin',
				];
				const rest = this.tableData.filter(item =>
					searchProps.some(
						key =>
							XEUtils.toValueString(item[key])
								.toLowerCase()
								.indexOf(filterName) > -1
					)
				);
				return rest;
				// return rest.map(row => {
				// 	const item = Object.assign({}, row);
				// 	searchProps.forEach(key => {
				// 		item[key] = XEUtils.toValueString(item[key]).replace(filterRE, match => {
				// 			return `<span class="keyword-lighten">${match}</span>`;
				// 		});
				// 	});
				// 	return item;
				// });
			}
			return this.tableData;
		}
	},
	methods: {
		checkColumnMethod({ column }) {
			if (column.property === 'role') {
				return false;
			}
			return true;
		},
		getStockList() {
			this.coreTableLoading = true;
			StockPool.getStockPoolList(3,2)
				.then(res => {
					res.data.forEach(e => {
						//_self.currentInfoStkList.push(e.stockCode);
					});

					this.tableData = res.data;
				})
				.catch(err => {
					console.log(err);
					//this.loginError();
				})
				.finally(() => {
					this.coreTableLoading = false;
				});
		},

		filterCodeMethod({ option, row }) {
			//console.log(option.data);
			return row.stock_code.indexOf(option.data) > -1;
		},

		filterMarketMethod({ option, row }) {
			//console.log(option.value);
			return row.market.indexOf(option.value) > -1;
		},

		codeFilter(data) {
			const coreTable = this.$refs.coreTable;
			const column = coreTable.getColumnByField('stock.code');
			const option = column.filters[0];
			option.data = data;
			option.checked = true;
			coreTable.updateData();
		},
	}
};
</script>
