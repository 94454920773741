<template>
  <v-menu
    transition="slide-y-transition"
    offset-y
    offset-x
    min-width="400"
    max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- 消息按钮 -->
      <v-btn
        icon
        :ripple="false"
        :class="{
          'btn-dark-hover': !hasBg,
          'btn-hover': hasBg,
          haveMsg: msgList.length > 0,
        }"
        class="text-body font-weight-600"
        :color="linkColor"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon size="16" :class="{ 'text-red': msgList.length > 0 }"
          >fa fa-bell text-sm</v-icon
        >
        <span
          class="d-sm-inline font-weight-bold d-none"
          :class="{ 'text-red': msgList.length > 0 }"
          >消息</span
        >
      </v-btn>
    </template>

    <v-list
      class="py-0"
      style="max-height: 500px;overflow: scroll; background-color: #fff'"
    >
      <div
        v-if="msgList.length == 0"
        style="width: 100%; height: 100px; align-items: center"
        class="d-flex justify-center"
      >
        <span class="text-dark">暂无消息</span>
      </div>
      <v-list-item
        v-for="(item, i) in msgList"
        :key="i"
        class="pa-4 list-item-hover-active"
      >
        <v-list-item-content class="pa-0">
          <div
            class="text-sm font-weight-normal text-typo"
            v-html="
              item.title !== '每日标签信息统计' ? item.title : item.content
            "
          ></div>

          <p class="text-xs text-secondary mb-0">
            <v-icon size="12" class="text-secondary">fa fa-clock me-1</v-icon>
            {{ item.updated_at }}
          </p>
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <a
              href="javascript:;"
              @click="singleRead(item.id)"
              class="text-xs font-weight-normal text-typo py-1"
              >标记已读</a
            >
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div
      style="
        line-height: 1.5;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      "
    >
      <a
        href="javascript:;"
        @click="allRead()"
        class="text-xs font-weight-normal text-typo py-1"
        >全部标记为已读</a
      >
      <a
        href="javascript:;"
        @click="allMsg()"
        class="text-xs font-weight-normal text-typo py-1 ml-2"
        >查看已读消息</a
      >
    </div>
  </v-menu>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Message from "@/api/message.js";
import MailBox from "@/components/MailBox.vue";
export default {
  name: "AppBarMessage",
  props: {
    hasBg: Boolean,
    linkColor: String,
  },
  components: {},
  data() {
    return {
      msgList: [],
    };
  },
  mounted() {
    this.getMessageList();
    setInterval(() => {
      this.getMessageList();
    }, 10000);
  },
  methods: {
    getMessageList() {
      Message.getMessageList()
        .then((res) => {
          //console.log(res);
          this.msgList = res.data;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          //this.hideLoading();
        });
    },
    singleRead(id) {
      this.showLoading("处理中，请稍候");
      Message.singleRead(id)
        .then((res) => {
          this.showToast("已标记为已读！", "success", 3000);
          this.getMessageList();
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    allRead() {
      this.showLoading("处理中，请稍候");
      Message.allRead()
        .then((res) => {
          this.showToast("已将全部消息标记为已读！", "success", 5000);
          this.getMessageList();
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    allMsg() {
      this.$layer.iframe({
        content: {
          content: MailBox, //传递的组件对象
          parent: this, //当前的vue对象
          data: {
            iframeData: {},
          },
        },
        area: ["1280px", "720px"],
        title: "消息中心",
        shade: false,
        maxmin: true, //开启最大化最小化
        resize: false, //是否允许拉伸，默认是不允许
      });
    },
  },
  watch: {},
};
</script>
<style scoped>
.haveMsg {
  animation: shining 1s ease-in-out infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
