<template>
  <div class="px-4 pb-3" style="display: flex">
    <vxe-table
      style="width: 350px"
      :data="tagList"
      class="my-scrollbar"
      border
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tagList"
      :loading="tagTableLoading"
      :height="pageHeight - 180 + 'px'"
      size="small"
      :stripe="true"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'updated_at', order: 'desc' },
        orders: ['desc', 'asc', null],
        multiple: false,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      @cell-click="tagClick"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
      :export-config="{}"
      :cell-class-name="cellClassName"
    >
      <vxe-column
        fixed="left"
        field="name"
        :title="'标签'"
        min-width="80"
        sortable
      >
        <template #default="{ row }">
          {{ row.name }}
        </template>
      </vxe-column>

      <vxe-column field="pinyin" title="拼音" sortable :visible="false">
        <template #default="{ row }">
          {{ row.pinyin }}
        </template>
      </vxe-column>

      <vxe-column width="80" field="updated_at" title="更新日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <span v-if="row.updated_at"
              ><span
                :style="$stockHelpers.getDateColor(row.updated_at.split('')[0])"
                >{{ row.updated_at.split(" ")[0] }}</span
              ></span
            >
            <span v-else>-</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column width="70" field="MOM_1D" title="日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_1D)">{{
              $stockHelpers.getPercent(row.MOM_1D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column width="70" field="MOM_5D" title="5日" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_5D)">{{
              $stockHelpers.getPercent(row.MOM_5D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column width="70" field="MOM_10D" title="10日" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_10D)">{{
              $stockHelpers.getPercent(row.MOM_10D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column width="70" field="MOM_20D" title="20日" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_20D)">{{
              $stockHelpers.getPercent(row.MOM_20D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column width="70" field="MOM_60D" title="60日" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.MOM_60D)">{{
              $stockHelpers.getPercent(row.MOM_60D)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        field="user_name"
        title="跟踪人"
        sortable
        min-width="100"
        fixed="right"
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.user_name }}
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        field="user_id"
        title="跟踪id"
        sortable
        :visible="false"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'user_id')
        "
      >
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          {{ row.user_id }}
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import XEUtils from "xe-utils";
import Tag from "@/api/tag.js";
export default {
  name: "TagListWithLevel",
  components: {},
  props: {
    currTag: {},
    pageHeight: null,
  },
  data() {
    return {
      tagTableLoading: false,
      filterName: "",

      tagList: [],
      tagData: [],
    };
  },
  mounted() {
    this.getTagList();
    Bus.$on("refreshTagList", (e) => {
      this.getTagList();
    });
  },
  methods: {
    cellClassName({ row, column }) {
      if (column.field === "name") {
        if (row.level) {
          if (row.level === 1) {
            return "red lighten-5";
          } else if (row.level === 2) {
            return "orange lighten-5";
          } else if (row.level === 3) {
            return "green lighten-5";
          }
        } else {
          return "blue-grey lighten-5";
        }
      }
      return null;
    },
    userSelect(val) {
      //console.log(val);
      this.$tableHelpers.filterInput(this.$refs.tagList, "user_id", val);
    },
    tagClick({ row }) {
      this.$emit("tagClick", { row });
    },
    getTagList() {
      this.filterName = "";
      this.tagTableLoading = true;
      this.exportList = [];
      Tag.getTagsList()
        .then((res) => {
          this.tagList = res.data;
          this.tagData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.tagTableLoading = false;
        });
    },
    searchEvent(filter) {
      this.filterName = filter;
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "pinyin"];
        this.tagList = this.tagData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
      } else {
        this.tagList = this.tagData;
      }
      //return this.tableData;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
