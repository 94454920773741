<template>
  <v-list-item style="height: 64px; padding: 0">
    <v-list-item-avatar
      :tile="true"
      style="z-index: 10; margin-left: 15px"
      width="30"
    >
      <svg
        t="1651046793332"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1046"
      >
        <path
          d="M0 128a51.2 51.2 0 0 1 51.2-51.2h350.24a51.2 51.2 0 0 1 47.0592 31.0336L473.6 166.4h499.2a51.2 51.2 0 0 1 51.2 51.2v537.6a51.2 51.2 0 0 1-51.2 51.2H51.2a51.2 51.2 0 0 1-51.2-51.2V128z"
          fill="#FFA000"
          p-id="1047"
        ></path>
        <path
          d="M89.6 249.6m51.2 0l742.4 0q51.2 0 51.2 51.2l0 460.8q0 51.2-51.2 51.2l-742.4 0q-51.2 0-51.2-51.2l0-460.8q0-51.2 51.2-51.2Z"
          fill="#FFFFFF"
          p-id="1048"
        ></path>
        <path
          d="M0 332.8m51.2 0l921.6 0q51.2 0 51.2 51.2l0 512q0 51.2-51.2 51.2l-921.6 0q-51.2 0-51.2-51.2l0-512q0-51.2 51.2-51.2Z"
          fill="#FFCA28"
          p-id="1049"
        ></path>
      </svg>
    </v-list-item-avatar>

    <v-list-item-content style="z-index: 10">
      <v-list-item-title>{{ fileItem.name }}</v-list-item-title>

      <v-list-item-subtitle
        >{{ status }} 进度:{{ progress }}%</v-list-item-subtitle
      >
    </v-list-item-content>

    <!-- <v-list-item-action style="z-index: 10;"><v-btn style="margin-right: 10px;" color="primary" dense x-small outlined>删除</v-btn></v-list-item-action> -->
    <div
      :style="{
        width: progress + '%',
        backgroundColor: progress == 100 ? '#dfffbc' : '#9cddff',
      }"
      style="position: absolute; height: 100%; z-index: 9"
    ></div>
  </v-list-item>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
const OSS = require("ali-oss");
import File from "@/api/file.js";
export default {
  name: "UploadWorker",
  components: {},
  props: {
    fileItem: {},
  },
  data() {
    return {
      progress: 0,
      status: "正在获取上传所需凭据",
    };
  },
  mounted() {
    File.getUploadSts()
      .then((res) => {
        this.status = "正在上传中";

        this.uploadFile(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.status = "获取上传凭据错误";
      })
      .finally(() => {});
  },
  methods: {
    uploadFile(info) {
      let client = new OSS({
        region: "oss-cn-shanghai",
        accessKeyId: info.AccessKeyId,
        accessKeySecret: info.AccessKeySecret,
        stsToken: info.SecurityToken,
        bucket: "qinglitouyan",
      });

      let fileName = "fileStore/" + info.uuid + "/" + this.fileItem.name;
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      //client 是第一步中的 client
      client
        .multipartUpload(fileName, this.fileItem, {
          progress: (p) => {
            //获取进度条的值
            this.progress = (p * 100).toFixed(2);
          },
        })
        .then((result) => {
          //下面是如果对返回结果再进行处理，根据项目需要

          this.status = "上传完成，正在通知服务器";
          let url = result.res.requestUrls[0];
          if (url.indexOf("uploadId") != -1) {
            url = /(.+(?=\?uploadId))/.exec(url)[0];
          }
          let bindInfo = {};
          if (this.fileItem.uploadType == "tagFile") {
            if (this.fileItem.info.tagType == "race") {
              bindInfo.races = [this.fileItem.info.tagId];
            } else if (this.fileItem.info.tagType == "style") {
              bindInfo.styles = [this.fileItem.info.tagId];
            }
          } else if (this.fileItem.uploadType == "stockFile") {
            bindInfo.stocks = [this.fileItem.info.stockId];
          }

          File.uploadFile(
            info.uuid,
            "/fileStore/" + info.uuid,
            this.fileItem.name,
            bindInfo
          )
            .then((res) => {
              //console.log(res);
              this.status = "上传成功";
              if (this.fileItem.uploadType == "tagFile") {
                Bus.$emit("refreshTagDetail", {});
              } else if (this.fileItem.uploadType == "stockFile") {
                Bus.$emit("refreshStockBindFile", {});
              }
            })
            .catch((err) => {
              this.status = "上传失败，服务器文件入库出错！";
              console.log("err:", err);
            });
        })
        .catch((err) => {
          this.status = "上传失败，OSS文件上传出错";
          console.log("err:", err);
        });
    },
  },
  computed: {},
  watch: {},
};
</script>
<style></style>
