<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; min-width: 500px; width: 80%">
      <div class="d-flex align-center justify-center">
        <label
          class="mr-3 white-space-nowrap"
          style="width: 88px; font-size: 14px"
          >描述</label
        >
        <vxe-input
          v-model="desc"
          placeholder="填入该股票加入标签的描述"
          type="text"
          class="my-2"
          style="width: 100%"
        ></vxe-input>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "@/api/tag.js";
import Bus from "@/components/Bus/bus.js";

import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { SlateEditor } from "@wangeditor/editor";

export default {
  name: "StockGroupEdit",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.desc = this.iframeData.oldDesc;
  },

  data() {
    return {
      desc: "",
    };
  },
  methods: {
    submit() {
      this.showLoading("保存描述中，请稍候");
      Tag.editStockDesc(
        this.iframeData.tagId,
        this.iframeData.stockIds,
        this.desc
      )
        .then((res) => {
          this.showToast("保存描述成功", "success", 5000);

          //console.log(this.appStore.tagDetail.stock);
          this.appStore.tagDetail.stock.forEach((group) => {
            group.children.forEach((stock) => {
              if (this.iframeData.stockIds[0] == stock.id) {
                stock.reason = this.desc;
              }
            });
          });

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
