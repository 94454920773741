var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-1 py-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex pt-0",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"width":"400px","height":"calc(100vh - 50px)","position":"fixed"}},[_c('div',{staticClass:"d-flex px-2",staticStyle:{"height":"50px","width":"100%","align-items":"center","justify-content":"space-between"}},[_c('vxe-input',{staticStyle:{"width":"150px"},attrs:{"type":"search","placeholder":"搜索笔记","size":"medium","clearable":""},on:{"change":function($event){return _vm.getData(true)}},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('vxe-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"选择分类"},on:{"change":function($event){return _vm.typeChange()}},model:{value:(_vm.activeType),callback:function ($$v) {_vm.activeType=$$v},expression:"activeType"}},_vm._l((_vm.typeArr),function(item){return _c('vxe-option',{attrs:{"value":item.value,"label":item.label}})}),1)],1),_c('div',{staticStyle:{"height":"calc(100% - 80px)"}},[_c('vxe-table',{ref:"noteTable",staticStyle:{"cursor":"pointer"},attrs:{"id":"noteTable","row-id":"id","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.listLoading,"column-config":{ resizable: true },"height":"auto","size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"tooltip-config":{ showAll: false, enterable: true, theme: 'dark' }},on:{"scroll":_vm.tableScroll,"cell-click":_vm.cellClick}},[_c('vxe-column',{attrs:{"min-width":"100","field":"title","title":"标题"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start pl-1"},[_vm._v(" "+_vm._s(row.title)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"75","field":"created_at","title":"日期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at.split(" ")[0])+" ")]}}])}),_c('vxe-column',{attrs:{"width":"60","field":"type","title":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getType(row.type))+" ")]}}])}),_c('vxe-column',{attrs:{"width":"50","field":"user_name","title":"作者"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user_name)+" ")]}}])})],1)],1)]),_c('div',{},[_c('div',{staticStyle:{"width":"calc(100vw - 500px)","margin-left":"410px"}},[_c('note',{ref:"note",staticStyle:{"width":"100%"},attrs:{"typeArr":_vm.typeArr,"readItem":_vm.readItem},on:{"newNoteChange":_vm.newNoteChange}}),(_vm.readItem.id && !_vm.isNewNote)?_c('NoteComment',{attrs:{"noteId":_vm.readItem.id}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }