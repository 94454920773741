<template>
  <div class="pl-4 momentum-timeline" style="display: flex">
    <div v-if="!tableLoading" style="width: 100%">
      <div
        v-for="day in chartData"
        :key="day.date"
        class="pa-0"
        style="width: 100%"
      >
        <div class="pt-0 mt-2" style="width: 100%">
          <div>
            <p style="font-size: 12px">
              <strong>{{ day.date }}</strong> {{ weekArr[day.week - 1] }}
            </p>
          </div>
          <div class="tagGroup">
            <div v-for="tag in day.data" :key="tag.name" class="tagItem">
              <div
                style="
                  font-size: 11px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  width: 100%;
                  height: 100%;
                  cursor: pointer;
                "
                :style="{
                  backgroundColor: getBGColor(tag.value),
                }"
                @mouseenter="(event) => tagEnter(event, tag)"
                @mouseout="tagLeave"
                @click="$stockHelpers.openTagDetail(tag.id)"
              >
                <span
                  style="text-align: center"
                  @mouseenter="(event) => tagEnter(event, tag)"
                  >{{ tag.name }}</span
                >
                <span @mouseenter="(event) => tagEnter(event, tag)">{{
                  $stockHelpers.getPercent(tag.value)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tableLoading" class="mt-2">
      <v-skeleton-loader v-for="item in 1" v-bind="attrs" type="article">
      </v-skeleton-loader>
    </div>

    <hoverBox ref="tagHover">
      <template slot="content">
        <div
          class="px-3 py-3"
          style="
            width: 200px;
            height: 100%;
            font-size: 13px;
            white-space: pre-line;
          "
        >
          <span style="font-weight: bolder"
            >{{ currTag.name }}:<span
              :style="$stockHelpers.getColor(currTag.value)"
              >{{ $stockHelpers.getPercent(currTag.value) }}</span
            ></span
          >
          <div>
            <div v-for="stock in currTag.stocks" :key="stock.name">
              {{ stock.name }} :
              <span :style="$stockHelpers.getColor(stock.value)"
                >{{ $stockHelpers.getPercent(stock.value) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </hoverBox>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import hoverBox from "@/components/HoverBox";
export default {
  name: "MomTimeLine",
  components: { hoverBox },
  props: {},
  data() {
    return {
      tableLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      weekArr: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      chartData: [],
      currTag: {
        name: "",
        value: 0,
      },
      typeId: "pool",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableLoading = true;
      ResonanceContrast.getMomTimeLine(this.typeId, 1)
        .then((res) => {
          //console.log(res.data);
          this.chartData = res.data.slice(0, 3);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getBGColor(value) {
      if (value > 0) {
        let opacity = 1 - Math.abs(0.1 - value) / 0.1;
        if (value > 0.1) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(0.1 - value) / 0.1 - 1;
        if (value < -0.1) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    //显示弹窗
    tagEnter(event, tagData) {
      this.currTag = tagData;
      this.$refs.tagHover.show(
        event.clientX - 80,
        event.clientY + 20,
        "",
        "",
        ``
      );
    },
    //关闭弹窗
    tagLeave() {
      this.$refs.tagHover.hide();
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}

.tagGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  width: 100%;
}
.tagItem {
  padding: 20 0 20 0;
  color: #000;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  height: 40px;
  width: calc(99% / 10);
}
</style>
<style>
.momentum-timeline .v-timeline-item__dot {
  width: 0px !important;
  height: 0px !important;
}
</style>
