var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[_c('div',{},[_c('div',[_c('vxe-table',{ref:"sourceList",staticClass:"my-scrollbar",staticStyle:{"width":"100%"},attrs:{"id":"sourceList","data":_vm.appStore.tagDetail.source,"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","size":"small","sort-config":{
          trigger: 'cell',
          defaultSort: { field: 'pinyin', order: 'asc' },
          orders: ['desc', 'asc', null],
          multiple: true,
        },"scroll-x":{ gt: 30 },"scroll-y":{ mode: 'wheel', gt: 100 },"tooltip-config":{
          showAll: false,
          enterable: true,
          theme: 'dark',
        },"custom-config":{ storage: true },"filter-config":{ showIcon: false },"expand-config":{ trigger: 'row' },"border":"","row-config":{ isHover: true },"edit-config":{ trigger: 'click', mode: 'row' }}},[_c('vxe-column',{attrs:{"field":"name","title":"来源名称"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.name ? row.name : "无数据--点击编辑")+" ")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }