<template>
  <div class="pt-3">
    <v-card class="border-radius-md pa-3 mb-3">
      <div class="ml-2">
        <a
          class="pr-4"
          v-if="item.display"
          v-for="item in tabList"
          :key="item.url"
          :ripple="true"
          @click="currentTab = item.url"
          style="background-color: transparent; width: auto"
          :elevation="0"
        >
          <span
            class="pa-0"
            :class="{
              'active-tab': currentTab == item.url,
              'un-active-tab': currentTab != item.url,
            }"
            >{{ item.title }}</span
          >
        </a>
      </div>
      <v-tabs-items v-model="currentTab" class="pa-2">
        <v-tab-item :key="0">
          <Zhongxinerji @getUpdateTime="getUpdateTime"></Zhongxinerji>
        </v-tab-item>

        <v-tab-item :key="1">
          <Tonghuashun @getUpdateTime="getUpdateTime"></Tonghuashun>
        </v-tab-item>

        <v-tab-item :key="2">
          <Shenwanerji @getUpdateTime="getUpdateTime"></Shenwanerji>
        </v-tab-item>

        <v-tab-item :key="3">
          <Shenwansanji @getUpdateTime="getUpdateTime"></Shenwansanji>
        </v-tab-item>

        <v-tab-item :key="4">
          <Qinglibiaoqian @getUpdateTime="getUpdateTime"></Qinglibiaoqian>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div style="color: #ccc; font-size: 12px">更新时间：{{ updated_on }}</div>
  </div>
</template>

<script>
import Tonghuashun from "@/components/ResonanceContrast/Momentum/View/Tonghuashun";
import Zhongxinerji from "@/components/ResonanceContrast/Momentum/View/Zhongxinerji";
import Shenwanerji from "@/components/ResonanceContrast/Momentum/View/Shenwanerji";
import Shenwansanji from "@/components/ResonanceContrast/Momentum/View/Shenwansanji";
import Qinglibiaoqian from "@/components/ResonanceContrast/Momentum/View/Qinglibiaoqian";
export default {
  name: "Momentum",
  components: {
    Tonghuashun,
    Zhongxinerji,
    Shenwanerji,
    Shenwansanji,
    Qinglibiaoqian,
  },
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          title: "中信二级行业",
          url: 0,
          display: true,
        },
        {
          title: "同花顺板块",
          url: 1,
          display: true,
        },
        {
          title: "申万二级行业",
          url: 2,
          display: true,
        },
        {
          title: "申万三级行业",
          url: 3,
          display: true,
        },
        {
          title: "青骊标签",
          url: 4,
          //display: true,
          display: this.$commonHelpers.getPermissions("momentum.pool"),
        },
      ],
      updated_on: "",
    };
  },
  mounted() {},
  methods: {
    getUpdateTime(time) {
      this.updated_on = time;
    },
    jumpTo(key) {
      this.currentTab = key;
    },
  },
  watch: {},
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
