var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-3 py-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex pt-3"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"500px"}},[_c('v-card',{staticClass:"border-radius-md pa-3"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"pt-1"},[_vm._v("公告汇总")]),_c('div',[_c('vxe-input',{staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":"股票、关键词","size":"mini","clearable":""},on:{"change":function($event){return _vm.getAnnouncementList(true)}},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)]),_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('vxe-input',{staticStyle:{"width":"200px"},attrs:{"type":"date","placeholder":"起始日期","size":"mini","clearable":""},on:{"change":function($event){return _vm.getAnnouncementList(true)}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('span',{},[_vm._v(" —— ")]),_c('vxe-input',{staticStyle:{"width":"200px"},attrs:{"type":"date","placeholder":"截止日期","size":"mini","clearable":""},on:{"change":function($event){return _vm.getAnnouncementList(true)}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1),_c('div',{staticClass:"mt-3"},[_c('vxe-table',{ref:"announcementTable",attrs:{"id":"announcementTable","row-id":"ID","data":_vm.tableData,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"left","loading":_vm.announcementTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight + 'px',"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"tooltip-config":{
              showAll: false,
              enterable: true,
              theme: 'dark',
            }},on:{"scroll":_vm.tableScroll,"cell-click":_vm.cellClick}},[_c('vxe-column',{attrs:{"fixed":"left","width":"80","field":"stock_code","title":"股票"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.stock_name)+" ")]}}])}),_c('vxe-column',{attrs:{"width":"75","field":"created_at","title":"日期"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.date)+" ")]}}])}),_c('vxe-column',{attrs:{"min-width":"80","field":"title","title":"公告名"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('div',{staticStyle:{"word-wrap":"break-word","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(column.title)+" ")])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.title)+" ")]}}])})],1)],1)])],1),_c('v-card',{staticClass:"border-radius-md pa-3",staticStyle:{"width":"calc(100% - 500px)","height":"calc(100vh - 100px)"}},[(_vm.showStock)?_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"height":"40px"}},[_c('div',[_c('span',{staticStyle:{"color":"#c10000"}},[_vm._v(_vm._s(_vm.showStock ? _vm.showStock.stock.name : '')+"("+_vm._s(_vm.showStock ? _vm.showStock.stock.code : '')+")")]),_c('small',{staticClass:"ml-2"},[_vm._v("《"+_vm._s(_vm.showStock ? _vm.showStock.title : '')+"》")])]),_c('div',{staticStyle:{"width":"160px","display":"flex"}},[(_vm.$commonHelpers.getPermissions('AI.AiAnalysis'))?_c('vxe-button',{staticClass:"ml-0 mr-1",staticStyle:{"width":"80px"},attrs:{"status":"primary","size":"medium"},on:{"click":function($event){return _vm.AiAnalysis(_vm.showStock)}}},[_vm._v(" AI解析 ")]):_vm._e(),_c('vxe-button',{staticClass:"ml-0 mr-1",attrs:{"status":"primary","size":"medium","content":"评分"},on:{"click":function($event){return _vm.openRate(_vm.showStock)}}})],1)]):_vm._e(),_c('iframe',{staticStyle:{"height":"calc(100% - 40px)","border":"none","width":"100%"},attrs:{"src":_vm.showStock ? _vm.showStock.url : ''}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }