<template>
  <v-container fluid class="px-1 py-1">
    <div class="d-flex pt-2">
      <component :is="dynamicComponent" style="width: calc(100%)"></component>
    </div>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "Review",
  components: {},
  data() {
    return {
      currTab: "todaySuggestion",
      reviewTabList: this.appStore.reviewTabList,
    };
  },
  computed: {
    //动态引入组件
    dynamicComponent() {
      if (this.currTab) {
        return () => {
          return import(`@/components/Review/${this.currTab}/${this.currTab}`);
        };
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.currTab = this.$route.params.id;
      Bus.$emit("AppBarReviewChange", this.$route.params.id);
    }
    Bus.$on("reviewTabChange", (e) => {
      this.currTab = e;
    });
  },
};
</script>
