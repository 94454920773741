var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"mx-5",attrs:{"custom":"","refresh":{ query: _vm.refreshData }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return undefined},proxy:true}])},[_vm._v("> ")]),_c('vxe-table',{ref:"applyTable",attrs:{"id":"applyTable","data":_vm.applyList,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.applyTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight,"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 50, oSize: 30 },"empty-render":{ name: 'NotData' },"tooltip-config":{ showAll: false, enterable: true, theme: 'dark' }}},[_c('vxe-column',{attrs:{"field":"stock.code","title":"股票代码","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.stock.code)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"stock.name","title":"股票名称","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.stock.name)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"date","title":"申请日期","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.date)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"apply_type_name","title":"方向","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.apply_type_name)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"reason","title":"申请理由","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.reason)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"user.base","title":"申请人","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.user ? row.user.base : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"status","title":"状态","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(_vm.getStatus(row.status))+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"修正","type":"seq","show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.user_id == _vm.userStore.user_id)?_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-3",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.cancel(row.id)}}},[_vm._v("撤销")]):_vm._e(),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-3",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.pass(row.id)}}},[_vm._v("通过")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-3",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.refuse(row.id)}}},[_vm._v("拒绝")])]}}])})],1),_c('vxe-pager',{attrs:{"current-page":_vm.pageInfo.currentPage,"page-size":_vm.pageInfo.pageSize,"total":_vm.pageInfo.totalResult,"layouts":[
      'PrevPage',
      'JumpNumber',
      'NextPage',
      'FullJump',
      'Sizes',
      'Total' ]},on:{"update:pageSize":function($event){return _vm.$set(_vm.pageInfo, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pageInfo, "pageSize", $event)},"page-change":_vm.handlePageChange1}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }