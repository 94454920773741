<template>
  <div>
    <vxe-table
      :data="pageData"
      style="width: 100%; min-height: 300px"
      class="mytable-scrollbar"
      :border="false"
      resizable
      :scroll-y="{ enabled: false }"
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="chartTable"
      :loading="tableLoading"
      max-height="100000"
      size="small"
      :sort-config="{ trigger: 'cell', multiple: false }"
      :empty-render="{ name: 'NotData' }"
      align="left"
      @sort-change="sortChangeEvent"
    >
      <vxe-column
        fixed="left"
        min-width="100"
        field="sec_name"
        title="金股组合名称"
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.sec_name ? row.sec_name : '-' }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        title="年化收益率"
        field="annual_return"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.annual_return)">{{
              $stockHelpers.getPercent(row.annual_return)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="累计收益" field="cum_return" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.cum_return)">{{
              $stockHelpers.getPercent(row.cum_return)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        title="波动率"
        field="annual_volatility"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span>{{ $stockHelpers.getPercent(row.annual_volatility) }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="夏普" field="sharpe_ratio" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.sharpe_ratio.toFixed(2) }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="最大回撤" field="max_drawdown" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span>{{ $stockHelpers.getPercent(row.max_drawdown) }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        title="超额收益率"
        field="excess_rate"
        sortable
      >
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.excess_rate)">{{
              $stockHelpers.getPercent(row.excess_rate)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="近一周收益" field="mom_w" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.mom_w)">{{
              $stockHelpers.getPercent(row.mom_w)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="近一月收益" field="mom_m" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.mom_m)">{{
              $stockHelpers.getPercent(row.mom_m)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="近一季收益" field="mom_q" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.mom_q)">{{
              $stockHelpers.getPercent(row.mom_q)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="70" title="近一年收益" field="mom_y" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.mom_y)">{{
              $stockHelpers.getPercent(row.mom_y)
            }}</span>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      :current-page="pageInfo.currentPage"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.totalResult"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
      @page-change="
        (value) => getPage(value, this.list, [$refs['chartTable']], pageInfo)
      "
    >
    </vxe-pager>
  </div>
</template>

<script>
export default {
  name: 'GoldStockTable',
  components: {},
  props: {
    list: [],
    type: '',
  },
  data() {
    return {
      tableLoading: true,

      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        totalResult: this.list.length,
      },
      pageData: [],
      sortInfo: {
        field: 'sec_name',
        order: 'desc',
      },
    };
  },
  mounted() {},
  methods: {
    getPage(pager, tableData, table, pageObj) {
      pageObj.currentPage = pager.currentPage;
      this.pageData = this.$tableHelpers.getPageWithSort(
        pager,
        tableData,
        table,
        this.sortInfo
      );
    },
    sortChangeEvent({ sortList }) {
      //console.log(sortList);
      this.sortInfo = sortList[0];

      this.pageData = this.$tableHelpers.getPageWithSort(
        this.pageInfo,
        this.list,
        [this.$refs.chartTable],
        this.sortInfo
      );
    },
  },
  watch: {
    list: {
      handler(newValue, oldValue) {
        //console.log('props', newValue);
        if (newValue.length > 0) {
          this.tableLoading = false;
        }
        this.pageData = this.$tableHelpers.getPageWithSort(
          this.pageInfo,
          newValue,
          [this.$refs.chartTable],
          this.sortInfo
        );
        this.pageData.totalResult = newValue.length;
      },
      immediate: true,
    },
  },
};
</script>
