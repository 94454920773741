<template>
  <TableFilter
    :inputWidth="inputWidth"
    :colName="colName"
    :autoSave="false"
    :selectData="selectData"
    :table="table"
    :placeholder="placeholder"
  ></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter';
export default {
  name: '',
  props: {
    placeholder: '',
    table: '',
    colName: '',
    inputWidth: '',
  },
  data() {
    return {
      selectData: [
        // { label: '待审核', value: '待审核' },
        // { label: '已通过', value: '已通过' },
        // { label: '已驳回', value: '已驳回' },
        { label: '待审核', value: '待审核' },
        { label: '已通过', value: '已通过' },
        { label: '已驳回', value: '已驳回' },
        { label: '已完成', value: '已完成' },
      ],
      inputValue: '',
    };
  },
  components: {
    TableFilter,
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
