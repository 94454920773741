<template>
  <div>
    <div v-if="tradingHistory.length == 0">
      <v-skeleton-loader v-for="item in 20" v-bind="attrs" type="card-avatar, article" :style="{ height: '500px' }">
      </v-skeleton-loader>
    </div>

    <!-- 过滤掉测试帐号 -->
    <v-card class="border-radius-md mt-2 pa-1" v-for="item in tradingHistory" :key="item.id"
      v-if="item.firm.user_name != '李逸伦'">

      <div class="border-bottom d-flex align-center px-2 py-1">
        <div class="d-flex align-center">
          <v-avatar size="37" rounded>
            <v-img src="@/assets/img/user/stock.png" alt="profile-image"></v-img>
          </v-avatar>
          <div class="mx-4">
            <v-chip dense small dark :color="item.firm.user_type == '内部' ? '#ffaa00' : '#344767'" class="mr-1 px-2 py-0"
              style="height: 16px;opacity: 0.8;margin-right: 1px;">
              {{ item.firm.user_type }}
            </v-chip>
            <span href="javascript:;" class="text-dark font-weight-800 text-decoration-none">
              {{ item.stock[0].name }}-{{ item.stock[0].code }}
            </span>
            <small class="d-block text-muted" style="letter-spacing:2px;">
              <span>{{ item.firm.name }}({{ item.firm.user_name }})</span>
              <span class="ml-3">{{ item.order_at }}</span>
            </small>
          </div>
        </div>
        <div class="text-end ms-auto">
          <vxe-button @click="openPortfolio(item.firm.id)" size="mini" content="查看组合">
          </vxe-button>
        </div>
      </div>
      <div class="px-5 pt-2 pb-1">

        <div class="mb-2 text-body">
          <span class="text-sm font-weight-bolder mr-2" :class="[getDirectionColor(item.direction)]">
            {{ item.direction == 1 ? '买入' : '卖出' }}
          </span>
          {{ item.comment }}
        </div>

      </div>
    </v-card>
  </div>
</template>
<script>
import ShowMoreText from '@/components/ShowMoreText';
import Portfolio from '@/api/portfolio.js';
export default {
  name: 'TradeHistory',
  props: {

  },
  components: {
    ShowMoreText,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 2,
      },
      tradingHistory: [],
    };
  },
  mounted() {
    this.getTradingHistory();
  },
  methods: {
    getTradingHistory() {
      Portfolio.getTradingHistoryList().then(res => {
        this.tradingHistory = res.data;
      }).catch(err => {
        console.log(err);
      })
    },
    getDirectionColor(val) {
      if (val == 1) {
        return 'text-red lighten-1 font-weight-bolder';
      } else {
        return 'text-teal lighten-1 font-weight-bolder';
      }
    },
    openPortfolio(id) {
      this.$router.push({ name: '组合详情', params: { id: id } });
    },
    getContent(item1) {
      return '<b class="text-dark" style="font-size: 15px;">评分理由</b>:' + item1.reason ? item1.reason : '无';
    },
  },
};
</script>
<style scoped="scoped">
</style>
