<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div class="px-0 py-1" v-show="!tableLoading">
      <div v-for="item in list" :key="item.id">
        <v-card class="border-radius-md pa-3 mb-3">
          <h3>
            {{ item.name }}
            <span
              style="font-size: 14px; margin-left: 10px"
              :style="$stockHelpers.getColor(item.pct_chg)"
              >当日涨跌幅:{{ $stockHelpers.getPercent(item.pct_chg) }}</span
            >
          </h3>
          <p>
            理由：{{ item.description == "nan" ? "暂无" : item.description }}
          </p>
          <vxe-table
            :data="item.stocks"
            style="width: 100%"
            class="mytable-scrollbar"
            :border="false"
            resizable
            :scroll-y="{ enabled: false }"
            show-overflow
            show-header-overflow
            highlight-hover-row
            ref="bindStockTable"
            :loading="tableLoading"
            max-height="100000"
            size="small"
            :sort-config="{ trigger: 'cell', multiple: true }"
            :empty-render="{ name: 'NotData' }"
            align="left"
          >
            <vxe-column
              fixed="left"
              min-width="80"
              field="code"
              title="股票代码"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  {{ row.code ? row.code : "-" }}
                </div>
              </template>
            </vxe-column>

            <vxe-column
              fixed="left"
              min-width="80"
              field="prod_name"
              title="股票名称"
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  {{ row.prod_name ? row.prod_name : "-" }}
                </div>
              </template>
            </vxe-column>

            <vxe-column
              min-width="100"
              field="m_days_n_boards"
              title="是否连板"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <span
                    v-if="row.m_days_n_boards"
                    style="
                      background-color: #fff1f1;
                      border: 1px solid #e80000;
                      color: #e80000;
                      padding: 2px 5px;
                    "
                    >{{ row.m_days_n_boards }}</span
                  >
                </div>
              </template>
            </vxe-column>

            <vxe-column
              min-width="70"
              title="收盘价"
              field="cur_price"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  <span :style="$stockHelpers.getColor(row.px_change_rate)">{{
                    row.cur_price
                  }}</span>
                </div>
              </template>
            </vxe-column>

            <vxe-column
              min-width="70"
              title="涨幅"
              field="px_change_rate"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  <span :style="$stockHelpers.getColor(row.px_change_rate)">{{
                    $stockHelpers.getPercent(row.px_change_rate)
                  }}</span>
                </div>
              </template>
            </vxe-column>

            <vxe-column
              field="circulation_value"
              min-width="70"
              title="流通市值(亿)"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  {{
                    row.circulation_value
                      ? (row.circulation_value / 100000000).toFixed(2)
                      : ""
                  }}
                </div>
              </template>
            </vxe-column>

            <vxe-column
              min-width="70"
              title="换手率"
              field="turnover_ratio"
              sortable
            >
              <template #default="{ row }">
                <div class="d-flex justify-end">
                  {{
                    row.turnover_ratio
                      ? $stockHelpers.getPercent(row.turnover_ratio)
                      : "-"
                  }}
                </div>
              </template>
            </vxe-column>

            <vxe-column min-width="300" field="races" title="标签">
              <template #default="{ row }">
                <div class="d-flex justify-start">
                  <div class="d-flex" style="flex-wrap: wrap">
                    <span
                      v-for="(race, index) in row.races"
                      v-if="race.level !== 0"
                      :key="race.id"
                      class="px-1 py-0"
                      style="
                        cursor: pointer;
                        font-size: 10px;
                        color: #fff;
                        border-radius: 3px;
                        margin-bottom: 1px;
                        margin-right: 1px;
                      "
                      :style="{
                        backgroundColor:
                          race.color == `#e8f5e9` ? `#00b294` : race.color,
                      }"
                      @click="$stockHelpers.openTagDetail(race.id)"
                    >
                      {{ race.name }}
                    </span>
                  </div>
                </div>
              </template>
            </vxe-column>

            <vxe-column title="解读" width="500">
              <template #default="{ row }" class="px-1">
                {{ row.description }}
              </template>
            </vxe-column>
          </vxe-table>
        </v-card>
      </div>
      <div style="color: #ccc; font-size: 12px">
        更新时间：{{ list[0] ? list[0].updated_on : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
export default {
  name: "Hot",
  components: {},
  data() {
    return {
      tableLoading: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      ResonanceContrast.getHotpot()
        .then((res) => {
          //console.log(res.data);
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
