<template>
  <vxe-pulldown ref="selectDown">
    <template #default>
      <vxe-input
        v-model="inputValue"
        :placeholder="placeholder"
        @focus="focusEvent"
        @keyup="keyupEvent"
        @clear="clearEvent"
        clearable
        :style="{ width: inputWidth }"
        style="z-index: 1; position: relative"
      ></vxe-input>
    </template>
    <template #dropdown style="z-index: 99999999999">
      <div style="z-index: 99999999999">
        <vxe-list max-height="200" class="my-dropdown" :data="selectList">
          <template #default="{ items }">
            <div
              class="list-item"
              v-for="item in items"
              :key="item.value"
              @click="selectEvent(item)"
            >
              <span>{{ item.label }}</span>
            </div>
          </template>
        </vxe-list>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
import User from "@/api/user.js";
export default {
  name: "TableFilter",
  props: {
    colName: "",
    placeholder: "",
    table: "",
    //自动保存最后一次选择
    autoSave: false,
    selectData: [],
    inputWidth: "",
    returnId: false,
    //默认选择项
    defaultSelect: "",
  },
  data() {
    return {
      selectList: [],
      inputValue: "",
    };
  },
  mounted() {
    this.selectList = JSON.parse(JSON.stringify(this.selectData));
    //处理自动选择
    if (this.autoSave) {
      if (localStorage.getItem("filterAutoSave_" + this.colName)) {
        setTimeout(() => {
          this.inputValue = localStorage.getItem(
            "filterAutoSave_" + this.colName
          );
          this.$tableHelpers.filterInput(
            this.table,
            this.colName,
            this.inputValue
          );
        }, 500);
      }
    }
  },
  methods: {
    focusEvent() {
      this.$refs.selectDown.showPanel();
    },
    selectEvent(item) {
      this.inputValue = item.label;
      this.$refs.selectDown.hidePanel();
      this.selectList = JSON.parse(JSON.stringify(this.selectData));
      if (this.autoSave) {
        localStorage.setItem("filterAutoSave_" + this.colName, item.label);
      }
      if (this.returnId) {
        this.$emit("change", item.id);
      } else {
        this.$tableHelpers.filterInput(this.table, this.colName, item.label);
      }
    },
    keyupEvent() {
      if (this.inputValue != "") {
        this.selectList = this.selectData.filter(
          (item) => item.label.indexOf(this.inputValue) > -1
        );
      } else {
        this.selectList = JSON.parse(JSON.stringify(this.selectData));
      }
    },
    clearEvent() {
      this.selectList = JSON.parse(JSON.stringify(this.selectData));
      if (this.autoSave) {
        localStorage.removeItem("filterAutoSave_" + this.colName);
      }
      if (this.returnId) {
        this.$emit("change", "");
      } else {
        this.$tableHelpers.filterInput(this.table, this.colName, "");
      }
    },
  },
  watch: {
    selectData(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
      //处理默认选择项
      if (this.defaultSelect) {
        setTimeout(() => {
          this.inputValue = this.defaultSelect;
          if (!this.returnId) {
            this.$tableHelpers.filterInput(
              this.table,
              this.colName,
              this.inputValue
            );
          }
        }, 500);
      }
    },
  },
};
</script>
<style scoped>
.my-dropdown {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  background-color: #fff;
}
.list-item {
  padding: 6px;
  font-size: 12px;
}
.list-item:hover {
  background-color: #f5f7fa;
}
</style>
