import { render, staticRenderFns } from "./AddStock.vue?vue&type=template&id=04e23e8a"
import script from "./AddStock.vue?vue&type=script&lang=js"
export * from "./AddStock.vue?vue&type=script&lang=js"
import style0 from "./AddStock.vue?vue&type=style&index=0&id=04e23e8a&prod&lang=css"
import style1 from "@vueform/slider/themes/default.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports