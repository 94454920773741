import { render, staticRenderFns } from "./Zhongxinerji.vue?vue&type=template&id=135e390b&scoped=true"
import script from "./Zhongxinerji.vue?vue&type=script&lang=js"
export * from "./Zhongxinerji.vue?vue&type=script&lang=js"
import style0 from "./Zhongxinerji.vue?vue&type=style&index=0&id=135e390b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135e390b",
  null
  
)

export default component.exports