import Request from '@/components/request/js/index.js';

export default {
  //获取调研列表
  getResearchList() {
    return Request().get('/api/researches', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //审批调研
  applyResearch(id, status, audit_comment) {
    return Request().post('/api/researches/' + id + '/apply', {
      header: {
        contentType: 'application/json',
      },
      data: {
        status: status,
        audit_comment: audit_comment,
      },
    });
  },
  //新建调研
  addResearch(stock_id, research_date, comment, file_id) {
    return Request().post('/api/researches', {
      header: {
        contentType: 'application/json',
      },
      data: {
        stock_id: stock_id,
        research_date: research_date,
        comment: comment,
        file_id: file_id,
      },
    });
  },
  //完成调研
  finishResearch(id) {
    return Request().post('/api/researches/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: {
        status: 3,
      },
    });
  },
  //修改调研
  editResearch(id, data) {
    return Request().post('/api/researches/' + id, {
      header: {
        contentType: 'application/json',
      },
      data: data,
    });
  },
};
