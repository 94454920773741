<template>
  <div>
    <v-container fluid class="py-1">
      <v-tabs-items v-model="currentTab">
        <v-tab-item :key="0">
          <Chat :pageHeight="pageHeight"></Chat>
        </v-tab-item>
        <!-- <v-tab-item :key="1">
          <ChatImg :pageHeight="pageHeight"></ChatImg>
        </v-tab-item> -->

        <!-- <v-tab-item :key="2">
          <Assistant :pageHeight="pageHeight"></Assistant>
        </v-tab-item> -->
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Chat from "@/components/Ai/Chat";
import ChatImg from "@/components/Ai/ChatImg";
import Assistant from "@/components/Ai/Assistant";
import Bing from "@/components/Ai/Bing";
export default {
  name: "VolatilitySurfaces",
  components: { Chat, ChatImg, Assistant, Bing },
  data() {
    return {
      currentTab: 0,
      pageHeight: 500,
    };
  },
  mounted() {
    this.pageHeight = document.body.clientHeight - 100;
    Bus.$on("AiTabChange", (e) => {
      this.currentTab = e;
      //console.log(e);
    });
  },
};
</script>
