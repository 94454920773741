<template>
	<div>
		<div class="pa-1" style="display: flex;justify-content: space-between;">
			<span class="text-body text-h6 font-weight-bolder">持股明细<span style="font-size: 14px;"> (仓位:{{$stockHelpers.getPercent(portfolioDetail.proportion)}})</span></span>
			<div>
				<vxe-button @click="exportStock()" size="small" content="导出持仓" status="my-primary"></vxe-button>
				<vxe-button @click="addStock()" size="small" content="添加成分股" status="my-danger"></vxe-button>
			</div>
		</div>
		<vxe-table
			id="tradingTable"
			class="mytable-scrollbar"
			:data="portfolioDetail.holdings"
			:border="false"
			resizable
			show-overflow
			show-header-overflow
			highlight-hover-row
			align="center"
			ref="tradingTable"
			:height="tableHeight"
			:column-config="{ resizable: true }"
			size="mini"
			:stripe="true"
			:sort-config="{ trigger: 'cell', multiple: true }"
			:scroll-y="{ mode: 'wheel', gt: 100 }"
			:empty-render="{ name: 'NotData' }"
			:export-config="{}"
		>
		
			<vxe-column width="80" field="code" title="股票代码">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.code ? row.code : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="80" field="stock[0].name" title="股票名称">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.stock[0].name ? row.stock[0].name : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="120" field="sw_industry.industry_name" title="申万三级" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-start">
						{{ row.sw_industry ? row.sw_industry.industry_name : '-' }}
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="income_rate" title="总收益率" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.income_rate)">{{ $stockHelpers.getPercent(row.income_rate) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="day_rate" title="日涨幅" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span :style="$stockHelpers.getColor(row.day_rate)">{{ $stockHelpers.getPercent(row.day_rate) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="wait_pos_ratio" title="待交易仓位" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span>{{ $stockHelpers.getPercent(row.wait_pos_ratio) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column min-width="80" field="proportion" title="仓位" sortable>
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<div class="d-flex justify-end">
						<span>{{ $stockHelpers.getPercent(row.proportion) }}</span>
					</div>
				</template>
			</vxe-column>
			
			<vxe-column width="100" title="操作">
				<template #header="{ column }">
					<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
				</template>
				<template #default="{ row }">
					<a href="javascript:;" @click="restocking(row)" class="text-xs font-weight-normal text-typo" v-if="isSelf">调仓</a>
					<a href="javascript:;" style="color: #ccc;" class="text-xs font-weight-normal" v-if="!isSelf">调仓</a>
				</template>
			</vxe-column>
			
		</vxe-table>
	</div>
</template>
<script>
import Portfolio from '@/api/portfolio.js';
import Bus from '@/components/Bus/bus.js';
import AddStock from '@/components/Portfolio/AddStock';
import Restocking from '@/components/Portfolio/Restocking';

export default {
	name: 'Trading',
	components: {
		
	},
	props: {
		portfolioDetail:{},
		isSelf:false,
	},
	mounted() {
		//console.log(this.portfolioDetail);
		//this.tableHeight = document.body.offsetHeight - 600 + 'px';
	},
	data() {
		return {
			tableHeight: '280px',
		};
	},
	methods: {
		addStock(){
			//this.showToast('测试版暂不支持','warning',5000);return;
			this.$layer.iframe({
				content: {
					content: AddStock,
					parent: this,
					data: { iframeData: {...this.portfolioDetail} }
				},
				area: ['600px', '400px'],
				title: '添加成分股(带*为必填)',
				maxmin: false,
				shade: true,
				shadeClose: false,
				cancel: () => {
					//关闭弹窗事件
				}
			});
		},
		//调仓
		restocking(item){
			//console.log(item);
			this.$layer.iframe({
				content: {
					content: Restocking,
					parent: this,
					data: { 
						iframeData: {
							...this.portfolioDetail,
							stock:item.stock[0],
							proportion:item.proportion,
						} ,
					}
				},
				area: ['600px', '400px'],
				title: '调仓(带*为必填)',
				maxmin: false,
				shade: true,
				shadeClose: false,
				cancel: () => {
					//关闭弹窗事件
				}
			});
		},
		exportStock(){
			this.$refs.tradingTable.openExport({  })
		},
	},
	computed: {},
	watch: {
		
	}
};
</script>
<style scoped="scoped"></style>
