<template>
  <div>
    <h3 class="text-dark" style="opacity: 0.8">标签热度</h3>
    <div
      :id="typeId + 'TagHeat'"
      style="height: 800px; width: calc(100vw - 170px); z-index: 10"
      v-show="!tableLoading"
    ></div>
    <div v-if="tableLoading" class="mt-2">
      <v-skeleton-loader v-for="item in 2" v-bind="attrs" type="article">
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
export default {
  name: "TagHeatChart",
  components: {},
  props: {
    typeId: "",
  },
  data() {
    return {
      tableLoading: true,
      myChart: null,
      chartData: [],
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  mounted() {
    let chartDom = document.getElementById(this.typeId + "TagHeat");
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    ResonanceContrast.getTagHeat(this.typeId)
      .then((res) => {
        console.log(res.data);
        this.chartData = res.data;
        this.initChart();
      })
      .catch((err) => {
        console.log(err);
        this.showToast(err.msg, "danger", 10000);
      })
      .finally(() => {
        this.tableLoading = false;
      });
  },
  methods: {
    initChart() {
      //console.log(data);
      let option = {
        animation: false,

        tooltip: {
          formatter: (info) => {
            //console.log(info);
            var value = info.value;
            var treePathInfo = info.treePathInfo;
            var treePath = [];
            for (var i = 1; i < treePathInfo.length; i++) {
              treePath.push(treePathInfo[i].name);
            }
            return [
              '<div class="tooltip-title">' +
                this.$echarts.format.encodeHTML(treePath.join("/")) +
                "</div>",
              value[1]
                ? "1日收益: " + (parseFloat(value[1]) * 100).toFixed(2) + "%"
                : "",
            ].join("");
          },
        },
        series: [
          {
            width: "100%",
            height: "100%",
            animation: false,
            squareRatio: 1,
            ...this.chartData[0],

            visualMin: this.chartData[0].min,
            visualMax: this.chartData[0].max,
            zoomToNodeRatio: 0.2,

            roam: "move",
            visualDimension: 1,

            upperLabel: {
              show: true,
              height: 20,
            },
            levels: [
              {
                visualDimension: 1,
                color: [
                  "#053061",
                  "#2166ac",
                  "#4393c3",
                  "#92c5de",
                  "#bbddf0",
                  "#d1d1d1",
                  "#fddbc7",
                  "#f4a582",
                  "#d6604d",
                  "#b2182b",
                  "#67001f",
                ],
                visualMin: this.chartData[0].min,
                visualMax: this.chartData[0].max,
                colorMappingBy: "value",
                itemStyle: {
                  borderWidth: 5,
                  gapWidth: 5,
                  borderColor: "#ccc",
                },
              },
              {
                visualDimension: 1,
                color: [
                  "#053061",
                  "#2166ac",
                  "#4393c3",
                  "#92c5de",
                  "#bbddf0",
                  "#d1d1d1",
                  "#fddbc7",
                  "#f4a582",
                  "#d6604d",
                  "#b2182b",
                  "#67001f",
                ],
                visualMin: this.chartData[0].min,
                visualMax: this.chartData[0].max,
                colorMappingBy: "value",
                itemStyle: {
                  borderWidth: 1,
                  gapWidth: 1,
                  borderColorSaturation: 0.7,
                },
              },
              {
                visualDimension: 1,
                color: [
                  "#053061",
                  "#2166ac",
                  "#4393c3",
                  "#92c5de",
                  "#bbddf0",
                  "#d1d1d1",
                  "#fddbc7",
                  "#f4a582",
                  "#d6604d",
                  "#b2182b",
                  "#67001f",
                ],
                visualMin: this.chartData[0].min,
                visualMax: this.chartData[0].max,
                colorMappingBy: "value",
                itemStyle: {
                  borderWidth: 1,
                  gapWidth: 1,
                  borderColorSaturation: 0.9,
                },
              },
            ],
          },
        ],
      };
      //console.log(option);
      this.myChart.setOption(option);
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
