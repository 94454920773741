<template>
  <div>
    <LoadingPage :visible="pageLoading"></LoadingPage>
    <v-container
      fluid
      class="px-6 py-0 mt-0 mb-4"
      style="display: flex; justify-content: center"
    >
      <div style="max-width: 1700px; width: 100%" v-if="!pageLoading">
        <v-card class="border-radius-md mt-6 pa-3">
          <div>
            <div class="d-flex justify-space-between align-items-center">
              <span class="text-body text-h5 font-weight-bolder"
                >{{ portfolioDetail.user_name }} -
                {{ portfolioDetail.name }}</span
              >
              <div class="d-flex justify-center align-items-center">
                <vxe-button
                  @click="portfolioEdit()"
                  size="small"
                  content="修改组合"
                  v-if="isSelf"
                  class="mx-1"
                >
                </vxe-button>
                <vxe-button
                  @click="instructions()"
                  size="small"
                  content="使用说明"
                  class="mx-1"
                  status="my-danger"
                >
                </vxe-button>
                <vxe-select
                  v-model="benchmarkType"
                  size="small"
                  placeholder="业绩基准"
                  class="mx-1"
                  style="width: 130px"
                >
                  <vxe-option
                    v-for="(item, index) in benchmarkTypeMap"
                    :key="index"
                    :value="item"
                    :label="'基准:' + item"
                  >
                  </vxe-option>
                </vxe-select>
              </div>
            </div>
            <div class="pt-2">
              <vxe-table
                id="portfolioTable"
                class="mytable-scrollbar"
                :data="[portfolioDetail]"
                border
                resizable
                show-overflow
                show-header-overflow
                highlight-hover-row
                align="center"
                ref="portfolioTable"
                :column-config="{ resizable: true }"
                size="small"
                :stripe="true"
                :sort-config="{ trigger: 'cell', multiple: true }"
                :scroll-y="{ mode: 'wheel', gt: 100 }"
                :empty-render="{ name: 'NotData' }"
              >
                <vxe-column
                  min-width="70"
                  field="user_contact"
                  title="联系方式"
                >
                  <template #default="{ row }">
                    {{ row.user_contact }}
                  </template>
                </vxe-column>

                <vxe-column min-width="70" field="created_at" title="创建时间">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    {{ row.created_at.split(' ')[0] }}
                  </template>
                </vxe-column>

                <vxe-column field="positions[0].income_rate" title="累计收益率">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span
                      :style="
                        $stockHelpers.getColor(row.positions[0].income_rate)
                      "
                      >{{
                        $stockHelpers.getPercent(row.positions[0].income_rate)
                      }}</span
                    >
                  </template>
                </vxe-column>

                <vxe-column field="positions[0].day_rate" title="日涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span
                      :style="$stockHelpers.getColor(row.positions[0].day_rate)"
                      >{{
                        $stockHelpers.getPercent(row.positions[0].day_rate)
                      }}</span
                    >
                  </template>
                </vxe-column>

                <vxe-column field="applies_five" title="5日涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies_five)">{{
                      $stockHelpers.getPercent(row.applies_five)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column field="applies_month" title="20日涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies_month)">{{
                      $stockHelpers.getPercent(row.applies_month)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column field="applies_month" title="60日涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies_month)">{{
                      $stockHelpers.getPercent(row.applies_month)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column field="applies_month" title="120日涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies_month)">{{
                      $stockHelpers.getPercent(row.applies_month)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column field="applies_the_year" title="今年以来涨幅">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span
                      :style="$stockHelpers.getColor(row.applies_the_year)"
                      >{{
                        $stockHelpers.getPercent(row.applies_the_year)
                      }}</span
                    >
                  </template>
                </vxe-column>

                <vxe-column
                  field="sharp_ratio"
                  title="夏普比率(成立以来)"
                  width="130"
                ></vxe-column>

                <vxe-column field="max_retreat" title="最大回撤">
                  <template #header="{ column }">
                    <div style="word-wrap: break-word; white-space: pre-wrap">
                      {{ column.title }}
                    </div>
                  </template>
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.max_retreat)">{{
                      $stockHelpers.getPercent(row.max_retreat)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column
                  field="benchmark"
                  :title="'业绩基准(' + benchmarkType + ')'"
                  width="120"
                >
                  <template #default="{ row }">
                    <span
                      :style="
                        $stockHelpers.getColor(row.benchmark[benchmarkType])
                      "
                      >{{
                        $stockHelpers.getPercent(row.benchmark[benchmarkType])
                      }}</span
                    >
                  </template>
                </vxe-column>
                <vxe-column field="excess_return" title="超额收益">
                  <template #default="{ row }">
                    <span
                      :style="
                        $stockHelpers.getColor(row.excess_return[benchmarkType])
                      "
                      >{{
                        $stockHelpers.getPercent(
                          row.excess_return[benchmarkType]
                        )
                      }}</span
                    >
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
          <div></div>
        </v-card>

        <div>
          <v-card
            class="border-radius-md mt-2 pa-2"
            style="width: 100%; height: 300px"
          >
            <YieldChart
              :portfolioDetail="portfolioDetail"
              :benchmarkType="benchmarkType"
            ></YieldChart>
          </v-card>
        </div>

        <div class="d-flex">
          <v-card class="border-radius-md mt-2 pa-2 mr-2" style="width: 60%">
            <Trading
              :isSelf="isSelf"
              :portfolioDetail="portfolioDetail"
            ></Trading>
          </v-card>
          <v-card
            class="border-radius-md mt-2 mr-2 pa-2"
            style="width: 40%; height: 335px"
          >
            <HoldingPie :portfolioDetail="portfolioDetail"></HoldingPie>
          </v-card>
        </div>

        <div>
          <v-card class="border-radius-md mt-2 pa-2 mr-2" style="width: 100%">
            <TradingHistory :portfolioDetail="portfolioDetail"></TradingHistory>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import Portfolio from '@/api/portfolio.js';
import XEUtils from 'xe-utils';
import PortfolioEdit from '@/components/Portfolio/PortfolioEdit';
import Trading from '@/components/Portfolio/Trading';
import HoldingPie from '@/components/Portfolio/HoldingPie';
import TradingHistory from '@/components/Portfolio/TradingHistory';
import YieldChart from '@/components/Portfolio/YieldChart';

export default {
  name: 'PortfolioDetail',
  components: {
    Trading,
    HoldingPie,
    TradingHistory,
    YieldChart,
  },
  computed: {},
  data() {
    return {
      isSelf: false,

      pageLoading: true,
      portfolioDetail: {},
      benchmarkTypeMap: [],
      benchmarkType: '沪深300',

      haveShowInstructions: false,
    };
  },
  mounted() {
    this.getPortfolioDetail();
    Bus.$on('refreshPortfolioDetail', (e) => {
      this.getPortfolioDetail();
    });
  },
  methods: {
    instructions() {
      this.$layer.alert(
        `	<div style="font-size:14px">
						<b>本系统使用实时价格结算，每天晚上结算一次，<span style="color:orange">非实时结算！</span></b><br>
						<b style="color:orange">注意：滑块调整的位置是个股占总资产的权重，非个股仓位。</b><br>
						<b>添加成分股：</b><br>
							(1)在详细仓位的右上角点击“添加成分股”，小窗口弹出。在弹出窗格上方选择股票，填入调仓理由（从<span style="color:red">逻辑</span>、<span style="color:red">业绩</span>、<span style="color:red">催化剂</span>三个方面进行说明，同时进行必要的<span style="color:red">风险提示</span>），拖动滑块调整仓位（最小10%）。<br>
							(2)在交易日买入，在当日收盘前调入组合，按照下单的<span style="color:orange">下一分钟均价</span>计算持股成本。<br>
							(3)在当日收盘后或非交易日调入组合，按照下个交易日九点三十分的<span style="color:orange">一分钟均价</span>成交。<br>
						<b>调仓、清仓：</b><br>
							(1)盘中可调仓换股，每天结算以收盘前的最后一次调仓为准。<br>
							(2)每日7点至8点半为结算时间，结算调仓期间不可调仓。<br>
						<b>管理员、联系人：</b><br>
							李跃/付宇娣18017835789<br>
					</div>
				`,
        {
          title: '模拟组合使用说明',
          area: ['600px', '450px'],
        },
        (layerid) => {
          this.$layer.close(layerid);
        }
      );
    },
    getPortfolioDetail() {
      this.pageLoading = true;
      Portfolio.getPortfolioDetail(this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.portfolioDetail = res.data;
          this.pageLoading = false;

          this.benchmarkTypeMap = [];
          for (let i in res.data.benchmark) {
            this.benchmarkTypeMap.push(i);
          }

          if (this.userStore.user_id == res.data.user_id) {
            this.isSelf = true;
            if (!this.haveShowInstructions) {
              this.instructions();
              this.haveShowInstructions = true;
            }
          } else {
            this.isSelf = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.pageLoading = false;
        });
    },
    portfolioEdit() {
      this.$layer.iframe({
        content: {
          content: PortfolioEdit,
          parent: this,
          data: { iframeData: { ...this.portfolioDetail } },
        },
        area: ['600px', '400px'],
        title: '修改组合(带*为必填)',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
  },
};
</script>
