<template>
	<div class="v-application bg-white" style="width: 100%;display: flex;justify-content: center;">
		<div style="min-height: 100%!important;width: 500px;">
			
			<div class="d-flex align-center justify-center">
				<label class="pt-0 mr-3" style="width: 100px;font-size: 14px;">调入日期</label>
				<vxe-input v-model="submitInfo.created_at" placeholder="调入日期" type="date" class="my-2" style="width: 100%;"></vxe-input>
			</div>
			<div class="my-4">
				<vxe-button status="primary" @click="submit">提交</vxe-button>
				<vxe-button status="warning" @click="cancel">取消</vxe-button>
			</div>
		</div>
	</div>
	
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import StockPool from '@/api/stockPool.js';
	
import StockSelect from '@/components/CommonSelect/StockSelect.vue';

export default {
	name: 'StockCreatedTimeEdit',
	components: {
		StockSelect,
	},
	props: {
		layerid: {//自动注入的layerid
			type: String,
			default: ''
		},
		iframeData: {//传递的数据
			type: Object,
			default: () => {
				return {};
			}
		},
	},
	mounted() {
		
	},
	data() {
		return {
			submitInfo:{
				created_at:this.iframeData.created_at,
			},
		};
	},
	methods: {
		submit(){
			//console.log(this.submitInfo);
			this.showLoading('保存中，请稍候');
			StockPool.editStock(this.iframeData.id,this.submitInfo).then(res => {
				this.showToast('保存成功','success',5000);
				Bus.$emit("refreshCoreStockPool", true);
				Bus.$emit("refreshCoreStockPoolOut", true);
				this.$layer.close(this.layerid);
			}).catch(err => {
				console.log(err.msg);
				this.showToast(err.msg,'danger',10000);
			}).finally(() => {
				this.hideLoading();
			});
		},
		cancel () {
			this.$layer.close(this.layerid);
		},
	},
	computed: {
		
	},
	watch: {
		
	},
};
</script>
<style scoped="scoped">
	
</style>
