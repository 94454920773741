<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div
      style="
        min-height: 100% !important;
        width: 500px;
        padding-top: 20px !important;
      "
    >
      <div class="d-flex align-center justify-center">
        <label class="pt-0 mr-3" style="width: 100px; font-size: 14px"
          >*选择日期</label
        >
        <vxe-input
          v-model="selectDate"
          placeholder="日期选择"
          type="date"
          style="width: 100%"
        ></vxe-input>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/Bus/bus.js';

import StockSelect from '@/components/CommonSelect/StockSelect.vue';
import Review from '@/api/review.js';
export default {
  name: 'AddStock',
  components: {
    StockSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: '',
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {
      selectDate: '',
    };
  },
  methods: {
    submit() {
      if (!this.selectDate) {
        this.showToast('请先选择创建的复盘日期', 'warning', 5000);
        return;
      }
      Review.createReviewData(this.selectDate)
        .then((res) => {
          Bus.$emit('refreshReviewDayList', {});
          this.showToast('创建成功', 'success', 5000);
          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          this.showToast(err.msg, 'danger', 5000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.slider-blue {
  --slider-connect-bg: #409eff;
  --slider-tooltip-bg: #409eff;
  --slider-handle-ring-color: #3b82f630;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
