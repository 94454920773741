<template>
  <div class="ml-4">
    <a
      class="pl-4"
      v-if="item.display"
      v-for="(item, index) in tabList"
      :key="item.url"
      :ripple="true"
      @click="openUrl(item.url)"
      style="background-color: transparent; width: auto"
      :elevation="0"
    >
      <span
        style="font-size: 15px"
        class="pa-0"
        :class="{
          'active-tab': currentTab == index,
          'un-active-tab': currentTab != index,
        }"
        >{{ item.title }}</span
      >
    </a>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
export default {
  name: "AppBarInfoCenter",
  props: {},
  components: {},
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          title: "信息流",
          url: "InfoCenter",
          display: true,
        },
        // {
        //   title: "信息瀑布",
        //   url: "HomePage",
        //   display: true,
        // },
      ],
    };
  },
  mounted() {},
  methods: {
    openUrl(url) {
      //   this.$router.push({ path: url });
      this.currentTab = this.tabList.findIndex((item) => item.url == url);
    },
  },
  watch: {
    currentTab(val) {
      Bus.$emit("infoCenterChange", val);
    },
  },
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
}
.un-active-tab {
  color: #7e7e7e;
}
</style>
