import { render, staticRenderFns } from "./comments.vue?vue&type=template&id=26458024&scoped=true"
import script from "./comments.vue?vue&type=script&lang=js"
export * from "./comments.vue?vue&type=script&lang=js"
import style0 from "./comments.vue?vue&type=style&index=0&id=26458024&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26458024",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VListItemContent,VListItemSubtitle,VListItemTitle,VRow})
