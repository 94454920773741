var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"height":"300px"}},[_c('vxe-table',{staticClass:"mytable-scrollbar",attrs:{"data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.tableLoading,"column-config":{ resizable: true },"height":"100%","size":"mini","stripe":true,"sort-config":{ trigger: 'cell', multiple: false },"empty-render":{ name: 'NotData' },"export-config":{},"filter-config":{ showIcon: false }}},[_c('vxe-column',{attrs:{"fixed":"left","width":"70","field":"code","title":"股票代码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.stock_id)?_c('a',{staticClass:"text-xs font-weight-normal text-dark",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.code ? row.code : "-"))]):_c('span',[_vm._v(_vm._s(row.code ? row.code : "-"))])])]}}])}),_c('vxe-column',{attrs:{"fixed":"left","width":"80","field":"sec_name","title":"股票名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[(row.stock_id)?_c('a',{staticClass:"text-xs font-weight-normal text-dark",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$stockHelpers.openStockDetail(row.stock_id)}}},[_vm._v(_vm._s(row.sec_name ? row.sec_name : "-"))]):_c('span',[_vm._v(_vm._s(row.sec_name ? row.sec_name : "-"))])])]}}])}),_c('vxe-column',{attrs:{"min-width":"150","field":"pattern_name","title":'形态 (更新时间：' + _vm.updateTime + ')'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.pattern_name)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"side","title":"方向","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.side == '正面')?_c('div',{staticClass:"d-flex justify-start text-red"},[_vm._v(" "+_vm._s(row.side)+" ")]):_vm._e(),(row.side == '负面')?_c('div',{staticClass:"d-flex justify-start",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(row.side)+" ")]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"width":"80","field":"win_rate","title":"平均胜率","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(_vm._s(row.win_rate)+"%")])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }