import Request from "@/components/request/js/index.js";

export default {
  //今日提示-雷达
  getTodaySuggest() {
    return Request().get("/api/suggest/today", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //股票池个股
  getSingleStocks() {
    return Request().get("api/review/singleStocks", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //行业涨跌幅按市值排序
  getIndustryAppliesOrderByMarketValue() {
    return Request().get("api/review/industryStocks", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //行业涨跌幅
  getIndustryApplies() {
    return Request().get("api/review/reviewIndustryStocks", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //标签涨跌幅
  getTagApplies() {
    return Request().get("api/review/reviewRaceStock", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //市场和内部行情分析
  getReviewMarkets() {
    return Request().get("api/review/reviewMarkets", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //申万三级细分龙头
  getIndustryTapStock() {
    return Request().get("api/review/reviewIndustryTapStock", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //全A个股
  getCnSingleStock() {
    return Request().get("api/review/reviewCnSingleStock", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //全A行业
  getCnIndustryStock() {
    return Request().get("api/review/reviewCnIndustryStock", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //每日复盘列表
  getReviewList() {
    return Request().get("/api/review_notices", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //每日复盘内容
  getReviewContent(id) {
    return Request().get("/api/review_notices/" + id + "/info", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //每日复盘获取编辑内容
  getReviewEdit(id) {
    return Request().get("/api/review_notices/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //每日复盘,获取锁定的id
  getReviewLockStatus(tagId, reviewId) {
    return Request().get("/api/review_notices/" + reviewId + "/lock", {
      header: {
        contentType: "application/json",
      },
      data: {
        unit: tagId,
      },
    });
  },
  //每日复盘,执行锁定一个id
  setReviewLock(tagId, reviewId) {
    return Request().post("/api/review_notices/" + reviewId + "/lock", {
      header: {
        contentType: "application/json",
      },
      data: {
        unit: tagId,
      },
    });
  },
  //每日复盘,执行解锁一个id
  setReviewUnlock(tagId, reviewId) {
    return Request().post("/api/review_notices/" + reviewId + "/unlock", {
      header: {
        contentType: "application/json",
      },
      data: {
        unit: tagId,
      },
    });
  },
  //每日复盘,保存编辑内容
  saveReviewData(data, unit_data, reviewId) {
    return Request().post("/api/review_notices/" + reviewId, {
      header: {
        contentType: "application/json",
      },
      data: {
        data: data,
        unit_data: unit_data,
      },
    });
  },
  //新建日复盘
  createReviewData(date) {
    return Request().post("/api/review_notices", {
      header: {
        contentType: "application/json",
      },
      data: {
        trade_date: date,
      },
    });
  },
  //导出日复盘
  exportEveryday() {
    return Request().get("/api/review_notices/exportEveryday", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
