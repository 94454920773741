<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div style="min-height: 100% !important; min-width: 500px; width: 80%">
      <div class="d-flex align-center justify-center">
        <label
          class="mr-3 white-space-nowrap"
          style="width: 88px; font-size: 14px"
          >*标签名</label
        >
        <vxe-input
          v-model="submitInfo.name"
          placeholder="*标签名"
          type="text"
          class="my-2"
          style="width: 100%"
        >
        </vxe-input>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-2 mr-3" style="width: 80px; font-size: 14px"
          >*分级</label
        >
        <v-select
          v-model="submitInfo.level"
          :items="[
            { text: '行业风格池', value: 0 },
            { text: '核心池', value: 1 },
            { text: '一级主题池', value: 2 },
            { text: '普通主题池', value: 3 },
          ]"
          color="rgba(0,0,0,.6)"
          class="
            input-style
            font-size-input
            text-light-input
            placeholder-light
            border-radius-sm
            select-style
          "
          outlined
          hide-details
          single-line
          height="36"
          placeholder="*分级"
        ></v-select>
      </div>

      <div class="d-flex align-center justify-center" v-if="!isOtc">
        <label class="pt-0 mr-3" style="width: 87px; font-size: 14px"
          >设置跟踪人</label
        >
        <StaffSelect
          v-model="submitInfo.user_id"
          class="my-2"
          placeholder="设置跟踪人"
          :multiple="true"
        ></StaffSelect>
      </div>

      <div class="d-flex align-center justify-center">
        <label class="pt-2 mr-3" style="width: 80px; font-size: 14px"
          >*显隐</label
        >
        <v-select
          :disabled="!$commonHelpers.getPermissions('tagStore.control')"
          v-model="submitInfo.is_show"
          :items="[
            { text: '显示', value: 1 },
            { text: '隐藏', value: 0 },
          ]"
          color="rgba(0,0,0,.6)"
          class="
            input-style
            font-size-input
            text-light-input
            placeholder-light
            border-radius-sm
            select-style
          "
          outlined
          hide-details
          single-line
          height="36"
          placeholder="*显隐"
        ></v-select>
      </div>

      <div class="mt-2">
        <label
          class="mr-2 white-space-nowrap"
          style="width: 88px; font-size: 14px"
        >
          新版标签说明
        </label>
        <a href="javascript:void(0)" class="text-dark mr-2" @click="addBlock()"
          >新建板块</a
        >

        <div
          style="width: 100%"
          class="mb-3"
          v-for="(block, blockIndex) in submitInfo.base_info"
        >
          <div
            class="d-flex"
            style="
              width: 100%;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="width: calc(20% - 20px)">
              <vxe-input
                v-model="block.name"
                placeholder="板块名"
                type="text"
                style="width: 100%"
                :readonly="!blcokCanEdit(block.name)"
              ></vxe-input>
              <a
                href="javascript:void(0)"
                class="text-dark mr-2"
                @click="deleteBlock(blockIndex)"
                >删除</a
              >
              <a
                href="javascript:void(0)"
                class="text-dark mr-2"
                @click="moveUpBlock(blockIndex)"
                >上移</a
              >
              <a
                href="javascript:void(0)"
                class="text-dark mr-2"
                @click="moveDownBlock(blockIndex)"
                >下移</a
              >
            </div>

            <div style="width: 80%">
              <vxe-textarea
                v-model="block.content_show"
                placeholder="板块内容（显示）"
                size="mini"
                style="width: 100%"
                :autosize="{ minRows: 2, maxRows: 10 }"
              ></vxe-textarea>
              <vxe-textarea
                v-model="block.content_hide"
                placeholder="板块内容（折叠）"
                size="mini"
                style="width: 100%"
                :autosize="{ minRows: 2, maxRows: 10 }"
              ></vxe-textarea>
            </div>
          </div>
        </div>
        <div class="d-flex align-center justify-center" v-if="!isOtc">
          <label class="pt-0 mr-3" style="width: 87px; font-size: 14px"
            >设置关键字</label
          >
          <KeyWordSelect
            v-model="submitInfo.keyword"
            class="my-2"
            placeholder="设置关键字"
            :multiple="true"
          ></KeyWordSelect>
        </div>
      </div>

      <div class="my-4">
        <vxe-button status="primary" @click="submit">提交</vxe-button>
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "@/api/tag.js";
import Bus from "@/components/Bus/bus.js";
import StaffSelect from "@/components/CommonSelect/StaffSelect.vue";
import KeyWordSelect from "@/components/CommonSelect/KeyWordSelect.vue";

export default {
  name: "TagAdd",
  components: {
    StaffSelect,
    KeyWordSelect,
  },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {
      isOtc: this.iframeData.isOtc,
      submitInfo: {
        name: this.iframeData.data.name,
        level: this.iframeData.data.level,
        user_id: this.iframeData.data.user_id,
        is_show: this.iframeData.data.is_show,
        base_info: this.iframeData.data.base_info,
        keyword: this.iframeData.data.keyword,
      },
    };
  },
  methods: {
    submit() {
      if (!this.submitInfo.name) {
        this.showToast("请先填写标签名", "warning", 5000);
        return;
      }
      this.showLoading("保存中，请稍候");
      Tag.editTag(this.iframeData.data.id, this.submitInfo)
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshCoreStockPool", true);
          Bus.$emit("refreshTagList", true);
          Bus.$emit("refreshTagDetail", true);

          this.$layer.close(this.layerid);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteBlock(blockIndex) {
      if (this.blcokCanEdit(this.submitInfo.base_info[blockIndex].name)) {
        this.submitInfo.base_info.splice(blockIndex, 1);
      } else {
        this.showToast("基础信息不可删除", "warning", 5000);
      }
    },
    blcokCanEdit(blockName) {
      let baseBlock = ["投资逻辑", "行业空间", "竞争格局", "催化剂"];
      if (baseBlock.indexOf(blockName) != -1) {
        return false;
      }
      return true;
    },
    moveUpBlock(blockIndex) {
      if (blockIndex == 0) {
        this.showToast("已经是第一个了", "warning", 5000);
        return;
      }
      let temp = this.submitInfo.base_info[blockIndex];
      this.$set(
        this.submitInfo.base_info,
        blockIndex,
        this.submitInfo.base_info[blockIndex - 1]
      );
      this.$set(this.submitInfo.base_info, blockIndex - 1, temp);
    },
    moveDownBlock(blockIndex) {
      if (blockIndex == this.submitInfo.base_info.length - 1) {
        this.showToast("已经是最后一个了", "warning", 5000);
        return;
      }
      let temp = this.submitInfo.base_info[blockIndex];
      this.$set(
        this.submitInfo.base_info,
        blockIndex,
        this.submitInfo.base_info[blockIndex + 1]
      );
      this.$set(this.submitInfo.base_info, blockIndex + 1, temp);
    },
    addBlock() {
      //先检查是否有未完成的新版块，有的话不允许添加新版块
      let haveEmpty = false;
      this.submitInfo.base_info.forEach((item) => {
        if (!item.name) {
          haveEmpty = true;
        }
      });
      if (haveEmpty) {
        this.showToast("请先填写未完成的新版块再添加", "warning", 5000);
        return;
      }

      //添加新版块
      this.submitInfo.base_info.push({
        name: "",
        content_show: "",
        content_hide: "",
      });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>
