<template>
	<v-container fluid class="px-6 py-0">
		<v-card
			class="border-radius-md mt-6 pa-3"
		>
			<vxe-toolbar class="px-3" custom :refresh="{ query: getRateHistory }">
				<template v-slot:buttons>
					<div class="d-flex justify-start align-items-center" style="">
						<div style="width: 130px;z-index: 10;" class="mr-1 d-flex justify-center align-items-center pb-0">
							<StockSelect v-model="stock_id" :showIcon="true" placeholder="搜索股票" :onlyPool="true" :disableArr="[]"></StockSelect>
						</div>
						
						<UserFilter @change="focusUsersChange" colName="focus_users" placeholder="关注人" :autoSave="false" inputWidth="100px" class="mr-1" :returnId="true"></UserFilter>
						<UserFilter @change="updateNameChange" colName="update_name" placeholder="评分者" :autoSave="false" inputWidth="100px" class="mr-1" :returnId="true"></UserFilter>
						
						<div>
							<vxe-input class="ml-1" v-model="start_date" type="date" placeholder="起始日期" style="width: 150px;" clearable @change="getRateHistory(true)"></vxe-input>
							<span class=""> - </span>
							<vxe-input v-model="end_date" type="date" placeholder="截止日期" style="width: 150px;" clearable @change="getRateHistory(true)"></vxe-input>
						</div>
					</div>
				</template>
			</vxe-toolbar>
			
			<vxe-table
				id="rateHistoryTable"
				row-id="id"
				ref="rateHistoryTable"
				:data="tableData"
				:border="false"
				resizable
				show-overflow
				show-header-overflow
				highlight-hover-row
				align="center"
				:loading="rateHistoryTableLoading"
				:column-config="{ resizable: true }"
				:height="tableHeight+'px'"
				size="small"
				:stripe="true"
				:sort-config="{ trigger: 'cell', multiple: true }"
				:scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
				:empty-render="{ name: 'NotData' }"
				:custom-config="{ storage: true, checkMethod: checkColumnMethod }"
				:tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
				:filter-config="{showIcon:false}"
				@scroll="tableScroll"
			>
				<vxe-column title="评分" fixed="left" type="seq" width="50" :resizable="false" show-overflow>
					<template #default="{ row }">
						<a @click="$stockHelpers.openRate(row.stock_id, row.stock_name, this)" class="text-xs font-weight-normal text-typo">
							<v-icon small>mdi-launch</v-icon>
						</a>
					</template>
				</vxe-column>

				<vxe-column fixed="left" width="100" field="stock_code" title="股票代码">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_code ? row.stock_code : '-' }}</a>
						</div>
					</template>
				</vxe-column>
				
				<vxe-column fixed="left" width="80" field="stock_name" title="股票名称">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<a href="javascript:;" @click="$stockHelpers.openStockDetail(row.stock_id)" class="text-xs font-weight-normal text-typo">{{ row.stock_name ? row.stock_name : '-' }}</a>
						</div>
					</template>
				</vxe-column>
			
				<vxe-column
					width="70"
					field="stock_level"
					title="跟踪级别"
					:filters="[{ data: ''}]" :filter-method="value => $tableHelpers.filterInputMethod(value,'stock_level')"
				>
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							{{row.stock_level}}
						</div>
					</template>
				</vxe-column>
				
				<vxe-column width="80" field="focus_users" title="关注人" :filters="[{ data: ''}]" :filter-method="value => $tableHelpers.filterInputMethod(value,'focus_users')">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #filter="{ $panel, column }">
						<template v-for="(option, index) in column.filters">
							<input class="pa-2" type="type" :key="index" v-model="option.data" @input="$panel.changeOption($event, !!option.data, option)" @keyup.enter="$panel.confirmFilter()" placeholder="按回车确认筛选">
						</template>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<div style="word-break:keep-all;white-space:pre-line;text-align: start;">{{ row.focus_users }}</div>
						</div>
					</template>
				</vxe-column>
							
				<vxe-column width="80" field="update_name" title="评分者" :filters="[{ data: ''}]" :filter-method="value => $tableHelpers.filterInputMethod(value,'update_name')">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #filter="{ $panel, column }">
						<template v-for="(option, index) in column.filters">
							<input class="pa-2" type="type" :key="index" v-model="option.data" @input="$panel.changeOption($event, !!option.data, option)" @keyup.enter="$panel.confirmFilter()" placeholder="按回车确认筛选">
						</template>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<div style="word-break:keep-all;white-space:pre-line;text-align: start;">{{ row.update_name }}</div>
						</div>
						
					</template>
				</vxe-column>
				
				<vxe-column width="80" field="score" title="得分">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-center">
							<div style="color: #fff;padding: 3px;border-radius: 5px;width: 40px;" :style="getScoreColor(row)">{{ row.score }}</div>
						</div>
						
					</template>
				</vxe-column>
				
				<vxe-column width="80" field="change_score" title="变化">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-center">
							<span :style="getScoreColor(row,true)">{{row.last_score?row.last_score:'?'}} → {{row.score}}</span>
						</div>
					</template>
				</vxe-column>
			
				<vxe-column width="80" field="created_at" title="打分日期">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<span :style="$stockHelpers.getDateColor(row.created_at.split(' ')[0])">{{ row.created_at.split(' ')[0] }}</span>
						</div>
						
					</template>
				</vxe-column>
				
				<vxe-column min-width="80" field="reason" title="理由">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<div class="d-flex justify-start">
							{{row.reason}}
						</div>
					</template>
				</vxe-column>

				<vxe-column width="60" field="attachments" title="附件">

					<template #default="{ row }">
						<vxe-pulldown :ref="'attachmentsPanel' + row.id" destroy-on-close class="ml-4" transfer>
							<template #default>
								<!-- <vxe-button @click="$refs.attachmentsPanel.togglePanel();" status="my-primary" size="mini">附件</vxe-button> -->
								<a href="javascript:;" @click="$refs['attachmentsPanel' + row.id].togglePanel();" class="text-xs font-weight-normal" :style="{color:getAttachmentsColor(row.attachments)}">附件({{row.attachments?row.attachments.length:''}})</a>
							</template>
							<template #dropdown>
								<div class="note-attachments-dropdown">
									<vxe-table auto-resize :data="row.attachments" height="300px">
										<vxe-column field="name" title="文件名">
											<template #default="{ row }">
												<Photoswipe 
													:options="{
														allowPanToNext:true,
														spacing: 0.12,
														mainClass: 'pswp--custom-zindex',
														bgOpacity: 0.8,
														mouseUsed: true,
														loop: true,
														pinchToClose: true,
														closeOnScroll: false,
														closeOnVerticalDrag: false,
														showHideOpacity: true,
														focus: true,
														escKey: true,
														arrowKeys: true,
													}"
												>
													<img
														class="me-2"
														:src="row.url"
														v-pswp="row.url"
													/>
												</Photoswipe>
											</template>
										</vxe-column>
										<vxe-column field="size" title="大小" width="80px">
											<template #default="{ row }">
												{{(row.size / 1024).toFixed(2)+'KB'}}
											</template>
										</vxe-column>
										<vxe-column title="操作" width="80px">
											<template #default="{ row:row1 }">
												<!-- <a href="javascript:;" @click="previewAttachment(row.name,row.url)" class="text-xs font-weight-normal text-typo" style="color: #344767;margin-right: 5px;">预览</a> -->
												<a href="javascript:;" @click="downloadAttachment(row1.url)" class="text-xs font-weight-normal text-typo" style="color: #344767;margin-right: 5px;">下载</a>
											</template>
										</vxe-column>
									</vxe-table>
								</div>
							</template>
						</vxe-pulldown>
					</template>

				</vxe-column>
				
			</vxe-table>
			
		</v-card>
	</v-container>
</template>

<script>
	import Bus from '@/components/Bus/bus.js';
	import XEUtils from 'xe-utils';
import UserFilter from '@/components/StockPool/filter/UserFilter'
import LevelFilter from '@/components/StockPool/filter/LevelFilter'
import Rate from '@/api/rate.js';
import StockSelect from '@/components/CommonSelect/StockSelect.vue';

export default {
  name: "RateHistory",
  components: {
		UserFilter,LevelFilter,StockSelect,
  },
  data() {
  	return {
			tableHeight:'500',
			filterName: '',
			tableData: [],
  		rateHistoryTableLoading: false,
			currPage:1,
			isEnd:false,
			
			update_user:'',
			focus_user_id:'',
			start_date:'',
			end_date:'',
			stock_id:'',
  	}
  },
	computed: {},
  mounted() {
		this.tableHeight = document.body.offsetHeight - 190;
		this.getRateHistory(true);
		Bus.$on('refreshRateHistory', e => {
			this.getRateHistory(true);
		});
  },
	methods: {
		getScoreColor(row,isChange=false){
			let color='#344767';
			if(parseInt(row.score)<parseInt(row.last_score)){
				color='rgb(0, 170, 0)';
			}else if(parseInt(row.score)>parseInt(row.last_score)){
				color='rgb(225, 61, 49)';
			}
			if(isChange){
				return {color:color}
			}else{
				return {backgroundColor:color}
			}
			
		},
		getRateHistory(refresh=true){
			this.rateHistoryTableLoading = true;
			if(refresh){
				this.currPage=1;
			}
			Rate.getRateHistory(this.update_user,this.focus_user_id,this.start_date,this.end_date,this.stock_id?this.stock_id:'',this.currPage)
				.then(res => {
					if(refresh){
						this.tableData=[];
						this.isEnd=false;
					}
					if(res.data.length==0){
						this.isEnd=true;
					}
					this.tableData = this.tableData.concat(res.data);
				})
				.catch(err => {
					console.log(err);
					//this.loginError();
				})
				.finally(() => {
					this.rateHistoryTableLoading = false;
				});
		},
		tableScroll({ scrollTop, scrollHeight , bodyHeight }){
			//console.log(this.tableHeight,scrollTop, scrollHeight , bodyHeight);
			if( (scrollTop+this.tableHeight>scrollHeight-100) && this.rateHistoryTableLoading == false && !this.isEnd){
				//到底了触发下一页
				this.currPage++;
				//console.log(this.currPage)
				this.getRateHistory(false);
			}
		},
		//显示列控制
		checkColumnMethod({ column }) {
			if (column.property === 'role') {
				return false;
			}
			return true;
		},
		focusUsersChange(val){
			this.focus_user_id=val;
			this.getRateHistory(true);
		},
		updateNameChange(val){
			this.update_user=val;
			this.getRateHistory(true);
		},
		//获取附件字段颜色
		getAttachmentsColor(attachments){
			let color='#344767';
			if(attachments){
				if(attachments.length>0){
					color='#a4262c';
				}
			}
			
			return color;
		},
		//下载附件
		downloadAttachment(url){
			window.location=url;
		},
	},
	watch: {
		stock_id(newValue, oldValue) {
			console.log(newValue);
			this.getRateHistory(true);
		}
	},
}
</script>
