import Request from '@/components/request/js/index.js';

export default {
  //获取首页的打分记录
  getRateExplore(page = 1, per_page = 50) {
    return Request().get('/api/rate/explore', {
      header: {
        contentType: 'application/json',
      },
      data: {
        page: page,
        per_page: per_page,
      },
    });
  },
  //获取所有的打分记录
  getRateHistory(
    update_user,
    focus_user_id,
    start_date,
    end_date,
    stock_id,
    page
  ) {
    return Request().get('/api/rate/list', {
      header: {
        contentType: 'application/json',
      },
      data: {
        update_user: update_user,
        focus_user_id: focus_user_id,
        start_date: start_date,
        end_date: end_date,
        stock_id: stock_id,
        page: page,
        pre_page: 100,
      },
    });
  },
  //获取一个股票的打分因子
  getStockFactor(stock_id) {
    return Request().post('/api/factor/getStockFactor', {
      header: {
        contentType: 'application/json',
      },
      data: {
        stock_id: stock_id,
      },
    });
  },
  //获取一个股票的打分历史
  getRateDetail(stock_id) {
    return Request().get('/api/rate/' + stock_id, {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //打分
  rateSave(rates) {
    return Request().post('/api/rate/stock', {
      header: {
        contentType: 'application/json',
      },
      data: {
        rates: rates,
      },
    });
  },
  //评论
  commentSave(rate_id, content) {
    return Request().post('/api/rate/comment', {
      header: {
        contentType: 'application/json',
      },
      data: {
        rate_id: rate_id,
        content: content,
      },
    });
  },
  //获取一个用户绑定的评分股票
  getUserBindStock(user_id) {
    return Request().get('/api/factor/user/stock', {
      header: {
        contentType: 'application/json',
      },
      data: {
        user_id: user_id,
      },
    });
  },
  //批量删除用户绑定的股票
  deleteUserBindStock(user_id, stock_ids) {
    return Request().post('/api/factor/deleteStockFactor', {
      header: {
        contentType: 'application/json',
      },
      data: {
        //_method:'DELETE',
        user_id: user_id,
        stock_ids: stock_ids,
      },
    });
  },
  //管理界面-获取股票因子列表
  getAdminStockFactor(stock_id, user_id) {
    return Request().post('/api/factor/getStockFactor/control', {
      header: {
        contentType: 'application/json',
      },
      data: {
        stock_id: stock_id,
        user_id: user_id,
      },
    });
  },
  //给用户绑定股票
  bindStockToUser(user_id, stock_ids) {
    return Request().post('/api/factor/bindStockFactor', {
      header: {
        contentType: 'application/json',
      },
      data: {
        user_id: user_id,
        stock_ids: stock_ids,
      },
    });
  },
  //给股票绑定多个打分因子
  bindFactorToStock(user_id, stock_id, factor_ids) {
    return Request().post('/api/factor/setStockFactor', {
      header: {
        contentType: 'application/json',
      },
      data: {
        user_id: user_id,
        stock_id: stock_id,
        factor_ids: factor_ids,
      },
    });
  },
  //删除打分
  deleteRate(id) {
    return Request().post('/api/rate/' + id + '/delete', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
};
