import Request from "@/components/request/js/index.js";
import { config } from "@/components/request/js/config.js";
export default {
  //获取上传的域名
  getApiUrl() {
    //console.log(config);
    return config.baseURL;
  },
  //获取上传所需凭据
  getUploadSts() {
    return Request().get("/api/file/sts", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //上传文件
  uploadFile(uuid, path, name, bindInfo = {}) {
    let data = {
      id: uuid,
      path: path,
      name: name,
    };
    for (let i in bindInfo) {
      data[i] = bindInfo[i];
    }
    return Request().post("/api/file", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
      },
    });
  },
  //删除文件
  deleteFile(id) {
    return Request().post("/api/file/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {
        _method: "DELETE",
      },
    });
  },
  //下载文件
  downloadFile(id) {
    return Request().get("/api/file/" + id + "/download", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //修改文件
  editFile(info) {
    return Request().post("/api/file/" + info.id, {
      header: {
        contentType: "application/json",
      },
      data: {
        name: info.name,
        tags: [info.tags],
      },
    });
  },
  //批量修改tag
  editFilesTags(ids, tags) {
    return Request().post("/api/file/tags", {
      header: {
        contentType: "application/json",
      },
      data: {
        ids: ids,
        tags: tags,
      },
    });
  },
  //按股票获取研报或纪要
  getFileByStock(page = 1, code, source) {
    return Request().get("/api/deal_files/stock/" + code, {
      header: {
        contentType: "application/json",
      },
      data: {
        page: page,
        source: source,
      },
    });
  },
  //按标签获取研报或纪要
  getFileByTag(page = 1, tagId, source) {
    return Request().get("/api/deal_files/race/" + tagId, {
      header: {
        contentType: "application/json",
      },
      data: {
        page: page,
        source: source,
      },
    });
  },
  //获取文件
  getFile(id) {
    return Request().get("/api/deal_files/file/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //获取微信消息列表
  getInfoList(page = 1, perPage = 999999, keyword = "") {
    return Request().post("/api/external/wxMsg", {
      header: {
        contentType: "application/json",
      },
      data: {
        page: page,
        perPage: perPage,
        keyword: keyword,
      },
    });
  },
  //获取信息流会议url
  getMeetingUrl(id) {
    return Request().get("/api/index/" + id, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },

  isFile(obj) {
    return Object.prototype.toString.call(obj) === "[object File]";
  },
};
