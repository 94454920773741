<template>
  <v-autocomplete
  	background-color="rgba(255,255,255,.9)"
  	color="rgba(0,0,0,.6)"
  	rounded-sm
  	outlined
  	v-model="model"
  	:items="items"
  	:loading="isLoading"
  	:search-input.sync="search"
  	dense
		chips
		small-chips
  	hide-details
		multiple
		:placeholder="placeholder"
  	class="
  	  input-icon
  	  font-size-input
  	  placeholder-lighter
  	  input-alternative
  	  border
			border-radius-sm
  	"
  	style="width: 100%;"
  	
  >
  	<template slot="prepend-inner" v-if="showIcon">
  	  <v-icon color="rgba(0,0,0,.6)" size=".875rem">
  			fas fa-search
  		</v-icon>
  	</template>
		
		<template v-slot:selection="data">
			<v-chip
				v-bind="data.attrs"
				:input-value="data.selected"
				close
				@click:close="remove(data.item.value)"
				class="my-1 py-1 px-2"
				style="height: 22px;"
			>
				{{ data.item.text }}
			</v-chip>
		</template>
  	
  	<template slot="append">
  	  &nbsp;
  	</template>
  </v-autocomplete>
</template>
<script>
import Stock from '@/api/stock.js';
export default {
	
  name: "",
  props: {
		value:'',
    placeholder:'',
		showIcon:false,
  },
  data() {
    return {
			
      model:'',
			search:'',
			items:[],
			isLoading:false,
    };
  },
	mounted() {
		this.model=this.value;
		this.getList();
	},
	methods: {
		submit(){
			
		},
		remove (item) {
			const index = this.model.indexOf(item)
			if (index >= 0) this.model.splice(index, 1)
		},
		getList(){
			this.isLoading=true;
			Stock.getStyleList()
			  .then(res => {
			    let arr = [];
			    res.data.forEach((item, index) => {
			    
			      arr.push({ text: `${item.name}` ,value:item.id });
			    
			    });
			    this.items=this.items.concat(arr);
			  })
			  .catch(err => {
			    console.log(err.msg);
			  }).finally(() => {
			    this.isLoading=false;
			  });
		},
	},
	watch: {
		value(val){
			this.model=this.value;
		},
		model(val){
			//console.log(val);
			this.$emit('input',val);
		},
		search(val) {
			// if (val) {
			//   let haveOld=false;
			//   this.items.forEach((item)=>{
			    
			//     if(item.text.indexOf(val)!=-1){
			//       haveOld=true;
			//     }
			
			//   })
			//   if (!haveOld) {
			//     this.getList(val);
			//   }
			// }
		}
	},
};
</script>
