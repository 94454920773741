<template>
  <div class="v-application bg-white" style="width: 100%">
    <div style="min-height: 100% !important; width: 100%" id="myDiv">
      <v-md-preview
        :text="responseContent"
        style="width: 100%; padding: 10px"
      ></v-md-preview>
      <vxe-button
        v-if="allComplete"
        class="mt-2"
        status="primary"
        @click="downloadSummary()"
      >
        下载本篇纪要
      </vxe-button>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Ai from "@/api/ai.js";
import axios from "axios";
export default {
  name: "AiAnalysisMeeting",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.getMeetingInfo();
  },
  data() {
    return {
      loading: true,
      responseContent: "",
      text: "",
      allComplete: false,

      summaryContentArr: [],
      QAContentArr: [],
      finalRes: "",
    };
  },
  methods: {
    getMeetingInfo() {
      axios
        .get(this.iframeData.url)
        .then((res) => {
          let currentSi = 0;
          res.data.forEach((item) => {
            let si = item.si;
            if (si != currentSi) {
              currentSi = si;
              this.text += `\n说话人${item.speaker}:`;
            }
            this.text += item.onebest;
          });
          //console.log(this.meetingText);
          this.submit();
        })
        .catch((err) => {
          console.log(err);
          this.responseContent = "获取会议内容失败，请关闭小窗后重试！";
        })
        .finally(() => {
          console.log("finally");
        });
    },
    submit() {
      if (!this.text) {
        this.showToast("没有获取到会议内容！", "warning", 5000);
        return;
      }
      //拿到文段，第一步拆分文本
      this.cutText();
      //this.mergeText();
      return;
    },
    //拆分文本，每段不超过3k的token，并放进数组
    cutText() {
      this.allComplete = false;
      this.responseContent = "";
      this.textArr = [];
      this.summaryContentArr = [];
      this.QAContentArr = [];

      let textArr = [];
      //先将所有文本按换行符拆分
      let sentenceArr = this.text.split("\n");
      //再依次将每段文本放进数组，超出限制长度时，将剩余文本放进下一段
      let temp = "";
      for (let i = 0; i < sentenceArr.length; i++) {
        if (temp.length + sentenceArr[i].length < 3000) {
          temp += sentenceArr[i] + "\n";
        } else {
          textArr.push(temp);
          temp = sentenceArr[i] + "\n";
        }
      }
      textArr.push(temp);
      this.textArr = textArr;
      //console.log(textArr);
      this.responseContent = `
文本拆分完成，文字总长度：${this.text.length}，共${textArr.length}段，开始AI处理。\n
      `;
      this.sliceController(0, textArr.length);
    },
    //分片处理
    async sliceController(index, length) {
      this.responseContent += `
## 开始处理第${index + 1}段文本，长度：${this.textArr[index].length}。\n
      `;
      //任务1，总结文段
      await this.getSummary(this.textArr[index]);
      console.log(this.summaryContentArr);

      //任务2,提取QA
      await this.getQA(this.textArr[index]);
      console.log(this.QAContentArr);

      if (index < length - 1) {
        this.responseContent += `
## 第${index + 1}段文本处理完毕，准备处理下一段文本\n
      `;
        this.sliceController(index + 1, length);
      } else {
        this.responseContent += `
## 所有分段处理完毕，即将开始融合文段\n
      `;
        await this.mergeText();
        this.allComplete = true;
      }
    },

    //总结文本任务
    async getSummary(textContent) {
      this.responseContent += `
## 开始总结本段文本\n
      `;
      let prompt = `
        <text></text>XML标签中是一个会议内容。你的角色是金融分析师。现在需要提取上市公司会议中的各类内容，并用中文进行总结和分析完成下面的任务，提供对投资者有价值的信息。
        输出的内容需要有完整和清晰的逻辑，并且是基于文本内容进行详细分析得到的，不能搜索相关公司的其他信息。\n
        根据下段会议内容文本的内容，依次完成以下任务,不用输出任务1的内容：
        1.请先自己做语篇规整，修正文段中的语法错误、多音字错误，[[注意不用输出规整后的内容，只用于后续任务的处理]]。
        2.使用任务1处理完成后的内容，按下面的格式分类总结文段中的重要内容。重要内容是指对投资者有价值的信息、对股价会产生正面或者负面影响的信息。每一类最多三级，顶级分类不用列表编号，其他使用使用无序列表。
        重点关注文本中各数值指标的变化。忽略掉会议开场的免责声明。

        格式：
        分类1名称
        (1)概况
        (2)概况

        分类2名称
        (1)概况
        (2)概况
        (3)概况

        <text>${textContent}<text>
        注意！不要输出任何任务外的内容和提示。\n
      `;
      let thisRes = await this.fetchAiResponse(prompt);
      this.summaryContentArr.push(thisRes);
      //console.log(thisRes.replace(/<br>/g, "\n"));
    },

    //提取QA任务
    async getQA(textContent) {
      this.responseContent += `
## 开始提取本段文本中的QA\n
      `;
      let prompt = `<text></text>XML标签中是一个会议内容。你的角色是金融分析师。现在需要提取上市公司会议中的各类内容，并用中文进行总结和分析完成下面的任务，提供对投资者有价值的信息。
      输出的内容需要有完整和清晰的逻辑，并且是基于文本内容进行详细分析得到的，不能搜索相关公司的其他信息。\n
      根据下段会议内容文本的内容，依次完成以下任务：
      1.请先自己做语篇规整，修正文段中的语法错误、多音字错误，不用输出规整后的内容，只用于后续任务的处理，删除文段中的会议免责内容、会议声明内容。
      2.使用任务1处理完成后的内容，根据格式总结出所有问题和对应回答的内容。注意问题的内容一定要是参会人提出的，不能想象问题。这个任务中每个Q必须对应一个A，并且A的回答必和Q的提问相关，如果不相关请搜索全文后再次总结；
      句

      格式：
      Q：问题1
      A：回答1\n

      Q：问题2
      A：回答2\n
      <text>${textContent}<text>
      注意！不要输出任何任务外的内容和提示，只输出QA。Q中如果有陈述句，统一改为问。\n
      `;
      let thisRes = await this.fetchAiResponse(prompt);
      this.QAContentArr.push(thisRes);
      //console.log(thisRes.replace(/<br>/g, "\n"));
    },
    //融合文段任务
    async mergeText() {
      this.responseContent += `
## 开始将总结全文\n
      `;
      let mergeSummary = "";
      this.summaryContentArr.forEach((item) => {
        mergeSummary += item + "\n";
      });
      //console.log(mergeSummary);
      let prompt = `<text></text>XML标签中是一个会议的分段总结内容。你的角色是金融分析师。现在需要提取上市公司会议中的各类内容，并用中文进行总结和分析完成下面的任务，提供对投资者有价值的信息。
      输出的内容需要有完整和清晰的逻辑，并且是基于文本内容进行详细分析得到的，不能搜索相关公司的其他信息。\n
      根据下段会议内容文本的内容，完成以下任务最后再输出，注意底下的每个要求：
      任务：按下面的格式将文段润色，将类似的分类合并，去掉重复信息，例如“产品价格”、“产品销售”放在“产品”的大分类下。每一类最多三级。忽略掉免责声明、会议对象等。

      格式：
      一.分类1名称
      1.1 子分类名称
      (1)概况
      (2)概况

      二.分类2名称
      (1)概况
      (2)概况
      (3)概况
      <text>${mergeSummary}<text>
      注意！不要输出任何任务外的内容和提示。\n
      `;
      let thisRes = await this.fetchAiResponse(prompt);
      this.finalRes = thisRes;
    },
    async fetchAiResponse(message) {
      let thisRes = "";
      this.responseContent += "\n\n";
      try {
        const response = await fetch("http://chat.lhotsetech.com/chat.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            q: message,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
          const { value, done } = await reader.read();
          if (value) {
            let partialResponse = decoder.decode(value, { stream: true });
            //console.log(partialResponse);
            if (partialResponse.indexOf("data: Connection closed") == -1) {
              const regex = /"content":"(.+?)"/g;
              const matches = partialResponse.match(regex);
              let extractedTexts = [];
              let finalText = "";
              if (matches) {
                extractedTexts = matches.map(
                  (match) => match.match(/"content":"(.+?)"/)[1]
                );
                extractedTexts.forEach((text) => {
                  text = text.replace(/\\n/g, "<br>").replace(/\\\//g, "/");

                  //text = text.replace(/\\n/g, "<br>");
                  finalText += text;

                  thisRes += text;

                  let myDiv = document.getElementById("myDiv").parentNode;
                  myDiv.scrollTop = myDiv.scrollHeight;
                });
              } else {
                console.log("接收结束");
              }
              this.responseContent += finalText;
            }
          }
          if (done) {
            return thisRes;
            break;
          }
        }
      } catch (error) {
        console.error("Error fetching AI response:", error);
        this.responseContent += "\n\n## AI处理失败，请重试！\n\n";
      }
    },
    //下载纪要
    downloadSummary() {
      let text = "## 会议纪要\n" + this.finalRes + "\n## Q&A \n";
      this.QAContentArr.forEach((item) => {
        text += item + "<br>";
      });
      this.appStore.printContent = text;
      let routeUrl = this.$router.resolve({ name: "打印MD" });
      window.open(routeUrl.href, "_blank");
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.is-shining {
  animation: shining 1s ease-in-out infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
