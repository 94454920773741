<template>
  <div style="height: 100%" class="mt-1">
    <vxe-radio-group v-model="modelSelect">
      <vxe-radio-button label="1" content="模型横测"></vxe-radio-button>
      <vxe-radio-button label="2" content="会议纪要"></vxe-radio-button>
      <vxe-radio-button label="3" content="多轮翻译"></vxe-radio-button>
      <!-- <vxe-radio-button label="4" content="月之暗面kimi"></vxe-radio-button> -->
    </vxe-radio-group>
    <iframe
      src="/language-model-comparison.html"
      frameborder="0"
      style="width: 100%; margin-top: 10px"
      :style="{ height: pageHeight - 30 + 'px' }"
      v-if="modelSelect == '1'"
    ></iframe>
    <iframe
      src="https://fastgpt.lhotsetech.com/chat/share?shareId=agwt5pn5p3tahustyd4yf038"
      frameborder="0"
      style="width: 100%; margin-top: 10px"
      :style="{ height: pageHeight - 30 + 'px' }"
      v-if="modelSelect == '2'"
    ></iframe>
    <iframe
      src="https://fastgpt.lhotsetech.com/chat/share?shareId=mgxbv0fvij4403t4syihnh75"
      frameborder="0"
      style="width: 100%; margin-top: 10px"
      :style="{ height: pageHeight - 30 + 'px' }"
      v-if="modelSelect == '3'"
    ></iframe>
    <!-- <iframe
      src="https://chatgpt.lhotsetech.com/chat/share?shareId=9tro1s3kjhk4jo364g6psp3j"
      frameborder="0"
      style="width: 100%; margin-top: 10px"
      :style="{ height: pageHeight - 30 + 'px' }"
      v-if="modelSelect == '4'"
    ></iframe> -->
  </div>
</template>

<script>
import Vue from "vue";
import lemonMessageMd from "@/components/Ai/lemonMessageMd";
Vue.component(lemonMessageMd.name, lemonMessageMd);

const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);
import { v4 as uuidv4 } from "uuid";
import Ai from "@/api/ai.js";
export default {
  name: "Chat",
  components: {},
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      user: {
        id: "1",
        displayName: "Knight",
        avatar: require("@/assets/img/user/user.png"),
      },
      conversationId: null,
      parentMessageId: null,

      conversationCounter: 0,
      modelSelect: "1",
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.chatCover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}
.lemon-editor__tool {
  height: 10px;
  border-top: 1px solid #ddd;
}
.v-md-hljs-js {
  padding: 0px !important;
}
</style>
