<template>
  <div>
    <vxe-toolbar class="mx-3" custom :refresh="{ query: getUserList }">
      <template v-slot:buttons>
        <span class="mr-2">研究员列表</span>
        <vxe-button
          @click="exportTable()"
          size="medium"
          content="导出"
          class="ml-0 mr-1"
          status="my-primary"
        >
        </vxe-button>
      </template>
    </vxe-toolbar>

    <div :style="{ height: tableHight + 'px' }">
      <vxe-table
        id="recommendUserTable"
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="center"
        ref="recommendUserTable"
        :loading="tableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :scroll-y="{ mode: 'wheel', gt: 100 }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
        :filter-config="{ showIcon: false }"
      >
        <vxe-column min-width="70" field="user.base" title="研究员">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.user.base }}
              </div>
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column min-width="150" field="user.sw_level2_name" title="行业">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.user.sw_level2_name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="benchmark" title="基准(行业)" sortable width="100">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.benchmark)">{{
                $stockHelpers.getPercent(row.benchmark)
              }}</span>
            </div>
          </template>
        </vxe-column> -->

        <!-- <vxe-column
          field="excessreturn"
          title="超额收益"
          sortable
          min-width="100"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.excessreturn)">{{
                $stockHelpers.getPercent(row.excessreturn)
              }}</span>
            </div>
          </template>
        </vxe-column> -->

        <vxe-column field="win_per" title="胜率" sortable min-width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.win_per)">{{
                $stockHelpers.getPercent(row.win_per)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="doing" title="总推荐数" sortable min-width="80">
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ row.success + row.fail + row.doing }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="success" title="达成" sortable min-width="80">
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ row.success }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="fail" title="未达成" sortable min-width="80">
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ row.fail }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="doing" title="正在推荐" sortable min-width="80">
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span>{{ row.doing }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="applies_focus"
          title="推荐以来涨幅"
          sortable
          min-width="120"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                $stockHelpers.getPercent(row.applies_focus)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_day" title="日涨幅" sortable min-width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_day)">{{
                $stockHelpers.getPercent(row.applies_day)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="applies_five"
          title="5日涨幅"
          sortable
          min-width="80"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_five)">{{
                $stockHelpers.getPercent(row.applies_five)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="applies_ten"
          title="10日涨幅"
          sortable
          min-width="80"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_ten)">{{
                $stockHelpers.getPercent(row.applies_ten)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_20" title="20日涨幅" sortable min-width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_20)">{{
                $stockHelpers.getPercent(row.applies_20)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_60" title="60日涨幅" sortable min-width="80">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_60)">{{
                $stockHelpers.getPercent(row.applies_60)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="applies_120"
          title="120日涨幅"
          sortable
          min-width="100"
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_120)">{{
                $stockHelpers.getPercent(row.applies_120)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column
          title="操作"
          type="seq"
          width="100"
          :resizable="false"
          show-overflow
          fixed="right"
        >
          <template #default="{ row }">
            <a
              href="javascript:;"
              @click="userEdit(row)"
              class="text-xs font-weight-normal text-typo"
              style="margin-left: 5px"
            >
              修改
            </a>
          </template>
        </vxe-column> -->
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import StockRecommend from "@/api/stockRecommend.js";
import AddRecommend from "@/components/StockRecommend/AddRecommend.vue";
import EditUser from "@/components/StockRecommend/EditUser.vue";
import XEUtils from "xe-utils";
import UserFilter from "@/components/StockPool/filter/UserFilter";
export default {
  name: "PortfolioList",
  components: { UserFilter },
  props: {
    tableHight: "",
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["stock_code", "stock_name", "user_name"];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  data() {
    return {
      tableLoading: false,
      filterName: "",
      tableData: [],
    };
  },
  mounted() {
    this.getUserList();
    Bus.$on("refreshStockRecommendUserList", (e) => {
      if (e.hasOwnProperty("id")) {
        this.tableData.forEach((item) => {
          if (item.id == e.id) {
            item.start_date = e.start_date;
            item.end_date = e.end_date;
            item.user.sw_level2 = e.sw_level2;
          }
        });
        this.getUserList(this.tableData);
      } else {
        this.getUserList();
      }
    });
  },
  methods: {
    getUserList(data = null) {
      this.tableLoading = true;
      StockRecommend.getUserList(data)
        .then((res) => {
          //console.log(res.data);
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    recommendAdd() {
      this.$layer.iframe({
        content: {
          content: AddRecommend,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "500px"],
        title: "新增推荐(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    deleteRecommend(id) {
      this.$layer.confirm(
        "确定删除该推荐吗？",
        {
          btn: ["确定", "取消"],
          title: "提示",
          icon: 3,
        },
        (layerid) => {
          StockRecommend.deleteStockRecommend(id)
            .then((res) => {
              this.showToast("已成功结束推荐！", "success", 5000);
              Bus.$emit("refreshStockRecommendList", true);
            })
            .catch((err) => {
              console.log(err.msg);
              this.showToast(err.msg, "danger", 10000);
            })
            .finally(() => {
              this.hideLoading();
            });

          this.$layer.close(layerid);
        }
      );
    },
    exportTable() {
      this.$refs.recommendUserTable.openExport({});
    },
    activeCellMethod({ row, column }) {
      //console.log(row, column);
      if (
        column.field == "start_date" ||
        column.field == "end_date" ||
        column.field == "user.sw_level2_name"
      ) {
        return true;
      } else {
        return false;
      }
    },
    userEdit(row) {
      console.log(row.user);
      this.$layer.iframe({
        content: {
          content: EditUser,
          parent: this,
          data: {
            iframeData: {
              id: row.id,
              start_date: row.start_date,
              end_date: row.end_date,
              sw_level2: row.user.sw_level2,
            },
          },
        },
        area: ["600px", "500px"],
        title: "修改用户",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
  },
};
</script>
