<template>
	<v-snackbar
		top
		v-model="snackbar.visible"
		:color="snackbar.color"
		class="snackbar-shadow"
		:timeout="timeout"
		style="z-index: 80000;"
	>
		<div class="alert-notify" style="display: flex;">
			<v-icon size="24" class="text-white">
				ni ni-bell-55
			</v-icon>
			<p class="mb-0" style="padding-left: 10px;">
				<!-- <span class="font-size-root font-weight-600"
					>Vuetify Snackbar</span
				>
				<br /> -->
				{{txt}}
			</p>
		</div>

		<template v-slot:action="{ attrs }">
			<v-btn
				icon
				elevation="0"
				max-width="136"
				:ripple="false"
				height="43"
				class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
				color="rgba(255,255,255, .85)"
				@click="snackbar.visible = false"
				v-bind="attrs"
			>
				<v-icon size="13">fas fa-times</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	export default {
	  name: "app-bar",
	  props: {
			
	  },
	  data() {
	    return {
				txt:'',
				snackbar:false,
				timeout:2000,
			}
	  },
	  methods: {
			showToast(txt,type='info',timeout=5000){
				switch (type) {
					case "info":
						this.snackbar = {
							color: "#17c1e8",
							visible: true,
						};
						break;
					case "success":
						this.snackbar = {
							color: "#82d616",
							visible: true,
						};
						break;
					case "warning":
						this.snackbar = {
							color: "#fbcf33",
							visible: true,
						};
						break;
					case "danger":
						this.snackbar = {
							color: "#ea0606",
							visible: true,
						};
						break;
				}
				this.timeout=timeout;
				this.txt=txt;
			}
	  },
	  watch: {
	    
	  },
	};
</script>

<style>
</style>
