<template>
  <div class="ml-2">
    <v-card
      class="border-radius-md mt-2"
      v-for="(item, index) in noteList"
      :key="index"
      style="margin-top: 10px"
    >
      <div class="border-bottom d-flex align-center px-4 py-1">
        <div class="d-flex align-center">
          <v-avatar size="37" rounded>
            <v-img
              src="@/assets/img/user/stock.png"
              alt="profile-image"
            ></v-img>
          </v-avatar>

          <div class="mx-4">
            <a
              href="javascript:;"
              class="text-dark font-weight-600 text-sm mr-2"
              @click="openNote(item.id)"
            >
              {{ item.title ? item.title : "" }}
            </a>

            <span
              v-for="(race, index) in item.races"
              v-if="race.level !== 0"
              :key="race.id"
              style="
                cursor: pointer;
                font-size: 10px;
                color: #fff;
                border-radius: 3px;
                margin-bottom: 1px;
                margin-right: 1px;
                padding: 3px;
                opacity: 0.8;
              "
              :style="{
                backgroundColor:
                  race.color == `#e8f5e9` ? `#00b294` : race.color,
              }"
              @click="$stockHelpers.openTagDetail(race.id)"
            >
              {{ race.name }}
            </span>

            <small class="d-block text-muted" style="letter-spacing: 2px">
              <span>
                {{ item.user_name }}
              </span>
              {{ item.created_at }}
            </small>
          </div>
        </div>
        <!-- <div class="text-end ms-auto">
          <vxe-button @click="$stockHelpers.openRate(item.stock_id, item.name, this);" size="mini" status="my-primary"
            content="打分详情"></vxe-button>
        </div> -->
      </div>
      <div class="px-5 py-2">
        <v-lazy
          v-model="item.isActive"
          :options="{
            threshold: 0.1,
          }"
          class="fill-height"
          transition="fade-transition"
        >
          <EditorDisplay
            :editorId="item.id"
            :editorContent="item.content"
          ></EditorDisplay>
        </v-lazy>
      </div>
      <div class="pb-1">
        <NoteComment :noteId="item.id" isDashBoard> </NoteComment>
      </div>
    </v-card>
  </div>
</template>
<script>
import Note from "@/api/note.js";
import EditorDisplay from "./EditorDisplay.vue";
import NoteComment from "@/components/Note/NoteComment";

export default {
  name: "TagReview",
  props: {},
  components: {
    EditorDisplay,
    NoteComment,
  },
  data() {
    return {
      noteList: [],
    };
  },
  mounted() {
    this.getDashboardNote();
  },
  methods: {
    getDashboardNote() {
      Note.getDashboardNote()
        .then((res) => {
          this.noteList = res.data.data;
          this.noteList.forEach((item) => {
            item.isActive = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openNote(id) {
      this.$router.push({ name: "笔记", params: { id: id } });
    },
  },
};
</script>
<style scoped="scoped"></style>
