<template>
  <v-row class="mb-6" style="position: sticky; top: 48px; z-index: 2">
    <v-col>
      <v-card color="grey lighten-4" flat>
        <v-toolbar>
          <v-col cols="12" sm="2" md="2" lg="2" class="mt-4 pa-0">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu = false"
                type="month"
                locale="zh-cn"
                color="#1976d2"
                @change="$emit('getDataList', date, search, hot)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="3">
            <v-text-field
              v-model="search"
              clearable
              rounded-sm
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="输入关键字搜索"
              @keydown="getContent"
              class="
                input-style
                font-size-input
                placeholder-lighter
                input-alternative input-icon
                border
              "
            >
              <template slot="prepend-inner">
                <v-icon
                  color="rgba(0,0,0,.6)"
                  size=".875rem"
                  @click="$emit('getDataList', date, search, hot)"
                  >fas fa-search</v-icon
                >
              </template>
            </v-text-field>
          </v-col>

          <vxe-button
            status="my-primary pt-1"
            @click="$emit('toAddInfo')"
            size="mini"
          >
            <span class="pt-5"> 添加 </span>
            <v-icon class="text-dark pb-1">mdi-plus-box</v-icon>
          </vxe-button>

          <vxe-button
            status="my-primary mr-8 pt-1"
            @click="isShowAll"
            size="mini"
          >
            {{ show ? "全部收起" : "全部展开"
            }}<v-icon class="pb-1 pl-2 text-dark">{{
              show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </vxe-button>

          <v-btn
            icon
            class="mr-6"
            @click="
              hot = hot ? 0 : 1;
              $emit('getDataList', date, search, hot);
            "
            :color="!hot ? 'red' : 'rgb(158, 158, 158)'"
          >
            <p :class="hot ? 'text-color' : 'text-color-light font-bold'">
              {{ hot ? "显示全部" : "筛选重磅" }}
            </p>
            <v-icon v-if="!hot">mdi-alert-box</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import moment from "moment";
export default {
  name: "TimeLineBar",
  data: () => ({
    date: moment().format("YYYY-MM"),
    menu: false,
    show: false,
    hot: "",
    search: "",
  }),
  methods: {
    isShowAll() {
      this.show = !this.show;
      this.$emit("isShowAll", this.show);
    },
    setShow(val) {
      this.show = val;
    },
    getContent: Vue.prototype.$commonHelpers.throttle(function (...args) {
      this.$emit("getDataList", this.date, this.search, this.hot);
    }, 1000),
    setDate(val) {
      this.date = val;
    },
  },
};
</script>
