<template>
  <div>
    <div
      :id="'NHNLChart' + swName"
      style="width: 400px; height: 300px; margin: 10px"
    ></div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import FanChartChild from "./FanChartChild.vue";
export default {
  name: "FanChart",
  components: {},
  props: {
    swName: {
      type: String,
      default: "",
    },
    nhnl: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      myChart: null,
      chartData: {},
      legends: [],
    };
  },
  mounted() {
    let chartDom = document.getElementById("NHNLChart" + this.swName);
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    this.chartData = this.nhnl;
    this.initChart();
  },
  methods: {
    initChart() {
      let option = {
        grid: {
          top: "10%", // 顶部留白
          bottom: "10%", // 底部留白
          left: "0%", // 左边留白
          right: "0%", // 右边留白
          containLabel: true, // 包含坐标轴标签
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.chartData.trade_dates,
        },
        yAxis: [
          {
            type: "value",
            name: "指数",
          },
          {
            type: "value",
            name: "NHNL指标",
            position: "right",
            min: -1,
            max: 1,
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数",
            type: "line",
            data: this.chartData.sw_data,
            yAxisIndex: 0,
            symbol: "none",
            lineStyle: {
              width: 2,
              color: "darkgray",
            },
          },
          {
            name: "NHNL",
            type: "line",
            data: this.chartData.nhnl,
            yAxisIndex: 1,
            symbol: "none",
            lineStyle: {
              color: "gold",
            },
            markLine: {
              symbol: "none",
              label: {
                show: true,
                formatter: "{b}",
              },
              data: [
                {
                  name: "贪婪",
                  yAxis: 0.3,
                  lineStyle: {
                    type: "solid",
                    color: "#37bab3",
                  },
                },
                {
                  name: "乐观",
                  yAxis: 0.2,
                  lineStyle: {
                    type: "dashed",
                    color: "#37bab3",
                  },
                },
                {
                  name: "悲观",
                  yAxis: -0.2,
                  lineStyle: {
                    type: "dashed",
                    color: "#dc143c",
                  },
                },
                {
                  name: "恐惧",
                  yAxis: -0.3,
                  lineStyle: {
                    type: "solid",
                    color: "#dc143c",
                  },
                },
              ],
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
