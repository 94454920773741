<template>
  <div style="width: 100%">
    <LoadingPage :visible="tableLoading"></LoadingPage>
    <div style="width: 100%" v-show="!tableLoading">
      <v-card
        v-for="(item, index) in tableData1"
        :key="index"
        style="height: 100%"
        class="border-radius-md px-3 pt-3 my-2"
      >
        <div>{{ index }}</div>
        <vxe-table
          :data="item.slice(0, tablePage1[index].pageSize)"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          :ref="'suggestionTable' + index"
          :loading="tableLoading"
          height="385px"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column
            fixed="left"
            min-width="100"
            field="stock.code"
            title="股票代码"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.code ? row.stock.code : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.name ? row.stock.name : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="focus_users" title="关注人">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    word-break: keep-all;
                    white-space: pre-line;
                    text-align: start;
                  "
                >
                  {{ row.focus_users }}
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" field="level_name" title="跟踪级别">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.level_name }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="100" field="industry.name" title="申万三级">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.industry ? row.industry.name : "-" }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" field="styles" title="风格">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level === 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="170" field="races" title="赛道">
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <div
                  style="
                    text-align: start;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                >
                  <v-chip
                    v-for="(race, index) in row.stock.races"
                    v-if="race.level !== 0"
                    :key="race.id"
                    dense
                    small
                    dark
                    :color="race.color"
                    class="px-2 py-0"
                    style="height: 16px; margin-right: 1px; opacity: 0.8"
                    :style="{ color: race.level == 3 ? '#000' : '#fff' }"
                    @click="$stockHelpers.openTagDetail(race.id)"
                  >
                    {{ race.name }}
                  </v-chip>
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="总分">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ index == "行业和公司" ? row.fundamentals : "" }}
                {{ index == "催化剂" ? row.catalyst : "" }}
                {{ index == "交易" ? row.amount_price : "" }}
              </div>
            </template>
          </vxe-column>

          <vxe-column field="sequential_today" min-width="70" title="今日变化">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  v-if="index == '行业和公司'"
                  :style="
                    $stockHelpers.getColor(row.sequential_today['fundamentals'])
                  "
                >
                  {{ row.sequential_today["fundamentals"] }}
                </span>
                <span
                  v-if="index == '催化剂'"
                  :style="
                    $stockHelpers.getColor(row.sequential_today['catalyst'])
                  "
                >
                  {{ row.sequential_today["catalyst"] }}
                </span>
                <span
                  v-if="index == '交易'"
                  :style="
                    $stockHelpers.getColor(row.sequential_today['amount_price'])
                  "
                >
                  {{ row.sequential_today["amount_price"] }}
                </span>
              </div>
            </template>
          </vxe-column>

          <vxe-column field="sequential_last" min-width="70" title="昨日变化">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span
                  v-if="index == '行业和公司'"
                  :style="
                    $stockHelpers.getColor(row.sequential_last['fundamentals'])
                  "
                >
                  {{ row.sequential_last["fundamentals"] }}
                </span>
                <span
                  v-if="index == '催化剂'"
                  :style="
                    $stockHelpers.getColor(row.sequential_last['catalyst'])
                  "
                >
                  {{ row.sequential_last["catalyst"] }}
                </span>
                <span
                  v-if="index == '交易'"
                  :style="
                    $stockHelpers.getColor(row.sequential_last['amount_price'])
                  "
                >
                  {{ row.sequential_last["amount_price"] }}
                </span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="当日涨幅" field="applies_day">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_day)">{{
                  $stockHelpers.getPercent(row.applies_day)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="量比" field="volume_ratio">
            <template #default="{ row }">
              <div class="d-flex justify-center">
                <div
                  style="
                    color: #fff;
                    padding: 3px;
                    border-radius: 5px;
                    width: 40px;
                  "
                  :style="{
                    backgroundColor:
                      row.volume_ratio > 1
                        ? 'rgb(225, 61, 49)'
                        : 'rgb(0, 170, 0)',
                  }"
                >
                  {{ row.volume_ratio }}
                </div>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="波动率" field="volatility">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.volatility }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="5日涨幅" field="applies_five">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_five)">{{
                  $stockHelpers.getPercent(row.applies_five)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="30日涨幅" field="applies_month">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_month)">{{
                  $stockHelpers.getPercent(row.applies_month)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="90日涨幅" field="applies_ninety">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_ninety)">{{
                  $stockHelpers.getPercent(row.applies_ninety)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" title="入池以来涨幅" field="applies_focus">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                  $stockHelpers.getPercent(row.applies_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="70"
            title="市值/亿"
            field="market_value_million"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.market_value_million }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="PE-TTM" field="pe_ttm">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.pe_ttm }}
              </div>
            </template>
          </vxe-column>

          <!-- <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[0] + ')'"
            field="pes[0]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[0]]
                    ? parseFloat(row.pes[pesArr[0]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[1] + ')'"
            field="pes[1]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[1]]
                    ? parseFloat(row.pes[pesArr[1]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[2] + ')'"
            field="pes[2]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[2]]
                    ? parseFloat(row.pes[pesArr[2]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column> -->

          <vxe-column min-width="70" title="入池日期" field="created_at">
            <template #default="{ row }">
              <div class="d-flex justify-srart">
                {{ row.created_at.split(" ")[0] }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>

        <vxe-pager
          :loading="tableLoading"
          :current-page="tablePage1[index].currentPage"
          :page-size="10"
          :total="item.length"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
          @page-change="
            (value) =>
              getPage(
                value,
                item,
                $refs['suggestionTable' + index],
                tablePage1[index]
              )
          "
        >
        </vxe-pager>
      </v-card>

      <v-card
        v-for="(item, index) in tableData2"
        :key="index"
        style="height: 100%"
        class="border-radius-md px-3 pt-3 my-2"
      >
        <div>{{ index }}</div>
        <vxe-table
          :data="item.slice(0, tablePage2[index].pageSize)"
          style="width: 100%"
          class="mytable-scrollbar"
          :border="false"
          resizable
          show-overflow
          show-header-overflow
          highlight-hover-row
          :ref="'suggestionTable' + index"
          :loading="tableLoading"
          height="385px"
          size="small"
          :sort-config="{ trigger: 'cell', multiple: true }"
          :empty-render="{ name: 'NotData' }"
          align="center"
          :tree-config="{ expandAll: true, reserve: false }"
        >
          <vxe-column
            fixed="left"
            min-width="100"
            field="stock.code"
            title="股票代码"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.code ? row.stock.code : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column
            fixed="left"
            min-width="80"
            field="stock.name"
            title="股票名称"
          >
            <template #default="{ row }">
              <div class="d-flex justify-start">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.stock.id)"
                  class="text-xs font-weight-normal text-typo"
                  >{{ row.stock.name ? row.stock.name : "-" }}</a
                >
              </div>
            </template>
          </vxe-column>

          <vxe-column field="recommend_date" min-width="70" title="推荐日期">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.recommend_date.split(" ")[0] }}
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="60"
            field="market_value_million"
            title="市值/亿"
          >
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.market_value_million }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="60" field="close_price" title="收盘价">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.close_price }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="60" field="applies_day" title="当日涨幅">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_day)">{{
                  $stockHelpers.getPercent(row.applies_day)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="50" field="volume_ratio" title="量比">
            <template #header="{ column }">
              <div style="word-wrap: break-word; white-space: pre-wrap">
                {{ column.title }}
              </div>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.volume_ratio }}
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="波动率" field="volatility">
          </vxe-column>

          <vxe-column min-width="70" title="5日涨幅" field="applies_five">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_five)">{{
                  $stockHelpers.getPercent(row.applies_five)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="30日涨幅" field="applies_month">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_month)">{{
                  $stockHelpers.getPercent(row.applies_month)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="90日涨幅" field="applies_ninety">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_ninety)">{{
                  $stockHelpers.getPercent(row.applies_ninety)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="80" title="入池涨幅" field="applies_focus">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_focus)">{{
                  $stockHelpers.getPercent(row.applies_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column
            min-width="70"
            title="入池最大涨幅"
            field="applies_max_focus"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                <span :style="$stockHelpers.getColor(row.applies_max_focus)">{{
                  $stockHelpers.getPercent(row.applies_max_focus)
                }}</span>
              </div>
            </template>
          </vxe-column>

          <vxe-column min-width="70" title="PE-TTM" field="pe_ttm">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.pe_ttm }}
              </div>
            </template>
          </vxe-column>

          <!-- <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[0] + ')'"
            field="pes[0]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[0]]
                    ? parseFloat(row.pes[pesArr[0]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[1] + ')'"
            field="pes[1]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[1]]
                    ? parseFloat(row.pes[pesArr[1]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            min-width="70"
            :title="'PE(' + pesArr[2] + ')'"
            field="pes[2]"
          >
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{
                  row.pes[pesArr[2]]
                    ? parseFloat(row.pes[pesArr[2]]).toFixed(2)
                    : "-"
                }}
              </div>
            </template>
          </vxe-column> -->

          <vxe-column min-width="70" title="入池理由" field="reason">
            <template #default="{ row }">
              <div class="d-flex justify-end">
                {{ row.reason }}
              </div>
            </template>
          </vxe-column>
        </vxe-table>

        <vxe-pager
          :loading="tableLoading"
          :current-page="tablePage2[index].currentPage"
          :page-size="10"
          :total="item.length"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Total']"
          @page-change="
            (value) =>
              getPage(
                value,
                item,
                $refs['suggestionTable' + index],
                tablePage2[index]
              )
          "
        >
        </vxe-pager>
      </v-card>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
export default {
  props: {},
  data() {
    return {
      tableLoading: false,
      pesArr: [],
      tableData1: [],
      tablePage1: {
        行业和公司: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
        催化剂: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
        交易: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
      },

      tableData2: [],
      tablePage2: {
        OTC: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
      },
    };
  },
  mounted() {
    this.getTodaySuggest();
  },
  methods: {
    getTodaySuggest() {
      this.tableLoading = true;
      Review.getTodaySuggest()
        .then((res) => {
          //console.log(res.data);
          this.pesArr = [];
          for (let i in res.data.fundamentals[0].pes) {
            this.pesArr.push(i);
          }

          this.tableData1 = {
            行业和公司: res.data.fundamentals,
            催化剂: res.data.catalyst,
            交易: res.data.amount_price,
          };
          this.tablePage1["行业和公司"] = {
            currentPage: 1,
            pageSize: 10,
            totalResult: res.data.fundamentals.length,
          };
          this.tablePage1["催化剂"] = {
            currentPage: 1,
            pageSize: 10,
            totalResult: res.data.catalyst.length,
          };
          this.tablePage1["交易"] = {
            currentPage: 1,
            pageSize: 10,
            totalResult: res.data.amount_price.length,
          };

          this.tableData2 = {
            OTC: res.data.outpools,
          };
          this.tablePage2["OTC"] = {
            currentPage: 1,
            pageSize: 10,
            totalResult: res.data.outpools.length,
          };
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    getPage(pager, tableData, table, pageObj) {
      pageObj.currentPage = pager.currentPage;
      this.$tableHelpers.getPage(pager, tableData, table);
    },
  },
};
</script>
<style scoped="scoped"></style>
