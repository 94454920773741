<template>
  <div>
    <p style="padding: 10px; font-size: 30px">{{ appStore.printTitle }}</p>
    <div v-html="appStore.printContent"></div>
  </div>
</template>

<script>
import EditorDisplay from '@/views/Dashboard/EditorDisplay';
export default {
  name: 'StockDetail',
  components: {
    EditorDisplay,
  },
  data() {
    return {};
  },
  mounted() {
    //console.log(this.appStore.printContent);
    this.showLoading('请稍候！');
    setTimeout(() => {
      this.hideLoading();
      setTimeout(() => {
        this.hideLoading();
        window.print();
      }, 500);
    }, 3000);
  },
  methods: {},
};
</script>
<style></style>
