import Vue from 'vue';
import Interceptor from './core/interceptor';
import Request from './index';
import TokenApi from '../.././../api/token';
import { useUserStore } from '@/store/user';
import qs from 'qs';
import router from '../../../router/index.js';

export const globalInterceptor = {
  request: new Interceptor(),
  response: new Interceptor(),
};

/**
 * 全局配置
 * 只能配置 静态数据
 * `content-type` 默认为 application/json
 * `header` 中`content-type`设置特殊参数 或 配置其他会导致触发 跨域 问题，出现跨域会直接进入响应拦截器的catch函数中
 */
export var config = {
  baseURL: 'https://100.lhotsetech.com',
  headers: {
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin',
  },
};

/**
 * 全局 请求拦截器, 支持添加多个拦截器
 * 例如: 配置token、添加一些默认的参数
 *
 * `return config` 继续发送请求
 * `return false` 会停止发送请求，不会进入错误数据拦截，也不会进入请求对象中的catch函数中
 * `return Promise.reject('xxxxx')` 停止发送请求, 会错误数据拦截，也会进入catch函数中
 *
 * @param {Object} config 发送请求的配置数据
 */
globalInterceptor.request.use(
  (config) => {
    //console.log('开始一个请求:', config.url);
    getToken() && (config.headers['Authorization'] = getToken());

    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if (config.method === 'get') {
      config.data = qs.stringify({
        ...config.data,
      });
    }
    return config;
  },
  (err) => {
    console.error('一个请求失败了: ', err);
    return false;
  }
);

/**
 * 全局 响应拦截器, 支持添加多个拦截器
 * 例如: 根据状态码选择性拦截、过滤转换数据
 *
 * `return res` 继续返回数据
 * `return false` 停止返回数据，不会进入错误数据拦截，也不会进入catch函数中
 * `return Promise.reject('xxxxx')` 返回错误信息, 会错误数据拦截，也会进入catch函数中
 *
 * @param {Object} res 请求返回的数据
 * @param {Object} config 发送请求的配置数据
 * @return {Object|Boolean|Promise<reject>}
 */
globalInterceptor.response.use(
  async (res, config) => {
    ////////////////////////////////////////
    //  demo使用的是 用code模拟http状态码   //
    ////////////////////////////////////////

    //console.log('请求已响应:', config.url);

    // 跳过 `request().download()` 这个拦截
    if (typeof res.tempFilePath !== 'undefined') {
      return res;
    }

    const {
      data,
      data: { code },
    } = res;

    if (data.version && data.version != '1.0.10') {
      location.reload();
    }

    try {
      return await handleCode({
        data,
        code,
        config,
      });
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  },
  (err, config) => {
    console.error('一个请求响应失败');
    console.error('err: ', err);
    console.error('config: ', config);

    //showToast(err.msg);

    return Promise.reject(err);
    // return false;
  }
);

/**
 * 重新请求更新获取 `token`
 * @param {number} uid
 * @return {Promise}
 */
function getApiToken(uid) {
  return TokenApi.getMockToken(uid).then((res) => {
    return res.token;
  });
}

/**
 * 获取 `localStorage` 中的 `token`
 * @return {string} token字符串
 */
function getToken() {
  return Vue.prototype.userStore.token;
}

/**
 * 保存 `token` 到  `localStorage`
 * @param {string} token token字符串
 * @return {void}
 */
function saveToken(token) {
  //store.commit('setToken', token);
}

/**
 * 处理 http状态码
 * @param {object} o
 * @param {object} o.data 请求返回的数据
 * @param {object} o.config 本次请求的config数据
 * @param {string|number} o.code http状态码
 * @return {object|Promise<reject>}
 */
function handleCode({ data, code, config }) {
  const STATUS = {
    0() {
      return data;
    },
    1() {
      return Promise.reject({
        code,
        msg: '请求错误',
      });
    },
    201() {
      return data;
    },
    202() {
      return data;
    },
    400() {
      // return { code, msg: '请求错误' };
      return Promise.reject({
        code,
        msg: '请求错误',
      });
    },
    401() {
      // return { code, msg: '请求错误' };
      return Promise.reject({
        code,
        msg: data.msg,
      });
    },
    422() {
      Vue.prototype.userStore.token = '';
      Vue.prototype.userStore.name = '';

      router.push({ name: '登录', params: {} });
      return Promise.reject({
        code,
        msg: '请重新登录',
      });
    },
    403() {
      return Promise.reject({
        code,
        msg: data.msg,
      });
    },
    404() {
      //store.set('user/userInfo', null);
      //store.set('user/token', null);
      router.push({ name: '登录', params: {} });
      // return Promise.reject({
      //   code,
      //   msg: data.msg
      // });
    },
    500() {
      return Promise.reject({
        code,
        msg: data.msg,
      });
    },
  };
  return STATUS[code] ? STATUS[code]() : Promise.reject(data, config); // 有状态码但不在这个封装的配置里，就直接进入 `fail`
}

// 显示消息提示框
function showToast(data) {}
