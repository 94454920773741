<template>
  <div>
    <vxe-toolbar class="mx-5" custom :refresh="{ query: getStockList }">>
      <template v-slot:buttons>
        <vxe-input v-model="filterName" type="search" placeholder="全文搜索"></vxe-input>
        <vxe-button @click="stockAdd()" size="medium" content="调入新股票" status="my-primary"></vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table id="coreTable" ref="coreTable" :data="list" :border="false" resizable show-overflow show-header-overflow
      highlight-hover-row align="center" :loading="coreTableLoading" :column-config="{ resizable: true }"
      :height="tableHeight" size="small" :stripe="true" :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }" :empty-render="{ name: 'NotData' }"
      :custom-config="{ storage: true, checkMethod: checkColumnMethod }"
      :tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }">

      <vxe-column fixed="left" min-width="100" field="stock_code" title="股票代码">
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a href="javascript:;" @click="$stockHelpers.openStockDetail(row.id)"
              class="text-xs font-weight-normal text-typo">{{ row.stock_code ? row.stock_code : '-' }}</a>
          </div>
        </template>
      </vxe-column>

      <vxe-column fixed="left" min-width="80" field="stock_name" title="股票名称">
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a href="javascript:;" @click="$stockHelpers.openStockDetail(row.id)"
              class="text-xs font-weight-normal text-typo">{{ row.stock_name ? row.stock_name : '-' }}</a>
          </div>
        </template>
      </vxe-column>

      <vxe-column field="created_at" min-width="70" title="入池日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.created_at.split(' ')[0] }}
          </div>
        </template>
      </vxe-column>

      <vxe-column field="recommend_date" min-width="70" title="推荐日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.recommend_date.split(' ')[0] }}
          </div>
        </template>
      </vxe-column>

      <vxe-column field="applies_recommend" min-width="70" title="推荐后涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span v-if="row.recommend == 1" :style="$stockHelpers.getColor(row.applies_recommend)">{{
                $stockHelpers.getPercent(row.applies_recommend)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="market_value_million" title="市值/亿" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.market_value_million }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="current_market_million" title="流通/亿" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.current_market_million }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="close_price" title="收盘价" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.close_price }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="applies_day" title="当日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_day)">{{ $stockHelpers.getPercent(row.applies_day)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="50" field="volume_ratio" title="量比" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.volume_ratio }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="50" field="volatility" title="波动率" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.volatility }}
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="applies_five" title="5日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_five)">{{ $stockHelpers.getPercent(row.applies_five)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="applies_20" title="20日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_20)">{{ $stockHelpers.getPercent(row.applies_20) }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="applies_60" title="60日涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_60)">{{ $stockHelpers.getPercent(row.applies_60) }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="60" field="applies_focus" title="入池涨幅" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.applies_focus)">{{ $stockHelpers.getPercent(row.applies_focus)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column field="pe_ttm" min-width="70" title="PE-TTM" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.pe_ttm }}
          </div>
        </template>
      </vxe-column>

      <vxe-column field="pb" min-width="50" title="PB" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            {{ row.pb }}
          </div>
        </template>
      </vxe-column>

      <vxe-column title="操作" type="seq" width="120" :resizable="false" show-overflow>
        <template #default="{ row }">
          <div>
            <a href="javascript:;" @click="stockRemove(row.id)" class="text-xs font-weight-normal text-typo">调出</a>
            <a href="javascript:;" @click="stockEdit(row.id)" class="text-xs font-weight-normal text-typo"
              style="margin-left: 5px">修改</a>
            <a v-if="row.recommend == 0" href="javascript:;" @click="recommend(row)"
              class="text-xs font-weight-normal text-typo" style="margin-left: 5px">推荐</a>
            <a v-if="row.recommend == 1" href="javascript:;" @click="recommend(row)"
              class="text-xs font-weight-normal text-typo" style="margin-left: 5px">取消推荐</a>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";

import rate from '@/components/Rate/Rate.vue';
import StockPool from '@/api/stockPool.js';
import Stock from '@/api/stock.js';
import XEUtils from 'xe-utils';
import StockAdd from '@/components/StockPool/StockAdd.vue';
import StockEdit from '@/components/StockPool/StockEdit.vue';

export default {
  name: 'StockPool',
  props: {},
  data() {
    return {
      stockList: [],
      coreTableLoading: false,
      tableHeight: '500px',
      stockLevelMap: {
        1: '核心',
        // 2: "备选",
        3: '一级',
        4: '二级',
        5: '三级'
      },

      filterName: '',
      tableData: [],
    };
  },
  components: {
    rate,
    StockAdd,
    StockEdit,
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 140 + 'px';
    this.getStockList();

    Bus.$on('refreshOtcStockPool', (e) => {
      this.getStockList();
    });
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, 'gi');
        const searchProps = [
          'stock_name',
          'stock_code',
          'level',
          'pinyin',
          'industry_name',
          'industry_pinyin',
          'styleGroupString',
          'stylePYString',
          'racesGroupString',
          'racesPYString',
          'focus_users',
          'research_user',
          'update_user'
        ];
        const rest = this.tableData.filter(item =>
          searchProps.some(
            key =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
        // return rest.map(row => {
        // 	const item = Object.assign({}, row);
        // 	searchProps.forEach(key => {
        // 		item[key] = XEUtils.toValueString(item[key]).replace(filterRE, match => {
        // 			return `<span class="keyword-lighten">${match}</span>`;
        // 		});
        // 	});
        // 	return item;
        // });
      }
      return this.tableData;
    }
  },
  methods: {
    checkColumnMethod({ column }) {
      if (column.property === 'role') {
        return false;
      }
      return true;
    },
    getStockList() {
      this.coreTableLoading = true;
      StockPool.getStockPoolList(3)
        .then(res => {
          res.data.forEach(e => {
            //_self.currentInfoStkList.push(e.stockCode);

            e.styleGroupString = '';
            e.stylePYString = '';
            if (e.styles) {
              e.styles.forEach(a => {
                e.styleGroupString = e.styleGroupString = e.styleGroupString + a.name + ',';
                e.stylePYString = e.stylePYString = e.stylePYString + a.pinyin + ',';
              });
            }

            e.racesGroupString = '';
            e.racesPYString = '';
            if (e.races) {
              e.races.forEach(a => {
                e.racesGroupString = e.racesGroupString = e.racesGroupString + a.name + ',';
                e.racesPYString = e.racesPYString = e.racesPYString + a.pinyin + ',';
              });
            }
          });

          this.tableData = res.data;
        })
        .catch(err => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.coreTableLoading = false;
        });
    },

    filterCodeMethod({ option, row }) {
      //console.log(option.data);
      return row.stock_code.indexOf(option.data) > -1;
    },

    filterMarketMethod({ option, row }) {
      //console.log(option.value);
      return row.market.indexOf(option.value) > -1;
    },

    codeFilter(data) {
      const coreTable = this.$refs.coreTable;
      const column = coreTable.getColumnByField('stock.code');
      const option = column.filters[0];
      option.data = data;
      option.checked = true;
      coreTable.updateData();
    },

    stockAdd() {
      this.$layer.iframe({
        content: {
          content: StockAdd,
          parent: this,
          data: { iframeData: { isOtc: true } }
        },
        area: ['600px', '600px'],
        title: '调入新股票(带*为必填)',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => { //关闭弹窗事件

        }
      });
    },
    stockEdit(id) {
      this.showLoading('获取股票已有信息中，请稍候');
      StockPool.getStockDetail(id).then(res => {
        //console.log(res.data);
        res.data.isOtc = true;
        this.$layer.iframe({
          content: {
            content: StockEdit,
            parent: this,
            data: { iframeData: res.data }
          },
          area: ['600px', '650px'],
          title: '修改股票(带*为必填)--' + res.data.stock.name,
          maxmin: false,
          shade: true,
          shadeClose: false,
          cancel: () => { //关闭弹窗事件

          }
        });
      }).catch(err => {
        console.log(err.msg);
        this.showToast(err.msg, 'danger', 10000);
      }).finally(() => {
        this.hideLoading();
      });

    },
    stockRemove(id) {
      this.$layer.confirm('确定要调出吗？', layerid => {
        this.showLoading('调出中，请稍候');
        StockPool.removeStock(id, 3).then(res => {
          this.showToast('已成功提交调出！', 'success', 5000);
          Bus.$emit("refreshOtcStockPool", true);
          Bus.$emit("refreshApplyStockPool", true);
        }).catch(err => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        }).finally(() => {
          this.hideLoading();
        });
        this.$layer.close(layerid);
      });
    },
    recommend(row) {
      let recommend;
      if (row.recommend == 0) {
        recommend = 1;
      } else {
        recommend = 0;
      }
      this.$layer.confirm('确定要' + (recommend == 1 ? '推荐' : '取消推荐') + '吗？', layerid => {
        this.showLoading('保存中，请稍候');
        StockPool.editStock(row.id, { recommend: recommend }).then(res => {
          this.showToast('操作成功', 'success', 5000);
          this.getStockList();
          this.$layer.close(this.layerid);
        }).catch(err => {
          console.log(err.msg);
          this.showToast(err.msg, 'danger', 10000);
        }).finally(() => {
          this.hideLoading();
        });
        this.$layer.close(layerid);
      });

    },
  }
};
</script>
