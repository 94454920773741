import { render, staticRenderFns } from "./QuantLayout.vue?vue&type=template&id=6d6827d2&scoped=true"
import script from "./QuantLayout.vue?vue&type=script&lang=js"
export * from "./QuantLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6827d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
