<template>
  <!-- <fade-transition> -->
  <div
    v-show="loadingVisible"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      @touchmove.prevent
      @mousewheel.prevent
      style="
        position: fixed;
        background-color: #000000;
        height: 100vh;
        width: 100vw;
        z-index: 9999998;
        opacity: 0.5;
        padding: 0;
      "
    ></div>
    <div
      @touchmove.prevent
      @mousewheel.prevent
      style="
        opacity: 0.95;
        border-radius: 20px;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        background-color: #ffffff;
        z-index: 9999999;
        padding: 5px;
      "
    >
      <img
        id="logorotate"
        class="add"
        src="~@/assets/img/logo.png"
        width="50px"
      />

      <div style="margin-top: 20px; text-align: center">
        {{ content }}
      </div>
    </div>
  </div>
  <!-- </fade-transition> -->
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import Vue from 'vue';
// import { mapStores } from 'pinia'
// import { useAppStore } from '@/store/app'

export default {
  name: 'loading',
  components: {
    FadeTransition,
  },
  props: {},
  mounted() {
    Vue.prototype.showLoading = (content = '加载中，请稍候') => {
      this.loadingVisible = true;
      this.content = content;
    };
    Vue.prototype.hideLoading = () => {
      //console.trace();
      this.loadingVisible = false;
    };

    //console.log(this.appStore.loadingVisible);
    document.getElementById('logorotate').animate(
      [
        {
          transform: 'rotateY(0deg)',
        },
        {
          transform: 'rotateY(360deg)',
        },
      ],
      {
        duration: 2000,
        iterations: Infinity,
      }
    );
  },
  data() {
    return {
      loadingVisible: false,
      content: '加载中，请稍候',
    };
  },
  methods: {},
  computed: {
    //...mapStores(useAppStore),
  },
};
</script>
<style scoped="scoped"></style>
