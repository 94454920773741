<template>
	<div>
		行业分布
		<div style="width: 100%; height: 300px" id="pieContainer"></div>
		
	</div>
</template>
<script>
import Portfolio from '@/api/portfolio.js';
import Bus from '@/components/Bus/bus.js';

export default {
	name: 'Trading',
	components: {
		
	},
	props: {
		portfolioDetail:{},
	},
	mounted() {
		//console.log(this.portfolioDetail.pie);
		this.showPie();
	},
	data() {
		return {
			
		};
	},
	methods: {
		showPie(){
			let dom = document.getElementById('pieContainer');
			let myChart = this.$echarts.init(dom);
			let option = {
			  tooltip: {
			    trigger: 'item',
			    //formatter: '{b}: {d}%'
					formatter: '{b}'
			  },
			  
			  series: [
			    
			    {
			      name: '持仓分布',
			      type: 'pie',
			      radius: ['30%', '60%'],
			      labelLine: {
			        length: 30
			      },
			     
			      data: this.portfolioDetail.pie,
						
			    }
			  ]
			};
			myChart.setOption(option, true);
		}
	},
	computed: {},
	watch: {
		
	}
};
</script>
<style scoped="scoped"></style>
