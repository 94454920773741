<template>
  <div>
    <div v-for="item in tableData">
      {{ tableData.plainText }}
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Stock from "@/api/stock.js";
import XEUtils from "xe-utils";
export default {
  name: "BindFile",
  components: {},
  props: {
    tagName: "",
  },

  data() {
    return {
      tableData: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  computed: {},
  mounted() {
    if (this.tagName) {
      this.getInfoList();
    }
  },
  methods: {
    getInfoList() {
      this.bindFileTableLoading = true;
      File.getInfoList(
        this.pageInfo.currentPage,
        this.pageInfo.pageSize,
        this.tagName
      )
        .then((res) => {
          this.tableData = res.data.data;
          this.pageInfo.totalResult = res.data.total;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.bindFileTableLoading = false;
        });
    },
    getText(text) {
      text = text.replace(/<br>/g, "\n").replace(/<br\/>/g, "\n");
      return text;
    },
    getPage(value) {
      this.pageInfo.currentPage = value.currentPage;
      this.getResearchList();
    },
    previewFile(row) {
      Bus.$emit("previewFile", { url: row.reportUrl, name: row.reportUrl });
    },
    downloadFile(row) {
      window.open(row.reportUrl);
    },
  },
  watch: {
    currKey(val) {
      if (val == -1) {
        this.$tableHelpers.filterInput(this.$refs.fileTable, "tags", "");
      } else {
        this.$tableHelpers.filterInput(
          this.$refs.fileTable,
          "tags",
          this.tagArr[val]
        );
      }
    },
    currTag() {
      let data = JSON.parse(JSON.stringify(this.currTag.file));
      data.forEach((item, index) => {
        data[index].tags = item.tags.toString();
      });
      this.tableData = data;
    },
  },
};
</script>
<style scoped="scoped"></style>
