<template>
  <div class="py-2">
    <div class="">
      <!-- <vxe-button
        @click="addSource"
        size="mini"
        status="my-primary"
        class="mb-2"
      >
        新增信息源
      </vxe-button> -->
      <div>
        <vxe-table
          id="sourceList"
          style="width: 100%"
          :data="appStore.tagDetail.source"
          class="my-scrollbar"
          show-overflow
          show-header-overflow
          highlight-hover-row
          ref="sourceList"
          size="small"
          :sort-config="{
            trigger: 'cell',
            defaultSort: { field: 'pinyin', order: 'asc' },
            orders: ['desc', 'asc', null],
            multiple: true,
          }"
          :scroll-x="{ gt: 30 }"
          :scroll-y="{ mode: 'wheel', gt: 100 }"
          :tooltip-config="{
            showAll: false,
            enterable: true,
            theme: 'dark',
          }"
          :custom-config="{ storage: true }"
          :filter-config="{ showIcon: false }"
          :expand-config="{ trigger: 'row' }"
          border
          :row-config="{ isHover: true }"
          :edit-config="{ trigger: 'click', mode: 'row' }"
        >
          <!-- <vxe-column title="类型" width="80" field="type" :edit-render="{}">
            <template #edit="{ row }">
              <vxe-select v-model="row.type" transfer>
                <vxe-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></vxe-option>
              </vxe-select>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.type ? row.type : "" }}
              </div>
            </template>
          </vxe-column> -->

          <vxe-column field="name" title="来源名称">
            <template #edit="{ row }">
              <vxe-input v-model="row.name" type="text"></vxe-input>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.name ? row.name : "无数据--点击编辑" }}
              </div>
            </template>
          </vxe-column>

          <!-- <vxe-column
            min-width="35"
            field="url"
            title="来源链接"
            :edit-render="{}"
          >
            <template #edit="{ row }">
              <vxe-input v-model="row.url" type="text"></vxe-input>
            </template>
            <template #default="{ row }">
              <div class="d-flex justify-start">
                {{ row.url ? row.url : "无数据--点击编辑" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column
            fixed="right"
            title="操作"
            type="seq"
            width="100"
            :resizable="false"
            show-overflow
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          >
            <template #default="{ row }">
              <div>
                <a
                  href="javascript:;"
                  @click="upRow(row)"
                  class="text-xs font-weight-normal mr-1 px-2"
                >
                  ↑
                </a>
                <a
                  href="javascript:;"
                  @click="downRow(row)"
                  class="text-xs font-weight-normal mr-1 px-2"
                >
                  ↓
                </a>
                <a
                  href="javascript:;"
                  @click="deleteSource(row)"
                  class="text-xs font-weight-normal text-red mr-1"
                  >删除</a
                >
              </div>
            </template>
          </vxe-column> -->
        </vxe-table>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Tag from "@/api/tag.js";
import XEUtils from "xe-utils";
import { v4 as uuidv4 } from "uuid";
import TagStore from "../../views/TagStore.vue";
export default {
  name: "BindFile",
  components: {},
  props: {
    currTag: "",
  },

  data() {
    return {
      tableData: [],
      pageInfo: {},
      typeList: [
        { label: "公众号", value: "公众号" },
        { label: "网站", value: "网站" },
      ],
    };
  },
  computed: {
    tagDetailSource() {
      return this.appStore.tagDetail.source;
    },
  },
  mounted() {
    console.log(this.appStore.tagDetail.source);
  },
  methods: {
    addSource() {
      this.appStore.tagDetail.source.unshift({
        id: uuidv4(),
        type: "网站",
        name: "",
        url: "",
      });
    },
    deleteSource(row) {
      this.appStore.tagDetail.source = this.appStore.tagDetail.source.filter(
        (item) => item.id !== row.id
      );
    },
    upRow(row) {
      const index = this.appStore.tagDetail.source.findIndex(
        (item) => item.id === row.id
      );
      if (index > 0) {
        const temp = this.appStore.tagDetail.source[index];
        this.appStore.tagDetail.source.splice(index, 1);
        this.appStore.tagDetail.source.splice(index - 1, 0, temp);
      }
    },
    downRow(row) {
      const index = this.appStore.tagDetail.source.findIndex(
        (item) => item.id === row.id
      );
      if (index < this.appStore.tagDetail.source.length - 1) {
        const temp = this.appStore.tagDetail.source[index];
        this.appStore.tagDetail.source.splice(index, 1);
        this.appStore.tagDetail.source.splice(index + 1, 0, temp);
      }
    },
  },
  watch: {
    // tagDetailSource: {
    //   handler(val) {
    //     //console.log(val);
    //     Tag.editTag(this.currTag.id, { source: val })
    //       .then((res) => {
    //         this.showToast("自动保存成功", "success", 1000);
    //       })
    //       .catch((err) => {
    //         console.log(err.msg);
    //         this.showToast(err.msg, "danger", 10000);
    //       })
    //       .finally(() => {
    //         this.hideLoading();
    //       });
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style scoped="scoped"></style>
