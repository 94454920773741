<template>
  <div>
    <div class="px-0 py-1">
      <div class="mt-1 px-2">
        <a
          class="pr-4"
          v-if="item.display"
          v-for="item in tabList"
          :key="item.url"
          :ripple="true"
          @click="currentTab = item.url"
          style="background-color: transparent; width: auto"
          :elevation="0"
        >
          <span
            class="pa-0"
            :class="{
              'active-tab': currentTab == item.url,
              'un-active-tab': currentTab != item.url,
            }"
            >{{ item.title }}</span
          >
        </a>
      </div>
      <v-tabs-items v-model="currentTab" class="pa-2">
        <v-tab-item :key="0"> <Hot></Hot> </v-tab-item>

        <v-tab-item :key="1"> <Movements></Movements> </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Hot from './Hot.vue';
import Movements from './Movements.vue';
export default {
  name: 'Hotspot',
  components: { Hot, Movements },
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          title: '热门',
          url: 0,
          display: true,
        },
        {
          title: '异动',
          url: 1,
          display: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
