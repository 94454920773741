<template>
  <div style="width: 100%">
    <div style="width: 100%" v-show="pageLoading">
      <LoadingPage :visible="pageLoading"></LoadingPage>
    </div>
    <div v-show="!pageLoading">
      <div class="mt-2 note-toolbar">
        <div style="width: 100%; justify-content: space-between" class="d-flex">
          <div class="d-flex" style="align-items: center">
            <vxe-button
              :content="isNewNote && noteType != -1 ? '修改类型' : '新建笔记'"
              transfer
              status="my-primary"
            >
              <template #dropdowns style="z-index: 1000">
                <vxe-button
                  v-for="item in typeArrFinal"
                  :content="item.label"
                  @click="newNote(item.value)"
                  :key="item.id"
                >
                </vxe-button>
              </template>
            </vxe-button>

            <vxe-pulldown
              ref="bindStockPanel"
              destroy-on-close
              class="ml-2"
              transfer
            >
              <template #default>
                <vxe-button
                  @click="$refs.bindStockPanel.togglePanel()"
                  status="my-primary"
                  :disabled="!ready"
                  >绑定股票
                </vxe-button>
              </template>
              <template #dropdown>
                <div class="note-bindStock-dropdown">
                  <StockSelect
                    :showIcon="true"
                    :returnItem="true"
                    @getItem="bindNewStock"
                    placeholder="搜索股票(选择后自动添加)"
                    :disableArr="bindStockIds"
                    style="width: 100%"
                  ></StockSelect>
                  <vxe-table auto-resize :data="bindStock" height="300px">
                    <vxe-column field="name" title="股票名"></vxe-column>
                    <vxe-column field="code" title="代码"></vxe-column>
                    <vxe-column title="操作">
                      <template #default="{ row }">
                        <a
                          href="javascript:;"
                          @click="deleteStock(row.id)"
                          class="text-xs font-weight-normal text-typo mr-1"
                          style="color: #344767"
                          >删除</a
                        >
                      </template>
                    </vxe-column>
                  </vxe-table>
                </div>
              </template>
            </vxe-pulldown>

            <vxe-pulldown
              ref="bindTagPanel"
              destroy-on-close
              class="ml-2"
              transfer
            >
              <template #default>
                <vxe-button
                  @click="$refs.bindTagPanel.togglePanel()"
                  status="my-primary"
                  :disabled="!ready"
                  >绑定标签
                </vxe-button>
              </template>
              <template #dropdown>
                <div class="note-bindStock-dropdown">
                  <TagSelect
                    :showIcon="true"
                    :returnItem="true"
                    @getItem="bindNewTag"
                    placeholder="搜索标签(选择后自动添加)"
                    :disableArr="bindTagIds"
                    style="width: 100%"
                  >
                  </TagSelect>
                  <vxe-table auto-resize :data="bindTag" height="300px">
                    <vxe-column field="name" title="标签名"></vxe-column>
                    <vxe-column field="type" title="类型" width="80px">
                      <template #default="{ row }">
                        {{ getTagType(row) }}
                      </template>
                    </vxe-column>
                    <vxe-column title="操作" width="80px">
                      <template #default="{ row }">
                        <a
                          href="javascript:;"
                          @click="deleteTag(row.id)"
                          class="text-xs font-weight-normal text-typo mr-1"
                          style="color: #344767"
                          >删除</a
                        >
                      </template>
                    </vxe-column>
                  </vxe-table>
                </div>
              </template>
            </vxe-pulldown>

            <vxe-button
              @click="print()"
              class="ml-2"
              status="my-primary"
              :disabled="!ready"
              >打印</vxe-button
            >
            <vxe-button
              size="medium"
              content="导出"
              class="ml-2"
              :disabled="!ready"
              status="my-primary"
              @click="exportData"
              v-if="$commonHelpers.getPermissions('reviewnotice.export')"
            ></vxe-button>
          </div>
          <div class="d-flex" style="align-items: center">
            <div v-if="noteType != -1" class="mr-4 text-dark">
              分类：<span style="font-weight: 800">{{
                getType(noteType)
              }}</span>
            </div>
            <div v-if="isSelf && !isNewNote" class="mr-4 text-dark">
              <vxe-button
                content="删除"
                status="danger"
                @click="deleteNote()"
              ></vxe-button>
            </div>

            <vxe-button
              v-if="isSelf || isNewNote"
              content="保存当前内容"
              status="primary"
              @click="saveNote()"
            ></vxe-button>
          </div>
        </div>
      </div>
      <div class="bg-white note-toolbar1">
        <Toolbar :defaultConfig="toolbarConfig" :editor="editor" />
      </div>

      <div
        v-show="!isNewNote && !readItem.id"
        style="
          width: 100%;
          height: 90vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <v-icon size="100" style="color: #aaaaaa">mdi-application-edit</v-icon>
        <p style="color: #aaaaaa; font-size: 25px">
          请点击“新建笔记”或选择要查看的笔记
        </p>
      </div>
      <div id="content" style="width: 100%" v-show="isNewNote || readItem.id">
        <div id="editor-container" style="margin-top: 120px">
          <div id="title-container">
            <input
              value="请点击新建笔记或选择要查看的笔记"
              placeholder="请输入标题"
              v-model="title"
              :readonly="!isSelf"
            />
          </div>
          <Editor
            style="min-height: 200px; overflow-y: hidden; width: 100%"
            @onChange="onChange"
            :editorId="editorId"
            :defaultConfig="editorConfig"
            @onCreated="onCreated"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import Note from "@/api/note.js";

import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

import attachmentModule from "@wangeditor/plugin-upload-attachment";
import markdownModule from "@wangeditor/plugin-md";
import { Boot } from "@wangeditor/editor";
Boot.registerModule(attachmentModule);
Boot.registerModule(markdownModule);

import StockSelect from "@/components/CommonSelect/StockSelect.vue";
import TagSelect from "@/components/CommonSelect/TagSelect.vue";

import Bus from "@/components/Bus/bus.js";

const OSS = require("ali-oss");
import File from "@/api/file.js";

export default Vue.extend({
  name: "Note",
  components: { Editor, Toolbar, StockSelect, TagSelect },
  props: {
    typeArr: [],
    readItem: {},
  },
  data() {
    return {
      typeArrFinal: [],
      ready: false,
      isSelf: false,
      pageLoading: false,

      today: "",
      noteType: -1,
      isNewNote: false,
      autoSaveInterval: null,
      title: "",
      content: "",
      bindStock: [],
      bindTag: [],
      comments: [],

      editor: {},
      editorId: `note-editor-text-area`, //【注意】编辑器 id ，要全局唯一
      toolbarConfig: {
        insertKeys: {
          index: 0, // 自定义插入的位置
          keys: ["uploadAttachment"], // “上传附件”菜单
        },
        toolbarKeys: [
          "bold",
          "underline",
          "italic",
          "clearStyle",
          "color",
          "bgColor",
          "|",
          "fontSize",
          {
            key: "group-justify", // 必填，要以 group 开头
            title: "对齐",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-link", // 必填，要以 group 开头
            title: "超链",
            menuKeys: ["insertLink", "editLink", "unLink", "viewLink"],
          },
          {
            key: "group-table", // 必填，要以 group 开头
            title: "表格",
            menuKeys: [
              "insertTable",
              "deleteTable",
              "insertTableRow",
              "deleteTableRow",
              "insertTableCol",
              "deleteTableCol",
              "tableHeader",
              "tableFullWidth",
            ],
          },
          "lineHeight",
          "divider",
          "blockquote",
          "headerSelect",
          "todo",
          "bulletedList",
          "numberedList",
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        scroll: false,
        autoFocus: true,
        hoverbarKeys: {
          attachment: {
            menuKeys: ["downloadAttachment"], // “下载附件”菜单
          },
        },
        MENU_CONF: {
          uploadImage: {
            customUpload: (file, insertFn) => {
              this.customUpload(file, insertFn);
            },
          },
          uploadAttachment: {
            customUpload: (file, insertFn) => {
              this.customUpload(file, insertFn);
            },
            onInsertedAttachment(elem) {
              //console.log('inserted attachment', elem);
            },
          },
        },
      },
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },

  beforeDestroy() {
    let editor = this.editor;
    if (editor == null) return;
    Bus.$off("refreshComment");

    // 【注意】组件销毁时，及时销毁编辑器
    editor.destroy();
  },
  methods: {
    //编辑器初始化
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.editor.disable();
      //console.log(this.editor);
    },
    getType(type) {
      let typeName = "请选择或新建笔记";
      //console.log(type);
      for (let i = 0; i < this.typeArr.length; i++) {
        if (this.typeArr[i].value == type) {
          typeName = this.typeArr[i].label;
        }
      }
      return typeName;
    },
    isImg(file) {
      let type = file.type;
      if (type.indexOf("image") != -1) {
        return true;
      }
      return false;
    },
    customUpload(fileItem, insertFn) {
      //console.log(fileItem);
      File.getUploadSts()
        .then((res) => {
          let info = res.data;
          let client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: "qinglitouyan",
          });

          let fileName = "fileStore/" + info.uuid + "/" + fileItem.name;

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf("uploadId") != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                "/fileStore/" + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  let url = `${File.getApiUrl()}/api/file/${
                    info.uuid
                  }/downloadDirect`;
                  if (this.isImg(fileItem)) {
                    insertFn(url, "插图", url);
                  } else {
                    insertFn(`附件-${fileItem.name}`, url);
                  }
                })
                .catch((err) => {
                  console.log("err:", err);
                });
            })
            .catch((err) => {
              console.log("err:", err);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    onChange() {
      let div = document.getElementById("content");
      div.scrollTop = div.scrollHeight;
    },
    newNote(type) {
      //this.pageLoading = true;
      this.ready = true;
      this.isSelf = true;
      if (this.isNewNote) {
        this.noteType = type;
      } else {
        this.noteType = type;
        this.isNewNote = true;
        this.editor.enable();
        this.editor.clear();
        this.editor.clear();
        this.title = "";
        this.bindStock = [];
        this.bindTag = [];
      }
    },
    readNewItem() {
      this.pageLoading = true;
      Note.getNoteContent(this.readItem.id)
        .then((res) => {
          this.isNewNote = false;
          this.editor.enable();
          this.editor.clear();
          this.editor.clear();
          //this.editor.setHtml('<p></p>');
          //console.log(this.editor);

          let noteDetail = res.data;

          this.title = noteDetail.title;
          this.bindStock = noteDetail.stocks;
          this.bindTag = noteDetail.races;
          this.noteType = noteDetail.type;
          this.content = noteDetail.content;
          this.editor.dangerouslyInsertHtml(this.content);
          this.comments = noteDetail.comments;
          this.ready = true;

          if (noteDetail.user_id == this.userStore.user_id || this.isNewNote) {
            this.isSelf = true;
          } else {
            this.isSelf = false;
            this.editor.move(999999);
            this.editor.blur();
            this.editor.disable();

            let arr = this.editor.getElemsByType("image");
            arr.forEach((item) => {
              let el = document.getElementById(item.id);
              el.onclick = () => {
                Bus.$emit("previewFile", {
                  url: item.src,
                  name: item.alt + ".jpg",
                });
              };
            });
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
          this.pageLoading = false;
        })
        .finally(() => {});
    },
    //绑定新股票
    bindNewStock(newItem) {
      let haveAdd = false;
      this.bindStock.forEach((item) => {
        if (item.id == newItem.id) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        this.bindStock.push(newItem);
      } else {
        this.showToast("不能重复绑定同一个股票", "danger", 5000);
      }
    },
    //绑定新标签
    bindNewTag(newItem) {
      console.log(newItem);
      let haveAdd = false;
      this.bindTag.forEach((item) => {
        if (item.id == newItem.id) {
          haveAdd = true;
        }
      });
      if (!haveAdd) {
        this.bindTag.push(newItem);
      } else {
        this.showToast("不能重复绑定同一个标签", "danger", 5000);
      }
    },
    //删除绑定的股票
    deleteStock(id) {
      this.bindStock.forEach((item, index) => {
        if (item.id == id) {
          this.bindStock.splice(index, 1);
        }
      });
    },
    //删除绑定的标签
    deleteTag(id) {
      this.bindTag.forEach((item, index) => {
        if (item.id == id) {
          this.bindTag.splice(index, 1);
        }
      });
    },
    deleteNote() {
      this.$layer.confirm("确定要删除笔记吗？", (layerid) => {
        this.showLoading("删除笔记中，请稍候");
        Note.deleteNote(this.readItem.id)
          .then((res) => {
            this.showToast("已成功删除笔记！", "success", 5000);
            Bus.$emit("refreshNoteList", true);
            this.isNewNote = false;
            this.editor.enable();
            this.editor.clear();
            this.editor.clear();
            this.title = "";
            this.bindStock = [];
            this.ready = false;
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    saveNote() {
      let tagIds = [];
      this.bindTag.forEach((item) => {
        tagIds.push({
          type: item.level ? "race" : "style",
          value: item.id,
        });
      });
      if (this.isNewNote) {
        this.showLoading("保存中，请稍候");
        let content = this.editor.getHtml().replace("<p><br></p>", "");
        Note.newNote(
          this.title,
          this.noteType,
          content,
          this.bindStockIds,
          tagIds
        )
          .then((res) => {
            this.showToast("保存成功", "success", 5000);
            Bus.$emit("refreshNoteList", true);
            this.isNewNote = false;
            window.localStorage.removeItem("saveNote");
            this.editor.enable();
            this.editor.clear();
            this.editor.clear();
            this.title = "";
            this.bindStock = [];
            this.bindTag = [];
            this.ready = false;
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      } else {
        this.showLoading("保存修改中，请稍候!");
        let content = this.editor.getHtml().replace("<p><br></p>", "");
        Note.editNote(
          this.readItem.id,
          this.title,
          content,
          this.bindStockIds,
          tagIds
        )
          .then((res) => {
            this.showToast("保存修改成功", "success", 5000);
            Bus.$emit("refreshNoteList", true);
            this.isNewNote = false;
            this.ready = false;
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },
    getTagType(item) {
      if (item.level == 1) {
        return "核心池";
      } else if (item.level == 2) {
        return "一级主题池";
      } else if (item.level == 3) {
        return "普通主题池";
      } else if (item.level == 0) {
        return "行业风格池";
      }
    },
    print() {
      if (this.isNewNote) {
        let content = this.editor.getHtml().replace("<p><br></p>", "");

        this.appStore.printTitle = this.title;
        this.appStore.printContent = content;
        let routeUrl = this.$router.resolve({ name: "打印" });
        window.open(routeUrl.href, "_blank");
      } else {
        this.appStore.printTitle = this.title;
        this.appStore.printContent = this.content;
        let routeUrl = this.$router.resolve({ name: "打印" });
        window.open(routeUrl.href, "_blank");
      }
    },
    beforeRouteUpdate() {
      return new Promise((resolve) => {
        if (this.isNewNote) {
          this.$layer.confirm(
            "检测到您正在编辑笔记，是否要离开？",
            (layerid) => {
              this.$layer.close(layerid);
              clearInterval(this.autoSaveInterval);
              let content = this.editor.getHtml().replace("<p><br></p>", "");
              window.localStorage.setItem("saveNote", content);
              this.showToast("已自动保存笔记草稿", "success", 1000);

              resolve(true);
            },
            (layerid) => {
              this.$layer.close(layerid);
              resolve(false);
            }
          );
        } else {
          resolve(true);
        }
      });
    },
    exportData() {
      this.showLoading("下载中，请稍候");
      Note.exportData(this.readItem.id)
        .then((res) => {
          //console.log(this.editContent);
          if (res.code == 0) {
            window.open(res.data.url);
          }
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 1000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  watch: {
    readItem(newValue) {
      if (newValue.id) {
        this.readNewItem();
      }
    },
    isNewNote(val) {
      this.$emit("newNoteChange", val);

      let openAutoSave = () => {
        //开启自动保存
        this.autoSaveInterval = setInterval(() => {
          let content = this.editor.getHtml().replace("<p><br></p>", "");
          window.localStorage.setItem("saveNote", content);
          this.showToast("已自动保存笔记草稿", "success", 1000);
        }, 10000);
      };
      if (val) {
        //提取草稿
        if (window.localStorage.getItem("saveNote")) {
          this.$layer.confirm(
            "检测到系统存储有您未完成的笔记草稿，是否要使用？不使用将会清空草稿。",
            (layerid) => {
              this.editor.dangerouslyInsertHtml(
                window.localStorage.getItem("saveNote")
              );
              openAutoSave();
              this.$layer.close(layerid);
            },
            (layerid) => {
              openAutoSave();
              this.$layer.close(layerid);
            }
          );
        } else {
          openAutoSave();
        }
      } else {
        clearInterval(this.autoSaveInterval);
      }
    },
    typeArr(val) {
      if (val) {
        val.forEach((item) => {
          if (item.value != "all") {
            this.typeArrFinal.push(item);
          }
        });
      }
    },
  },
  computed: {
    bindTagIds() {
      if (!Array.isArray(this.bindTag)) {
        this.bindTag = [];
      }
      let ids = [];
      this.bindTag.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
    bindStockIds() {
      if (!Array.isArray(this.bindStock)) {
        this.bindStock = [];
      }
      let ids = [];
      this.bindStock.forEach((item) => {
        ids.push(item.id);
      });
      return ids;
    },
  },
});
</script>
<style>
#top-container {
  border-bottom: 1px solid #e8e8e8;
  padding-left: 50px;
}

#editor-toolbar {
  width: 100%;
  background-color: #fcfcfc;
  margin: 0 auto;
}

#content {
  width: 100%;
  /* background-color: rgb(245, 245, 245); */
  overflow-y: auto;
  position: relative;
}

#editor-container {
  width: calc(100% - 30px);
  margin: 30px auto 0px auto;
  background-color: #fff;
  padding: 20px 50px 50px 50px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
}

#title-container {
  padding: 10px 0px 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}

#title-container input {
  font-size: 30px;
  border: 0;
  outline: none;
  width: 100%;
  line-height: 1;
}

#note-editor-text-area {
  min-height: 100%;
  margin-top: 20px;
}

.w-e-text-container [data-slate-editor] p {
  margin: 5px;
}

.note-bindStock-dropdown {
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.note-toolbar {
  border: 1px solid #ccc;
  position: fixed;
  width: calc(100% - 500px);
  background-color: #fff;
  padding: 10px;
  opacity: 0.9;
  z-index: 20;
}

.note-toolbar1 {
  position: fixed;
  margin-top: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  width: calc(100% - 500px);
  height: 50px;
  background-color: #fff;
  z-index: 20;
  opacity: 0.9;
}
.w-e-text-container a {
  color: #000;
  background-color: #f1f1f1;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}
</style>
