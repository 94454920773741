<template>
  <div>
    <div v-if="loading" style="display: flex; align-items: center">
      加载中...
    </div>
    <div
      :id="'tagAcc' + iframeData.race_name"
      :ref="'tagAcc' + iframeData.race_name"
      style="height: 540px; width: 780px; z-index: 10"
    ></div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import FanChartTagChild from "./FanChartTagChild.vue";
export default {
  name: "FanChart",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,

      myChart: null,
      chartData: [],
      legends: [],
    };
  },
  mounted() {
    let chartDom = this.$refs["tagAcc" + this.iframeData.race_name];
    this.myChart = this.$echarts.init(chartDom, null, {
      locale: "ZH",
    });
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.loading = true;
      ResonanceContrast.getRaceAcc(this.iframeData.race_name)
        .then((res) => {
          //console.log(res.data);
          this.chartData = res.data;
          this.initChart();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initChart() {
      let dateArr = [];
      let navData = [];

      this.chartData.forEach((item) => {
        dateArr.push(item.trade_date);
        navData.push([item.trade_date, item.accumulative_ret - 1]);
      });

      let option = {
        animation: true,
        animationThreshold: 2000,
        animationDuration: 1000,
        animationEasing: "cubicOut",
        animationDelay: 0,
        animationDurationUpdate: 300,
        animationEasingUpdate: "cubicOut",
        animationDelayUpdate: 0,
        aria: {
          enabled: false,
        },
        xAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            data: dateArr,
          },
        ],
        yAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                opacity: 1,
              },
            },
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          triggerOn: "mousemove|click",
          axisPointer: {
            type: "cross",
          },
          showContent: true,
          alwaysShowContent: false,
          showDelay: 0,
          hideDelay: 100,
          enterable: false,
          confine: false,
          appendToBody: false,
          transitionDuration: 0.4,
          textStyle: {
            color: "#000",
          },
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderColor: "#ccc",
          borderWidth: 1,
          padding: 5,
          order: "seriesAsc",
          formatter: function (params) {
            let str = "";
            params.forEach((item) => {
              str += `${item.marker} ${item.seriesName}: ${(
                item.value[1] * 100
              ).toFixed(2)}%<br/>`;
            });
            return str;
          },
        },

        series: [
          {
            name: "累计涨幅",
            type: "line",
            smooth: false,
            symbol: "none",
            data: navData,
            areaStyle: {},
          },
        ],
        grid: [
          {
            show: false,
            zlevel: 0,
            z: 2,
            top: "5%",
            left: "8%",
            right: "2%",
            height: "90%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        ],
        visualMap: {
          show: false,
          hoverLink: false,
          type: "piecewise",
          pieces: [
            { lte: -0.000001, color: "#2AA49D", label: "负收益" },
            { gt: -0.000001, lt: 0.0001, color: "transparent", label: " " },
            { gte: -0.000001, color: "#EF534F", label: "正收益" },
          ],
          seriesIndex: 0,
          outOfRange: {
            color: "#6e6e6e",
          },
        },
      };

      this.myChart.setOption(option);
    },
  },
  watch: {},
};
</script>

<style scoped>
.active-tab {
  font-weight: bolder;
  color: #cf0000;
  font-size: 16px;
}

.un-active-tab {
  color: #7e7e7e;
  font-size: 15px;
}
</style>
