<template>
  <div class="v-application bg-white" style="width: 100%">
    <iframe
      :src="url"
      frameborder="0"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Ai from "@/api/ai.js";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "AiAnalysis",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.submit();
  },
  data() {
    return {
      url: this.iframeData.url,
    };
  },
  methods: {
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.is-shining {
  animation: shining 1s ease-in-out infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
