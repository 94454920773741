<template>
  <div>
    <div style="height: 300px">
      <vxe-table
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="center"
        :loading="tableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: false }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
        :filter-config="{ showIcon: false }"
      >
        <vxe-column fixed="left" width="70" field="code" title="股票代码">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                v-if="row.stock_id"
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-dark"
                >{{ row.code ? row.code : "-" }}</a
              >
              <span v-else>{{ row.code ? row.code : "-" }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column fixed="left" width="80" field="sec_name" title="股票名称">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                v-if="row.stock_id"
                href="javascript:;"
                @click="$stockHelpers.openStockDetail(row.stock_id)"
                class="text-xs font-weight-normal text-dark"
                >{{ row.sec_name ? row.sec_name : "-" }}</a
              >
              <span v-else>{{ row.sec_name ? row.sec_name : "-" }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          min-width="150"
          field="pattern_name"
          :title="'形态 (更新时间：' + updateTime + ')'"
        >
          <template #default="{ row }">
            <div class="d-flex justify-start">
              {{ row.pattern_name }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="80" field="side" title="方向" sortable>
          <template #default="{ row }">
            <div
              class="d-flex justify-start text-red"
              v-if="row.side == '正面'"
            >
              {{ row.side }}
            </div>
            <div
              class="d-flex justify-start"
              v-if="row.side == '负面'"
              style="color: green"
            >
              {{ row.side }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="80" field="win_rate" title="平均胜率" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-start">{{ row.win_rate }}%</div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Quant from "@/api/quant.js";
export default {
  name: "StockRecommend",
  components: {},
  props: {
    pageHeight: 0,
  },
  computed: {
    list() {
      return this.tableData;
    },
  },
  data() {
    return {
      tableLoading: false,
      filterName: "",
      tableData: [],
      updateTime: "",
    };
  },
  mounted() {
    this.getStockPattern();
  },
  methods: {
    getStockPattern() {
      this.tableLoading = true;
      Quant.getStockPattern()
        .then((res) => {
          //console.log(res.data);
          this.updateTime = res.data.date;
          this.tableData = res.data.stocks;
          //修改排序，让有stock_id的排在前面
          this.tableData.sort((a, b) => {
            if (a.stock_id && !b.stock_id) {
              return -1;
            } else if (!a.stock_id && b.stock_id) {
              return 1;
            } else {
              return 0;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
