var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vxe-pulldown',{ref:"colEdit",attrs:{"destroy-on-close":"","transfer":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{staticClass:"mx-1 px-1",attrs:{"size":"medium","content":"字段设置","status":"my-primary"},on:{"click":function($event){return _vm.$refs.colEdit.togglePanel()}}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('div',{staticClass:"roles-dropdown",staticStyle:{"width":"500px","border":"1px solid #ccc"}},[_c('div',{staticStyle:{"padding":"5px","display":"flex","justify-content":"space-between"}},[_c('p',{staticStyle:{"padding-top":"5px","color":"#ff0000"}},[_vm._v("修改后请及时保存")]),_c('div',[_c('vxe-input',{staticStyle:{"width":"100px"},attrs:{"placeholder":"输入新字段"},model:{value:(_vm.newRowInput),callback:function ($$v) {_vm.newRowInput=$$v},expression:"newRowInput"}}),(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-button',{attrs:{"status":"my-primary","size":"small","content":"新建字段"},on:{"click":function($event){return _vm.addRow()}}}):_vm._e()],1),(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-button',{attrs:{"status":"my-danger","size":"small","content":"保存"},on:{"click":function($event){return _vm.saveColDisplay()}}}):_vm._e()],1),_c('vxe-table',{ref:"colEditTable",attrs:{"auto-resize":"","data":_vm.appStore.tagDetail.labels,"checkbox-config":{ range: true, checkField: 'show' },"height":"300px","edit-config":{
          trigger: 'click',
          mode: 'cell',
          icon: ' ',
          activeMethod: _vm.activeCellMethod,
        }}},[_c('vxe-column',{attrs:{"field":"name","title":"字段名","edit-render":{ name: 'input' }},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('vxe-column',{attrs:{"field":"width","width":"60px","title":"列宽","edit-render":{ name: 'input' }},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"integer","step":"1","min":"60"},model:{value:(row.width),callback:function ($$v) {_vm.$set(row, "width", $$v)},expression:"row.width"}})]}}])}),_c('vxe-column',{attrs:{"field":"is_progress","title":"进度条","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('vxe-switch',{attrs:{"open-label":"开","close-label":"关","open-value":1,"close-value":0},model:{value:(row.is_progress),callback:function ($$v) {_vm.$set(row, "is_progress", $$v)},expression:"row.is_progress"}})]}}])}),_c('vxe-column',{attrs:{"type":"checkbox","width":"60px","title":"显隐"}}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"100","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",staticStyle:{"margin-right":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveToUp(row)}}},[_vm._v("上移")]),_c('a',{staticClass:"text-xs font-weight-normal text-typo mr-1",staticStyle:{"margin-right":"5px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.moveToDown(row)}}},[_vm._v("下移")]),(row.type == 3)?_c('a',{staticClass:"text-xs font-weight-normal text-red mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteRow(row)}}},[_vm._v("删除")]):_vm._e()])]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }