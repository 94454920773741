<template>
  <div>
    <vxe-toolbar class="mx-3" custom :refresh="{ query: getPortfolioList }">
      <template v-slot:buttons>
        <span
          class="mr-2"
          v-if="
            $commonHelpers.getPermissions('portfolio.external') &&
            $commonHelpers.getPermissions('portfolio.insiders')
          "
          >{{ isOut ? '服务机构' : '研究员' }}</span
        >
        <span
          class="mr-2"
          v-if="
            !(
              $commonHelpers.getPermissions('portfolio.external') &&
              $commonHelpers.getPermissions('portfolio.insiders')
            )
          "
          >{{ isOut ? '组合' : '组合' }}</span
        >
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="搜索组合名或管理人"
          class="mx-1"
        ></vxe-input>
        <vxe-select
          v-model="benchmarkType"
          size="medium"
          placeholder="业绩基准"
          class="mx-1"
          style="width: 130px"
          status="my-primary"
        >
          <vxe-option
            v-for="(item, index) in benchmarkTypeMap"
            :key="index"
            :value="item"
            :label="'基准:' + item"
          >
          </vxe-option>
        </vxe-select>
        <vxe-button
          v-if="!$commonHelpers.getPermissions(['组合观察员'])"
          @click="portfolioAdd()"
          size="medium"
          content="新建组合"
          class="mx-1"
          status="my-primary"
        ></vxe-button>
        <vxe-button
          @click="exportTable()"
          size="medium"
          content="导出"
          class="ml-0 mr-1"
          status="my-primary"
        >
        </vxe-button>
      </template>
    </vxe-toolbar>

    <div :style="{ height: tableHight + 'px' }">
      <vxe-table
        id="portfolioTable"
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="center"
        ref="portfolioTable"
        :loading="portfolioTableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :scroll-y="{ mode: 'wheel', gt: 100 }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
      >
        <vxe-column
          fixed="left"
          width="200"
          field="name"
          title="组合名称"
          sortable
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <a
                href="javascript:;"
                @click="openPortfolio(row.id)"
                class="text-xs font-weight-normal text-typo"
                :style="{ backgroundColor: row.is_real == 0 ? '#ddd' : '' }"
              >
                {{ row.is_real == 0 ? '(旧版已停用)' : '' }}{{ row.name }}
              </a>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="70" field="user_name" title="服务机构">
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.user_name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="70" field="user_contact" title="联系方式">
          <template #default="{ row }">
            {{ row.user_contact }}
          </template>
        </vxe-column>

        <vxe-column width="80" field="created_at" title="创建时间" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              {{ row.created_at.split(' ')[0] }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          width="90"
          field="position.income_rate"
          title="累计收益率"
          sortable
        >
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.position.income_rate)">{{
                $stockHelpers.getPercent(row.position.income_rate)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="position.day_rate" title="日涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.position.day_rate)">{{
                $stockHelpers.getPercent(row.position.day_rate)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_five" title="5日涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_five)">{{
                $stockHelpers.getPercent(row.applies_five)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_month" title="20日涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_month)">{{
                $stockHelpers.getPercent(row.applies_month)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_60" title="60日涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_60)">{{
                $stockHelpers.getPercent(row.applies_60)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_120" title="120日涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_120)">{{
                $stockHelpers.getPercent(row.applies_120)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column field="applies_the_year" title="今年以来涨幅" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.applies_the_year)">{{
                $stockHelpers.getPercent(row.applies_the_year)
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          field="sharp_ratio"
          title="夏普比率(成立以来)"
          sortable
          width="130"
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.sharp_ratio }}
            </div>
          </template>
        </vxe-column>
        <vxe-column field="max_retreat" title="最大回撤" sortable>
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span :style="$stockHelpers.getColor(row.max_retreat)">{{
                $stockHelpers.getPercent(row.max_retreat)
              }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column
          field="benchmark"
          :title="'业绩基准(' + benchmarkType + ')'"
          width="120"
        >
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span
                :style="$stockHelpers.getColor(row.benchmark[benchmarkType])"
                >{{
                  $stockHelpers.getPercent(row.benchmark[benchmarkType])
                }}</span
              >
            </div>
          </template>
        </vxe-column>
        <vxe-column field="excess_return" title="超额收益" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              <span
                :style="
                  $stockHelpers.getColor(row.excess_return[benchmarkType])
                "
                >{{
                  $stockHelpers.getPercent(row.excess_return[benchmarkType])
                }}</span
              >
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column field="position.position_amt" title="持仓市值" sortable>
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<span v-if="row.position">{{ parseFloat(row.position.position_amt).toFixed(0) }}</span>
					</template>
				</vxe-column> -->
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from '@/components/Bus/bus.js';
import Portfolio from '@/api/portfolio.js';
import PortfolioAdd from '@/components/Portfolio/PortfolioAdd';
import XEUtils from 'xe-utils';
export default {
  name: 'PortfolioList',
  components: {},
  props: {
    tableHight: '',
    isOut: false,
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, 'gi');
        const searchProps = ['name', 'user_name'];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  data() {
    return {
      portfolioTableLoading: false,
      filterName: '',
      tableData: [],
      benchmarkTypeMap: [],
      benchmarkType: '沪深300',
    };
  },
  mounted() {
    this.getPortfolioList();
    Bus.$on('refreshPortfolioList', (e) => {
      this.getPortfolioList();
    });
  },
  methods: {
    getPortfolioList() {
      this.portfolioTableLoading = true;
      Portfolio.getPortfolioList(this.isOut ? 2 : 1)
        .then((res) => {
          //console.log(res.data);
          this.tableData = res.data;

          this.benchmarkTypeMap = [];
          if (res.data.length > 0) {
            for (let i in res.data[0].benchmark) {
              this.benchmarkTypeMap.push(i);
            }
          }
          // console.log(this.benchmarkTypeMap);
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.portfolioTableLoading = false;
        });
    },
    openPortfolio(id) {
      this.$router.push({ name: '组合详情', params: { id: id } });
    },
    portfolioAdd() {
      this.$layer.iframe({
        content: {
          content: PortfolioAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ['600px', '200px'],
        title: '新建组合(带*为必填)',
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    exportTable() {
      this.$refs.portfolioTable.openExport({});
    },
  },
};
</script>
