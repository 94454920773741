<template>
	<div class="v-application bg-white" style="width: 100%;display: flex;justify-content: center;">
		<div style="min-height: 100%!important;min-width: 500px;width: 80%;">
			
			<div class="d-flex align-center justify-center">
				<label class="mr-3 white-space-nowrap" style="width: 88px;font-size: 14px;">*组合名</label>
				<vxe-input v-model="submitInfo.name" placeholder="*组合名" type="text" class="my-2" style="width: 100%;"></vxe-input>
			</div>

			<div class="my-4">
				<vxe-button status="primary" @click="submit">提交</vxe-button>
				<vxe-button status="warning" @click="cancel">取消</vxe-button>
			</div>
		</div>
	</div>
</template>
<script>
import Portfolio from '@/api/portfolio.js';
import Bus from '@/components/Bus/bus.js';


export default {
	name: 'PortfolioAdd',
	components: {
		
	},
	props: {
		layerid: {
			//自动注入的layerid
			type: String,
			default: ''
		},
		iframeData: {
			//传递的数据
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	mounted() {
		this.submitInfo.name=this.iframeData.name;
	},
	beforeDestroy() {
		
	},
	data() {
		return {
			
			submitInfo: {
				name: '',
			},

			
		};
	},
	methods: {
		
		submit() {
			//console.log(this.editor.getHtml());;return;
			if (!this.submitInfo.name) {
				this.showToast('请先填写组合名', 'warning', 5000);
				return;
			}
			this.showLoading('保存中，请稍候');
			Portfolio.editPortfolio(this.iframeData.id,this.submitInfo).then(res => {
				this.showToast('保存成功', 'success', 5000);
				Bus.$emit('refreshPortfolioList', true);
				Bus.$emit('refreshPortfolioDetail', true);
				
				this.$layer.close(this.layerid);
			}).catch(err => {
				console.log(err.msg);
				this.showToast(err.msg, 'danger', 10000);
			}).finally(() => {
				this.hideLoading();
			});
				
			
		},
		cancel() {
			this.$layer.close(this.layerid);
		}
	},
	computed: {},
	watch: {
		
	}
};
</script>
<style scoped="scoped"></style>
