import { isNumber } from "xe-utils";
import Bus from "@/components/Bus/bus.js";

//公共筛选方法，用于直接筛选文本字段
const filterInputMethod = function (rowData, colName) {
  //console.log(rowData);
  //console.log(rowData.row[colName]);
  if (Array.isArray(rowData.row[colName])) {
    if (rowData.option.data) {
      let haveKeyword = false;
      rowData.row[colName].forEach((item) => {
        //console.log(typeof(item));
        if (typeof item == "string") {
          if (item == rowData.option.data) {
            haveKeyword = true;
          }
        } else {
          if (item.name == rowData.option.data) {
            haveKeyword = true;
          }
        }
      });
      return haveKeyword;
    } else {
      return true;
    }
  } else if (isNumber(rowData.row[colName])) {
    console.log(rowData.row[colName], rowData.option.data);
    return rowData.row[colName] == rowData.option.data;
  } else {
    if (rowData.row[colName]) {
      if (rowData.row[colName].hasOwnProperty("name")) {
        return rowData.row[colName].name.indexOf(rowData.option.data) != -1;
      } else {
        return rowData.row[colName].indexOf(rowData.option.data) != -1;
      }
    } else {
      return false;
    }
  }
};
//公共筛选控制器，用于直接筛选文本字段
const filterInput = function (table, colName, data) {
  const column = table.getColumnByField(colName);
  const option = column.filters[0];
  option.data = data;
  option.checked = true;
  table.updateData();
  Bus.$emit("updateFilter", {
    table: table,
    colName: colName,
    data: option.data,
  });
};
//公共分页函数
const getPage = function (pager, tableData, table) {
  let currentPage = pager.currentPage;
  let pageSize = pager.pageSize;
  let newData = tableData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  table[0].reloadData(newData);

  //table.loadData();
};

//公共分页函数,用于分页带排序
const getPageWithSort = function (pager, tableData, table, sortInfo) {
  //console.log(sortInfo);
  let currentPage = pager.currentPage;
  let pageSize = pager.pageSize;

  //处理排序
  let afterSortData = JSON.parse(JSON.stringify(tableData));
  if (sortInfo) {
    let sortField = sortInfo.field;
    let sortType = sortInfo.order;

    afterSortData = afterSortData.sort((a, b) => {
      if (sortType == "desc") {
        return b[sortField] - a[sortField];
      } else {
        return a[sortField] - b[sortField];
      }
    });
  }

  let newData = afterSortData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  //table[0].reloadData(newData);
  return newData;
  //table.loadData();
};

export default {
  filterInputMethod,
  filterInput,
  getPage,
  getPageWithSort,
};
