<template>
	<TableFilter :inputWidth="inputWidth" :colName="colName" :autoSave="false" :selectData="selectData" :table="table" :placeholder="placeholder"></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter'
import Stock from '@/api/stock.js';
export default {
	name: '',
	props: {
		placeholder: '',
		table:'',
		colName:'',
		inputWidth:'',
	},
	data() {
		return {
			selectData: [],
			inputValue: '',
		};
	},
	components: {
		TableFilter,
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			Stock.getIndustryList().then(res => {
				let arr = [];
				res.data.forEach((item, index) => {
					arr.push({ label: `${item.name}`, value: item.name });
				});
				this.selectList = JSON.parse(JSON.stringify(arr)) ;
				this.selectData = JSON.parse(JSON.stringify(arr)) ;
			}).catch(err => {
				console.log(err.msg);
			});
		}
	},
	watch: {
		
	}
};
</script>
<style scoped>
	
</style>
