<template>
  <div style="display: flex; width: 100%; height: 100%">
    <div style="width: 50%; height: 100%">
      <RateAction :stockId="this.iframeData.id"></RateAction>
    </div>
    <div
      style="
        width: 50%;
        height: 100%;
        padding: 0px 10px 10px 10px;
        overflow-y: scroll;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 100;
          background-color: #fff;
          width: calc(50% - 20px);
          height: 50px;
          align-items: center;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>
          <span> 评分历史 </span>
          <vxe-button
            @click="cancelFilter()"
            size="small"
            content="取消筛选因子"
            status="my-warning"
            v-if="showFactor != '全部'"
          ></vxe-button>
        </div>
        <span style="padding-right: 10px">
          当前因子:
          <span style="color: #cb0000; font-weight: 800; font-size: 14px">
            {{ showFactor }}
          </span>
        </span>
      </div>
      <div v-if="stockRateHistoryTableLoading" class="history-loading">
        <img
          src="@/assets/loading.gif"
          width="200px"
          height="200px"
          style="mix-blend-mode: multiply; opacity: 0.3"
        />
        <div style="opacity: 0.3">数据加载中...</div>
      </div>
      <div style="margin-top: 55px">
        <v-card
          class="border-radius-md"
          v-for="item in list"
          :key="item.id"
          style="padding: 10px; border: 1px solid #ccc; margin-top: 10px"
        >
          <div class="border-bottom d-flex align-center px-4 py-1">
            <div style="display: flex; align-items: center">
              <v-avatar size="37" rounded
                ><v-img
                  src="@/assets/img/user/stock.png"
                  alt="profile-image"
                ></v-img
              ></v-avatar>

              <div class="" style="padding: 10px">
                <div>
                  {{ item.factor[0].name }}
                  <span
                    class="mr-1 pa-1"
                    :class="[getScoreColor(item)]"
                    style="text-align: center"
                  >
                    <span>{{ item.last_score ? item.last_score : 0 }}</span>
                    →
                    <span>{{ item.score }}</span>
                  </span>
                </div>

                <small class="d-block text-muted" style="letter-spacing: 2px">
                  <span>{{ item.update_name }}</span>
                  {{ item.created_at }}
                </small>
              </div>

              <div style="position: absolute; right: 15px; top: 15px">
                <vxe-button
                  @click="deleteItem(item.id)"
                  size="small"
                  content="删除"
                  status="my-danger"
                  v-if="
                    item.update_user == userStore.user_id &&
                    today == item.created_at.split(' ')[0]
                  "
                ></vxe-button>
              </div>
            </div>
            <div class="text-end ms-auto">
              <!-- <vxe-button @click="$stockHelpers.openRate(item.stock_id, item.name, this);" size="mini" content="打分详情"></vxe-button> -->
            </div>
          </div>
          <div class="px-5 py-2">
            <div class="pt-1" style="font-size: 14px">
              <!-- <b class="ml-1 text-dark" style="font-size: 15px;">评分理由:</b> -->
              <span
                style="white-space: pre-line"
                v-html="item.reason ? item.reason : '无评分理由'"
              ></span>

              <!-- 附件显示 -->
              <Photoswipe
                :options="{
                  allowPanToNext: true,
                  spacing: 0.12,
                  mainClass: 'pswp--custom-zindex',
                  bgOpacity: 0.8,
                  mouseUsed: false,
                  pinchToClose: true,
                  closeOnScroll: false,
                  closeOnVerticalDrag: false,
                  showHideOpacity: true,
                  focus: true,
                  escKey: true,
                  arrowKeys: true,
                }"
              >
                <div>
                  <img
                    v-for="(item, index) in item.attachments"
                    style="margin-right: 20px; margin-top: 10px"
                    :src="item.url"
                    v-pswp="item.url"
                  />
                </div>
              </Photoswipe>

              <hr class="horizontal dark" style="margin: 10px 0px 10px 0px" />

              <div>
                <span v-if="item.comments.length == 0">暂无留言</span>
                <div v-for="comment in item.comments" :key="comment.id">
                  <span style="font-weight: 800">{{ comment.user_name }}:</span
                  >{{ comment.content }}
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 5px;
                  "
                >
                  <vxe-input
                    style="z-index: 10; width: 90%"
                    placeholder="请输入说明"
                    v-model="item.comment"
                  ></vxe-input>
                  <vxe-button
                    style="z-index: 10; width: 10%"
                    @click="saveComment(item)"
                    size="small"
                    content="留言"
                  ></vxe-button>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/Bus/bus.js';
import XEUtils from 'xe-utils';
import Stock from '@/api/stock.js';
import Rate from '@/api/rate.js';
import RateAction from '@/components/Rate/RateAction';
import moment from 'moment';
export default {
  props: {
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    RateAction,
  },
  data() {
    return {
      stockRateHistoryTableLoading: false,
      historyList: [],
      historyFactorId: '',

      showFactor: '全部',
      today: moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    this.initHistory();
    Bus.$on('refreshRateHistory', (e) => {
      this.initHistory();
    });
    Bus.$on('selectFactor', (e) => {
      this.historyFactorId = e.stock_factor_id;
      this.showFactor = e.name;
    });
  },
  computed: {
    list() {
      if (this.historyFactorId) {
        let res = [];
        this.historyList.forEach((item) => {
          if (item.stock_factor_id == this.historyFactorId) {
            res.push(item);
          }
        });
        return res;
      } else {
        return this.historyList;
      }
    },
  },
  methods: {
    initHistory() {
      this.stockRateHistoryTableLoading = true;
      Rate.getRateDetail(this.iframeData.id)
        .then((res) => {
          //console.log(res.data.rate);
          if (res.data.rate.length > 0) {
            //let arr = res.data.rate.splice(0, 50);
            let arr = res.data.rate;
            arr.forEach((item) => {
              item.comment = '';
            });
            this.historyList = arr;
          }
          if (this.iframeData.factorInfo) {
            console.log(this.iframeData.factorInfo);
            this.historyFactorId = this.iframeData.factorInfo.id;
            this.showFactor = this.iframeData.factorInfo.name;
          }
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.stockRateHistoryTableLoading = false;
        });
    },
    getScoreColor(item) {
      if (item.change_score) {
        return parseInt(item.change_score) > 0
          ? 'text-red lighten-1 font-weight-bolder'
          : 'text-teal lighten-1 font-weight-bolder';
      } else {
        return 'text-red lighten-1 font-weight-bolder';
      }
    },
    saveComment(item) {
      this.showLoading('保存中，请稍候');
      Rate.commentSave(item.id, item.comment)
        .then((res) => {
          //Bus.$emit("refreshStockFactor", true);
          //this.initHistory();
          this.historyList.forEach((oldItem, oldIndex) => {
            if (oldItem.id == item.id) {
              this.historyList[oldIndex].comments.push({
                user_name: this.userStore.name,
                content: item.comment,
              });
              this.historyList[oldIndex].comment = '';
            }
          });
          this.showToast('留言成功', 'success', 5000);
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancelFilter() {
      this.showFactor = '全部';
      this.historyFactorId = '';
    },
    deleteItem(item) {
      console.log(item);
      this.$layer.confirm('确定要删除该评分记录吗？', (layerid) => {
        this.showLoading('删除中，请稍候');
        Rate.deleteRate(item)
          .then((res) => {
            Bus.$emit('refreshRateHistory', true);
            Bus.$emit('refreshStockFactor', true);
            this.initHistory();
            this.showToast('删除成功', 'success', 5000);
          })
          .catch((err) => {
            this.showToast(err.msg, 'danger', 5000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
  },
};
</script>
<style scoped="scoped">
.history-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
