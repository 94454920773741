<template>
	<TableFilter @change="change" :returnId="returnId" :inputWidth="inputWidth" :colName="colName" :autoSave="autoSave" :selectData="selectData" :table="table" :placeholder="placeholder"></TableFilter>
</template>
<script>
import TableFilter from '@/components/TableFilter'
import User from '@/api/user.js';
export default {
	name: '',
	props: {
		placeholder: '',
		table:'',
		colName:'',
		inputWidth:'',
		autoSave:true,
		returnId:false,
	},
	data() {
		return {
			selectData: [],
			inputValue: '',
		};
	},
	components: {
		TableFilter,
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			User.getUserList(1).then(res => {
				let arr = [];
				res.data.forEach((item, index) => {
					arr.push({ label: `${item.base}`, value: item.base ,id:item.id});
				});
				this.selectData = JSON.parse(JSON.stringify(arr)) ;
			}).catch(err => {
				console.log(err.msg);
			}).finally(() => {});
		},
		change(val){
			this.$emit('change',val);
		},
	},
	watch: {
		
	}
};
</script>
<style scoped>
	
</style>
