<template>
  <div class="quant-container">
    <!-- <v-card
      class="mt-2"
      :elevation="4"
      style="width: 500px"
      :style="{ height: pageHeight - 80 + 'px' }"
    >
      <div>
        <h3>标签行情</h3>
        <img src="/tag.png" style="width: 100%" />
      </div>
    </v-card> -->
    <v-card
      class="mt-2 ml-2"
      :elevation="4"
      style="width: calc(100vw); border: 1px solid #ccc"
      :style="{ height: pageHeight - 80 + 'px' }"
    >
      <div style="width: 100%; display: flex">
        <QuantTrade style="width: 25%" title="精选标签监控"></QuantTrade>
        <QuantTrade style="width: 25%" title="核心标签监控"></QuantTrade>
        <QuantTrade style="width: 25%" title="所有标签监控"></QuantTrade>
        <QuantTrade style="width: 25%" title="全市场监控"></QuantTrade>
      </div>
    </v-card>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Settings from "@/components/Quant/Settings";
import QuantTrade from "@/components/Quant/QuantTrade";

export default {
  name: "Quant",
  components: { Settings, QuantTrade },
  data() {
    return {
      pageHeight: 500,
      pageWidth: 800,
    };
  },
  mounted() {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
  },

  methods: {},
};
</script>
<style>
.quant-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
