<template>
  <div style="height: 100%">
    <div
      style="
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div style="padding-left: 10px">
        因子打分
        <span
          v-if="haveChanged"
          style="
            color: #ff0000;
            opacity: 0.7;
            font-size: 14px;
            font-weight: 800;
          "
          >已修改数据请及时保存</span
        >
        <span
          v-if="!haveChanged"
          style="color: #344767; font-size: 14px; font-weight: 800"
          >(点击因子名筛选评分历史,点击当前分修改分数)</span
        >
      </div>
      <div style="padding-right: 10px">
        <vxe-button
          @click="save()"
          size="small"
          content="保存 (Ctrl+S)"
          status="my-danger"
        ></vxe-button>
      </div>
    </div>
    <div style="height: calc(100% - 50px)">
      <vxe-table
        :data="rateFactorList"
        style="width: 100%"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        ref="rateFactorList"
        :loading="rateFactorListLoading"
        height="100%"
        size="small"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :empty-render="{ name: 'NotData' }"
        align="center"
        :tree-config="{ expandAll: true, reserve: false }"
        :edit-config="{
          trigger: 'click',
          mode: 'row',
          activeMethod: activeCellMethod,
          showUpdateStatus: true,
          showStatus: true,
          icon: ' ',
        }"
        keep-source
      >
        <vxe-column width="70" field="groupName" title="分组" tree-node>
          <template #default="{ row }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ row.groupName }}
            </div>
          </template>
        </vxe-column>

        <vxe-column width="120" field="name" title="因子名称">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div
              style="cursor: pointer"
              class="d-flex justify-start"
              @click="factorNameClick(row)"
            >
              <div
                style="
                  word-break: break-all;
                  white-space: pre-line;
                  text-align: start;
                "
              >
                {{ row.name }}
              </div>
            </div>
          </template>
        </vxe-column>

        <!-- <vxe-column width="70" field="rate_date" title="日期" :edit-render="{}">
					<template #header="{ column }">
						<div style="word-wrap: break-word;white-space: pre-wrap;">{{ column.title }}</div>
					</template>
					<template #default="{ row }">
						<span v-if="row.rate_date!=''" >{{row.rate_date}}</span>
						<span v-else="row.groupName==''" style="color: #cccccc;">点选日期</span>
					</template>
					<template #edit="{ row }">
						<vxe-input :disabled="dateDisabled" v-model="row.rate_date" type="date" placeholder="日期" @change="haveChanged=true"></vxe-input>
					</template>
				</vxe-column> -->

        <vxe-column width="50" field="rate.last_score" title="上期分">
          <template #default="{ row }">
            <span v-if="row.last_score != ''">{{ row.last_score }}</span>
          </template>
        </vxe-column>

        <vxe-column
          width="70"
          field="rate.score"
          title="当前分"
          :edit-render="{ name: 'input' }"
        >
          <template #default="{ row }">
            <span v-if="row.score != ''" :style="getScoreColor(row, true)">{{
              row.score
            }}</span>
            <span v-else="row.groupName == ''" style="color: #cccccc"
              >点击打分</span
            >
          </template>
          <template #edit="{ row }">
            <vxe-input
              :max="100"
              :min="0"
              v-model="row.score"
              type="number"
              placeholder="请打分"
              @change="haveChanged = true"
            ></vxe-input>
          </template>
        </vxe-column>

        <vxe-column
          min-width="60"
          field="comment"
          title="得分说明"
          :edit-render="{ name: 'textarea' }"
        >
          <template #default="{ row }">
            <div
              v-if="row.reason != ''"
              style="display: flex; justify-content: flex-start"
            >
              <span>{{ row.reason }}</span>
            </div>
            <span v-else="row.reason == ''" style="color: #cccccc"
              >点击填写评分说明</span
            >
          </template>
          <template #edit="{ row }">
            <vxe-textarea
              style="z-index: 10"
              placeholder="请输入说明"
              v-model="row.reason"
              @change="reasonChange(row)"
              :autosize="{ minRows: 1, maxRows: 5 }"
              @focus="reasonFocus(row)"
              @blur="(value, event) => reasonBlur(value, row)"
            >
            </vxe-textarea>
          </template>
        </vxe-column>

        <vxe-column width="60" field="attachments" title="附件">
          <template #default="{ row }">
            <vxe-pulldown
              :ref="'attachmentsPanel' + row.id"
              destroy-on-close
              class="ml-4"
              transfer
              v-show="!row.groupName"
            >
              <template #default>
                <!-- <vxe-button @click="$refs.attachmentsPanel.togglePanel();" status="my-primary" size="mini">附件</vxe-button> -->
                <a
                  href="javascript:;"
                  @click="$refs['attachmentsPanel' + row.id].togglePanel()"
                  class="text-xs font-weight-normal"
                  :style="{ color: getAttachmentsColor(row.attachments) }"
                  >附件({{ row.attachments ? row.attachments.length : "" }})</a
                >
              </template>
              <template #dropdown>
                <div class="note-attachments-dropdown">
                  <div
                    style="
                      height: 40px;
                      padding: 5px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <vxe-button
                      @click="uploadAttachments(row.id)"
                      status="my-primary"
                      >上传
                    </vxe-button>
                    <p
                      style="margin-left: 5px; font-weight: 800; color: #a4262c"
                    >
                      上传和删除文件后请及时保存当前打分
                    </p>
                  </div>
                  <vxe-table auto-resize :data="row.attachments" height="300px">
                    <vxe-column field="name" title="文件名">
                      <template #default="{ row }">
                        <Photoswipe
                          :options="{
                            allowPanToNext: true,
                            spacing: 0.12,
                            mainClass: 'pswp--custom-zindex',
                            bgOpacity: 0.8,
                            mouseUsed: true,
                            loop: true,
                            pinchToClose: true,
                            closeOnScroll: false,
                            closeOnVerticalDrag: false,
                            showHideOpacity: true,
                            focus: true,
                            escKey: true,
                            arrowKeys: true,
                          }"
                        >
                          <img class="me-2" :src="row.url" v-pswp="row.url" />
                        </Photoswipe>
                      </template>
                    </vxe-column>
                    <vxe-column field="size" title="大小" width="80px">
                      <template #default="{ row }">
                        {{ (row.size / 1024).toFixed(2) + "KB" }}
                      </template>
                    </vxe-column>
                    <vxe-column title="操作" width="100px">
                      <template #default="{ row: row1 }">
                        <!-- <a href="javascript:;" @click="previewAttachment(row.name,row.url)" class="text-xs font-weight-normal text-typo" style="color: #344767;margin-right: 5px;">预览</a> -->
                        <a
                          href="javascript:;"
                          @click="downloadAttachment(row1.url)"
                          class="text-xs font-weight-normal text-typo"
                          style="color: #344767; margin-right: 5px"
                          >下载</a
                        >
                        <a
                          href="javascript:;"
                          @click="deleteAttachment(row.id, row1.id)"
                          class="text-xs font-weight-normal text-typo"
                          style="color: #d20000; margin-right: 5px"
                          >删除</a
                        >
                      </template>
                    </vxe-column>
                  </vxe-table>
                </div>
              </template>
            </vxe-pulldown>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <div style="display: none">
      <v-file-input
        :id="'attachmentSelect' + this.stockId"
        v-model="files"
        accept="image/png,image/jpeg"
        prepend-icon=""
      >
      </v-file-input>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Rate from "@/api/rate.js";
import Stock from "@/api/stock.js";
import XEUtils from "xe-utils";

const OSS = require("ali-oss");
import File from "@/api/file.js";
export default {
  name: "RateAction",
  props: {
    stockId: "",
  },
  components: {},
  data() {
    return {
      isSaving: false,
      haveChanged: false,
      dateDisabled: false,
      rateFactorListLoading: false,
      rateFactorList: [],

      score: "",
      oldReason: "",
      newReason: "",

      files: [],
      attachmentCurrFactorId: "",
    };
  },
  mounted() {
    this.getStockFactor();
    Bus.$on("refreshStockFactor", (e) => {
      this.getStockFactor();
    });
    //保存功能
    onkeydown = (e) => {
      if (e.ctrlKey && e.keyCode == "S".charCodeAt(0)) {
        e.preventDefault();
        if (!this.isSaving) {
          this.save();
        }
      }
    };
  },
  beforeDestroy() {
    Bus.$off("refreshStockFactor");
  },
  methods: {
    reasonFocus(row) {
      //console.log(row.parent_category_name);
      this.oldReason = JSON.stringify({ reason: row.reason });
      // if(row.parent_category_name!="基本面"){
      // 	row.reason='';
      // }
    },
    reasonBlur(value, row) {
      let oldReason = JSON.parse(this.oldReason);
      if (value.value == "" && row.parent_category_name != "基本面") {
        row.reason = oldReason.reason;
      }
    },
    getScoreColor(row, isChange = false) {
      let color = "#000000";
      if (row.score < row.last_score) {
        color = "rgb(0, 170, 0)";
      } else if (row.score > row.last_score) {
        color = "rgb(225, 61, 49)";
      }
      //console.log(row.score,row.last_score);
      if (isChange) {
        return { color: color };
      } else {
        return { backgroundColor: color };
      }
    },
    getStockFactor() {
      this.rateFactorListLoading = true;
      Rate.getStockFactor(this.stockId)
        .then((res) => {
          //过滤掉没启用的
          let afterFilter = [];
          res.data.forEach((i) => {
            if (i.has == 1) {
              afterFilter.push(i);
            }
          });
          //console.log(afterFilter);
          let result = [];
          XEUtils.each(
            XEUtils.groupBy(afterFilter, "parent_category_name"),
            (childs, key) => {
              if (key != "null") {
                result.push({
                  groupName: key,
                  children: childs,
                });
              } else {
                result.push({
                  groupName: "未分组",
                  children: childs,
                });
              }
            }
          );
          //console.log(result);

          let finalResult = [];
          result.forEach((item) => {
            if (item.groupName == "基本面") {
              finalResult[0] = item;
            } else if (item.groupName == "催化剂") {
              finalResult[1] = item;
            } else if (item.groupName == "量价") {
              finalResult[3] = item;
            }
          });
          // if(result.length==1){
          // 	finalResult.push(result[0]);
          // }else if(result.length==2){

          // }

          this.rateFactorList = finalResult;

          setTimeout(() => {
            this.$refs.rateFactorList.setAllTreeExpand(true);
          }, 100);
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.rateFactorListLoading = false;
        });
    },
    activeCellMethod({ row, column }) {
      if (!row.groupName) {
        if (column.field == "rate_date") {
          this.dateDisabled = row.parent_category_name == "量价" ? false : true;
        }
        return true;
      } else {
        return false;
      }
    },
    save() {
      this.isSaving = true;
      let submitInfo = [];
      this.rateFactorList.forEach((i1) => {
        if (i1.children) {
          i1.children.forEach((i2) => {
            //i2.attachments=JSON.stringify(i2.attachments);
            submitInfo.push(i2);
          });
        }
      });
      //console.log(submitInfo);
      this.showLoading("保存中，请稍候");
      Rate.rateSave(submitInfo)
        .then((res) => {
          this.showToast("保存成功", "success", 5000);
          this.haveChanged = false;
          Bus.$emit("refreshRateHistory", true);
          this.getStockFactor();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
          //this.loginError();
        })
        .finally(() => {
          this.hideLoading();
          this.isSaving = false;
        });
    },
    factorNameClick(row) {
      //console.log(row.name);
      Bus.$emit("selectFactor", row);
    },
    uploadAttachments(id) {
      //console.log(this.rateFactorList);return;
      this.attachmentCurrFactorId = id;
      document.getElementById("attachmentSelect" + this.stockId).click();
    },
    uploadWorker(fileItem) {
      this.showLoading("上传中，请稍候");
      File.getUploadSts()
        .then((res) => {
          let info = res.data;
          let client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: "qinglitouyan",
          });

          let fileName = "fileStore/" + info.uuid + "/" + fileItem.name;

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf("uploadId") != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                "/fileStore/" + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  this.showToast("上传成功！请及时保存打分！", "success", 5000);
                  this.haveChanged = true;
                  let url = `${File.getApiUrl()}/api/file/${
                    info.uuid
                  }/downloadDirect`;
                  this.rateFactorList.forEach((father) => {
                    if (father.children) {
                      father.children.forEach((child) => {
                        if (child.id == this.attachmentCurrFactorId) {
                          //console.log(child);
                          child.attachments.push({
                            id: info.uuid,
                            name: fileItem.name,
                            size: fileItem.size,
                            url: url,
                          });
                        }
                      });
                    }
                  });
                  //处理附件json
                })
                .catch((err) => {
                  console.log("err:", err);
                })
                .finally(() => {
                  this.hideLoading();
                });
            })
            .catch((err) => {
              this.showToast("上传失败！", "danger", 5000);
              console.log("err:", err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.hideLoading();
          this.showToast("上传失败，获取上传前置信息失败！", "danger", 5000);
        });
    },
    //删除附件
    deleteAttachment(factorId, attachmentId) {
      console.log(this.rateFactorList);
      this.rateFactorList.forEach((i1) => {
        if (i1.children) {
          i1.children.forEach((i2) => {
            if (i2.id == factorId) {
              //找到了对应的评分因子
              i2.attachments.forEach((i3, index) => {
                if (i3.id == attachmentId) {
                  i2.attachments.splice(index, 1);
                }
              });
            }
          });
        }
      });
    },

    //下载附件
    downloadAttachment(url) {
      window.location = url;
    },

    //获取附件字段颜色
    getAttachmentsColor(attachments) {
      let color = "#344767";
      if (attachments) {
        if (attachments.length > 0) {
          color = "#a4262c";
        }
      }

      return color;
    },
    //原因变化，询问是否清空该因子的附件
    reasonChange(row) {
      this.haveChanged = true;
      if (!row.haveConfirmAttachment && row.attachments.length > 0) {
        this.$layer.confirm(
          "该因子评分理由已变化，需要删除该因子的附件吗？",
          (layerid) => {
            this.showToast("删除成功", "success", 2000);
            row.attachments = [];
            this.$layer.close(layerid);
          }
        );
        row.haveConfirmAttachment = true;
      }
    },
  },
  watch: {
    files(value) {
      if (value) {
        this.uploadWorker(value);
      }
      this.uploadFolder = "webkitdirectory";
    },
  },
};
</script>
<style>
.note-attachments-dropdown {
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
}

.pswp--custom-zindex {
  z-index: 999999999;
}
</style>
