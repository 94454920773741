<template>
	<div class="v-application bg-white" style="width: 100%;display: block;">
		<div style="width: 100%;height: 50px;" class="pt-2 px-2 d-flex justify-space-between">
			<vxe-input v-model="filterName" type="search" placeholder="搜索股票" class="mr-1"></vxe-input>
			<vxe-button @click="submit()" size="small" content="关联已选股票" class="" status="my-danger"></vxe-button>
		</div>
		<div style="width: 100%;height: calc( 100% - 50px );">
			<vxe-table
				style="width: 100%;"
				:data="list"
				class="my-scrollbar"
				align="center"
				:border="false"
				show-overflow
				show-header-overflow
				highlight-hover-row
				ref="stockTable"
				:loading="stockTableLoading"
				height="100%"
				size="small"
				:stripe="true"
				:sort-config="{ trigger: 'cell', multiple: true }"
				:scroll-x="{ gt: 30 }"
				:scroll-y="{ mode: 'wheel', gt: 100 }"
				:tooltip-config="{ showAll: false, enterable: true, theme: 'dark' }"
				:checkbox-config="{ range:true,trigger: 'row' }"
			>
				<vxe-column field="multi" type="checkbox" title="选择股票" width="80">
					
				</vxe-column>
				
				<vxe-column field="code" min-width="70" title="代码">
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<span>{{row.code}}</span>
						</div>
					</template>
				</vxe-column>
				
				<vxe-column field="name" min-width="80" title="股票名">
					<template #default="{ row }">
						<div class="d-flex justify-start">
							<span>{{row.name}}</span>
						</div>
					</template>
				</vxe-column>
				
			</vxe-table>
		</div>
	</div>
	
</template>
<script>
import moment from 'moment';
import Bus from "@/components/Bus/bus.js";
import Stock from '@/api/stock.js';
import Rate from '@/api/rate.js';
import XEUtils from 'xe-utils';
export default {
	name: 'StockAdd',
	components: {
	},
	props: {
		layerid: {//自动注入的layerid
			type: String,
			default: ''
		},
		iframeData: {//传递的数据
			type: Object,
			default: () => {
				return {};
			}
		},
	},
	mounted() {
		//console.log(this.iframeData);
		this.getStockList();
	},
	data() {
		return {
			stockTableLoading:false,
			tableData:[],
			filterName:'',
		};
	},
	computed: {
		list() {
			const filterName = XEUtils.toValueString(this.filterName)
				.trim()
				.toLowerCase();
			if (filterName) {
				const filterRE = new RegExp(filterName, 'gi');
				const searchProps = [
					'name',
					'code',
					'pinyin',
				];
				const rest = this.tableData.filter(item =>
					searchProps.some(
						key =>
							XEUtils.toValueString(item[key])
								.toLowerCase()
								.indexOf(filterName) > -1
					)
				);
				return rest;
			}
			return this.tableData;
		}
	},
	methods: {
		submit(){
			let selectRecords = this.$refs.stockTable.getCheckboxRecords();
			if (selectRecords.length < 1) {
				this.showToast('请先选择项目', 'warning', 5000);
				return;
			}
			let stockIds = [];
			selectRecords.forEach(i => {
				stockIds.push(i.id);
			});
			//console.log(this.submitInfo);
			this.showLoading('保存中，请稍候');
			Rate.bindStockToUser(this.iframeData.currUser.id,stockIds).then(res => {
				this.showToast('保存成功','success',5000);
				Bus.$emit("refreshUserBindStock", true);
				
				this.$layer.close(this.layerid);
			}).catch(err => {
				console.log(err.msg);
				this.showToast(err.msg,'danger',10000);
			}).finally(() => {
				this.hideLoading();
			});
		},
		getStockList(){
			this.stockTableLoading=true;
			//console.log(this.iframeData.bindStockList);
			Stock.searchStockList('',0,1).then(res => {
			    let arr = [];
			    res.data.forEach((item, index) => {
						let haveBinded=false;
						this.iframeData.bindStockList.forEach((oldItem)=>{
							if(item.id==oldItem.id){
								haveBinded=true;
							}
						})
						if(!haveBinded){
							arr.push(item);
						}
			    });
					this.tableData=arr;
			  })
			  .catch(err => {
			    console.log(err);
			  }).finally(() => {
			    this.stockTableLoading=false;
			  });
		},
		cancel () {
			this.$layer.close(this.layerid);
		},
	},
	watch: {
		
	},
};
</script>
<style scoped="scoped">
	
</style>
