//骊阅评分
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.name }}
        </span>
        <b> | </b>
        <span class="font-weight-bold text-dark" style="opacity: 0.5"
          >骊阅评分
        </span>
      </div>
    </v-row>

    <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      <v-row no-gutters v-for="item in str.data.rates" class="my-1">
        <div v-for="data in item" class="my-1">
          <div>
            <b class="mr-2 text-dark" style="font-size: 15px"
              >{{ data.factor[0].name }}:</b
            >
            <span
              class="mr-1 pa-1"
              :class="[getScoreColor(data)]"
              style="text-align: center"
            >
              <span>{{ data.last_score ? data.last_score : 0 }}</span>
              →
              <span>{{ data.score }}</span>
            </span>
            | 更新人：{{ data.update_name }} | 更新时间：{{ data.updated_at }}
          </div>

          <div><span>原因：</span><span v-html="data.reason"></span></div>

          <div
            style="display: flex; width: 100%; flex-wrap: wrap"
            v-if="data.attachments.length > 0"
          >
            <div
              v-for="(attachmentItem, index) in data.attachments"
              style="
                margin-right: 20px;
                margin-top: 10px;
                width: 100px;
                height: 100px;
              "
            >
              <v-img
                :src="attachmentItem.url"
                :lazy-src="attachmentItem.url"
                @click="$commonHelpers.previewFile(attachmentItem.id)"
                aspect-ratio="1"
                class="grey lighten-2"
                style="width: 100px; height: 100px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </div>
        </div>
      </v-row>
    </v-list-item-subtitle>
    <vxe-button
      @click="$stockHelpers.openRate(str.data.stock_id, str.data.name, this)"
      size="mini"
      status="my-primary"
      content="查看打分详情"
    ></vxe-button>
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  methods: {
    getScoreColor(item) {
      if (parseInt(item.score) > parseInt(item.last_score)) {
        return "text-red lighten-1 font-weight-bolder";
      } else if (parseInt(item.score) < parseInt(item.last_score)) {
        return "text-teal lighten-1 font-weight-bolder";
      } else {
        return "text-dark lighten-1 font-weight-bolder";
      }
    },
  },
};
</script>
