<template>
  <div>
    <vxe-toolbar class="px-3" custom :refresh="{ query: getUserList }">
      <template v-slot:buttons>
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="全文搜索"
          class="mr-1"
        ></vxe-input>
        <TypeFilter
          colName="type"
          placeholder="账号类型"
          :table="$refs.userTable"
          inputWidth="100px"
          class="mr-1"
        >
        </TypeFilter>
        <StatusFilter
          colName="status"
          placeholder="启用状态"
          :table="$refs.userTable"
          inputWidth="100px"
          class="mr-1"
        >
        </StatusFilter>
        <RoleDesc></RoleDesc>
        <vxe-button
          @click="userAdd()"
          size="medium"
          content="新增用户"
          status="my-primary"
          class="ml-0 mr-1"
        ></vxe-button>
        <vxe-button
          @click="kpiExport()"
          size="medium"
          content="工作报表"
          status="my-primary"
          class="ml-0 mr-1"
        ></vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      id="userTable"
      row-id="id"
      ref="userTable"
      :data="list"
      :border="false"
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      align="center"
      :loading="userTableLoading"
      :column-config="{ resizable: true }"
      :height="tableHeight"
      size="small"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
    >
      <vxe-column width="150" field="name" title="用户名">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.name }}
          </div>
        </template>
      </vxe-column>

      <vxe-column width="150" field="base" title="名称">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.base }}
          </div>
        </template>
      </vxe-column>

      <vxe-column width="150" field="depot" title="代号">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.depot }}
          </div>
        </template>
      </vxe-column>

      <vxe-column width="150" field="contact" title="联系方式">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.contact }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        width="100"
        field="type"
        title="账号类型"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'type')
        "
      >
        <template #default="{ row }">
          <div class="d-flex justify-center">
            <span
              :style="{ color: row.type == '内部' ? '#ffaa00' : '#000000' }"
              >{{ row.type }}</span
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column
        width="100"
        field="status"
        title="启用状态"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'status')
        "
      >
        <template #default="{ row }">
          <div class="d-flex justify-center">
            {{ row.status }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="100"
        field="rolesArr"
        title="角色"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'rolesArr')
        "
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                text-align: start;
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <v-chip
                v-for="(role, index) in row.rolesArr"
                :key="index"
                dense
                small
                dark
                :color="getRoleColor(role)"
                class="mr-1 px-2 py-0"
                style="height: 16px; opacity: 0.8; margin-right: 1px"
              >
                {{ role }}
              </v-chip>
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        title="操作"
        type="seq"
        width="150"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <div class="d-flex justify-center">
            <a
              href="javascript:;"
              @click="editUser(row)"
              class="text-xs font-weight-normal text-typo"
              >修改</a
            >

            <a
              href="javascript:;"
              @click="banUser(row)"
              class="text-xs font-weight-normal text-typo"
              style="margin-left: 5px"
            >
              {{ row.status == "正常" ? "禁用" : "启用" }}
            </a>

            <a
              href="javascript:;"
              @click="deleteUser(row.id)"
              class="text-xs font-weight-normal text-typo"
              style="margin-left: 5px; color: red"
              >删除</a
            >
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Tag from "@/api/tag.js";
import User from "@/api/user.js";
import XEUtils from "xe-utils";
import TypeFilter from "@/components/Admin/userManage/TypeFilter";
import StatusFilter from "@/components/Admin/userManage/StatusFilter";
import UserAdd from "@/components/Admin/userManage/UserAdd";
import UserEdit from "@/components/Admin/userManage/UserEdit";
import RoleDesc from "@/components/Admin/userManage/RoleDesc";
export default {
  components: {
    TypeFilter,
    StatusFilter,
    RoleDesc,
  },
  props: {},
  data() {
    return {
      userTableLoading: false,
      tableData: [],
      tableHeight: "500px",
      filterName: "",
    };
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 140 + "px";
    this.getUserList();
    Bus.$on("refreshUserList", (e) => {
      this.getUserList();
    });
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "base", "depot"];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  methods: {
    getRoleColor(role) {
      if (role == "管理员") {
        return "#f6695e";
      } else if (role == "基金经理") {
        return "#ffaa00";
      } else {
        return "#84a5b8";
      }
    },

    getUserList() {
      this.userTableLoading = true;
      User.getUserList()
        .then((res) => {
          res.data.forEach((item) => {
            item.type = item.type === 1 ? "内部" : "服务机构";
            item.status = item.status === 1 ? "正常" : "禁用";
            let rolesArr = [];
            item.roles.forEach((role) => {
              rolesArr.push(role.name);
            });
            item.rolesArr = rolesArr;
          });
          //console.log(res.data);

          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.userTableLoading = false;
        });
    },
    deleteUser(id) {
      this.$layer.confirm("确定要删除用户吗？", (layerid) => {
        this.showLoading("删除用户中，请稍候");
        User.deleteUser(id)
          .then((res) => {
            this.showToast("已成功删除用户！", "success", 5000);
            Bus.$emit("refreshUserList", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    userAdd() {
      this.$layer.iframe({
        content: {
          content: UserAdd,
          parent: this,
          data: {
            iframeData: {},
          },
        },
        area: ["700px", "700px"],
        title: "新增用户(*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    editUser(row) {
      this.$layer.iframe({
        content: {
          content: UserEdit,
          parent: this,
          data: {
            iframeData: {
              userInfo: { ...row },
            },
          },
        },
        area: ["700px", "700px"],
        title: "修改用户(*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    banUser(row) {
      let toStatus = 1;
      let text = "启用";
      if (row.status == "正常") {
        toStatus = 0;
        text = "禁用";
      }
      this.$layer.confirm(`是否要${text}该用户？`, (layerid) => {
        this.showLoading("操作中，请稍候");
        User.banUser(row.id, toStatus)
          .then((res) => {
            this.showToast(`已成功${text}用户！`, "success", 5000);
            Bus.$emit("refreshUserList", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });

        this.$layer.close(layerid);
      });
    },
    kpiExport() {
      this.showLoading("导出中请稍候");
      Tag.kpiExport()
        .then((res) => {
          window.location = res.data.url;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
<style scoped="scoped"></style>
