<template>
  <div style="width: 100%;">
    <pdf v-for="i in numPages" :key="i" :src="src" :page="i" style="width: 100%;overflow-y: hidden;">
    </pdf>
  </div>
</template>
<script>
  import pdf from 'vue-pdf'
  export default {
    name: "PreviewPdf",
    props: {
      pdfUrl: '', // 这里是pdf文件地址，后台给的流文件请求地址也是可以的
    },
    components: {
      pdf
    },
    data() {
      return {
        numPages: undefined,
        src: pdf.createLoadingTask(this.pdfUrl),
      }
    },
    mounted() {
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages;
      });
    },
    computed: {

    },
    methods: {

    }
  };
</script>
<style>
</style>
