<template>
  <div>
    <v-card
      class="border-radius-md mt-2"
      v-for="(item, index) in noteList"
      :key="index"
      style="margin-top: 10px; border: 1px solid #ccc"
    >
      <div class="border-bottom d-flex align-center px-4 py-1">
        <div class="d-flex align-center">
          <v-avatar size="37" rounded>
            <v-img
              src="@/assets/img/user/stock.png"
              alt="profile-image"
            ></v-img>
          </v-avatar>

          <div class="mx-4">
            <a
              href="javascript:;"
              class="text-dark font-weight-600 text-sm mr-2"
              @click="openNote(item.id)"
            >
              {{ item.title ? item.title : "" }}
            </a>

            <v-chip
              v-for="(race, index) in item.races"
              :key="race.id"
              dense
              small
              dark
              :color="race.color"
              class="px-2 py-0 mr-1"
              style="height: 16px; opacity: 0.8; margin-right: 1px"
              @click="$stockHelpers.openTagDetail(race.id)"
            >
              {{ race.name }}
            </v-chip>

            <small class="d-block text-muted" style="letter-spacing: 2px">
              <span>
                {{ item.user_name }}
              </span>
              {{ item.created_at }}
            </small>
          </div>
        </div>
        <!-- <div class="text-end ms-auto">
          <vxe-button @click="$stockHelpers.openRate(item.stock_id, item.name, this);" size="mini" status="my-primary"
            content="打分详情"></vxe-button>
        </div> -->
      </div>
      <div class="px-5 py-2">
        <EditorDisplay
          :editorId="item.id"
          :editorContent="item.content"
        ></EditorDisplay>
      </div>
    </v-card>
  </div>
</template>
<script>
import Note from "@/api/note.js";
import EditorDisplay from "@/views/Dashboard/EditorDisplay";

export default {
  name: "TagReview",
  props: {
    noteList: [],
  },
  components: {
    EditorDisplay,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openNote(id) {
      this.$router.push({ name: "笔记", params: { id: id } });
    },
  },
};
</script>
<style scoped="scoped"></style>
