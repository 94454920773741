<template>
  <div>
    <LoadingPage
      :visible="tableLoading"
      style="width: 100%; height: 100vh"
    ></LoadingPage>
    <div v-show="!tableLoading">
      <v-card class="border-radius-md pa-2 mb-3 mt-1">
        <div class="px-0 py-1">
          <div>
            <div class="d-flex align-center">
              <h3 class="text-dark" style="opacity: 0.8">
                {{ !isTag ? "全行业轮动图" : "标签轮动图" }}
              </h3>
              <div class="ml-4">
                <vxe-radio-group v-model="fanDays">
                  <vxe-radio :label="5" content="5天"></vxe-radio>
                  <vxe-radio :label="20" content="20天"></vxe-radio>
                </vxe-radio-group>
              </div>
              <vxe-button
                @click="isTag = !isTag"
                size="mini"
                :content="isTag ? '切换到全行业轮动图' : '切换到标签轮动图'"
                status="my-danger"
                class="ml-5"
                style="z-index: 12"
              ></vxe-button>
            </div>
            <FanChart v-if="!isTag" :fanDays="fanDays"></FanChart>
            <FanChartTag v-if="isTag" :fanDays="fanDays"></FanChartTag>
          </div>

          <NHNL></NHNL>

          <RadarTable></RadarTable>
        </div>
      </v-card>
      <div style="color: #ccc; font-size: 12px">
        更新时间：{{ list[0] ? list[0].updated_on : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import ResonanceContrast from "@/api/resonanceContrast.js";
import FanChart from "./FanChart.vue";
import FanChartTag from "./FanChartTag.vue";
import RadarTable from "./RadarTable.vue";
import NHNL from "./NHNL.vue";
export default {
  name: "Radar",
  components: { FanChart, RadarTable, FanChartTag, NHNL },
  data() {
    return {
      tableLoading: false,
      fanChartData: [],
      list: [],
      isTag: true,
      fanDays: 5,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      ResonanceContrast.getRadarTable()
        .then((res) => {
          //console.log(res.data);
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
