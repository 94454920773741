<template>
  <div class="v-application bg-white" style="width: 100%">
    <div
      class="text-dark"
      style="
        font-size: 30px;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      v-if="!getContent()"
    >
      <div class="is-shining">
        <span style="font-weight: bolder">AI</span>
        <span style="font-weight: lighter"> is </span>
        <span style="color: #d80d18; font-weight: bolder">Thinking...</span>
      </div>
    </div>

    <div v-if="getContent()" style="min-height: 100% !important; width: 100%">
      <v-md-preview
        :text="getContent()"
        style="width: 100%; padding: 10px"
      ></v-md-preview>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Ai from "@/api/ai.js";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "AiAnalysis",
  components: {},
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    //console.log(this.iframeData);
    this.submit();
  },
  data() {
    return {
      loading: false,
      responseContent: "",
    };
  },
  methods: {
    async submit() {
      //this.loading = true;
      // 调用ChatGPT API
      const response = await fetch(
        "https://chatgpt.lhotsetech.com/api/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.iframeData.ApiKey,
          },
          body: JSON.stringify({
            chatId: uuidv4(),
            stream: true,
            detail: false,
            messages: [
              {
                content: this.iframeData.content,
                role: "user",
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to get response from ChatGPT API");
        this.responseContent = "Failed to get response from ChatGPT API";
        return;
      }

      // const data = await response.json();
      // const botReply = data.choices[0].message.content;

      // // 添加ChatGPT的回答到聊天窗口
      // this.responseContent = botReply;
      const reader = response.body.getReader();
      let decoder = new TextDecoder();

      // Read the data
      let result = "";
      let resContent = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        let textChunk = decoder.decode(value, { stream: true });
        // 假设每个JSON对象都是独立的一行
        let lines = textChunk.split("\n");
        for (let line of lines) {
          if (line) {
            line = line.replace("data: ", "");
            if (line == "[DONE]") {
              break;
            }
            try {
              let json = JSON.parse(line);
              // 处理解析后的JSON对象...
              //console.log(json);
              if (json.choices[0].delta.content) {
                this.responseContent += json.choices[0].delta.content;
              }
            } catch (e) {
              console.error("Error parsing JSON", e);
            }
          }
        }
      }
    },
    getContent() {
      return this.responseContent;
    },

    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
.is-shining {
  animation: shining 1s ease-in-out infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
